import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  requests: null,
  requestsState: actionTypes.VIEW_STATE.INITIAL,
  requestsStateMsg: "",
  requestsCovid: null,
  requestsCovidState: actionTypes.VIEW_STATE.INITIAL,
  requestsCovidStateMsg: "",
};

const getTrackingRequestStart = (state, action) => {
  return updateObject(state, {
    requests: null,
    requestsState: actionTypes.VIEW_STATE.LOADING,
    requestsStateMsg: "",
  });
};

const getTrackingRequestSuccess = (state, action) => {
  return updateObject(state, {
    requests: action.requests,
    requestsState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getTrackingRequestFailed = (state, action) => {
  return updateObject(state, {
    requestsState: actionTypes.VIEW_STATE.ERROR,
    requestsStateMsg: action.error,
  });
};

const getTrackingRequestCovidStart = (state, action) => {
  return updateObject(state, {
    requestsCovid: null,
    requestsCovidState: actionTypes.VIEW_STATE.LOADING,
    requestsCovidStateMsg: "",
  });
};

const getTrackingRequestCovidSuccess = (state, action) => {
  return updateObject(state, {
    requestsCovid: action.requests_covid,
    requestsCovidState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getTrackingRequestCovidFailed = (state, action) => {
  return updateObject(state, {
    requestsCovidState: actionTypes.VIEW_STATE.ERROR,
    requestsCovidStateMsg: action.error,
  });
};

const clearTrackingState = (state, action) => {
  return initialState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRACKING_REQUEST_START:
      return getTrackingRequestStart(state, action);
    case actionTypes.GET_TRACKING_REQUEST_SUCCESS:
      return getTrackingRequestSuccess(state, action);
    case actionTypes.GET_TRACKING_REQUEST_FAILED:
      return getTrackingRequestFailed(state, action);
    case actionTypes.GET_TRACKING_REQUEST_COVID_START:
      return getTrackingRequestCovidStart(state, action);
    case actionTypes.GET_TRACKING_REQUEST_COVID_SUCCESS:
      return getTrackingRequestCovidSuccess(state, action);
    case actionTypes.GET_TRACKING_REQUEST_COVID_FAILED:
      return getTrackingRequestCovidFailed(state, action);
    case actionTypes.CLEAR_TRACKING_STATE:
      return clearTrackingState(state, action);
    default:
      return state;
  }
};

export default reducer;
