import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../Apply/Apply.css';


const ManageAgreementPayment = (props) => {
    const topic = {
        marginTop: '2rem',
    }
    return (
        <center>
            <Grid item xs={12}>
                <p className="text-bold text-center ask__font__title" style={topic}>ชำระเงิน</p>
            </Grid>
            <br />
            {props.children}

        </center>
    );
}

export default ManageAgreementPayment;
