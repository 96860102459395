import React from 'react';
import './Header.css';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';


const styles = {
    root: {
        background: 'rgba(0,0,0,0)',
        textAlign: 'center',
        height: '100%',
        // top: '35%',
        fontFamily: 'Prompt-Regular !important',
    },
    title: {
        fontSize: '4rem',
        lineHeight: '6rem',
        '@media (max-width: 600px)': {
            fontSize: '3rem',
            lineHeight: '4rem',
        },
        '@media (max-width: 400px)': {
            fontSize: '2rem',
            lineHeight: '2rem',
        },
    },
    subtitle: {
        fontSize: '2rem',
        lineHeight: '2rem'
    }
};

const header = (props) => {
    const { classes } = props;
    return (
        <div className="head" style={props.style} >
            <img className="img_header" src={props.img} alt={props.text} />
            {/* <div style={{ backgroundColor: 'black', opacity: '0.2', height: '100%' }}> */}
            <GridListTileBar className={classes.root}
                title={props.textHeader}
                subtitle={<span>{props.subText}</span>}
                classes={
                    {
                        title: classes.title,
                        subtitle: classes.subtitle
                    }
                }
            />
            {/* </div> */}
        </div>
    );
};

export default withStyles(styles)(header);
