import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from './../Modal/Modal';
import Button from './../Button/Button';
import { Redirect } from 'react-router-dom'

const ErrorModal = (props) => {

    const useStyles = makeStyles(theme => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '0.1rem solid #000',
            borderRadius: '1rem',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));
    const classes = useStyles();


    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
        // close={handleClose}
        >
            <div className={classes.paper}>
                <p className="text-bold text-center ask__font__title">{props.title}</p>
                <br></br>
                <p className="text-center ask__font__main">{props.text}</p>
                <br></br>
                <br></br>
                <center><Button path={props.link} btnType="normal" color={props.color} border={props.border} width="100%" clicked={handleClose}>OK</Button></center>
            </div>

        </Modal>
    );
};

export default ErrorModal;