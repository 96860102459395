import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../../store/action/index";
import * as actionTypes from "../../store/action/actionTypes";
import Loading from "./../../components/UI/Loading/Loading";
import TrackingRequest from "../../components/Tracking/TrackingRequest";

const TrackingRequests = (props) => {
  return (
    <div>
      {props.requests != null && props.requests.length === 0 && (
        <center>
          <p className="ask__font__main">ไม่พบข้อมูล</p>
        </center>
      )}
      {props.requestsState === actionTypes.VIEW_STATE.LOADING && (
        <center>
          <Loading />
        </center>
      )}

      <div className="grid">
        {props.requests != null &&
          props.requests.length > 0 &&
          props.requests.map((request, index) => (
            <div
              className="col-12 sm:col-12 md:col-6 md:col-offset-3 lg:col-6 lg:col-offset-3 
              xl:col-6 xl:col-offset-3 "
              key={"div_req_" + index}
            >
              <TrackingRequest request={request} key={"req" + index} />
            </div>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    requests: state.tracking.requests,
    requestsState: state.tracking.requestsState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingRequests);
