import {
  takeEvery,
  takeLatest,
  all,
  cancel,
  fork,
  cancelled,
} from "redux-saga/effects";

import * as actionTypes from "../action/actionTypes";

import {
  getAgreementsSaga,
  getAgreementDetailSaga,
  getInsuranceAgreementSaga,
  getPaymentAgreementSaga,
  getClosedAgreementDetailSaga,
  clearStateAgreementSaga,
  insertPayDataSaga,
  getPaymentDetailOriginSaga,
} from "./agreement";

import {
  getTrackingRequestSaga,
  getTrackingRequestCovidSaga,
  clearStateTrackingSaga,
} from "./tracking";

import {
  getPaymentItemSaga,
  getFullSummaryPaymentSaga,
  clearStatePaymentSaga,
  generateBatchSaga,
} from "./payment";

import { csFilterSaga } from "./cs";

export function* watchAgreement() {
  yield takeEvery(actionTypes.GET_AGREEMENTS, getAgreementsSaga);
  yield takeEvery(actionTypes.GET_AGREEMENT_DETAIL, getAgreementDetailSaga);
  yield takeEvery(
    actionTypes.GET_INSURANCE_AGREEMENT,
    getInsuranceAgreementSaga
  );
  yield takeEvery(actionTypes.GET_PAYMENT_AGREEMENT, getPaymentAgreementSaga);
  yield takeEvery(
    actionTypes.GET_CLOSED_AGREEMENT_DETAIL,
    getClosedAgreementDetailSaga
  );
  yield takeEvery(actionTypes.AUTH_LOGOUT, clearStateAgreementSaga);
  yield takeEvery(actionTypes.INSERT_PAY_DATA, insertPayDataSaga);
  yield takeEvery(
    actionTypes.GET_PAYMENT_DETAIL_ORIGIN,
    getPaymentDetailOriginSaga
  );

  // yield all([takeEvery(actionTypes.GET_AGREEMENTS, getAgreementsSaga)]);
  // yield all([
  //   takeEvery(actionTypes.GET_AGREEMENT_DETAIL, getAgreementDetailSaga),
  // ]);
  // yield all([
  //   takeEvery(actionTypes.GET_INSURANCE_AGREEMENT, getInsuranceAgreementSaga),
  // ]);
  // yield all([
  //   takeEvery(actionTypes.GET_PAYMENT_AGREEMENT, getPaymentAgreementSaga),
  // ]);
  // yield all([
  //   takeEvery(
  //     actionTypes.GET_CLOSED_AGREEMENT_DETAIL,
  //     getClosedAgreementDetailSaga
  //   ),
  // ]);
  // yield all([takeEvery(actionTypes.AUTH_LOGOUT, clearStateAgreementSaga)]);
  // yield all([takeEvery(actionTypes.INSERT_PAY_DATA, insertPayDataSaga)]);
  // yield all([
  //   takeEvery(
  //     actionTypes.GET_PAYMENT_DETAIL_ORIGIN,
  //     getPaymentDetailOriginSaga
  //   ),
  // ]);
}

export function* watchTracking() {
  yield takeEvery(actionTypes.GET_TRACKING_REQUEST, getTrackingRequestSaga);
  yield takeEvery(
    actionTypes.GET_TRACKING_REQUEST_COVID,
    getTrackingRequestCovidSaga
  );
  yield takeEvery(actionTypes.AUTH_LOGOUT, clearStateTrackingSaga);
  // yield all([
  //   takeEvery(actionTypes.GET_TRACKING_REQUEST, getTrackingRequestSaga),
  // ]);
  // yield all([
  //   takeEvery(
  //     actionTypes.GET_TRACKING_REQUEST_COVID,
  //     getTrackingRequestCovidSaga
  //   ),
  // ]);
  // yield all([takeEvery(actionTypes.AUTH_LOGOUT, clearStateTrackingSaga)]);
}

export function* watchPayment() {
  try {
    yield all([
      takeEvery(actionTypes.GET_PAYMENT_ITEM, getPaymentItemSaga),
      takeEvery(
        actionTypes.GET_FULL_SUMMARY_PAYMENT,
        getFullSummaryPaymentSaga
      ),
      takeEvery(actionTypes.AUTH_LOGOUT, clearStatePaymentSaga),
    ]);
  } finally {
    if (yield cancelled()) {
      console.log("watchPayment: canceled");
    }
  }
  // yield takeEvery(actionTypes.GENERATE_BATCH, generateBatchSaga); //unused

  // yield all([takeEvery(actionTypes.GET_PAYMENT_ITEM, getPaymentItemSaga)]);
  // yield all([
  //   takeEvery(actionTypes.GET_FULL_SUMMARY_PAYMENT, getFullSummaryPaymentSaga),
  // ]);
  // yield all([takeEvery(actionTypes.AUTH_LOGOUT, clearStatePaymentSaga)]);
  // yield all([takeEvery(actionTypes.GENERATE_BATCH, generateBatchSaga)]);
}

export function* watchCs() {
  try {
    yield all([takeEvery(actionTypes.CS_FILTER, csFilterSaga)]);
  } finally {
    if (yield cancelled()) {
      console.log("watchPayment: canceled");
    }
  }
}
