import React, { useState, useEffect } from "react";
import ManageAgreementDescription from "../../components/ManageAgreement/ManageAgreementDescription/ManageAgreementDescription";
import Description from "../../components/ManageAgreement/description";
import PathTree from "../../components/UI/PathTree/PathTree";
import axios from "axios";
import { API_MYASK } from "./../../shared/constant";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import * as actions from "./../../store/action/index";

const ManageAgreementControlsDetail = (props) => {
  let data = "";
  let click = "";
  console.log(props.location);
  const [fin_type] = React.useState(
    props.location.fin_type || sessionStorage.getItem("fin_type") || ""
  );
  const [agr_code] = React.useState(
    props.location.agr_code || sessionStorage.getItem("agr_code") || ""
  );
  const [body] = React.useState(
    props.location.body || sessionStorage.getItem("body") || ""
  );
  const [brand] = React.useState(
    props.location.brand || sessionStorage.getItem("brand") || ""
  );
  const [reg_num] = React.useState(
    props.location.reg_num || sessionStorage.getItem("reg_num") || ""
  );
  const [reg_province] = React.useState(
    props.location.reg_province || sessionStorage.getItem("reg_province") || ""
  );
  const [asset_description] = React.useState(
    props.location.asset_description ||
      sessionStorage.getItem("asset_description") ||
      ""
  );
  const [install] = React.useState(
    props.location.install || sessionStorage.getItem("install") || ""
  );
  const [os_balance] = React.useState(
    props.location.os_balance || sessionStorage.getItem("os_balance") || ""
  );
  const [n_due_i] = React.useState(
    props.location.n_due_i || sessionStorage.getItem("n_due_i") || ""
  );
  const [flat_rate] = React.useState(
    props.location.flat_rate || sessionStorage.getItem("flat_rate") || ""
  );
  const [disbursement_date] = React.useState(
    props.location.disbursement_date ||
      sessionStorage.getItem("disbursement_date") ||
      ""
  );
  const [ar] = React.useState(
    props.location.ar || sessionStorage.getItem("ar") || ""
  );
  const [terms] = React.useState(
    props.location.terms || sessionStorage.getItem("terms") || ""
  );
  const [scan_code] = React.useState(
    props.location.scan_code || sessionStorage.getItem("scan_code") || ""
  );
  const [mobile_no] = React.useState(
    props.location.mobile_no || sessionStorage.getItem("mobile_no") || ""
  );
  const [email] = React.useState(
    props.location.email || sessionStorage.getItem("email") || ""
  );
  const [add1] = React.useState(
    props.location.add1 || sessionStorage.getItem("add1") || ""
  );
  const [add2] = React.useState(
    props.location.add2 || sessionStorage.getItem("add2") || ""
  );
  const [post] = React.useState(
    props.location.post || sessionStorage.getItem("post") || ""
  );

  window.scrollTo(0, 0);
  const [redi, setRedi] = useState(false);
  const [edit, setEdit] = useState(false);

  const onSelectHistory = (
    fin_type,
    agr_code,
    body,
    brand,
    reg_num,
    reg_province,
    asset_description,
    install,
    os_balance,
    n_due_i,
    flat_rate,
    disbursement_date,
    ar,
    terms,
    scan_code
  ) => {
    props.history.push({
      pathname: "/manageagreement-history",
      fin_type: fin_type,
      agr_code: agr_code,
      body: body,
      brand: brand,
      reg_num: reg_num,
      reg_province: reg_province,
      asset_description: asset_description,
      install: install,
      os_balance: os_balance,
      n_due_i: n_due_i,
      flat_rate: flat_rate,
      disbursement_date: disbursement_date,
      ar: ar,
      terms: terms,
      scan_code: scan_code,
    });
  };

  const onSelectInsurance = (
    fin_type,
    agr_code,
    body,
    brand,
    reg_num,
    reg_province,
    asset_description,
    install,
    os_balance,
    n_due_i,
    flat_rate,
    disbursement_date,
    ar,
    terms,
    scan_code
  ) => {
    props.history.push({
      pathname: "/manageagreement-insurance",
      agrtmp: "",
      fin_type: fin_type,
      agr_code: agr_code,
      body: body,
      brand: brand,
      reg_num: reg_num,
      reg_province: reg_province,
      asset_description: asset_description,
      install: install,
      os_balance: os_balance,
      n_due_i: n_due_i,
      flat_rate: flat_rate,
      disbursement_date: disbursement_date,
      ar: ar,
      terms: terms,
      scan_code: scan_code,
    });
  };

  useEffect(() => {
    let mount = true;

    if (mount == true) {
      sessionStorage.setItem("agrtmp", "");
      sessionStorage.setItem("fin_type", fin_type);
      sessionStorage.setItem("agr_code", agr_code);
      sessionStorage.setItem("body", body);
      sessionStorage.setItem("brand", brand);
      sessionStorage.setItem("reg_num", reg_num);
      sessionStorage.setItem("reg_province", reg_province);
      sessionStorage.setItem("asset_description", asset_description);
      sessionStorage.setItem("install", install);
      sessionStorage.setItem("os_balance", os_balance);
      sessionStorage.setItem("n_due_i", n_due_i);
      sessionStorage.setItem("flat_rate", flat_rate);
      sessionStorage.setItem("disbursement_date", disbursement_date);
      sessionStorage.setItem("ar", ar);
      sessionStorage.setItem("terms", terms);
      sessionStorage.setItem("scan_code", scan_code);
      sessionStorage.setItem("mobile_no", mobile_no);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("add1", add1);
      sessionStorage.setItem("add2", add2);
      sessionStorage.setItem("post", post);
    }

    return () => {
      mount = false;
    };
  }, [agr_code]);

  const onSelectQRCode = (agr_code, scan_code) => {
    props.history.push({
      pathname: "/manageagreement-paymentlist",
      agr_code: agr_code,
      scan_code: scan_code,
    });
  };

  let redirectpage;
  if (redi === true) {
    redirectpage = <Redirect to="/login" />;
  }

  const path = {
    Path: [
      {
        title: "ข้อมูลสินเชื่อของฉัน",
        pathLink: "/manageagreement",
      },
      {
        title: agr_code,
        pathLink: "/manageagreement-detail",
      },
    ],
  };

  const [manageAgrForm2, setManageAgrForm2] = useState([]);

  const formElementsArray2 = [];
  for (let key in manageAgrForm2) {
    formElementsArray2.push({
      id: key,
      config: manageAgrForm2[key],
    });
  }

  const [manageAgrForm] = useState([
    {
      fin_type: fin_type,
      agr_code: agr_code,
      body: body,
      brand: brand,
      reg_num: reg_num,
      reg_province: reg_province,
      asset_description: asset_description,
      install: install,
      os_balance: os_balance,
      n_due_i: n_due_i,
      flat_rate: flat_rate,
      disbursement_date: disbursement_date,
      ar: ar,
      terms: terms,
      scan_code: scan_code,
      mobile_no: mobile_no,
      email: email,
      add1: add1,
      add2: add2,
      post: post,
    },
  ]);

  const formElementsArray = [];
  for (let key in manageAgrForm) {
    formElementsArray.push({
      id: key,
      config: manageAgrForm[key],
    });
  }

  const clickEdit = () => {
    if (!edit) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  };

  return (
    <PathTree list={path.Path}>
      {redirectpage}
      <div className="ask__font__main">
        <ManageAgreementDescription
          children={formElementsArray.map((formElement) => (
            <Description
              key={formElement.id}
              number={formElement.config.agr_code}
              cartype={formElement.config.body}
              carbrand={formElement.config.brand}
              description={formElement.config.asset_description}
              carnumber={formElement.config.reg_num}
              carcountry={formElement.config.reg_province}
              type={formElement.config.fin_type}
              interest={formElement.config.flat_rate}
              firstdate={formElement.config.disbursement_date}
              total={formElement.config.ar}
              term={formElement.config.terms}
              pricepermonth={formElement.config.install}
              balance={formElement.config.os_balance}
              date={formElement.config.n_due_i}
              mobile_no={formElement.config.mobile_no}
              email={formElement.config.email}
              add1={formElement.config.add1}
              add2={formElement.config.add2}
              post={formElement.config.post}
              edit={edit}
              btnedit={() => clickEdit()}
              onSelectNumber={() =>
                onSelectInsurance(
                  formElement.fin_type,
                  formElement.agr_code,
                  formElement.body,
                  formElement.brand,
                  formElement.reg_num,
                  formElement.reg_province,
                  formElement.asset_description,
                  formElement.install,
                  formElement.os_balance,
                  formElement.n_due_i,
                  formElement.flat_rate,
                  formElement.disbursement_date,
                  formElement.ar,
                  formElement.terms,
                  formElement.scan_code
                )
              }
              onSelectHistory={() =>
                onSelectHistory(
                  formElement.fin_type,
                  formElement.agr_code,
                  formElement.body,
                  formElement.brand,
                  formElement.reg_num,
                  formElement.reg_province,
                  formElement.asset_description,
                  formElement.install,
                  formElement.os_balance,
                  formElement.n_due_i,
                  formElement.flat_rate,
                  formElement.disbursement_date,
                  formElement.ar,
                  formElement.terms,
                  formElement.scan_code
                )
              }
            />
          ))}
        ></ManageAgreementDescription>
      </div>
    </PathTree>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAgreementControlsDetail);
