import React, { useState, useEffect } from "react";
import ApplyForm from "../../components/Apply/ApplyForm/ApplyForm";
import axios from "axios";
import { connect } from "react-redux";
import {
  updateObject,
  checkValidity,
  checkError,
} from "./../../shared/utility";
// import { Redirect } from "react-router-dom";
import { API_MYASK, API_LINE } from "./../../shared/constant";
import ModalError from "./../../components/UI/Error/Error";

// import * as actions from "./../../store/action/index";

const ApplyProduct = (props) => {
  const [productvalue] = useState(
    props.location.product_id || sessionStorage.getItem("product_id") || ""
  );
  const [firstName] = useState(props.firstName || "");
  const [lastName] = useState(props.lastName || "");

  const label = {
    color: "#000",
    width: "50%",
  };

  const [reren, setReren] = useState("");

  const [applyForm, setApplyForm] = useState({
    form: {
      name: {
        order: "0",
        label: "ชื่อ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "ชื่อ",
        },
        validation: {
          required: true,
        },
        display: true,
        value: firstName,
        valid: false,
        touched: false,
        styleLabel: label,
        labelError: "ชื่อ",
        textInvalid: "",
      },
      lastname: {
        order: "1",
        label: "นามสกุล:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "นามสกุล",
        },
        display: true,
        value: lastName,
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        labelError: "นามสกุล",
        textInvalid: "",
      },
      tel: {
        order: "2",
        label: "เบอร์มือถือ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "เบอร์มือถือ",
        },
        type: "number",
        mode: "numeric",
        display: true,
        value: "",
        maxLength: 10, //ใช้ของ input html
        validation: {
          required: true,
          isNumeric: true,
          minLength: 10, //ใช้ของ utility เพราะต้องการดัก spacebar
          maxLength: 10,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        labelError: "เบอร์มือถือ",
        textInvalid: "",
      },
      email: {
        order: "3",
        label: "อีเมล:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "อีเมล",
        },
        display: true,
        value: "",
        validation: {
          required: false,
          isEmail: true,
        },
        isEmail: false,
        valid: true,
        touched: false,
        styleLabel: label,
        textInvalid: "",
      },
      time: {
        order: "4",
        label: "เวลาที่สะดวกให้ติดต่อกลับ:",
        labelPos: "left",
        elementType: "select",
        elementConfig: {
          options: [{ value: "", displayValue: "กรุณาเลือก" }],
        },
        display: true,
        value: "",
        validation: {},
        valid: true,
        touched: true,
        styleLabel: label,
        textInvalid: "",
        // styleInput: select,
      },
      type: {
        order: "5",
        label: "ประเภทสินเชื่อ:",
        labelPos: "left",
        elementType: "select",
        elementConfig: {
          options: [{ value: "", displayValue: "กรุณาเลือก" }],
        },
        display: true,
        value: productvalue,
        validation: {},
        valid: true,
        touched: true,
        styleLabel: label,
        textInvalid: "",
        // styleInput: select,
      },
      cartype: {
        order: "6",
        label: "ประเภทรถ:",
        labelPos: "left",
        elementType: "select",
        elementConfig: {
          options: [{ value: "", displayValue: "กรุณาเลือก" }],
        },
        display: false,
        value: "",
        validation: {},
        valid: true,
        touched: true,
        styleLabel: label,
        // styleInput: { display: "none" },
        textInvalid: "",
        // styleInput: select,
      },
      caryear: {
        order: "7",
        label: "ปีที่ซื้อรถ (พ.ศ.):",
        labelPos: "left",
        elementType: "select",
        elementConfig: {
          options: [{ value: "", displayValue: "กรุณาเลือก" }],
        },
        display: false,
        value: "",
        validation: {},
        valid: true,
        touched: true,
        styleLabel: label,
        // styleInput: { display: "none" },
        textInvalid: "",
        // styleInput: select,
      },
      limit: {
        order: "8",
        label: "วงเงินที่ต้องการ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "number",
          placeholder: "วงเงินที่ต้องการ",
        },
        type: "cash",
        mode: "numeric",
        display: true,
        value: "",
        validation: {
          isNumeric: true,
        },
        valid: true,
        touched: true,
        styleLabel: label,
        textInvalid: "",
      },
      description: {
        order: "9",
        label: "รายละเอียดเพิ่มเติม:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder:
            "อาทิ ยี่ห้อ รุ่นรถ และปีรถ เพื่อแจ้งเป็นข้อมูลเบื้องต้น",
        },
        display: true,
        value: "",
        validation: {},
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
      },
    },
    formIsValid: false,
  });

  const onChangeTel = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    if (isValid || val === "") {
      return val;
    } else {
      return val.substring(val.length - 1, 0);
    }
  };

  const inputChangedHandler = (event, field) => {
    if (
      (event === "CML" && field === "type") ||
      (event === "PL" && field === "type") ||
      (event === "" && field === "type")
    ) {
      applyForm.form["cartype"].display = false;
      applyForm.form["caryear"].display = false;
    } else if (
      (event === "HP-NEW" && field === "type") ||
      (event === "HP-USED" && field === "type") ||
      (event === "SHB" && field === "type") ||
      (event === "CL" && field === "type")
    ) {
      applyForm.form["cartype"].display = true;
      applyForm.form["caryear"].display = true;
    }
    if (field === "tel") {
      event = onChangeTel(event);
    }
    // if (field === "limit") {
    //   event = onChangeCash(event);
    // }
    const validate = checkValidity(event, applyForm.form[field].validation);
    const text = checkError(
      validate.code,
      applyForm.form[field].labelError,
      applyForm.form[field].validation
    );

    let updatedFormElement;
    if (field === "email") {
      //formIsValid : true
      if (event === "") {
        updatedFormElement = updateObject(applyForm.form[field], {
          value: event,
          valid: validate.isValid,
          textInvalid: text,
          touched: false,
          isEmail: false,
        });
      } else {
        updatedFormElement = updateObject(applyForm.form[field], {
          value: event,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
          isEmail: true,
        });
      }
    } else {
      updatedFormElement = updateObject(applyForm.form[field], {
        value: event,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });
    }
    let updatedForm = updateObject(applyForm.form, {
      [field]: updatedFormElement,
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    const updatedState = updateObject(applyForm, {
      form: updatedForm,
      formIsValid: formIsValid,
    });
    setApplyForm(updatedState);
  };

  const checkedValidate = () => {
    if (
      applyForm.form["type"].value === "PL" ||
      applyForm.form["type"].value === "CML"
    ) {
      applyForm.form["cartype"].valid = true;
      applyForm.form["caryear"].valid = true;
    }
    if (
      applyForm.form["email"].valid === true &&
      applyForm.form["name"].valid === true &&
      applyForm.form["lastname"].valid === true &&
      applyForm.form["tel"].valid === true
    ) {
      applyForm.formIsValid = true;
    }
    if (!applyForm.formIsValid) {
      window.scrollTo(0, 450);
      let updatedForm;
      let arrayObj = [];
      let updatedFormElement;
      let text;
      for (let field in applyForm.form) {
        const validate = checkValidity(
          applyForm.form[field].value,
          applyForm.form[field].validation
        );
        text = checkError(
          validate.code,
          applyForm.form[field].labelError,
          applyForm.form[field].validation
        );
        updatedFormElement = updateObject(applyForm.form[field], {
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
        arrayObj.push(updatedFormElement);
      }
      const arrayApply = [];
      for (let i in arrayObj) {
        if (arrayObj[i].order === "0") {
          arrayApply[0] = arrayObj[i];
        } else if (arrayObj[i].order === "1") {
          arrayApply[1] = arrayObj[i];
        } else if (arrayObj[i].order === "2") {
          arrayApply[2] = arrayObj[i];
        } else if (arrayObj[i].order === "3") {
          arrayApply[3] = arrayObj[i];
        } else if (arrayObj[i].order === "4") {
          arrayApply[4] = arrayObj[i];
        } else if (arrayObj[i].order === "5") {
          arrayApply[5] = arrayObj[i];
        } else if (arrayObj[i].order === "6") {
          arrayApply[6] = arrayObj[i];
        } else if (arrayObj[i].order === "7") {
          arrayApply[7] = arrayObj[i];
        } else if (arrayObj[i].order === "8") {
          arrayApply[8] = arrayObj[i];
        } else if (arrayObj[i].order === "9") {
          arrayApply[9] = arrayObj[i];
        }
      }
      updatedForm = updateObject(applyForm.form, {
        name: arrayApply[0],
        lastname: arrayApply[1],
        tel: arrayApply[2],
        email: arrayApply[3],
        time: arrayApply[4],
        type: arrayApply[5],
        cartype: arrayApply[6],
        caryear: arrayApply[7],
        limit: arrayApply[8],
        description: arrayApply[9],
      });
      let updatedState = updateObject(applyForm, {
        form: updatedForm,
      });

      setApplyForm(updatedState);
    } else {
      sendData();
    }
  };

  const [loading, setLoading] = useState(false);
  const [loadPage, setLoadPage] = useState(true);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const sendData = () => {
    setLoading(true);
    let apply;
    if (props.lineId !== null) {
      apply = "LINE";
    } else if (
      sessionStorage.getItem("src") ===
      "mOZl4zuJDIV9CbjgwaaeyabZZrPGhyyC9ZVKhrSzFA0oZ6mTJPJeaRyc6F"
    ) {
      apply = "MYCAR";
    } else {
      apply = "WEB";
    }

    const data = {
      FIRST_NAME: applyForm.form.name.value,
      LAST_NAME: applyForm.form.lastname.value,
      MOBILE_NO: applyForm.form.tel.value,
      EMAIL: applyForm.form.email.value,
      CONTRACT_TIME: applyForm.form.time.value,
      PRODUCT: applyForm.form.type.value,
      CAR_TYPE: applyForm.form.cartype.value,
      YEAR: applyForm.form.caryear.value,
      AMOUNT: applyForm.form.limit.value,
      INFORMATION: applyForm.form.description.value,
      LINE_ID: props.lineId,
      APPLY: apply,
    };

    let cartypevalue;
    let producttypevalue;
    let timevalue;
    let caryearvalue;
    let amountvalue;
    let emailvalue;

    if (applyForm.form.limit.value === "") {
      amountvalue = "-";
    } else {
      amountvalue = numberWithCommas(data.AMOUNT);
    }

    if (applyForm.form.email.value === "") {
      emailvalue = "-";
    } else {
      emailvalue = applyForm.form.email.value;
    }

    if (applyForm.form.caryear.value === "") {
      caryearvalue = "-";
    } else {
      caryearvalue = applyForm.form.caryear.value;
    }

    for (let i in applyForm.form.cartype.elementConfig.options) {
      if (
        applyForm.form.cartype.elementConfig.options[i].value ===
          applyForm.form.cartype.value &&
        applyForm.form.cartype.value !== ""
      ) {
        cartypevalue =
          applyForm.form.cartype.elementConfig.options[i].displayValue;
      } else if (applyForm.form.cartype.value === "") {
        cartypevalue = "-";
      }
    }
    for (let i in applyForm.form.type.elementConfig.options) {
      if (
        applyForm.form.type.elementConfig.options[i].value ===
          applyForm.form.type.value &&
        applyForm.form.type.value !== ""
      ) {
        producttypevalue =
          applyForm.form.type.elementConfig.options[i].displayValue;
      } else if (applyForm.form.type.value === "") {
        producttypevalue = "-";
      }
    }
    for (let i in applyForm.form.time.elementConfig.options) {
      if (
        applyForm.form.time.elementConfig.options[i].value ===
          applyForm.form.time.value &&
        applyForm.form.time.value !== ""
      ) {
        timevalue = applyForm.form.time.elementConfig.options[i].displayValue;
      } else if (applyForm.form.time.value === "") {
        timevalue = "-";
      }
    }
    axios
      .post(API_MYASK + "/Customer_Apply", data)
      .then((res) => {
        const mobile = data.MOBILE_NO;
        const amount = amountvalue;
        const firstName = data.FIRST_NAME;
        const lastName = data.LAST_NAME;
        // const email = emailvalue;
        const product = producttypevalue;
        const carType = cartypevalue;
        const year = caryearvalue;
        const contractTime = timevalue;
        const date = new Date().toLocaleDateString("en-GB").split(" ");
        const time = new Date().toLocaleTimeString(); //date[1];
        var mdy = date[0];
        mdy = mdy.split("/");
        const month = parseInt(mdy[1]);
        const day = parseInt(mdy[0]);
        const years = parseInt(mdy[2]);
        const formattedDate = day + "." + month + "." + years + " " + time;
        props.history.push({
          pathname: "/success",
          title: "การสมัครเสร็จสมบูรณ์",
          path: "/",
          text: "ขอบคุณที่ให้ความไว้วางใจกับทางบริษัท",
          // text2: 'ท่านสามารถติดตามผลได้ทาง...'
        });

        const dataLine = {
          events: [
            {
              message: {
                text: "confirmed",
                data: {
                  to: props.lineId,
                  messages: [
                    {
                      type: "flex",
                      altText: "สนใจสมัคร",
                      contents: {
                        type: "bubble",
                        direction: "ltr",
                        body: {
                          type: "box",
                          layout: "vertical",
                          contents: [
                            {
                              type: "box",
                              layout: "baseline",
                              contents: [
                                {
                                  type: "text",
                                  text: "สนใจสมัคร",
                                  flex: 0,
                                  size: "lg",
                                  weight: "bold",
                                  color: "#22648A",
                                },
                              ],
                            },
                            {
                              type: "box",
                              layout: "baseline",
                              contents: [
                                {
                                  type: "text",
                                  text: "฿" + amount,
                                  flex: 4,
                                  size: "xxl",
                                  weight: "bold",
                                },
                                {
                                  type: "text",
                                  text: "วงเงิน",
                                  flex: 0,
                                  align: "end",
                                  color: "#999395",
                                },
                              ],
                            },
                            {
                              type: "box",
                              layout: "vertical",
                              contents: [
                                {
                                  type: "text",
                                  text: formattedDate,
                                  flex: 10,
                                  size: "xxs",
                                  align: "start",
                                  color: "#B4B2B2",
                                },
                                { type: "spacer" },
                              ],
                            },
                            {
                              type: "box",
                              layout: "baseline",
                              contents: [
                                {
                                  type: "text",
                                  text: "คุณ" + firstName + " " + lastName,
                                  flex: 4,
                                  size: "lg",
                                  align: "start",
                                  weight: "bold",
                                  color: "#444444",
                                },
                              ],
                            },
                            {
                              type: "separator",
                              margin: "sm",
                              color: "#B4B2B2",
                            },
                            {
                              type: "box",
                              layout: "vertical",
                              contents: [{ type: "spacer", size: "md" }],
                            },
                            {
                              type: "text",
                              text: mobile,
                              flex: 4,
                              size: "md",
                              align: "start",
                              color: "#444444",
                            },
                            {
                              type: "text",
                              text: product,
                              flex: 4,
                              size: "md",
                              weight: "bold",
                              color: "#1A7BDC",
                            },
                            {
                              type: "text",
                              text: carType,
                              flex: 4,
                              size: "md",
                              color: "#1A7BDC",
                            },
                            {
                              type: "box",
                              layout: "baseline",
                              contents: [
                                {
                                  type: "text",
                                  text: "ปีที่ซื้อรถ",
                                  flex: 4,
                                  size: "md",
                                  color: "#B4B2B2",
                                },
                                {
                                  type: "text",
                                  text: year,
                                  flex: 5,
                                  align: "end",
                                  color: "#444444",
                                },
                              ],
                            },
                            {
                              type: "box",
                              layout: "baseline",
                              contents: [
                                {
                                  type: "text",
                                  text: "เวลาที่สะดวก",
                                  flex: 4,
                                  color: "#B4B2B2",
                                },
                                {
                                  type: "text",
                                  text: contractTime,
                                  flex: 5,
                                  align: "end",
                                  color: "#444444",
                                },
                              ],
                            },
                            {
                              type: "separator",
                              margin: "md",
                              color: "#B4B2B2",
                            },
                            {
                              type: "box",
                              layout: "vertical",
                              contents: [{ type: "spacer", size: "md" }],
                            },
                            {
                              type: "text",
                              text: "ขอขอบคุณที่วางใจใช้บริการ ASK",
                              size: "md",
                              align: "center",
                              gravity: "center",
                              weight: "bold",
                              color: "#000000",
                              wrap: true,
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              },
            },
          ],
        };

        if (props.lineId !== null && applyForm.form.limit.value !== "") {
          axios
            .post(API_LINE, dataLine, {
              headers: props.lineHeader,
            })
            .then((res) => {});
        }
      })
      .catch((err) => {
        setReren(
          <ModalError
            title="เกิดข้อผิดพลาด"
            text="กรุณาทำรายการใหม่ในภายหลัง"
            color="red"
            border="0.1rem solid red"
            link="/forgotusername"
          ></ModalError>
        );
      });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    checkedValidate();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      props.match.params.product === "commercial-loan" ||
      props.match.params.product === "personal-loan" ||
      !props.match.params.product
    ) {
      applyForm.form["cartype"].display = false;
      applyForm.form["caryear"].display = false;
    } else {
      applyForm.form["cartype"].display = true;
      applyForm.form["caryear"].display = true;
    }
    if (props.match.params.product === "hire-purchase-new") {
      sessionStorage.setItem("product_id", "HP-NEW");
    } else if (props.match.params.product === "hire-purchase-used") {
      sessionStorage.setItem("product_id", "HP-USED");
    } else if (props.match.params.product === "sale-and-hire-purchase-back") {
      sessionStorage.setItem("product_id", "SHB");
    } else if (props.match.params.product === "car-loan") {
      sessionStorage.setItem("product_id", "CL");
    } else if (props.match.params.product === "personal-loan") {
      sessionStorage.setItem("product_id", "PL");
    } else if (props.match.params.product === "commercial-loan") {
      sessionStorage.setItem("product_id", "CML");
    }
    let updateState = "";
    let updateForm = "";
    let updateProducts = "";
    let updateTime = "";
    let updateYear = "";
    let updateCar = "";
    let username = "";
    let token = "";

    if (sessionStorage.getItem("userName")) {
      username = sessionStorage.getItem("userName");
    }
    if (sessionStorage.getItem("token")) {
      token = sessionStorage.getItem("token");
    }

    axios
      .get(API_MYASK + "/Apply?username=" + username + "&token=" + token)
      .then((res) => {
        let time = applyForm.form.time.elementConfig.options;
        let car = applyForm.form.cartype.elementConfig.options;
        let year = applyForm.form.caryear.elementConfig.options;
        let products = applyForm.form.type.elementConfig.options;
        axios.get(API_MYASK + "/Products").then((res2) => {
          for (let i in res2.data.LIST_DATA) {
            products.push({
              value: res2.data.LIST_DATA[i].product_id,
              displayValue: res2.data.LIST_DATA[i].name_th,
            });
          }
          for (let i in res.data.DATA.TIME) {
            time.push({
              value: res.data.DATA.TIME[i].val_1,
              displayValue: res.data.DATA.TIME[i].val_2,
            });
          }
          for (let i in res.data.DATA.CAR) {
            car.push({
              value: res.data.DATA.CAR[i].val_1,
              displayValue: res.data.DATA.CAR[i].val_2,
            });
          }
          for (let i in res.data.DATA.YEAR) {
            year.push({
              value: res.data.DATA.YEAR[i].val_1,
              displayValue: res.data.DATA.YEAR[i].val_1,
            });
          }

          updateProducts = updateObject(applyForm.form.type, {
            value: sessionStorage.getItem("product_id"),
            elementConfig: updateObject(applyForm.form.type.elementConfig, {
              options: products,
            }),
          });
          updateTime = updateObject(applyForm.form.time, {
            elementConfig: updateObject(applyForm.form.time.elementConfig, {
              options: time,
            }),
          });
          updateCar = updateObject(applyForm.form.cartype, {
            elementConfig: updateObject(applyForm.form.cartype.elementConfig, {
              options: car,
            }),
          });
          updateYear = updateObject(applyForm.form.caryear, {
            elementConfig: updateObject(applyForm.form.caryear.elementConfig, {
              options: year,
            }),
          });
          if (username !== "" && token !== "") {
            let updateName = updateObject(applyForm.form.name, {
              value: res.data.DATA.FirstName,
            });
            let updateLastName = updateObject(applyForm.form.lastname, {
              value: res.data.DATA.LastName,
            });
            updateForm = updateObject(applyForm.form, {
              time: updateTime,
              type: updateProducts,
              cartype: updateCar,
              caryear: updateYear,
              name: updateName,
              lastname: updateLastName,
            });
          } else {
            updateForm = updateObject(applyForm.form, {
              time: updateTime,
              type: updateProducts,
              cartype: updateCar,
              caryear: updateYear,
            });
          }
          updateState = updateObject(applyForm, {
            form: updateForm,
          });

          setLoadPage(false);
          setApplyForm(updateState);
        });
      });
    return;
  }, []);

  const formElementsArray = [];
  for (let key in applyForm.form) {
    if (applyForm.form[key].display === true) {
      formElementsArray[applyForm.form[key].order] = {
        id: key,
        config: applyForm.form[key],
      };
    }
  }
  return (
    <div>
      {reren}
      <ApplyForm
        {...props}
        formElementsArray={formElementsArray}
        loadPage={loadPage}
        loading={loading}
        inputChangedHandler={inputChangedHandler}
        submitHandler={submitHandler}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lineId: state.line.userId,
    lineHeader: state.line.lineHeader,
    firstName: state.custInfo.firstName,
    lastName: state.custInfo.lastName,
  };
};

export default connect(mapStateToProps, null)(ApplyProduct);
