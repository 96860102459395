import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./newotp.css";
import { Dialog } from "primereact/dialog";

import Loading from "../../components/UI/Loading/Loading";
import { API_MYASK, API_MYASK_HEADER } from "../../shared/constant";
import styled from "styled-components";

const RadioBoxContainer = styled.div`
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: #ff9600;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #4b4b4b;
    background: #ff9600;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #4b4b4b;
    background: #ff9600;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #ff9600;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem #ffc898;
  }
`;
const NewOTP = (props) => {
  const [mobileNumberType, setMobileNumberType] = useState("");
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [existMobileNumber, setExistMobileNumber] = useState("");
  const [OTPInput, setOTPInput] = useState("");
  const [isLoadingMobileNumber, setIsLoadingMobileNumber] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [refOtp, setRefOtp] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  useEffect(() => {
    getMobileNumber();
  }, []);

  const getMobileNumber = () => {
    setIsLoadingMobileNumber(true);

    axios
      .post(
        API_MYASK + "/getMobileNumber",
        {
          id_card: props.idcard,
        },
        {
          headers: API_MYASK_HEADER,
        }
      )
      .then((res) => {
        setIsLoadingMobileNumber(false);
        if (res.data.CODE == "200") {
          setExistMobileNumber(res.data.DATA);
        }
      })
      .catch((err) => {
        setIsLoadingMobileNumber(false);
      });
  };

  const requestOTP = () => {
    let valid = validRequestOTP();
    if (valid) {
      setIsSendingOtp(true);

      let mobileNumberForSend = "";

      if (mobileNumberType === "new_number") {
        mobileNumberForSend = newMobileNumber;
      } else if (mobileNumberType === "exist_number") {
        mobileNumberForSend = existMobileNumber;
      }
      setMobileNumber(mobileNumberForSend);
      axios
        .post(
          API_MYASK + "/sendMobileOTP",
          {
            id_card: mobileNumberType === "new_number" ? "" : props.idcard,
            mobile_type: mobileNumberType === "new_number" ? "2" : "1",
            mobile_no: mobileNumberType === "new_number" ? newMobileNumber : "",
          },
          {
            headers: API_MYASK_HEADER,
          }
        )
        .then((res) => {
          setIsSendingOtp(false);
          if (res.data.CODE == "200") {
            setRefOtp(res.data.DATA);
          } else {
            setErrorMsg(res.data.MSG);
          }
        })
        .catch((err) => {
          setIsSendingOtp(false);
          setErrorMsg(err.message);
        });
    }
  };

  const validRequestOTP = () => {
    if (mobileNumberType == "") {
      setErrorMsg("โปรดเลือกเบอร์โทรศัพท์ที่จะรับ OTP");
      return false;
    }

    if (
      props.mode == "regis" &&
      mobileNumberType == "new_number" &&
      newMobileNumber == ""
    ) {
      setErrorMsg("โปรดกรอกเลขเบอร์โทรศัพท์ที่จะรับ OTP");
      return false;
    }

    return true;
  };

  const submitHandler = () => {
    let valid = validRequestOTP();

    if (valid) {
      if (OTPInput.length == 0) {
        setErrorMsg("โปรดระบุ OTP ที่ได้รับ");
        return;
      }
      if (props.onSubmit != null) {
        props.onSubmit(mobileNumber, refOtp, OTPInput);
      }
    }
  };

  return (
    <div className="ask__font__main">
      <Dialog visible={isSendingOtp} style={{ width: "60vw" }}>
        <center>
          <Loading />
        </center>
      </Dialog>
      <Dialog
        visible={errorMsg != ""}
        style={{ width: "80vw" }}
        onHide={() => setErrorMsg("")}
        dismissableMask
      >
        <center>
          <p className="mt-4" style={{ color: "red", fontSize: "1.8rem" }}>
            {errorMsg}
          </p>
          <div className="mt-6">
            <Button label="ตกลง" onClick={() => setErrorMsg("")} autoFocus />
          </div>
        </center>
      </Dialog>
      <Button className="p-button-text" onClick={() => props.goback()}>
        <span style={{ color: "#ff9600" }}> {"< ย้อนกลับ"}</span>
      </Button>
      <div className="flex flex-column mt-6">
        <h4>เลือกเบอร์โทรศัพท์เพื่อขอรับ OTP</h4>

        {isLoadingMobileNumber && (
          <center className="mt-5">
            <Loading />
          </center>
        )}
        {!isLoadingMobileNumber && (
          <Fragment>
            <div>
              {existMobileNumber != "" && (
                <RadioBoxContainer className="flex flex-row mt-5">
                  <RadioButton
                    value="exist_number"
                    name="mobileNumerType"
                    onChange={(e) => setMobileNumberType(e.value)}
                    checked={mobileNumberType === "exist_number"}
                  />
                  <div htmlFor="exist_number" className="ml-4">
                    เบอร์โทรศัพท์ที่แจ้งไว้ {existMobileNumber}
                  </div>
                </RadioBoxContainer>
              )}

              {props.mode == "regis" && (
                <RadioBoxContainer className="flex flex-row mt-5">
                  <RadioButton
                    value="new_number"
                    name="mobileNumerType"
                    onChange={(e) => setMobileNumberType(e.value)}
                    checked={mobileNumberType === "new_number"}
                  />
                  <div htmlFor="new_number" className="ml-4">
                    ระบุเบอร์โทรศัพท์ใหม่
                    <input
                      className="input__element"
                      style={{ fontSize: "1.6rem" }}
                      value={newMobileNumber}
                      pattern="\d*"
                      maxLength={10}
                      onChange={(e) => setNewMobileNumber(e.target.value)}
                    />
                  </div>
                </RadioBoxContainer>
              )}

              <div className="mt-6">
                <Button
                  className="p-button-outlined"
                  label="ขอรหัส OTP"
                  onClick={requestOTP}
                />
              </div>

              <div className="mt-6">
                ระบุ OTP ที่ได้รับ
                {refOtp != "" && (
                  <p className="text-bold">
                    Ref: {refOtp}
                    <span style={{ color: "red" }}>
                      {" "}
                      (รหัสจะหมดอายุภายใน 5 นาที)
                    </span>
                  </p>
                )}
                <div className="p-inputgroup">
                  <input
                    className="input__element"
                    style={{ fontSize: "1.6rem" }}
                    value={OTPInput}
                    onChange={(e) => setOTPInput(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <div className="mt-4">
          {!isLoadingMobileNumber ? (
            <button
              className="btn-submit"
              style={{ fontFamily: "Prompt-Regular" }}
              onClick={submitHandler}
            >
              ตกลง
            </button>
          ) : (
            <button
              className="btn-submit"
              style={{
                fontFamily: "Prompt-Regular",
                backgroundColor: "#ccc",
                cursor: "no-drop",
              }}
              disabled={!isLoadingMobileNumber}
            >
              ตกลง
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewOTP;
