import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '../UI/Paper/Paper';
import './Step.css'
import Stepper from '../UI/Steppers/Stepper';

const step = (props) => {
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <Grid item xs={12} sm={12} md={6} >
            <center>
                <Paper margin="1rem" classes="width100" borderRadius="1rem" padding="1.5rem" align="left"
                    children={
                        <Grid container spacing={2}>

                            <Grid item xs={6}>
                                <p className="text-bold">{props.ref_code}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-bold text-right">{props.regnum}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="hline" />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <center><img src={props.step} style={{ width: '70%' }} alt="" /></center> */}
                                <Stepper status={props.step}></Stepper>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="ask__font__main text-bold">{props.fin_type}</p>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <p className="ask__font__main text-bold">ยอดสินเชื่อ: {numberWithCommas(parseFloat(Math.round(props.amount * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <p className="ask__font__main text-bold">จำนวนงวดทั้งหมด: {props.terms} งวด</p>
                            </Grid>
                            {props.cartype ?
                                <Grid item md={12} sm={12} xs={12}>
                                    <p>{props.cartype}</p>
                                </Grid> : <p></p>}
                            {props.carbrand ?
                                <Grid item md={12} sm={12} xs={12}>
                                    <p>{props.carbrand} - {props.description}</p>
                                </Grid> : <p></p>}
                        </Grid>}

                />
            </center>
        </Grid>
    );
};

export default step;