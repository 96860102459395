import React from "react";
import { Card } from "primereact/card";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { numberWithCommas } from "../../../../../shared/utility";

const History = (props) => {
  const { history } = props;

  return (
    <Card
      style={{
        color: "#000000de",
        border: "1px solid",
        borderRadius: "3px",
        fontFamily: "Prompt-Regular",
      }}
    >
      <div className="grid ask__font__main">
        <div className="col-12">
          <p>{history.last_paid_dt_th}</p>
        </div>
        <div className="col-12">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="grid" style={{ width: "100%" }}>
                <div className="col-6">
                  <p className="text-left">จำนวนที่ชำระ</p>
                </div>
                <div className="col-6">
                  <p className="text-right">
                    {numberWithCommas(
                      parseFloat(
                        Math.round(history.sum_paid * 100) / 100
                      ).toFixed(2)
                    )}{" "}
                    บาท
                  </p>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="grid" style={{ width: "100%" }}></div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </Card>
  );
};

export default History;
