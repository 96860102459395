export const FONT_SIZE = "1.4rem";

export const FONT_SIZE_TITLE = "1.6rem";

export const API_MYASK_PROD = "https://myservices.ask.co.th:8484";
export const API_MYASK_DEV = "http://dev-myconnect.ask.co.th:8081";
// export const API_MYASK_DEV =
//   "https://dev-myconnect.ask.co.th:8443/WebServices_MyASK/ask/MyASK/";
export const API_MYASK = API_MYASK_PROD;

export const API_MYASK_HEADER = {
  "Content-Type": "application/json;charset=utf-8",
};

export const API_OTP =
  "https://asia-east2-myask-b2656.cloudfunctions.net/OtpApiAsk";

export const API_LINE =
  "https://asia-east2-myask-b2656.cloudfunctions.net/LineBot";
// export const API_LINE = "https://asia-east2-myasktest.cloudfunctions.net/LineBot";

export const URL_PAYMENT_DEV =
  "https://dev-onlinepay-01.ask.co.th:8443/Payment";
export const URL_PAYMENT_PRODUCTION =
  "https://paymentgateway.ask.co.th:8443/Payment";
export const URL_PAYMENT =
  API_MYASK === API_MYASK_DEV ? URL_PAYMENT_DEV : URL_PAYMENT_PRODUCTION;

export const URL_PAYMENT_HISTORY_DEV =
  "https://dev-onlinepay-01.ask.co.th:8443/History";
export const URL_PAYMENT_HISTORY_PRODUCTION =
  "https://paymentgateway.ask.co.th:8443/History";
export const URL_PAYMENT_HISTORY =
  API_MYASK === API_MYASK_DEV
    ? URL_PAYMENT_HISTORY_DEV
    : URL_PAYMENT_HISTORY_PRODUCTION;

export const ERROR_MSG_USER = "มี username นี้อยู่แล้วในระบบ";
export const ERROR_MSG_AGRCODE = "ไม่พบ เลขที่สัญญา/เลขที่อ้างอิง";
export const ERROR_MSG_AGRCODE2 =
  "เลขที่สัญญา / เลขที่อ้างอิง นี้เคยลงทะเบียนแล้ว";
export const ERROR_MSG_TEL = "มี เบอร์โทรศัพท์ นี้อยู่แล้วในระบบ";
