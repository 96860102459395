export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const OTP_START = "OTP_START";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAIL = "OTP_FAIL";

export const LINE_SETPROFILE = "LINE_SETPROFILE";
export const LINE_SHOULDLOADING = "LINE_SHOULDLOADING";
export const LINE_TOKEN = "LINE_TOKEN";

export const SET_CONFIG = "SET_CONFIG";

export const CUST_INFO = "CUST_INFO";

export const SET_SINGLE = "SET_SINGLE";

export const GET_AGREEMENTS = "GET_AGREEMENTS";
export const GET_AGREEMENTS_START = "GET_AGREEMENTS_START";
export const GET_AGREEMENTS_SUCCESS = "GET_AGREEMENTS_SUCCESS";
export const GET_AGREEMENTS_FAILED = "GET_AGREEMENTS_FAILED";

export const INSERT_PAY_DATA = "INSERT_PAY_DATA";
export const INSERT_PAY_DATA_START = "INSERT_PAY_DATA_START";
export const INSERT_PAY_DATA_SUCCESS = "INSERT_PAY_DATA_SUCCESS";
export const INSERT_PAY_DATA_FAILED = "INSERT_PAY_DATA_FAILED";

export const GET_AGREEMENT_DETAIL = "GET_AGREEMENT_DETAIL";
export const GET_AGREEMENT_DETAIL_START = "GET_AGREEMENT_DETAIL_START";
export const GET_AGREEMENT_DETAIL_SUCCESS = "GET_AGREEMENT_DETAIL_SUCCESS";
export const GET_AGREEMENT_DETAIL_FAILED = "GET_AGREEMENT_DETAIL_FAILED";

export const GET_INSURANCE_AGREEMENT = "GET_INSURANCE_AGREEMENT";
export const GET_INSURANCE_AGREEMENT_START = "GET_INSURANCE_AGREEMENT_START";
export const GET_INSURANCE_AGREEMENT_SUCCESS =
  "GET_INSURANCE_AGREEMENT_SUCCESS";
export const GET_INSURANCE_AGREEMENT_FAILED = "GET_INSURANCE_AGREEMENT_FAILED";

export const GET_PAYMENT_AGREEMENT = "GET_PAYMENT_AGREEMENT";
export const GET_PAYMENT_AGREEMENT_START = "GET_PAYMENT_AGREEMENT_START";
export const GET_PAYMENT_AGREEMENT_SUCCESS = "GET_PAYMENT_AGREEMENT_SUCCESS";
export const GET_PAYMENT_AGREEMENT_FAILED = "GET_PAYMENT_AGREEMENT_FAILED";

export const GET_TRACKING_REQUEST = "GET_TRACKING_REQUEST";
export const GET_TRACKING_REQUEST_START = "GET_TRACKING_REQUEST_START";
export const GET_TRACKING_REQUEST_SUCCESS = "GET_TRACKING_REQUEST_SUCCESS";
export const GET_TRACKING_REQUEST_FAILED = "GET_TRACKING_REQUEST_FAILED";

export const GET_TRACKING_REQUEST_COVID = "GET_TRACKING_REQUEST_COVID";
export const GET_TRACKING_REQUEST_COVID_START =
  "GET_TRACKING_REQUEST_COVID_START";
export const GET_TRACKING_REQUEST_COVID_SUCCESS =
  "GET_TRACKING_REQUEST_COVID_SUCCESS";
export const GET_TRACKING_REQUEST_COVID_FAILED =
  "GET_TRACKING_REQUEST_COVID_FAILED";

export const SET_TAB_AGREEMENT_INDEX = "SET_TAB_AGREEMENT_INDEX";
export const FILTER_AGREEMENTS = "FILTER_AGREEMENTS";
export const INSERT_AGREEMENT_PAY_LIST = "INSERT_AGREEMENT_PAY_LIST";
export const REMOVE_AGREEMENT_PAY_LIST = "REMOVE_AGREEMENT_PAY_LIST";
export const UPDATE_AGREEMENT_PAY_LIST = "UPDATE_AGREEMENT_PAY_LIST";

export const GET_PAYMENT_ITEM = "GET_PAYMENT_ITEM";
export const GET_PAYMENT_ITEM_START = "GET_PAYMENT_ITEM_START";
export const GET_PAYMENT_ITEM_SUCCESS = "GET_PAYMENT_ITEM_SUCCESS";
export const GET_PAYMENT_ITEM_FAILED = "GET_PAYMENT_ITEM_FAILED";

export const GET_CLOSED_AGREEMENT_DETAIL = "GET_CLOSED_AGREEMENT_DETAIL";
export const GET_CLOSED_AGREEMENT_DETAIL_START =
  "GET_CLOSED_AGREEMENT_DETAIL_START";
export const GET_CLOSED_AGREEMENT_DETAIL_SUCCESS =
  "GET_CLOSED_AGREEMENT_DETAIL_SUCCESS";
export const GET_CLOSED_AGREEMENT_DETAIL_FAILED =
  "GET_CLOSED_AGREEMENT_DETAIL_FAILED";

export const GET_FULL_SUMMARY_PAYMENT = "GET_FULL_SUMMARY_PAYMENT";
export const GET_FULL_SUMMARY_PAYMENT_START = "GET_FULL_SUMMARY_PAYMENT_START";
export const GET_FULL_SUMMARY_PAYMENT_SUCCESS =
  "GET_FULL_SUMMARY_PAYMENT_SUCCESS";
export const GET_FULL_SUMMARY_PAYMENT_FAILED =
  "GET_FULL_SUMMARY_PAYMENT_FAILED";

export const CLEAR_PAYMENT_STATE = "CLEAR_PAYMENT_STATE";
export const CLEAR_AGREEMENT_STATE = "CLEAR_AGREEMENT_STATE";
export const CLEAR_TRACKING_STATE = "CLEAR_TRACKING_STATE";

export const GET_PAYMENT_DETAIL_ORIGIN = "GET_PAYMENT_DETAIL_ORIGIN";
export const GET_PAYMENT_DETAIL_ORIGIN_START =
  "GET_PAYMENT_DETAIL_ORIGIN_START";
export const GET_PAYMENT_DETAIL_ORIGIN_SUCCESS =
  "GET_PAYMENT_DETAIL_ORIGIN_SUCCESS";
export const GET_PAYMENT_DETAIL_ORIGIN_FAILED =
  "GET_PAYMENT_DETAIL_ORIGIN_FAILED";

export const GENERATE_BATCH = "GENERATE_BATCH";
export const GENERATE_BATCH_START = "GENERATE_BATCH_START";
export const GENERATE_BATCH_SUCCESS = "GENERATE_BATCH_SUCCESS";
export const GENERATE_BATCH_FAILED = "GENERATE_BATCH_FAILED";

export const CS_FILTER = "CS_FILTER";
export const CS_FILTER_START = "CS_FILTER_START";
export const CS_FILTER_SUCCESS = "CS_FILTER_SUCCESS";
export const CS_FILTER_FAILED = "CS_FILTER_FAILED";

export const VIEW_STATE = {
  INITIAL: "initial",
  LOADING: "loading",
  ERROR: "error",
  SUCCESS: "success",
  NO_CONTENT: "nocontent",
};
