import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { Paper } from "@material-ui/core";
import axios from "axios";

import * as actions from "./../../../../store/action/index";
import * as actionTypes from "../../../../store/action/actionTypes";
import Loading from "./../../../../components/UI/Loading/Loading";
import Payment from "../../../../components/Personal/Agreements/List/Payment";
import { numberWithCommas } from "../../../../shared/utility";
import {
  API_MYASK,
  API_MYASK_HEADER,
  URL_PAYMENT,
} from "../../../../shared/constant";
import { Dialog } from "primereact/dialog";

const MyPayments = (props) => {
  let location = useLocation();
  let history = useHistory();
  const [paymentAmount, setPaymentAmount] = useState(0);
  let [generatingState, setGeneratingState] = useState(
    actionTypes.VIEW_STATE.INITIAL
  );
  let [errorMsg, setErrorMsg] = useState(null);
  let [batchNo, setBatchNo] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let sum = 0.0;
    for (let i = 0; i < props.payment_agreements.length; i++) {
      let obj = props.payment_agreements[i];
      sum = sum + parseFloat(obj.payment_amount);
    }
    setPaymentAmount(sum);
  }, [props.payment_agreements]);

  const onRemoveSinglePayment = (agr_code) => {
    props.removeAgreementPayList(agr_code);
  };

  const onEditSummary = (agreement) => {
    history.push({
      pathname: "/agreement-payment-item",
      search: "?agr=" + agreement.agr_code,
    });
  };

  const onViewClosedAgreement = (agr_code, com_code) => {
    history.push({
      pathname: "/agreement-closed",
      search: "?agr=" + agr_code + "&comCode=" + com_code + "&action=n",
    });
  };

  const goToPaymentChannal = () => {
    history.push({
      pathname: "/payment-channal",
    });
  };

  const goToPaymentExternal = (batchNo) => {
    // console.log(location);
    // console.log(location.pathname);
    // console.log(window.location.origin);
    // console.log(window.location.pathname);
    // console.log(window.location.href);
    // console.log(window.location.hash);
    let url = window.location.origin;
    if (window.location.hash.indexOf("#") == 0) {
      url += "/myask/#";
    }
    url += "/payment-result";

    url = encodeURIComponent(url);

    window.location.href =
      URL_PAYMENT +
      "?batch_no=" +
      batchNo +
      "&user_name=" +
      sessionStorage.getItem("userName") +
      "&url=" +
      url;
  };

  const generateBatch = () => {
    setGeneratingState(actionTypes.VIEW_STATE.LOADING);

    const username = sessionStorage.getItem("userName");
    const token = sessionStorage.getItem("token");

    let payments = props.payment_agreements;

    let paymentsReq = [];

    for (let i in payments) {
      let payment = payments[i];
      let dtArr = [];
      for (let j in payment.payment_detail) {
        let dt = payment.payment_detail[j];
        let obj = {
          offsetting_rule: dt.key,
          sub_off_rule: dt.sub_key,
          amount: dt.amount,
        };
        dtArr.push(obj);
      }
      let req = {
        run_no: props.runNo,
        com_code: payment.agreement.com_code,
        agr_code: payment.agreement.agr_code,
        pay_flag: payment.closed == true ? "C" : "U",
        amount: payment.payment_amount,
        list_payment_detail: dtArr,
      };
      paymentsReq.push(req);
    }

    const req = {
      user_name: username,
      token: token,
      list_payment: paymentsReq,
    };

    let url = API_MYASK + "/generateBatch";

    axios
      .post(url, req, {
        headers: API_MYASK_HEADER,
      })
      .then((response) => {
        setGeneratingState(actionTypes.VIEW_STATE.SUCCESS);
        if (response.data.CODE === "200" || response.data.CODE === "204") {
          const data = response.data.DATA;
          goToPaymentExternal(data.BATCH_NO);
        } else {
          setGeneratingState(actionTypes.VIEW_STATE.ERROR);
          setErrorMsg(response.data.MSG);
        }
      })
      .catch((err) => {
        setGeneratingState(actionTypes.VIEW_STATE.ERROR);
        setErrorMsg(err);
      });
  };

  return (
    <div className="ask__font__main">
      <div>
        {props.payment_agreements != null &&
          props.payment_agreements.length === 0 && (
            <center>
              <p className="ask__font__main">ไม่พบข้อมูล</p>
            </center>
          )}

        <Dialog
          header="โปรดรอสักครู่..."
          visible={generatingState == actionTypes.VIEW_STATE.LOADING}
          style={{ width: "80vw" }}
          closable={false}
        >
          <center>
            <Loading></Loading>
            <p>ระบบกำลังสร้างข้อมูลสำหรับชำระเงิน</p>
          </center>
        </Dialog>

        <Dialog
          visible={generatingState == actionTypes.VIEW_STATE.ERROR}
          style={{ width: "80vw" }}
          onHide={() => setGeneratingState(actionTypes.VIEW_STATE.INITIAL)}
        >
          <center>
            <i
              className="pi pi-exclamation-circle"
              style={{ fontSize: "2em" }}
            ></i>
            <p className="mt-4">พบปัญหาขณะกำลังสร้างข้อมูลสำหรับการชำระเงิน</p>
            <p>โปรดลองอีกครั้ง</p>
          </center>
        </Dialog>

        <div className="grid">
          {props.payment_agreements != null &&
            props.payment_agreements.length > 0 &&
            props.payment_agreements.map((paymentItem, index) => (
              <div
                className="col-12 sm:col-12 md:col-6 lg:col-4 xs:col-4"
                key={"div_payment_" + index}
              >
                <Payment
                  payment={paymentItem}
                  key={"payment_" + index}
                  onRemove={onRemoveSinglePayment}
                  onEditSummary={onEditSummary}
                  onViewClosedAgreement={onViewClosedAgreement}
                />
              </div>
            ))}
        </div>
      </div>

      {props.payment_agreements != null && props.payment_agreements.length > 0 && (
        <Fragment>
          <div className="px-6 mt-6">
            <p className="font-bold">
              * บริษัทขอสงวนสิทธิ์ในการหักชำระเบี้ยปรับ ค่าติดตามทวงถาม
              ค่าใช้จ่ายและค่าธรรมเนียมต่างๆที่ท่านค้างชำระอยู่กับบริษัทตามความเหมาะสม
            </p>
          </div>
          <Paper
            className="mt-4"
            style={{
              padding: "2rem",
              border: "1px solid #001F3F",
              borderRadius: "0px",
              backgroundColor: "#001F3F",
            }}
            elevation={16}
            children={
              <div className="grid">
                <div className="col-6">
                  <p style={{ color: "white" }}>ยอดชำระรวม</p>
                </div>
                <div className="col-6">
                  <p
                    className="text-right font-bold"
                    style={{ color: "white" }}
                  >
                    {numberWithCommas(parseFloat(paymentAmount).toFixed(2))} บาท
                  </p>
                </div>
                <div className="col-6 sm:col-6 md:col-9 lg:col-9 xl:col-9"></div>
                <div className="col-6 sm:col-6 md:col-3 lg:col-3 xl:col-3">
                  <Button
                    label="ชำระเงิน"
                    className="p-button-raised p-button-success mt-4 p-3"
                    style={{
                      width: "100%",
                      borderRadius: "0px",
                      backgroundColor: "#3bb54a",
                    }}
                    onClick={() => generateBatch()}
                  />
                </div>
              </div>
            }
          ></Paper>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    payment_agreements: state.payment.payment_agreements,
    runNo: state.agreement.run_no,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeAgreementPayList: (agr_code) =>
      dispatch(actions.removeAgreementPayList(agr_code)),
    generateBatch: (payments, runNo) =>
      dispatch(actions.generateBatch(payments, runNo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPayments);
