import React from 'react';
import Grid from '@material-ui/core/Grid';
// import Button from '../../UI/Button/Button';
import Icon from '../../UI/Icon/Icon';
import '../../Apply/Apply.css';
import Loading from './../../UI/Loading/Loading';
// import { PATH_TEL, PATH_LINE, PATH_MAIL } from './../../../shared/constant';
// import * as actions from "./../../../store/action/index";
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';

const Contact = (props) => {
    const topic = {
        marginTop: '0.5rem',
        marginBottom: '2rem',
    }

    let mail = "mailto:" + props.mailTo;
    let tel = "tel:" + props.telNo;

    return (
        <div className="panel">
            <form onSubmit={props.submitHandler}>
                <Grid container spacing={3}>
                    <Grid item>
                        <div style={{ display: "flex" }}>
                            <Icon icon="telephone" path={tel} margin="0 1rem 0 0" />
                            <Icon icon="line" target="_blank" path={props.lineFriend} margin="0 1rem 0 0" />
                            <Icon icon="mail" path={mail} margin="0 1rem 0 0" />
                            <Icon icon="map" path="/branch" margin="0 1rem 0 0" />
                        </div>
                    </Grid>
                    {/* <Grid item xs={2} sm={2} md={1}>
                    <Icon icon="facebook" target="_blank" path="https://www.facebook.com/ask.shb/" />
                </Grid> */}
                    <Grid item xs={12}>
                        <p className="text-bold text-center ask__font__title" style={topic}>{props.title}</p>
                    </Grid>
                    {props.children}

                    {props.loading
                        ? <Grid item xs={12}>
                            <center><Loading /></center>
                        </Grid>
                        :
                        <Grid
                            item
                            xs={12}
                        >
                            <button
                                className="btn-submit btn_apply_submit"
                            >ส่งข้อความ</button>
                        </Grid>}

                    {props.covid
                        ?
                        <Paper variant="outlined" square style={{ padding: "1rem" }}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <p className="text-bold">การขยายระยะเวลาชำระหนี้</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p className="text-bold">การปรับปรุงโครงสร้างหนี้</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>ผู้เช่า ผู้เช่าซื้อ ผู้กู้ จะต้องเข้ามาดำเนินการที่บริษัทฯ ดังนี้</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>ผู้เช่า ผู้เช่าซื้อ ผู้กู้ และผู้ค้ำประกัน จะต้องเข้ามาดำเนินการที่บริษัทฯ ดังนี้</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>1.	ลงนามในเอกสารต่างๆ ตามแบบฟอร์มซึ่งบริษัทฯกำหนดไว้</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>1.	ลงนามใน สัญญา ตลอดจนเอกสารที่เกี่ยวข้องตามแบบฟอร์มซึ่งบริษัทฯกำหนดไว้</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>2.	ส่งมอบเอกสารระบุตัวตนพร้อมลงนามรับรอง</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>2.	ส่งมอบเอกสารระบุตัวตนพร้อมลงนามรับรอง</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;2.1.	บุคคลธรรมดา</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;2.1.	บุคคลธรรมดา</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;&nbsp;•	สำเนาบัตรประจำตัวประชาชน</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;&nbsp;•	สำเนาบัตรประจำตัวประชาชน</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;&nbsp;•	สำเนาใบเปลี่ยนชื่อ-สกุล ถ้ามี</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;&nbsp;•	สำเนาใบเปลี่ยนชื่อ-สกุล ถ้ามี</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;2.2.	นิติบุคคล</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;2.2.	นิติบุคคล</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;&nbsp;•	สำเนาหนังสือรับรองบริษัท (อายุไม่เกิน 6 เดือน)</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;&nbsp;•	สำเนาหนังสือรับรองบริษัท (อายุไม่เกิน 6 เดือน)</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;&nbsp;•	สำเนาบัตรประชาชนกรรมการผู้มีอำนาจ</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>&nbsp;&nbsp;•	สำเนาบัตรประชาชนกรรมการผู้มีอำนาจ</p>
                                </Grid>
                            </Grid>
                        </Paper>

                        : <div></div>}
                </Grid>
            </form>
        </div >
    );
}
const mapStateToProps = state => {
    return {
        lineFriend: state.auth.lineFriend,
        telNo: state.auth.telNo,
        mailTo: state.auth.mailTo,
    };
};

export default connect(
    mapStateToProps,
    null)(Contact);
