import React, { Fragment, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import CookieConsent from "react-cookie-consent";
import "./../containers/App.css";
import Layout from "./Layout/layout";
import { Route, Switch } from "react-router-dom";
import test from "./Layout/layout";
import Home from "./Home/home";
import Signin from "./../containers/Authen/SignIn/signin";
import Signup from "./../containers/Authen/SignUp/signup";
import Logout from "./../containers/Authen/Logout/logout";
import Forgot from "./../containers/Authen/Forgot/forgot";
import Question from "./../containers/Authen/Question/question";
import ConfirmOTP from "./Authen/Otp/otp";
import Calculate from "../containers/Calculate/CalculateControl";
import ProductControls from "./ProductControls/ProductControl";
import ProductShowDetail from "./../containers/ProductControls/ProductShowDetail";
import Branch from "./../containers/ContactControls/Branch/BranchControls";
import Contact from "./../containers/ContactControls/Contact/ContactControls";
import ComplaintControls from "./../containers/ComplaintControls/ComplaintControls";
import ContractCovid19Controls from "./../containers/ContactControls/ContactCovid19/ContactCovid19Controls";
import ManageAgreement from "../containers/ManageAgreementControls/ManageAgreementControls";
import ManageAgreementMainControls from "../containers/ManageAgreementControls/ManageAgreementMainControls";
import ManageAgreementPaymentList from "../containers/ManageAgreementControls/ManageAgreementControlsPaymentList";
import ManageAgreementPayment from "../containers/ManageAgreementControls/ManageAgreementControlsPayment";
import ManageAgreementQRCode from "../containers/ManageAgreementControls/ManageAgreementControlsQRCode";
import ManageAgreementDetail from "../containers/ManageAgreementControls/ManageAgreementControlsDetail";
import ManageAgreementControlsInsurance from "../containers/ManageAgreementControls/ManageAgreementControlsInsurance";
import ManageAgreementControlsHistory from "../containers/ManageAgreementControls/ManageAgreementControlsHistory";
import ManageAgreementControlsCloseAcc from "../containers/ManageAgreementControls/ManageAgreementControlsCloseAcc";
import ManageAgreementControlsChangeAddr from "../containers/ManageAgreementControls/ManageAgreementControlsChangeAddr";
import { connect } from "react-redux";
import ApplyProduct from "./../containers/ApplyControls/ApplyControls";
import ApplySuccess from "./../components/Apply/ApplyStep5/ApplyStep5";
import ResetPassword from "./Authen/Forgot/resetpassword";
import Tracking from "./../components/Tracking/Step";
import DummyPage from "./../containers/Dummy/DummyPage";
import * as actions from "./../store/action/index";
// import { Button } from "@material-ui/core";
import axios from "axios";
import { API_MYASK, API_MYASK_HEADER } from "./../shared/constant";
import Loading from "./../components/UI/Loading/Loading";
import ForgotUsername from "./Authen/Forgot/forgotusername";
import BlankPage from "../components/UI/Error/BlankPage";
import TrackingAgreement from "./Tracking/Tracking";
import Agreements from "./Personal/Agreement/List/Agreements";
import AgreementDetail from "./Personal/Agreement/List/Detail/AgreementDetail";
import InsuranceAgreement from "./Personal/Agreement/List/Detail/InsuranceAgreement";
import PaymentHistory from "./Personal/Agreement/List/Detail/PaymentHistory";
import PaymentItemList from "./Personal/Agreement/PaymentItem/PaymentItemList";
import PaymentChannal from "./Payment/PaymentChannal";
import BarcodeAndQR from "./Payment/BarcodeAndQR";
import ClosedAgreement from "./Personal/Agreement/ClosedAgreement/ClosedAgreement";
import RegisterEtax from "./EtaxEreceipt/RegisterEtax";
import ScrollToTop from "../hoc/scrollToTop";
import PaymentSuccess from "./Payment/PaymentSuccess";
import FilterPage from "./CustomerService/filterpage";
import ConsentControls from "./ConsentControls/ConsentControls";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import PrimeReact from "primereact/api";
import { Button } from "primereact/button";
import VConsole from "vconsole";
import liff from "@line/liff";
// const vConsole = new VConsole();

PrimeReact.ripple = true;

// const liff = window.liff;

const App = (props) => {
  const [showPolicy, setShowPolicy] = useState(false);
  useEffect(() => {
    // console.log(performance.getEntriesByType("navigation")[0].type);
    // https://w3c.github.io/navigation-timing/#sec-performance-navigation-types
    const navigationType = performance.navigation.type;
    if (
      (window.performance &&
        window.performance.getEntriesByType("navigation").length) ||
      navigationType === 1
    ) {
      console.log("support performance API");
      const perFormanceNavigation = performance.getEntriesByType("navigation");
      if (
        navigationType === 1 ||
        (perFormanceNavigation.length &&
          perFormanceNavigation[0].type == "reload")
      ) {
        console.log("reload website");
        props.clearAgreementState();
        props.clearPaymentState();
      } else {
        // console.log("This page is not reloaded");
      }
    }

    const liffId = "1656982302-AjKpEZPj";
    liff
      .init({ liffId })
      .then(() => {
        getConfig();
        getProfile();
      })
      .catch((err) => {
        console.log(err.code, err.message);
      });
  }, []);

  const getConfig = () => {
    axios.get(API_MYASK + "/GetConfig?user_name=&token=").then((res) => {
      props.onTryAutoSignup();
      props.onSetConfig(
        res.data.DATA.LINE_FRIEND,
        res.data.DATA.OTP_TIME,
        res.data.DATA.TEL_NO,
        res.data.DATA.MAIL_TO
      );
    });
  };

  useEffect(() => {
    if (props.lineId && sessionStorage.getItem("isAlreadyLogin") !== "Y") {
      const data = {
        lineid: props.lineId,
      };

      axios
        .post(API_MYASK + "/TryAutoLoginLine", data, {
          headers: API_MYASK_HEADER,
        })
        .then((res) => {
          if (res.data.CODE === "200") {
            const data2 = {
              username: res.data.LIST_DATA[0].username,
              lineid: props.lineId,
            };

            props.onAuth(res.data.MSG, data2.username);
            axios
              .get(
                API_MYASK +
                  "/GetConfig?user_name=" +
                  data2.username +
                  "&token=" +
                  res.data.MSG
              )
              .then((res) => {
                axios.post(API_MYASK + "/SetLineLogin", data2, {
                  headers: API_MYASK_HEADER,
                });
                props.onSetLineToken(res.data.MSG);
                props.onSetShouldLoading(false);
              });
          } else {
            props.onSetShouldLoading(false);
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  }, [props.lineId]);

  // useEffect(() => {
  //   let mount = true;

  //   if (liff._auth !== null && props.isAuthenticated) {
  //     if (mount) {
  //       sessionStorage.setItem("isAlreadyLogin", "Y");
  //       props.onSetShouldLoading(false);
  //     }
  //   }
  //   return () => {
  //     mount = false;
  //   };
  // }, [props.isAuthenticated]);

  const getProfile = () => {
    console.log("OS : " + liff.getOS());
    console.log("LINE LOGIN : " + liff.isLoggedIn());
    console.log("USE BROWSER LINE : " + liff.isInClient());
    if (liff.isLoggedIn()) {
      liff
        .getProfile()
        .then((profile) => {
          console.log("user line Id : " + profile.userId);
          props.onSetLineProfile(profile);
          if (props.isAuthenticated) {
            sessionStorage.setItem("isAlreadyLogin", "Y");
            props.onSetShouldLoading(false);
          } else {
          }
        })
        .catch((err) => {
          console.error(err);
          props.onSetShouldLoading(false);
        });
    } else {
      props.onSetShouldLoading(false);
    }
  };

  if (props.shouldLoading && sessionStorage.getItem("isAlreadyLogin") !== "Y") {
    return (
      <div style={style}>
        <Loading />
      </div>
    );
  }

  return (
    <Layout isAuthenticated={props.isAuthenticated}>
      <Dialog
        visible={showPolicy}
        // style={{ width: "75vw" }}
        dismissableMask
        onHide={() => {
          setShowPolicy(false);
        }}
        // maximizable
        maximized
      >
        <div style={{padding: "1.5em"}}>
          <h2>
            <strong>นโยบายการใช้คุกกี้</strong>
          </h2>
          คุกกี้ คือ ไฟล์ข้อความ
          ซึ่งประกอบด้วยข้อมูลขนาดเล็กที่ถูกดาวน์โหลดและจัดเก็บไว้ในอุปกรณ์ของคุณเมื่อคุณเข้าชมเว็บไซต์
          จากนั้น
          คุกกี้จะถูกส่งกลับไปยังเว็บไซต์เดิมในแต่ละครั้งที่คุณเข้าชมเว็บไซต์ดังกล่าว
          หรือถูกส่งไปยังเว็บไซต์อื่นๆ ที่รองรับคุกกี้นั้นได้
          โดยคุกกี้มีประโยชน์ในการช่วยให้เว็บไซต์สามารถจดจำอุปกรณ์ของคุณได้และให้บริการที่เหมาะสมกับคุณมากยิ่งขึ้น
          <br />
          การใช้คุกกี้และเทคโนโลยีที่คล้ายคลึงกันไม่ได้จำกัดเพียงการใช้ในเว็บไซต์และเว็บเบราว์เซอร์
          แต่แอปพลิเคชันบนโทรศัพท์มือถือ ซึ่งโดยทั่วไป
          จะเชื่อมต่อกับเว็บไซต์และบริการบนเว็บไซต์ก็สามารถสร้างคุกกี้ได้เช่นกัน
          คุกกี้มีหน้าที่ทำงานได้หลากหลาย เช่น
          ช่วยให้คุณสามารถเยี่ยมชมเว็บไซต์ได้อย่างราบรื่นและมีประสิทธิภาพ
          จดจำสิ่งที่คุณชื่นชอบ และพัฒนาประสบการณ์การใช้งานของผู้ใช้เว็บไซต์
          นอกจากนี้
          เพื่อให้มั่นใจว่าคุณจะได้รับโฆษณาออนไลน์ที่เกี่ยวข้องและตรงกับความสนใจของคุณมากยิ่งขึ้น
          โดยคุกกี้ที่มีการใช้งานบนเว็บไซต์ของบริษัท เอเซียเสริมกิจ ลีสซิ่ง
          จำกัด มหาชน และบริษัทในเครือ (“บริษัท”) แบ่งเป็นประเภทต่างๆ
          ได้ดังต่อไปนี้
          <br />
          <br />
          <h3>
            <strong>คุกกี้ที่จำเป็นอย่างยิ่ง</strong>
          </h3>
          คุกกี้ประเภทนี้มีความสำคัญในการทำให้คุณสามารถเยี่ยมชมเว็บไซต์ของบริษัทได้
          และในการให้บริการและฟังก์ชันต่างๆ บนเว็บไซต์ของบริษัท
          และการเข้าสู่พื้นที่ปลอดภัยบนเว็บไซต์ของบริษัท หากไม่มีคุกกี้ประเภทนี้
          เว็บไซต์ของบริษัทหรือบางฟังก์ชันบนเว็บไซต์อาจจะไม่สามารถทำงานได้ตามปกติ
          รวมทั้งคุณจะไม่สามารถใช้บริการบางอย่างตามที่ร้องขอบนเว็บไซต์ของบริษัทได้
          <br />
          <br />
          <h3>
            <strong>คุกกี้ที่เก็บข้อมูลการใช้งาน</strong>
          </h3>
          คุกกี้ประเภทนี้จะเก็บรวบรวมข้อมูลการใช้งานของคุณบนเว็บไซต์ของบริษัท
          เช่น เว็บเพจที่คุณเข้าชม เว็บเพจที่คุณเข้าชมบ่อยที่สุด
          และลิงก์ที่คุณอาจคลิกเข้าไปชม ทั้งนี้
          คุกกี้ประเภทนี้จะไม่เก็บรวบรวมข้อมูลใดๆ ที่สามารถระบุตัวตนของคุณได้
          แต่จะอยู่ในลักษณะข้อมูลแบบรวมและไม่สามารถระบุตัวตนได้
          บริษัทใช้คุกกี้ประเภทนี้เพื่อเข้าใจถึงวิธีการที่คุณใช้งานบริการออนไลน์และเว็บไซต์ของบริษัท
          และเพื่อพัฒนาการทำงานของเว็บไซต์ของบริษัทและเพิ่มประสิทธิภาพในการให้บริการของบริษัท
          <br />
          <br />
          <h3>
            <strong>คุกกี้ที่เพิ่มประสิทธิภาพในการใช้งาน</strong>
          </h3>
          คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ของบริษัทจดจำข้อมูลหรือตัวเลือกที่คุณได้กำหนดไว้แล้ว
          (เช่น ชื่อผู้เข้าใช้ ภาษา หรือภูมิภาคที่คุณอาศัยอยู่)
          และให้บริการที่เหมาะสมสำหรับคุณโดยเฉพาะ (เช่น
          เว็บไซต์สามารถจดจำขนาดตัวอักษร หรือให้บริการลูกค้าผ่าน live chat ได้)
          นอกจากนี้ คุกกี้ประเภทนี้ยังสามารถจดจำการตั้งค่าขนาดหรือรูปแบบตัวอักษร
          และส่วนอื่นๆ ของเว็บเพจที่คุณสามารถตั้งค่าได้
          คุกกี้ประเภทนี้อาจเก็บรวบรวมข้อมูลที่ไม่สามารถระบุตัวตนได้
          และไม่สามารถติดตามกิจกรรมการเยี่ยมชมเว็บไซต์ของคุณบนเว็บไซต์อื่นๆ ได้
          <br />
          <br />
          <h3>
            <strong>คุกกี้เพื่อการกำหนดเป้าหมายหรือเพื่อการโฆษณา</strong>
          </h3>
          บริษัทใช้คุกกี้ประเภทนี้เพื่อนำเสนอโฆษณาที่เกี่ยวข้องและตรงกับความสนใจของคุณมากยิ่งขึ้น
          และเพื่อจำกัดจำนวนครั้งที่คุณจะได้รับโฆษณาทั่วไป
          รวมถึงช่วยตรวจสอบประสิทธิภาพของแคมเปญโฆษณาของบริษัท
          คุกกี้ประเภทนี้จะถูกใช้งานโดยเครือข่ายสื่อโฆษณา (เช่น Facebook, Google
          และอื่นๆ) โดยได้รับอนุญาตจากบริษัท
          และจะจดจำวิธีการที่คุณเยี่ยมชมเว็บไซต์ของบริษัท
          และบริษัทอาจเปิดเผยข้อมูลนี้ให้แก่บุคคลอื่นๆ เช่น
          ผู้ให้บริการสื่อโฆษณา ทั้งนี้
          คุกกี้ประเภทนี้มักจะเชื่อมโยงไปยังเว็บไซต์ที่ให้บริการโดยบุคคลอื่น
          ในกรณีที่บริษัทใช้คุกกี้ประเภทนี้ในการเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
          บริษัทจะขอความยินยอมจากคุณหรือดำเนินการตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนด
          <br />
          ในการใช้บริการเว็บไซต์ของบริษัท
          คุณรับทราบว่าบริษัทจะมีการใช้งานคุกกี้ประเภทต่างๆ
          เพื่อวัตถุประสงค์ตามที่ระบุไว้ข้างต้น และบริษัทอาจเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ ซึ่งเป็นส่วนหนึ่งของคุกกี้ดังกล่าว
          เพื่อวัตถุประสงค์ตามที่ระบุไว้ในประกาศนโยบายความเป็นส่วนตัวของบริษัท
          <br />
          หากบริษัทมีการเชื่อมโยงไปยังเว็บไซต์ของบุคคลภายนอก
          โปรดทราบว่าบุคคลดังกล่าวอาจมีนโยบายการใช้คุกกี้ของตนเอง
          บริษัทขอแนะนำให้คุณอ่านนโยบายการใช้คุกกี้ของบุคคลดังกล่าวก่อนการใช้งานเว็บไซต์เหล่านั้น
          <br />
          <br />
          <br />
          <br />
          ประกาศ เมื่อ เดือนกรกฎาคม 2563
          <br />
          <br />
          <br />
        </div>
      </Dialog>
      <CookieConsent
        overlay="true"
        cookieName="askConnectCookieConsent"
        onAccept={() => {}}
        location="bottom"
        buttonText="ยอมรับ"
        style={{
          background: "#001f3f",
          fontSize: "18px",
          paddingBottom: "1em",
          paddingTop: "1em",
        }}
        buttonStyle={{ color: "#001f3f", fontSize: "18px" }}
      >
        บริษัทมีการใช้เทคโนโลยี เช่น คุกกี้ (cookies)
        และเทคโนโลยีที่คล้ายคลึงกันบนเว็บไซต์ของบริษัท
        เพื่อสร้างประสบการณ์การใช้งานเว็บไซต์ของท่านให้ดียิ่งขึ้น
        โปรดอ่านรายละเอียดเพิ่มเติมที่ โปรดอ่านรายละเอียดเพิ่มเติมที่{" "}
        <span
          style={{ color: "#bc9e5c", fontSize: "18px", cursor: "pointer" }}
          onClick={() => setShowPolicy(true)}
        >
          <u>นโยบายการใช้คุกกี้</u>
        </span>
      </CookieConsent>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" render={(props) => <Signin {...props} />} />
        <Route path="/logout" component={Logout} />
        <Route path="/register" component={Signup} />
        <Route path="/forgot" component={Forgot} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/question" component={Question} />
        <Route path="/otp" component={ConfirmOTP} />
        <Route path="/calculate" component={Calculate} />
        <Route path="/apply/:product" exact component={ApplyProduct} />
        <Route path="/apply" exact component={ApplyProduct} />
        <Route path="/products" component={ProductControls} />
        <Route path="/product-detail" exact component={ProductShowDetail} />
        <Route
          path="/product-detail/:product"
          exact
          component={ProductShowDetail}
        />
        <Route path="/branch" component={Branch} />
        <Route path="/contact" component={Contact} />
        <Route path="/complaint" component={ComplaintControls} />
        <Route path="/contact__covid19" component={ContractCovid19Controls} />
        <Route path="/contact_covid19" component={ContractCovid19Controls} />
        <Route
          path="/information"
          exact
          component={ManageAgreementMainControls}
        />
        <Route path="/tracking-request" exact component={TrackingAgreement} />
        <Route path="/agreements" exact component={Agreements} />
        <Route path="/agreement-detail" exact component={AgreementDetail} />
        <Route
          path="/agreement-insurance"
          exact
          component={InsuranceAgreement}
        />
        <Route
          path="/agreement-payment-history"
          exact
          component={PaymentHistory}
        />
        <Route
          path="/agreement-payment-item"
          exact
          component={PaymentItemList}
        />
        <Route path="/payment-channal" exact component={PaymentChannal} />
        <Route path="/payment-qr" exact component={BarcodeAndQR} />
        <Route path="/payment-result" component={PaymentSuccess} />
        <Route path="/agreement-closed" exact component={ClosedAgreement} />
        <Route path="/etax_ereceipt" exact component={RegisterEtax} />

        <Route path="/manageagreement" exact component={ManageAgreement} />
        <Route
          path="/manageagreement-detail"
          component={ManageAgreementDetail}
        />
        <Route
          path="/manageagreement-history"
          component={ManageAgreementControlsHistory}
        />
        <Route
          path="/manageagreement-payment"
          component={ManageAgreementPayment}
        />
        <Route
          path="/manageagreement-qrcode"
          component={ManageAgreementQRCode}
        />
        <Route
          path="/manageagreement-paymentlist"
          component={ManageAgreementPaymentList}
        />
        <Route
          path="/manageagreement-closeacc"
          component={ManageAgreementControlsCloseAcc}
        />
        <Route
          path="/manageagreement-insurance"
          component={ManageAgreementControlsInsurance}
        />
        <Route
          path="/manageagreement-changeaddr"
          component={ManageAgreementControlsChangeAddr}
        />
        <Route path="/success" component={ApplySuccess} />
        <Route path="/tracking" component={Tracking} />
        <Route path="/forgotUsername" component={ForgotUsername} />
        <Route path="/test-page" exact component={DummyPage} />
        <Route path="/cs-filter" exact component={FilterPage} />
        <Route path="/consent" exact component={ConsentControls} />
        <Route path="/" component={BlankPage} />
      </Switch>
    </Layout>
  );
};

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    lineId: state.line.userId,
    shouldLoading: state.line.shouldLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetLineProfile: (profile) => dispatch(actions.line(profile)),
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onLineAutoSignup: (lineId) => dispatch(actions.authCheckState()),
    onAuth: (token, userName) => dispatch(actions.auth(token, userName)),
    onSetShouldLoading: (should) => dispatch(actions.setShouldLoading(should)),
    onSetLineToken: (token) => dispatch(actions.setLineToken(token)),
    onSetConfig: (lineFriend, otpTime, telNo, mailTo) =>
      dispatch(actions.setConfig(lineFriend, otpTime, telNo, mailTo)),
    clearAgreementState: () => dispatch(actions.clearAgreementState()),
    clearPaymentState: () => dispatch(actions.clearPaymentState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
