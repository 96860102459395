import React, { useState, useEffect } from "react";
import ManageAgreementHistory from "../../components/ManageAgreement/ManageAgreementHistory/ManageAgreementHistory";
import Description from "../../components/ManageAgreement/description";
import History from "../../components/ManageAgreement/history";
import PathTree from "../../components/UI/PathTree/PathTree";
import axios from "axios";
import { API_MYASK } from "./../../shared/constant";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import * as actions from "./../../store/action/index";

const ManageAgreementControlsHistory = (props) => {
  let data = "";
  let click = "";
  const [isDefault] = useState(props.location.isDefault);
  const [fin_type] = React.useState(
    props.location.fin_type || sessionStorage.getItem("fin_type") || ""
  );
  const [agr_code] = React.useState(
    props.location.agr_code || sessionStorage.getItem("agr_code") || ""
  );
  const [body] = React.useState(
    props.location.body || sessionStorage.getItem("body") || ""
  );
  const [brand] = React.useState(
    props.location.brand || sessionStorage.getItem("brand") || ""
  );
  const [reg_num] = React.useState(
    props.location.reg_num || sessionStorage.getItem("reg_num") || ""
  );
  const [reg_province] = React.useState(
    props.location.reg_province || sessionStorage.getItem("reg_province") || ""
  );
  const [asset_description] = React.useState(
    props.location.asset_description ||
      sessionStorage.getItem("asset_description") ||
      ""
  );
  const [install] = React.useState(
    props.location.install || sessionStorage.getItem("install") || ""
  );
  const [os_balance] = React.useState(
    props.location.os_balance || sessionStorage.getItem("os_balance") || ""
  );
  const [n_due_i] = React.useState(
    props.location.n_due_i || sessionStorage.getItem("n_due_i") || ""
  );
  const [flat_rate] = React.useState(
    props.location.flat_rate || sessionStorage.getItem("flat_rate") || ""
  );
  const [disbursement_date] = React.useState(
    props.location.disbursement_date ||
      sessionStorage.getItem("disbursement_date") ||
      ""
  );
  const [ar] = React.useState(
    props.location.ar || sessionStorage.getItem("ar") || ""
  );
  const [terms] = React.useState(
    props.location.terms || sessionStorage.getItem("terms") || ""
  );
  const [scan_code] = React.useState(
    props.location.scan_code || sessionStorage.getItem("scan_code") || ""
  );
  const [loading, setLoading] = React.useState(true);

  window.scrollTo(0, 0);
  const [redi, setRedi] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("fin_type", fin_type);
    sessionStorage.setItem("agr_code", agr_code);
    sessionStorage.setItem("body", body);
    sessionStorage.setItem("brand", brand);
    sessionStorage.setItem("reg_num", reg_num);
    sessionStorage.setItem("reg_province", reg_province);
    sessionStorage.setItem("asset_description", asset_description);
    sessionStorage.setItem("install", install);
    sessionStorage.setItem("os_balance", os_balance);
    sessionStorage.setItem("n_due_i", n_due_i);
    sessionStorage.setItem("flat_rate", flat_rate);
    sessionStorage.setItem("disbursement_date", disbursement_date);
    sessionStorage.setItem("ar", ar);
    sessionStorage.setItem("terms", terms);
    sessionStorage.setItem("scan_code", scan_code);

    let mount = true;
    axios
      .get(
        API_MYASK +
          "/Payment_History?" +
          "agr_code=" +
          sessionStorage.getItem("agr_code") +
          "&user_name=" +
          sessionStorage.getItem("userName") +
          "&token=" +
          sessionStorage.getItem("token")
      )
      .then((res) => {
        if (res.data.CODE === "200") {
          let form = [];
          for (let i in res.data.LIST_DATA) {
            form.push({
              last_paid_dt: res.data.LIST_DATA[i].LAST_PAID_DT,
              sum_paid: res.data.LIST_DATA[i].SUM_PAID,
            });
          }
          if (mount) {
            setManageAgrForm2(form);
            setLoading(false);
          }
        } else if (res.data.CODE === "404") {
          if (mount) {
            setManageAgrForm2([]);
            setLoading(false);
          }
        } else {
          if (mount) {
            sessionStorage.removeItem("userName");
            sessionStorage.removeItem("token");
            props.onLogout();
            setRedi(true);
            setLoading(false);
          }
        }
      });

    return () => {
      mount = false;
    };
  }, [agr_code]);

  const onSelectQRCode = (agr_code, scan_code) => {
    props.history.push({
      pathname: "/manageagreement-paymentlist",
      agr_code: agr_code,
      scan_code: scan_code,
    });
  };

  let redirectpage;
  if (redi === true) {
    redirectpage = <Redirect to="/login" />;
  }

  const path = {
    Path: [
      {
        title: "ข้อมูลสินเชื่อของฉัน",
        pathLink: "/manageagreement",
      },
      {
        title: agr_code,
        pathLink: "/manageagreement-detail",
      },
      {
        title: "ประวัติการชำระเงิน",
        pathLink: "/manageagreement-history",
      },
    ],
  };

  const [manageAgrForm2, setManageAgrForm2] = useState([]);

  const formElementsArray2 = [];
  for (let key in manageAgrForm2) {
    formElementsArray2.push({
      id: key,
      config: manageAgrForm2[key],
    });
  }
  return (
    <PathTree list={path.Path}>
      {redirectpage}
      <div className="ask__font__main">
        <ManageAgreementHistory
          children={formElementsArray2.map((formElement) => (
            <History
              key={formElement.id}
              date={formElement.config.last_paid_dt}
              price={formElement.config.sum_paid}
              loading={loading}
            />
          ))}
        ></ManageAgreementHistory>
      </div>
    </PathTree>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAgreementControlsHistory);
