import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, createMuiTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import AdjustIcon from '@material-ui/icons/Adjust';
import StepConnector from '@material-ui/core/StepConnector';
import { MuiThemeProvider } from '@material-ui/core';
import Loading from './../Loading/Loading';

const SteppersCovid19 = props => {
    const useStyles = makeStyles(theme => ({
        root: {
            width: '100%',
        },
    }));

    const theme = createMuiTheme({
        typography: {
            fontFamily: "inherit"
        },
        overrides: {
            // Style sheet name ⚛️
            MuiStepLabel: {
                // Name of the rule
                active: {
                    // Some CSS
                    fontWeight: 'bold !important'
                },
            },
            MuiTypography: {
                body2: {
                    fontSize: '1.4rem',
                    fontFamily: 'inherit'
                }
            },
            MuiStepConnector: {
                vertical: {
                    padding: '0'
                }
            },
            MuiStepper: {
                root: {
                    padding: '0'
                }
            }
        },
    });

    function getSteps() {
        if (props.apply_date !== "" && props.approve_date === "") {
            return [props.apply_msg + ' เมื่อวันที่ ' + props.apply_date,
                'อยู่ระหว่างดำเนินการพิจารณา'];
        }
        else if (props.approve_date !== "") {
            return [props.apply_msg + ' เมื่อวันที่ ' + props.apply_date,
            props.approve_msg + ' เมื่อวันที่ ' + props.approve_date];
        }
        else {
            return ['',
                ''];
        }
    }

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    useEffect(() => {
        let mount = true;
        if (mount) {
            if (props.apply_date !== "" && props.approve_date === "") {
                setActiveStep(0);
            }
            else if (props.approve_date !== "") {
                setActiveStep(1);
            }
        }

        return (() => {
            mount = false;
        })

    }, [props.apply_date]);

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        let mount = true;
        if (mount) {
            // setInterval(() => {
            setLoading(false);
            // }, 200);
        }

        return (() => {
            mount = false;
        })
    }, [activeStep])


    if (loading) {
        return <Loading />
    }

    const QontoConnector = withStyles({
        alternativeLabel: {
            // top: 10,
            // alignItems: 'flex'
        },
        active: {
            '& $line': {
                borderColor: '#33CC00',
            },
        },
        completed: {
            '& $line': {
                borderColor: '#33CC00',
            },
        },
        line: {
            borderColor: '#D5D8DC',
            borderTopWidth: 3,
            borderRadius: 1,
            margin: '0px 2px',
        },
    })(StepConnector);

    const useQontoStepIconStyles = makeStyles({
        root: {
            display: 'flex',
            width: '3rem',
            height: '3rem',
            zIndex: 1,
            padding: '.5rem',
            backgroundColor: '#ccc',
            borderRadius: '50%',
            color: '#fff',
            justifyContent: 'center',
            alignItems: 'center'
        },
        active: {
            backgroundColor: '#33CC00',
        },
        icon: {
            fontSize: '2rem',
        },
        completed: {
            backgroundColor: '#33CC00',
        },
    });

    function QontoStepIcon(props) {
        const classes = useQontoStepIconStyles();
        const { active, completed } = props;

        const icons = {
            1: <Check className={classes.icon} />,
            2: <AdjustIcon className={classes.icon} />,
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed
                })}
            >
                {completed ? icons[1]
                    : active ? icons[1] : null}


            </div>
        );
    }

    QontoStepIcon.propTypes = {
        active: PropTypes.bool,
        completed: PropTypes.bool,
        icon: PropTypes.node,
    };
    let stepper = ''
    stepper =
        <MuiThemeProvider theme={theme}>
            <Stepper activeStep={activeStep} connector={<QontoConnector />} orientation="vertical" >
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </MuiThemeProvider>
    return (
        <div className={classes.root}>
            {stepper}
        </div>
    );

}

export default SteppersCovid19;



