import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button } from "primereact/button";
import { ScrollTop } from "primereact/scrolltop";

import * as actions from "./../../../../store/action/index";
import ButtonTab from "../../../../components/UI/Button/Button";
import PathTree from "../../../../components/UI/PathTree/PathTree";
import MyAgreements from "./MyAgreements";
import MyPayments from "./MyPayments";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Divider } from "primereact/divider";
import trackingIcon from "./../../../../assets/icon/tracking_menu.png";
import taxIcon from "./../../../../assets/icon/tax_menu.png";
import historyPaymentIcon from "./../../../../assets/icon/history_payment_menu.png";
import { URL_PAYMENT_HISTORY } from "./../../../../shared/constant";

const Agreements = (props) => {
  let history = useHistory();
  const { tabIndex } = props;
  const path = {
    Path: [
      {
        title: "จัดการสินเชื่อ",
        pathLink: "/agreements",
      },
    ],
  };

  const handleAgreementTab = () => {
    props.setTabIndex(0);
  };

  const handlePaymentTab = () => {
    props.setTabIndex(1);
  };

  useEffect(() => {
    if (props.agreements == null) {
      props.getAgreements();
    }

    return () => {};
  }, []);

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  const onSelectTracking = () => {
    props.history.push({
      pathname: "/tracking-request",
    });
  };

  const onSelectRegisterEtax = () => {
    props.history.push({
      pathname: "/etax_ereceipt",
    });
  };

  const onOpenPaymentHistory = () => {
    let url = window.location.origin;
    if (window.location.hash.indexOf("#") == 0) {
      url += "/myask/#";
    }
    url += "/agreements";

    url = encodeURIComponent(url);

    window.location.href =
      URL_PAYMENT_HISTORY +
      "?user_name=" +
      sessionStorage.getItem("userName") +
      "&url=" +
      url;
  };

  return (
    // <PathTree list={path.Path}>
    <div>
      {redirectpage}
      <div className="grid mt-2" style={{ width: "100%" }}>
        <div className="col-12">
          <center>
            <p className="font-bold ask__font__title">ASK Connect Services</p>
          </center>
        </div>
        <div className="col-12 pl-6 py-4">
          <div
            className="flex align-items-center"
            style={{ overflowX: "auto" }}
          >
            <ButtonBase
              className="mr-4"
              focusRipple
              style={{
                alignItems: "center",
                fontFamily: "Prompt-Regular",
                height: "120px",
                minWidth: "100px",
              }}
              onClick={() => onSelectTracking()}
            >
              <div className="ask__font__main">
                <img
                  src={trackingIcon}
                  style={{ width: "48px", height: "48px" }}
                  alt="Tracking request"
                ></img>
                <p style={{ fontSize: "1.4rem" }}>
                  ติดตาม
                  <br />
                  คำร้องขอ
                </p>
              </div>
            </ButtonBase>

            <ButtonBase
              className="mr-4"
              focusRipple
              style={{
                alignItems: "center",
                fontFamily: "Prompt-Regular",
                height: "120px",
                minWidth: "100px",
              }}
              onClick={() => onSelectRegisterEtax()}
            >
              <div className="ask__font__main">
                <img
                  src={taxIcon}
                  style={{ width: "48px", height: "48px" }}
                  alt="Register eTax/eReceipt"
                ></img>
                <p style={{ fontSize: "1.4rem" }}>
                  สมัครบริการ
                  <br />
                  eTax/eReceipt
                </p>
              </div>
            </ButtonBase>
            <ButtonBase
              className="mr-4"
              focusRipple
              style={{
                alignItems: "center",
                fontFamily: "Prompt-Regular",
                height: "120px",
                minWidth: "100px",
              }}
              onClick={() => onOpenPaymentHistory()}
            >
              <div className="ask__font__main">
                <img
                  src={historyPaymentIcon}
                  style={{ width: "48px", height: "48px" }}
                  alt="Tracking request"
                ></img>
                <p style={{ fontSize: "1.4rem" }}>
                  ประวัติ
                  <br />
                  ชำระเงิน
                </p>
              </div>
            </ButtonBase>
          </div>
        </div>
        <Divider />
        <div className="col-12 mb-4">
          <center>
            <p className="font-bold ask__font__title">จัดการสินเชื่อ</p>
          </center>
        </div>
        <div
          className="grid col-12 branchpanel mx-3"
          style={{ paddingTop: "1rem" }}
        >
          <div className="col-6">
            <center>
              <ButtonTab
                btnType={tabIndex === 0 ? "menuclick" : "menunormal"}
                clicked={() => handleAgreementTab()}
              >
                สินเชื่อของฉัน
                {props.agreements != null
                  ? "(" + props.agreements.length + ")"
                  : ""}
              </ButtonTab>
            </center>
          </div>
          <div className="col-6">
            <center>
              <ButtonTab
                btnType={tabIndex === 1 ? "menuclick" : "menunormal"}
                clicked={() => handlePaymentTab()}
              >
                สินเชื่อที่จะชำระ({props.payment_agreements_size})
              </ButtonTab>
            </center>
          </div>

          <div className="col-12 my-4" style={{ background: "#F8F9FA" }}>
            {tabIndex === 0 && <MyAgreements />}
            {tabIndex === 1 && <MyPayments />}
          </div>
          <ScrollTop threshold={200} icon="pi pi-arrow-up" />
        </div>
      </div>
      {/* </PathTree> */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    agreements: state.agreement.agreements,
    payment_agreements_size: state.payment.payment_agreements.length,
    tabIndex: state.agreement.tabIndex,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
    getAgreements: () => dispatch(actions.getAgreements()),
    setTabIndex: (index) => dispatch(actions.setTabAgreementIndex(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
