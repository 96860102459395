import React from 'react';
import Grid from '@material-ui/core/Grid';
import Input from './../UI/Input/Input';
import Paper from './../UI/Paper/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../Apply/Apply.css';

const changeaddr = (props) => {

    const rows = [
        { number: '1111111', type: '1', name: 'testtesttest tsetstestste' },
        { number: '2222222', type: '1', name: 'asdsadasdad 12313asd' },
    ];

    const agr = [
        { number: '1111111', type: '1', agrcode: '123123', carnum: '1กข 2345' },
        { number: '1111111', type: '1', agrcode: '123123', carnum: '1กข 2345' },
    ];

    return (
        <div className="panel">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Input label="เลขที่สัญญา" styleLabel={{ float: 'left' }} disabled />
                </Grid>
                <Grid item xs={4}>
                    <Input label="ชื่อลูกค้า" styleLabel={{ float: 'left' }} disabled />
                </Grid>
                <Grid item xs={4}>
                    {/* <Input elementType="select" label="Request By" styleLabel={{ float: 'left' }} elementConfig={{ option:[ {value: 'test', displayValue: 'test'}]}} /> */}
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table style={{ width: '100%' }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '20%' }}>รหัส</TableCell>
                                    <TableCell style={{ width: '20%' }}>ประเภท</TableCell>
                                    <TableCell style={{ width: '60%' }}>ชื่อ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.number}>
                                        <TableCell>{row.number}</TableCell>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <p className="text-center" style={{ color: 'red' }}>สัญญาที่เกี่ยวข้อง</p>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table style={{ width: '100%' }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '20%' }}>รหัส</TableCell>
                                    <TableCell style={{ width: '20%' }}>ประเภท</TableCell>
                                    <TableCell style={{ width: '30%' }}>เลขที่สัญญา</TableCell>
                                    <TableCell style={{ width: '30%' }}>ทะเบียน</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agr.map((row) => (
                                    <TableRow key={row.number}>
                                        <TableCell>{row.number}</TableCell>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.agrcode}</TableCell>
                                        <TableCell>{row.carnum}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                    <Paper padding="1.5rem" align="left" border="1px solid" borderColor="#909090" width="100%" borderRadius="0px"
                        children={
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p className="text-center" style={{ color: 'red' }}>Current</p>
                                </Grid>
                                <Grid item xs={4}>
                                    <Input />
                                </Grid>
                                <Grid item xs={8}>
                                    <Input />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input label="ที่อยู่" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input label="รหัสที่อยู่" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <Input label="รหัสที่อยู่" styleLabel={{ float: 'left' }} /> */}
                                </Grid>
                                <Grid item xs={3}>
                                    <Input label="รหัสไปรษณีย์" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input label="สถานที่ใกล้เคียง" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="Tel1" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="Tel2" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="Tel3" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input label="Remark" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="โทรสาร" styleLabel={{ float: 'left' }} />
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper padding="1.5rem" align="left" border="1px solid" borderColor="#909090" width="100%" borderRadius="0px"
                        children={
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p className="text-center" style={{ color: 'red' }}>Card</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input label="ที่อยู่" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input label="รหัสที่อยู่" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <Input label="รหัสที่อยู่" styleLabel={{ float: 'left' }} /> */}
                                </Grid>
                                <Grid item xs={3}>
                                    <Input label="รหัสไปรษณีย์" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="Tel1" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="Tel2" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="Tel3" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input label="Remark" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="โทรสาร" styleLabel={{ float: 'left' }} />
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper padding="1.5rem" align="left" border="1px solid" borderColor="#909090" width="100%" borderRadius="0px"
                        children={
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p className="text-center" style={{ color: 'red' }}>Office</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input label="ที่อยู่" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="รหัสที่อยู่" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={8}>
                                    {/* <Input label="รหัสที่อยู่" styleLabel={{ float: 'left' }} /> */}
                                </Grid>
                                <Grid item xs={12}>
                                    <Input label="สถานที่ใกล้เคียง" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="รหัสไปรษณีย์" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="Tel1" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="Tel2" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="Tel3" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={7}>
                                    <Input label="โทรศัพท์ที่ทำงาน" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="โทรสาร" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={7}>
                                    <Input label="บริษัท" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="แผนก" styleLabel={{ float: 'left' }} />
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper padding="1.5rem" align="left" border="1px solid" borderColor="#909090" width="100%" borderRadius="0px"
                        children={
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p className="text-center" style={{ color: 'red' }}>Mail</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input label="ที่อยู่" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="รหัสที่อยู่" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={8}>
                                    {/* <Input label="รหัสที่อยู่" styleLabel={{ float: 'left' }} /> */}
                                </Grid>
                                <Grid item xs={4}>
                                    <Input label="รหัสไปรษณีย์" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input label="ชื่อผู้ติดต่อ" styleLabel={{ float: 'left' }} />
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper padding="1.5rem" align="left" border="1px solid" borderColor="#909090" width="100%" borderRadius="0px"
                        children={
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p className="text-center" style={{ color: 'red' }}>Telephone</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <Input label="SMS" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input label="แก้ไขครั้งล่าสุดโดย" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input label="โทรศัพท์มือถือ 2" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input label="วันที่แก้ไขครั้งล่าสุด" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input label="โทรศัพท์มือถือ 3" styleLabel={{ float: 'left' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Input label="Email Address" styleLabel={{ float: 'left' }} />
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
            </Grid>
        </div>
    )
}
export default changeaddr;