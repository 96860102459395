import * as actionTypes from "./actionTypes";

export const insertAgreementPayList = (
  agreement,
  agr_code,
  items,
  is_closed
) => {
  return {
    type: actionTypes.INSERT_AGREEMENT_PAY_LIST,
    agreement: agreement,
    agr_code: agr_code,
    items: items,
    is_closed: is_closed,
  };
};

export const removeAgreementPayList = (agr_code) => {
  return {
    type: actionTypes.REMOVE_AGREEMENT_PAY_LIST,
    agr_code: agr_code,
  };
};

export const updateAgreementPayList = (agr_data, payment_detail) => {
  return {
    type: actionTypes.UPDATE_AGREEMENT_PAY_LIST,
    agreement: agr_data,
    payment_detail: payment_detail,
  };
};

export const getPaymentItem = (agr_code, com_code, run_no) => {
  return {
    type: actionTypes.GET_PAYMENT_ITEM,
    agr_code: agr_code,
    com_code: com_code,
    run_no: run_no,
  };
};

export const getPaymentItemStart = () => {
  return {
    type: actionTypes.GET_PAYMENT_ITEM_START,
  };
};

export const getPaymentItemSuccess = (paymentItems) => {
  return {
    type: actionTypes.GET_PAYMENT_ITEM_SUCCESS,
    paymentItems: paymentItems,
  };
};

export const getPaymentItemFailed = (error) => {
  return {
    type: actionTypes.GET_PAYMENT_ITEM_FAILED,
    error: error,
  };
};

export const getFullSummaryPayment = (agr_code, amount) => {
  return {
    type: actionTypes.GET_FULL_SUMMARY_PAYMENT,
    agr_code: agr_code,
    amount: amount,
  };
};

export const getFullSummaryPaymentStart = () => {
  return {
    type: actionTypes.GET_FULL_SUMMARY_PAYMENT_START,
  };
};

export const getFullSummaryPaymentSuccess = (detail) => {
  return {
    type: actionTypes.GET_FULL_SUMMARY_PAYMENT_SUCCESS,
    detail: detail,
  };
};

export const getFullSummaryPaymentFailed = (error) => {
  return {
    type: actionTypes.GET_FULL_SUMMARY_PAYMENT_FAILED,
    error: error,
  };
};

export const clearPaymentState = () => {
  return {
    type: actionTypes.CLEAR_PAYMENT_STATE,
  };
};

export const generateBatch = (payments, runNo) => {
  return {
    type: actionTypes.GENERATE_BATCH,
    payments: payments,
    runNo: runNo,
  };
};

export const generateBatchStart = () => {
  return {
    type: actionTypes.GENERATE_BATCH_START,
  };
};

export const generateBatchSuccess = (batchNo) => {
  return {
    type: actionTypes.GENERATE_BATCH_SUCCESS,
    batchNo: batchNo,
  };
};

export const generateBatchFailed = (error) => {
  return {
    type: actionTypes.GENERATE_BATCH_FAILED,
    error: error,
  };
};
