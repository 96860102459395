import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '../UI/Paper/Paper';
import Link from '@material-ui/core/Link';
import EditIcon from '@material-ui/icons/Edit';
import Button from './../../components/UI/Button/Button';
import '../Apply/Apply.css';

const insurance = (props) => {

    let button;
    // console.log(props)
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let todate = new Date();
    let lastdate = new Date(props.ins_exp);

    if (todate > lastdate) {
        button = <Button btnType="normal" width="70%" children="ต่อประกัน" color="#A8B212" borderRadius="0px" border="0px" clicked={props.onSelectRenew}></Button>;
    }
    else {
        if (parseFloat(props.paid_amount) < parseFloat(props.premium)) {
            button = <Button btnType="normal" width="70%" children="จ่ายค่าประกัน" borderRadius="0px" border="0px" clicked={props.onSelectPayment}></Button>;
        }
        else {
            button = <br />;
        }
    }

    return (
        <div>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Paper padding="1.5rem" align="left" border="1px solid" width="100%" borderColor="#909090" borderRadius="0px"
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p className="text-bold text-center" style={{ "color": "#970093", "fontSize": "1.8rem" }}><u>{props.ins_type}</u></p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ยี่ห้อ / รุ่น :</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.brand} - {props.model_name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ทะเบียน :</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.reg_num}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ปีรุ่น :</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.year}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ชื่อบริษัทประกัน :</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.ins_com}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ทุนประกัน :</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{numberWithCommas(parseFloat(Math.round(props.cov_amt * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>เบี้ยประกัน :</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{numberWithCommas(parseFloat(Math.round(props.total_prem * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>หมายเลขกรมธรรม์ :</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.pol_num}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ระยะเวลาคุ้มครอง :</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.ins_date_th} - {props.ins_exp_th}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-bold">เลขที่สัญญาเช่าซื้อ :</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.agr_code}</p>
                            </Grid>
                            <Grid item xs={false} sm={false} md={3}>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <center>{button}</center>
                            </Grid>
                            <Grid item xs={false} sm={false} md={3}>
                            </Grid>
                        </Grid >
                    }
                />
            </Grid>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <br />
            {/* <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Paper padding="1.5rem" align="left" border="1px solid" width="100%" borderColor="#909090" borderRadius="0px"
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p className="text-bold text-center" style={{ "color": "#777F76", "fontSize": "1.8rem" }}><u>ประกันภัยรถยนต์ภาคบังคับ</u></p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ยี่ห้อ / รุ่น</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ทะเบียน</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ปีรุ่น</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ชื่อบริษัทประกัน</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ทุนประกัน</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>เบี้ยประกัน</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>หมายเลขกรมธรรม์</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.no}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ระยะเวลาคุ้มครอง</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.firstdate} - {props.lastdate}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-bold">เลขที่สัญญาเช่าซื้อ</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.agrcode}</p>
                            </Grid>
                            <Grid item xs={false} sm={false} md={3}>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <center><Button btnType="normal" width="70%" children="จ่ายค่าประกัน" borderRadius="0px" border="0px" clicked={props.onSelectPayment}></Button></center>
                            </Grid>
                            <Grid item xs={false} sm={false} md={3}>
                            </Grid>
                        </Grid >
                    }
                />
            </Grid>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <br />
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Paper padding="1.5rem" align="left" border="1px solid" width="100%" borderColor="#909090" borderRadius="0px"
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p className="text-bold text-center" style={{ "color": "#C3BB46", "fontSize": "1.8rem" }}><u>ประกันภัยเบ็ดเตล็ด</u></p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ยี่ห้อ / รุ่น</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ทะเบียน</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ปีรุ่น</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ชื่อบริษัทประกัน</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ทุนประกัน</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>เบี้ยประกัน</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.name}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>หมายเลขกรมธรรม์</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.no}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ระยะเวลาคุ้มครอง</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.firstdate} - {props.lastdate}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-bold">เลขที่สัญญาเช่าซื้อ</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.agrcode}</p>
                            </Grid>
                            <Grid item xs={false} sm={false} md={3}>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <center><Button btnType="normal" width="70%" children="ต่อประกัน" color="#A8B212" borderRadius="0px" border="0px" clicked={props.onSelectRenew}></Button></center>
                            </Grid>
                            <Grid item xs={false} sm={false} md={3}>
                            </Grid>
                        </Grid >
                    }
                />
            </Grid>
            <Grid item xs={false} sm={false} md={3}>
            </Grid> */}
        </div>
    );
}

export default insurance;
