import React, { Fragment, useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Chip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBillAlt,
  faFileAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import ButtonBase from "@material-ui/core/ButtonBase";
import { ProgressBar } from "primereact/progressbar";
import { numberWithCommas } from "../../../../shared/utility";
import * as actionTypes from "../../../../store/action/actionTypes";
import Loading from "../../../UI/Loading/Loading";
import { Button } from "primereact/button";
import qrcode from "./../../../../assets/qrcode.png";
import barcode from "./../../../../assets/barcode.png";

const showAreEqual = (prev, next) => {
  return prev.agreement === next.agreement && prev.canInsert === next.canInsert;
};

const AgreementItem = (props) => {
  const { agreement } = props;
  let borderColor = "";
  if (agreement.fin_type === "สินเชื่อส่วนบุคคล") {
    borderColor = "#F003C8";
  } else {
    borderColor = "#075796";
  }

  useEffect(() => {
    props.onInsertPayData(agreement.agr_code, agreement.com_code);
  }, []);

  const getViewOnInsertPayData = (key, isCurrency) => {
    if (key != null) {
      return (
        <p className="text-right">
          {isCurrency
            ? numberWithCommas(
                parseFloat(Math.round(key * 100) / 100).toFixed(2)
              ) + " บาท"
            : key.toString()}
        </p>
      );
    } else if (agreement.pay_data_status === actionTypes.VIEW_STATE.LOADING) {
      return (
        <div style={{ textAlign: "end" }}>
          <Loading style={{ width: "25px", height: "25px" }} />
        </div>
      );
    }
    return null;
  };

  const onPayment = () => {
    props.onPayment(agreement);
  };

  const getActionsView = () => {
    if (agreement.pay_data_status === actionTypes.VIEW_STATE.SUCCESS) {
      return (
        <Fragment>
          <ButtonBase
            className="col-4"
            focusRipple
            disabled={!props.canInsert || agreement.x_condition !== ""}
            style={{
              alignItems: "baseline",
              fontFamily: "Prompt-Regular",
              opacity:
                props.canInsert &&
                agreement.x_condition === "" &&
                agreement.overdue_amount != null
                  ? 1
                  : 0.3,
            }}
            onClick={() =>
              props.canInsert &&
              agreement.x_condition === "" &&
              agreement.overdue_amount != null
                ? onPayment(agreement)
                : null
            }
          >
            <div className="flex flex-column p-3">
              <div className="mb-2">
                <FontAwesomeIcon icon={faMoneyBillAlt} size="2x" />
              </div>
              <div>ชำระค่างวด</div>
            </div>
          </ButtonBase>

          <ButtonBase
            className="col-4"
            focusRipple
            style={{ alignItems: "baseline", fontFamily: "Prompt-Regular" }}
            onClick={() => props.onView(agreement.agr_code, agreement.com_code)}
          >
            <div className="flex flex-column pt-3">
              <div className="mb-2">
                <FontAwesomeIcon icon={faFileAlt} size="2x" />
              </div>
              <div>
                รายละเอียด
                <br />
                สัญญา
              </div>
            </div>
          </ButtonBase>

          <ButtonBase
            className="col-4"
            focusRipple
            disabled={!props.canInsert || agreement.x_condition !== ""}
            style={{
              alignItems: "baseline",
              fontFamily: "Prompt-Regular",
              opacity:
                props.canInsert &&
                agreement.x_condition === "" &&
                agreement.overdue_amount != null
                  ? 1
                  : 0.3,
            }}
            onClick={() =>
              props.canInsert &&
              agreement.x_condition === "" &&
              agreement.overdue_amount != null
                ? props.onClosedAgreement(
                    agreement.agr_code,
                    agreement.com_code
                  )
                : null
            }
          >
            <div className="flex flex-column p-3">
              <div className="mb-2">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  size="2x"
                  color="#f44336"
                />
              </div>
              <div>ปิดบัญชี</div>
            </div>
          </ButtonBase>
        </Fragment>
      );
    } else if (agreement.pay_data_status === actionTypes.VIEW_STATE.LOADING) {
      return (
        <div className="col-12">
          <center>
            <Loading />
          </center>
        </div>
      );
    } else if (agreement.pay_data_status === actionTypes.VIEW_STATE.ERROR) {
      return (
        <div className="col-12">
          <center>
            <Button
              label="โหลดใหม่"
              className="p-button-raised p-button-text"
              onClick={() =>
                props.onInsertPayData(agreement.agr_code, agreement.com_code)
              }
            />
          </center>
        </div>
      );
    }
  };

  return (
    <Card
      style={{
        color: "#000000de",
        border: "2px solid",
        borderRadius: "3px",
        borderColor: borderColor,
        fontFamily: "Prompt-Regular",
      }}
    >
      <div className="ask__font__main">
        <div className="flex align-items-center justify-content-between">
          <p className="text-bold">เลขที่สัญญา {agreement.agr_code}</p>
          {!props.canInsert && (
            <Chip
              label="อยู่ในรายการชำระเงิน"
              style={{
                color: "#ffffff",
                background: "#f44336",
                fontFamily: "Prompt-Regular",
                fontSize: "1.2rem",
              }}
              onDelete={() => {
                props.onRemove(agreement.agr_code);
              }}
            />
          )}
        </div>

        <div className="flex mt-5">
          {agreement.brand ? (
            <p className="text-bold mr-4">{agreement.brand}</p>
          ) : null}
          {agreement.reg_num ? (
            <p className="text-bold mr-4">{agreement.reg_num}</p>
          ) : null}
          {agreement.reg_province ? (
            <p className="text-bold mr-4">{agreement.reg_province}</p>
          ) : null}
          {agreement.brand === "" &&
            agreement.reg_num === "" &&
            agreement.reg_province === "" && (
              <p className="text-bold mr-4">
                <br />
              </p>
            )}
        </div>
        {agreement.x_condition !== "" && (
          <div className="grid my-6 py-6 align-items-center">
            <div
              className="col-12"
              style={{ color: "#296DD6", textAlign: "center" }}
            >
              {agreement.x_condition}
            </div>
          </div>
        )}
        {agreement.x_condition === "" && (
          <Fragment>
            <div className="grid mt-5 align-items-center">
              <div className="col-8">
                <ProgressBar
                  value={(
                    (parseFloat(agreement.paid_terms) /
                      parseFloat(agreement.terms)) *
                    100
                  ).toString()}
                  showValue={false}
                  color="#00FF44"
                />
              </div>
              <div className="col-4">
                <p className="font-bold ml-3">
                  {agreement.paid_terms + " / " + agreement.terms + " งวด"}
                </p>
              </div>
            </div>

            <div className="grid mt-5">
              <div className="col-12 grid">
                <div className="col-6">
                  <p>
                    {agreement.b_type === "Loan"
                      ? "ยอดเงินต้นทั้งหมด"
                      : "ยอดทั้งหมด"}
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-right">
                    {numberWithCommas(
                      parseFloat(
                        Math.round(agreement.original_amount * 100) / 100
                      ).toFixed(2)
                    )}
                    {"  "}
                    บาท
                  </p>
                </div>
              </div>
              <div className="col-12 grid">
                <div className="col-6">
                  <p>
                    {" "}
                    {agreement.b_type === "Loan"
                      ? "ยอดเงินต้นที่ชำระแล้ว"
                      : "ชำระแล้ว"}
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-right">
                    {numberWithCommas(
                      parseFloat(
                        Math.round(agreement.paid_amount * 100) / 100
                      ).toFixed(2)
                    )}
                    {"  "}
                    บาท
                  </p>
                </div>
              </div>
              <div className="col-12 grid">
                <div className="col-6">
                  <p>
                    {agreement.b_type === "Loan"
                      ? "ยอดเงินต้นคงเหลือ"
                      : "คงเหลือ"}
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-right">
                    {numberWithCommas(
                      parseFloat(
                        Math.round(agreement.os_balance * 100) / 100
                      ).toFixed(2)
                    )}
                    {"  "}
                    บาท
                  </p>
                </div>
              </div>
              <div className="col-12 grid">
                <div className="col-6">
                  <p>ยอดค้างชำระ ณ ปัจจุบัน</p>
                </div>
                <div className="col-6">
                  {getViewOnInsertPayData(agreement.overdue_amount, true)}
                </div>
              </div>
              <div className="col-12 grid">
                <div className="col-6">
                  <p>ค่างวดค้างชำระ ตั้งแต่วันที่</p>
                </div>
                <div className="col-6">
                  {getViewOnInsertPayData(agreement.n_due_i_date, false)}
                </div>
              </div>

              <div className="col-12 grid">
                <div className="col-6">
                  <p>
                    {/* {agreement.b_type === "Loan" ? "ค่างวด" : "ค่างวดปัจจุบัน"} */}
                    ค่างวด
                  </p>
                </div>
                <div className="col-6">
                  {getViewOnInsertPayData(agreement.installment_i_vat, true)}
                </div>
              </div>

              <div className="col-12 grid">
                <div className="col-6">
                  <p>ค่างวด ครบกำหนด</p>
                </div>
                <div className="col-6">
                  {getViewOnInsertPayData(agreement.n_due_i, false)}
                </div>
              </div>
              <div className="col-12 grid mt-3">
                <div className="col-12">
                  <p className="text-bold">เจ้าหน้าที่ดูแล</p>
                </div>
                <div className="col-12">
                  <p className="text-left">{agreement.officer}</p>
                </div>
              </div>
              <div className="col-12 grid mt-3">
                <div
                  className="flex justify-content-around justify-content-end"
                  style={{ width: "100%" }}
                >
                  <ButtonBase
                    focusRipple
                    style={{
                      alignItems: "baseline",
                      fontFamily: "Prompt-Regular",
                    }}
                    className="p-4"
                    onClick={() =>
                      props.onOpenQR(
                        agreement.scan_code,
                        agreement.ref_1,
                        agreement.ref_2,
                        true
                      )
                    }
                  >
                    <div className="ask__font__main">
                      <img
                        src={qrcode}
                        style={{ width: "60px", height: "60px" }}
                        alt="QR Code"
                      ></img>
                      <p className="font-bold">QR Code</p>
                    </div>
                  </ButtonBase>
                  <ButtonBase
                    focusRipple
                    style={{
                      alignItems: "baseline",
                      fontFamily: "Prompt-Regular",
                    }}
                    className="p-4"
                    onClick={() =>
                      props.onOpenQR(
                        agreement.scan_code,
                        agreement.ref_1,
                        agreement.ref_2,
                        false
                      )
                    }
                  >
                    <div className="ask__font__main">
                      <img
                        src={barcode}
                        style={{ width: "60px", height: "60px" }}
                        alt="Bar Code"
                      ></img>
                      <p className="font-bold">Bar Code</p>
                    </div>
                  </ButtonBase>
                </div>
              </div>
              <div className="col-12 grid mt-2">
                <div className="col-6">
                  <p className="font-bold" style={{ color: "#8997A6" }}>
                    เลขอ้างอิงที่ 1
                  </p>
                  <p className="font-bold">{agreement.ref_1}</p>
                </div>
                <div className="col-6">
                  <p className="font-bold" style={{ color: "#8997A6" }}>
                    เลขอ้างอิงที่ 2
                  </p>
                  <p className="font-bold">{agreement.ref_2}</p>
                </div>
              </div>
              <div className="hline mt-5" />
            </div>
          </Fragment>
        )}

        <div className="grid mt-4">{getActionsView()}</div>
      </div>
    </Card>
  );
};

export default React.memo(AgreementItem, showAreEqual);
