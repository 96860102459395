import React, { Fragment } from "react";

import { Button } from "primereact/button";

const CompleteEtax = (props) => {
  return (
    <Fragment>
      <div
        className="col-12 sm:col-12 md:col-8 md:col-offset-2 
              lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2 mt-6 px-6"
      >
        <center>
          <p
            className="text-bold ask__font__title"
            style={{ color: "#EC2323" }}
          >
            ทางบริษัทฯ ขอขอบคุณท่านเป็นอย่างยิ่ง
            ที่สนใจรับใบเสร็จรับเงิน/ใบกำกับภาษี ในรูปแบบอิเล็กทรอนิกส์
          </p>
        </center>
        <div style={{ marginTop: "8rem" }}></div>
        <center>
          <p className="ask__font__title">
            บริษัทฯจะเริ่มส่งเอกสารในรูปแบบอิเล็กทรอนิกส์ ให้ท่านในรอบบิลถัดไป
          </p>
        </center>
        <div style={{ marginTop: "8rem" }}></div>

        <div className="grid justify-content-center mt-6">
          <Button
            label="กลับไปหน้าหลัก"
            className="col-5 sm:col-5  xl:col-3
            p-button-success mt-6 p-3"
            style={{
              borderRadius: "0px",
              backgroundColor: "#ff9600",
            }}
            onClick={() => props.onClickHandler()}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CompleteEtax;
