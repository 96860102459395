import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "./../../../store/action/index";

const Logout = (props) => {
  useEffect(() => {
    props.clearAgreementState();
    props.clearPaymentState();
    props.onLogout(props.lineId, sessionStorage.getItem("userName"));
  }, []);

  return <Redirect to="/" />;
};

const mapStateToProps = (state) => {
  return {
    lineId: state.line.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (lineId, userName) => dispatch(actions.logout(lineId, userName)),
    clearAgreementState: () => dispatch(actions.clearAgreementState()),
    clearPaymentState: () => dispatch(actions.clearPaymentState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
