import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '../UI/Paper/Paper';
import Link from '@material-ui/core/Link';
import Button from './../UI/Button/Button';
import Input from './../UI/Input/Input';
import Modal from './../UI/Modal/Modal';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import '../Apply/Apply.css';
import bbl from './../../assets/bbl.png'
import scb from './../../assets/scb.png'
import kbank from './../../assets/kbank.png'
import qrcode from './../../assets/qrcode.png'
import creditcard from './../../assets/creditcard.png'

const payment = (props) => {

    const amount = {
        color: 'red',
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const onChangeHandler = (val, id) => {
        return;
    }
    return (
        <div>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Paper padding="1.5rem" align="left" border="1px solid" borderColor="#001F3F" width="90%" borderRadius="0px" color="#001F3F"
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <p style={{ color: 'white' }}>ยอดชำระรวม</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right text-bold" style={{ color: 'white', fontSize: '2rem' }}>{numberWithCommas(parseFloat(Math.round(props.amount * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={8}>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4}>
                                <div className="text-right"><Link style={{
                                    fontSize: "1.6rem",
                                    color: "#ff9600",
                                }} component="button" variant="body2" onClick={props.handleOpen}>
                                    <InfoOutlinedIcon style={{ color: "#FF851B", width: "20px", height: "20px", marginBottom: "-5px" }} /> ดูรายละเอียด
                            </Link></div>
                            </Grid>
                        </Grid>
                    } />
            </Grid>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <br />
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Paper padding="1.5rem" align="left" border="1px solid" width="90%" borderColor="#909090" borderRadius="0px"
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p className="text-bold text-left">ชำระเงินผ่าน</p>
                            </Grid>
                            <Grid item xs={3}>
                                <center><Link style={{
                                    fontSize: "1.6rem",
                                }} component="button" variant="body2" onClick={props.onSelectDetail}>
                                    <img src={creditcard} style={{ width: '75px', height: '60px' }} alt="บัตรเครดิต"></img>
                                    <p>บัตรเครดิต</p>
                                </Link></center>
                            </Grid>
                            <Grid item xs={3}>
                                <center><Link style={{
                                    fontSize: "1.6rem",
                                }} component="button" variant="body2" onClick={props.onSelectDetail}>
                                    <img src={bbl} style={{ width: '60px', height: '60px' }} alt="ธนาคารกรุงเทพ"></img>
                                    <p>ธนาคารกรุงเทพ</p>
                                </Link></center>
                            </Grid>
                            <Grid item xs={3}>
                                <center><Link style={{
                                    fontSize: "1.6rem",
                                }} component="button" variant="body2" onClick={props.onSelectDetail}>
                                    <img src={scb} style={{ width: '60px', height: '60px' }} alt="ธนาคารไทยพานิชย์"></img>
                                    <p>ธนาคารไทยพานิชย์</p>
                                </Link></center>
                            </Grid>
                            <Grid item xs={3}>
                                <center><Link style={{
                                    fontSize: "1.6rem",
                                }} component="button" variant="body2" onClick={props.onSelectDetail}>
                                    <img src={kbank} style={{ width: '60px', height: '60px' }} alt="ธนาคารกสิกรไทย"></img>
                                    <p>ธนาคารกสิกรไทย</p>
                                </Link></center>
                            </Grid>
                            <Grid item xs={3}>
                                <center><Link style={{
                                    fontSize: "1.6rem",
                                }} component="button" variant="body2" onClick={props.onSelectQRCode}>
                                    <img src={qrcode} style={{ width: '60px', height: '60px' }} alt="QR Code/Bar Code"></img>
                                    <p>QR Code/Bar Code</p>
                                </Link></center>
                            </Grid>
                        </Grid >
                    } />

            </Grid>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <Modal open={props.open} close={props.close} top="50%" left="50%" width="100%" transform="translate(-50%, -50%)" children={
                <div style={{ fontSize: '1.6rem' }}>
                    <center>
                        <Grid item xs={false} sm={false} md={3}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Paper padding="1.5rem" align="left" width="90%" borderRadius="0px"
                                children={
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className="text-bold text-center">รายละเอียด
                                                <span style={{ float: 'right', cursor: 'pointer' }} onClick={props.close}>
                                                    ปิด
                                                </span>
                                            </p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="hline">
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p className="text-bold text-left"><u>เลขที่สัญญา {props.number}</u></p>
                                        </Grid>
                                        {props.carcountry || props.carnumber || props.carbrand
                                            ? <Grid item xs={12}>
                                                <p className="text-bold text-left">{props.carbrand} {props.carnumber} {props.carcountry}</p>
                                            </Grid>
                                            : <Grid item xs={12}>
                                                <p className="text-bold text-left"><br /></p>
                                            </Grid>
                                        }
                                        <Grid item xs={6}>
                                            <p className="text-left">ค่างวด</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(2000 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-left">ค่าเบี้ยปรับล่าช้าสะสม</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(2000 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-left">ค่าเบี้ยประกันรถยนต์ ภาคสมัครใจ</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(2000 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-left">ค่าเบี้ยประกันรถยนต์ ภาคบังคับ</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(2000 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-bold">รวม</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-right text-bold" style={{ color: 'red' }} ><u>{numberWithCommas(parseFloat(Math.round(2000 * 100) / 100).toFixed(2))}</u> <span style={{ color: 'black' }}>บาท</span></p>
                                        </Grid>
                                    </Grid>
                                }>
                            </Paper>
                        </Grid>
                        <Grid item xs={false} sm={false} md={3}>
                        </Grid>

                    </center>
                </div>
            }>
            </Modal>
        </div>
    );
}

export default payment;
