import * as actionTypes from "./actionTypes";

export const getAgreements = () => {
  return {
    type: actionTypes.GET_AGREEMENTS,
  };
};

export const getAgreementsStart = () => {
  return {
    type: actionTypes.GET_AGREEMENTS_START,
  };
};

export const getAgreementsSuccess = (runNo, agreements) => {
  return {
    type: actionTypes.GET_AGREEMENTS_SUCCESS,
    agreements: agreements,
    run_no: runNo,
  };
};

export const getAgreementsFailed = (error) => {
  return {
    type: actionTypes.GET_AGREEMENTS_FAILED,
    error: error,
  };
};

export const getAgreementDetail = (agr_code, com_code, run_no) => {
  return {
    type: actionTypes.GET_AGREEMENT_DETAIL,
    agr_code: agr_code,
    com_code: com_code,
    run_no: run_no,
  };
};

export const getAgreementDetailStart = () => {
  return {
    type: actionTypes.GET_AGREEMENT_DETAIL_START,
  };
};

export const getAgreementDetailSuccess = (detail) => {
  return {
    type: actionTypes.GET_AGREEMENT_DETAIL_SUCCESS,
    detail: detail,
  };
};

export const getAgreementDetailFailed = (error) => {
  return {
    type: actionTypes.GET_AGREEMENT_DETAIL_FAILED,
    error: error,
  };
};

export const getInsuranceAgreement = (agr_code) => {
  return {
    type: actionTypes.GET_INSURANCE_AGREEMENT,
    agr_code: agr_code,
  };
};

export const getInsuranceAgreementStart = () => {
  return {
    type: actionTypes.GET_INSURANCE_AGREEMENT_START,
  };
};

export const getInsuranceAgreementSuccess = (insurances) => {
  return {
    type: actionTypes.GET_INSURANCE_AGREEMENT_SUCCESS,
    insurances: insurances,
  };
};

export const getInsuranceAgreementFailed = (error) => {
  return {
    type: actionTypes.GET_INSURANCE_AGREEMENT_FAILED,
    error: error,
  };
};

export const getPaymentAgreement = (agr_code) => {
  return {
    type: actionTypes.GET_PAYMENT_AGREEMENT,
    agr_code: agr_code,
  };
};

export const getPaymentAgreementStart = () => {
  return {
    type: actionTypes.GET_PAYMENT_AGREEMENT_START,
  };
};

export const getPaymentAgreementSuccess = (payments_history) => {
  return {
    type: actionTypes.GET_PAYMENT_AGREEMENT_SUCCESS,
    payments_history: payments_history,
  };
};

export const getPaymentAgreementFailed = (error) => {
  return {
    type: actionTypes.GET_PAYMENT_AGREEMENT_FAILED,
    error: error,
  };
};

export const filterAgreements = (value) => {
  return {
    type: actionTypes.FILTER_AGREEMENTS,
    filter: value,
  };
};

export const setTabAgreementIndex = (index) => {
  return {
    type: actionTypes.SET_TAB_AGREEMENT_INDEX,
    tab_index: index,
  };
};

export const getClosedAgreementDetail = (agr_code, com_code, run_no) => {
  return {
    type: actionTypes.GET_CLOSED_AGREEMENT_DETAIL,
    agr_code: agr_code,
    com_code: com_code,
    run_no: run_no,
  };
};

export const getClosedAgreementDetailStart = () => {
  return {
    type: actionTypes.GET_CLOSED_AGREEMENT_DETAIL_START,
  };
};

export const getClosedAgreementDetailSuccess = (detail) => {
  return {
    type: actionTypes.GET_CLOSED_AGREEMENT_DETAIL_SUCCESS,
    detail: detail,
  };
};

export const getClosedAgreementDetailFailed = (error) => {
  return {
    type: actionTypes.GET_CLOSED_AGREEMENT_DETAIL_FAILED,
    error: error,
  };
};
export const clearAgreementState = () => {
  return {
    type: actionTypes.CLEAR_AGREEMENT_STATE,
  };
};

export const insertPayData = (agr_code, com_code, run_no) => {
  return {
    type: actionTypes.INSERT_PAY_DATA,
    agr_code: agr_code,
    com_code: com_code,
    run_no: run_no,
  };
};

export const insertPayDataStart = (agr_code) => {
  return {
    type: actionTypes.INSERT_PAY_DATA_START,
    agr_code: agr_code,
  };
};

export const insertPayDataSuccess = (agr_code, data) => {
  return {
    type: actionTypes.INSERT_PAY_DATA_SUCCESS,
    data: data,
    agr_code: agr_code,
  };
};

export const insertPayDataFailed = (agr_code, error) => {
  return {
    type: actionTypes.INSERT_PAY_DATA_FAILED,
    error: error,
    agr_code: agr_code,
  };
};

export const getPaymentDetailOrigin = (agr_code, com_code, run_no) => {
  return {
    type: actionTypes.GET_PAYMENT_DETAIL_ORIGIN,
    agr_code: agr_code,
    com_code: com_code,
    run_no: run_no,
  };
};

export const getPaymentDetailOriginStart = (agr_code) => {
  return {
    type: actionTypes.GET_PAYMENT_DETAIL_ORIGIN_START,
    agr_code: agr_code,
  };
};

export const getPaymentDetailOriginSuccess = (
  agr_code,
  paymentDetailOrigin
) => {
  return {
    type: actionTypes.GET_PAYMENT_DETAIL_ORIGIN_SUCCESS,
    paymentDetailOrigin: paymentDetailOrigin,
    agr_code: agr_code,
  };
};

export const getPaymentDetailOriginFailed = (error) => {
  return {
    type: actionTypes.GET_PAYMENT_DETAIL_ORIGIN_FAILED,
    error: error,
  };
};
