import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../UI/Button/Button";
import Paper from "../UI/Paper/Paper";
import "../Apply/Apply.css";

const closeacc = (props) => {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const topic = {
    marginTop: "2rem",
  };
  return (
    <div>
      <Grid item xs={false} sm={false} md={3}></Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper
          padding="1.5rem"
          align="left"
          border="1px solid"
          borderColor="#001F3F"
          width="90%"
          borderRadius="0px"
          color="#001F3F"
          children={
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <p style={{ color: "white" }}>ยอดชำระรวม</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right text-bold" style={{ color: "white" }}>
                  {numberWithCommas(
                    parseFloat(
                      Math.round(props.pricepermonth * 100) / 100
                    ).toFixed(2)
                  )}{" "}
                  บาท
                </p>
              </Grid>
              <Grid item xs={6} sm={6} md={9}></Grid>
              <Grid item xs={6} sm={6} md={3}>
                <Button
                  btnType="submit"
                  width="100%"
                  children="ชำระเงิน"
                  borderRadius="0px"
                  clicked={props.onSelectQRCode}
                ></Button>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={false} sm={false} md={3}></Grid>
      <Grid item xs={12}>
        <p className="text-bold text-center ask__font__title" style={topic}>
          รายการชำระเงิน (ปิดบัญชี)
        </p>
      </Grid>
      <Grid item xs={false} sm={false} md={3}></Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper
          padding="1.5rem"
          align="left"
          border="1px solid"
          borderColor="#909090"
          width="90%"
          borderRadius="0px"
          children={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <u>
                  <p className="text-bold">เลขที่สัญญา {props.number}</p>
                </u>
              </Grid>
              {props.carcountry || props.carnumber || props.carbrand ? (
                <Grid item xs={12}>
                  <p className="text-bold">
                    {props.carbrand} {props.carnumber} {props.carcountry}
                  </p>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <p className="text-bold">
                    <br />
                  </p>
                </Grid>
              )}
              <Grid item xs={12}></Grid>

              {props.type == "สินเชื่อรถยนต์" ? (
                <Grid item xs={6}>
                  <p>ค่างวดคงเหลือ</p>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <p>เงินต้นคงเหลือ</p>
                </Grid>
              )}
              <Grid item xs={6}>
                <p className="text-right" style={{ color: "red" }}>
                  {numberWithCommas(
                    parseFloat(
                      Math.round(props.pricepermonth * 100) / 100
                    ).toFixed(2)
                  )}{" "}
                  <span style={{ color: "black" }}>บาท</span>
                </p>
              </Grid>
              {props.type == "สินเชื่อรถยนต์" ? (
                <Grid item xs={6}>
                  <p>ภาษีมูลค่าเพิ่มคงเหลือ</p>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <p>ดอกเบี้ย</p>
                </Grid>
              )}
              <Grid item xs={6}>
                <p className="text-right" style={{ color: "red" }}>
                  {numberWithCommas(
                    parseFloat(
                      Math.round(props.pricepermonth * 100) / 100
                    ).toFixed(2)
                  )}{" "}
                  <span style={{ color: "black" }}>บาท</span>
                </p>
              </Grid>
              {props.type == "สินเชื่อรถยนต์" ? (
                <Grid item xs={6}>
                  <p>ส่วนลด</p>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <p>ค่าธรรมเนียมการใช้วงเงิน</p>
                </Grid>
              )}
              {props.type == "สินเชื่อรถยนต์" ? (
                <Grid item xs={6}>
                  <p className="text-right" style={{ color: "#00FF44" }}>
                    (
                    {numberWithCommas(
                      parseFloat(
                        Math.round(props.pricepermonth * 100) / 100
                      ).toFixed(2)
                    )}
                    ) <span style={{ color: "black" }}>บาท</span>
                  </p>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <p className="text-right" style={{ color: "red" }}>
                    {numberWithCommas(
                      parseFloat(
                        Math.round(props.pricepermonth * 100) / 100
                      ).toFixed(2)
                    )}{" "}
                    <span style={{ color: "black" }}>บาท</span>
                  </p>
                </Grid>
              )}
              <Grid item xs={6}>
                <p>ค่าเบี้ยปรับ</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right" style={{ color: "red" }}>
                  {numberWithCommas(
                    parseFloat(
                      Math.round(props.pricepermonth * 100) / 100
                    ).toFixed(2)
                  )}{" "}
                  <span style={{ color: "black" }}>บาท</span>
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>ค่าติดตามทวงถาม</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right" style={{ color: "red" }}>
                  {numberWithCommas(
                    parseFloat(
                      Math.round(props.pricepermonth * 100) / 100
                    ).toFixed(2)
                  )}{" "}
                  <span style={{ color: "black" }}>บาท</span>
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>ค่าธรรมเนียมเช็คคืน,หักบัญชี</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right" style={{ color: "red" }}>
                  {numberWithCommas(
                    parseFloat(
                      Math.round(props.pricepermonth * 100) / 100
                    ).toFixed(2)
                  )}{" "}
                  <span style={{ color: "black" }}>บาท</span>
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>ค่าประกันภัยภาคสมัครใจ,ภาคบังคับ</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right" style={{ color: "red" }}>
                  {numberWithCommas(
                    parseFloat(
                      Math.round(props.pricepermonth * 100) / 100
                    ).toFixed(2)
                  )}{" "}
                  <span style={{ color: "black" }}>บาท</span>
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>ค่าใช้จ่ายเกี่ยวกับทะเบียนค้างชำระ</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right" style={{ color: "red" }}>
                  {numberWithCommas(
                    parseFloat(
                      Math.round(props.pricepermonth * 100) / 100
                    ).toFixed(2)
                  )}{" "}
                  <span style={{ color: "black" }}>บาท</span>
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>ค่าอื่นๆ</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right" style={{ color: "red" }}>
                  {numberWithCommas(
                    parseFloat(
                      Math.round(props.pricepermonth * 100) / 100
                    ).toFixed(2)
                  )}{" "}
                  <span style={{ color: "black" }}>บาท</span>
                </p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-bold">ยอดชำระ(ภายในวันที่ {props.date})</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right text-bold" style={{ color: "red" }}>
                  <u>
                    {numberWithCommas(
                      parseFloat(
                        Math.round(props.pricepermonth * 100) / 100
                      ).toFixed(2)
                    )}
                  </u>{" "}
                  <span style={{ color: "black" }}>บาท</span>
                </p>
              </Grid>
              <Grid item xs={12}>
                <p className="text-bold text-center">
                  หากชำระเกินวันที่ {props.date}{" "}
                  ยอดดังกล่าวอาจจะมีการเปลี่ยนแปลง
                </p>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={false} sm={false} md={3}></Grid>
    </div>
  );
};

export default closeacc;
