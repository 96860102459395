import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import createSagaMiddleware from "redux-saga";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

import {
  watchAgreement,
  watchTracking,
  watchPayment,
  watchCs,
} from "./store/saga/index";

export default function configureStore(preloadedState) {
  // const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    persistReducer(
      {
        key: "data",
        storage: storage,
        whitelist: ["agreement", "payment", "auth", "cs"],
      },
      rootReducer
    ),
    composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
  );

  const agreementSagaTask = sagaMiddleware.run(watchAgreement);
  const paymentSagaTask = sagaMiddleware.run(watchPayment);
  const trackingSagaTask = sagaMiddleware.run(watchTracking);
  const csSagaTask = sagaMiddleware.run(watchCs);

  agreementSagaTask.toPromise().catch((error) => {
    console.log("agreement saga error");
    console.log(error);
  });

  paymentSagaTask.toPromise().catch((error) => {
    console.log("payment saga error");
    console.log(error);
  });

  trackingSagaTask.toPromise().catch((error) => {
    console.log("tracking saga error");
    console.log(error);
  });

  csSagaTask.toPromise().catch((error) => {
    console.log("cs saga error");
    console.log(error);
  });

  return store;
}
