import axios from "axios";
import { API_MYASK, API_MYASK_HEADER } from "./constant";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let data = {
    isValid: true,
    code: 200,
  };

  if (!rules) {
    data.code = 200;
    return data;
  }

  if (rules.required) {
    data.isValid = value.trim() !== "" && data.isValid;
    if (data.isValid === false) {
      data.code = 1;
      return data;
    }
  }

  if (
    rules.minLength &&
    value.length < rules.minLength &&
    value.trim() !== ""
  ) {
    data.isValid = value.length >= rules.minLength && data.isValid;
    if (data.isValid === false) {
      data.code = 2;
      return data;
    }
  }

  if (rules.maxLength && value.length > rules.maxLength) {
    data.isValid = value.length <= rules.maxLength && data.isValid;
    if (data.isValid === false) {
      data.code = 3;
      return data;
    }
  }

  if (rules.isEmail) {
    if (rules.required === false && value === "") {
      data.isValid = true;
      data.code = 200;
      return data;
    } else {
      const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      data.isValid = pattern.test(value) && data.isValid;
      if (data.isValid === false) {
        data.code = 4;
        return data;
      }
    }
  }

  if (rules.isNumeric && value.trim() !== "") {
    const pattern = /^\d+$/;
    data.isValid = pattern.test(value) && data.isValid;
    if (data.isValid === false) {
      data.code = 5;
      return data;
    }
  }

  return data;
};

export const searchData = (pFilter, pData) => {
  const filter = pFilter;
  const data = pData;
  const lowercasedFilter = filter.toLowerCase();
  const filteredData = data.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key].toLowerCase().includes(lowercasedFilter)
    );
  });
  const formElementsArray = [];
  for (let key in data) {
    filteredData.map((item) => {
      if (item === data[key]) {
        formElementsArray.push(data[key]);
      }
    });
  }
  return formElementsArray;
};

export const checkError = (code, label, value) => {
  let textError = "";
  if (code === 1) {
    textError = "กรุณาระบุ" + label;
  } else if (code === 2) {
    textError = "กรุณาระบุ" + label + " อย่างน้อย " + value.minLength + " ตัว";
  } else if (code === 3) {
    textError = "กรุณาระบุ" + label + " ไม่เกิน " + value.maxLength + " ตัว";
  } else if (code === 4) {
    textError = "กรุณาระบุ E-mail ให้ถูกต้อง";
  } else if (code === 5) {
    textError = "กรุณาระบุเฉพาะตัวเลขเท่านั้น";
  }

  return textError;
};

export const randNumber = (len) => {
  const min = 0;
  const max = 999999;
  const str = Math.floor(min + Math.random() * (max - min)).toString();
  return str.length < len ? pad("0" + str, len) : str;
};

function pad(str, max) {
  str = str.toString();
  return str.length < max ? pad("0" + str, max) : str;
}

export const randString = (len) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const logError = (log) => {
  const DATA = {
    log_err: log,
  };

  axios.post(API_MYASK + "/LogError", DATA, {
    headers: API_MYASK_HEADER,
  });
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const validNationalId = (val) => {
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(val.charAt(i)) * (13 - i);
  }
  let mod = sum % 11;
  let check = (11 - mod) % 10;
  if (check == parseInt(val.charAt(12))) {
    return true;
  }
  return false;
};
