import { combineReducers } from "redux";

import authReducer from "./store/reducer/auth";
import lineReducer from "./store/reducer/line";
import custInfoReducer from "./store/reducer/custInfo";
import globalReducer from "./store/reducer/global";
import agreementReducer from "./store/reducer/agreement";
import trackingReducer from "./store/reducer/tracking";
import paymentReducer from "./store/reducer/payment";
import csReducer from "./store/reducer/cs";

const rootReducer = combineReducers({
  auth: authReducer,
  line: lineReducer,
  custInfo: custInfoReducer,
  global: globalReducer,
  agreement: agreementReducer,
  tracking: trackingReducer,
  payment: paymentReducer,
  cs: csReducer,
});

export default rootReducer;
