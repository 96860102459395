import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import "../../../components/Apply/Apply.css";
import axios from "axios";
import PathTree from "../../../components/UI/PathTree/PathTree";
import Button from "../../../components/UI/Button/Button";
import Paper from "./../../../components/UI/Paper/Paper";
import Branch from "./../../../components/Contact/Branch/Branch";
// import { updateObject } from "./../../../shared/utility";
import { API_MYASK } from "./../../../shared/constant";
import "./BranchControls.css";

const BranchControls = (props) => {
  const path = {
    Path: [
      {
        title: "ค้นหาสาขา",
        pathLink: "/branch",
      },
    ],
  };

  const [branchForm, setBranchForm] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    axios.get(API_MYASK + "/Branch").then((res) => {
      let branch = [];
      for (let i in res.data.LIST_DATA) {
        branch.push({
          PREBRANCH_NAME_T: res.data.LIST_DATA[i].PREBRANCH_NAME_T,
          SHORT_NAME_E: res.data.LIST_DATA[i].SHORT_NAME_E,
          ADD_NO: res.data.LIST_DATA[i].ADD_NO,
          ADD_SOI: res.data.LIST_DATA[i].ADD_SOI,
          ADD_RD: res.data.LIST_DATA[i].ADD_RD,
          ADD_DIS: res.data.LIST_DATA[i].ADD_DIS,
          ADD_AMP: res.data.LIST_DATA[i].ADD_AMP,
          ADD_PRV: res.data.LIST_DATA[i].ADD_PRV,
          ADD_ZIP: res.data.LIST_DATA[i].ADD_ZIP,
          OPEN_TIME: res.data.LIST_DATA[i].OPEN_TIME,
          PREBRANCH_TEL: res.data.LIST_DATA[i].PREBRANCH_TEL,
          FAX: res.data.LIST_DATA[i].FAX,
          MAP: res.data.LIST_DATA[i].MAP,
          MAP_DIRECTION: res.data.LIST_DATA[i].MAP_DIRECTION,
          REGION: res.data.LIST_DATA[i].REGION,
        });
      }
      setBranchForm(branch);
    });
  }, []);

  const [province, setProvince] = useState("HO");

  let show;
  let detail;

  const handleBranch = (value) => {
    setProvince(value);
  };

  for (let i in branchForm) {
    if (branchForm[i].SHORT_NAME_E === province) {
      show = (
        <iframe
          src={branchForm[i].MAP}
          width="100%"
          height="500rem"
          frameBorder="0"
        ></iframe>
      );
      detail = (
        <Branch
          branchs={branchForm[i].PREBRANCH_NAME_T}
          no={branchForm[i].ADD_NO}
          soi={branchForm[i].ADD_SOI}
          rd={branchForm[i].ADD_RD}
          dis={branchForm[i].ADD_DIS}
          amp={branchForm[i].ADD_AMP}
          prv={branchForm[i].ADD_PRV}
          zip={branchForm[i].ADD_ZIP}
          tel={branchForm[i].PREBRANCH_TEL}
          fax={branchForm[i].FAX}
          time={branchForm[i].OPEN_TIME}
          map={branchForm[i].MAP_DIRECTION}
        ></Branch>
      );
    }
  }

  const [region, setRegion] = useState("");
  const [useRegion, setUseRegion] = useState("C");
  useEffect(() => {
    setRegion(props.location.region);
    if (region === "C") {
      window.scroll(0, 0);
      handleCenter();
    } else if (region === "E") {
      window.scroll(0, 0);
      handleEast();
    } else if (region === "WS") {
      window.scroll(0, 0);
      handleWest_South();
    } else if (region === "N") {
      window.scroll(0, 0);
      handleNorth();
    } else if (region === "NE") {
      window.scroll(0, 0);
      handleNorthEast();
    }
  }, [region, props.location.region]);

  const handleCenter = () => {
    setUseRegion("C");
    setProvince("HO");
  };
  const handleEast = () => {
    setUseRegion("E");
    setProvince("RY");
  };
  const handleWest_South = () => {
    setUseRegion("WS");
    setProvince("KAN");
  };
  const handleNorth = () => {
    setUseRegion("N");
    setProvince("LPG");
  };
  const handleNorthEast = () => {
    setUseRegion("NE");
    setProvince("KKN");
  };

  let classes1;
  let classes2;
  let classes3;
  let classes4;
  let classes5;
  const formElementsArray = [];

  if (useRegion === "C") {
    classes1 = "menuclick";
    classes2 = "menunormal";
    classes3 = "menunormal";
    classes4 = "menunormal";
    classes5 = "menunormal";
    for (let key in branchForm) {
      if (branchForm[key].REGION === "1") {
        formElementsArray.push({
          id: key,
          config: branchForm[key],
        });
      }
    }
  } else if (useRegion === "E") {
    classes2 = "menuclick";
    classes1 = "menunormal";
    classes3 = "menunormal";
    classes4 = "menunormal";
    classes5 = "menunormal";
    for (let key in branchForm) {
      if (branchForm[key].REGION === "3") {
        formElementsArray.push({
          id: key,
          config: branchForm[key],
        });
      }
    }
  } else if (useRegion === "WS") {
    classes3 = "menuclick";
    classes1 = "menunormal";
    classes2 = "menunormal";
    classes4 = "menunormal";
    classes5 = "menunormal";
    for (let key in branchForm) {
      if (branchForm[key].REGION === "2" || branchForm[key].REGION === "5") {
        formElementsArray.push({
          id: key,
          config: branchForm[key],
        });
      }
    }
  } else if (useRegion === "N") {
    classes4 = "menuclick";
    classes1 = "menunormal";
    classes2 = "menunormal";
    classes3 = "menunormal";
    classes5 = "menunormal";
    for (let key in branchForm) {
      if (branchForm[key].REGION === "6") {
        formElementsArray.push({
          id: key,
          config: branchForm[key],
        });
      }
    }
  } else if (useRegion === "NE") {
    classes5 = "menuclick";
    classes1 = "menunormal";
    classes2 = "menunormal";
    classes4 = "menunormal";
    classes3 = "menunormal";
    for (let key in branchForm) {
      if (branchForm[key].REGION === "4") {
        formElementsArray.push({
          id: key,
          config: branchForm[key],
        });
      }
    }
  } else {
    classes1 = "menuclick";
    classes2 = "menunormal";
    classes3 = "menunormal";
    classes4 = "menunormal";
    classes5 = "menunormal";
    for (let key in branchForm) {
      if (branchForm[key].region === "1") {
        formElementsArray.push({
          id: key,
          config: branchForm[key],
        });
      }
    }
  }

  return (
    <PathTree list={path.Path}>
      <div className="branchpanel">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <p className="text-bold text-center ask__font__title">ค้นหาสาขา</p>
          </Grid>
          <div className="cd-tabs__list">
            <Grid item md={2} sm={12} xs={12}>
              <center>
                <Button btnType={classes1} clicked={() => handleCenter()}>
                  ภาคกลาง
                </Button>
              </center>
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <center>
                <Button btnType={classes2} clicked={() => handleEast()}>
                  ภาคตะวันออก
                </Button>
              </center>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <center>
                <Button btnType={classes3} clicked={() => handleWest_South()}>
                  ภาคตะวันตก-ภาคใต้
                </Button>
              </center>
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <center>
                <Button btnType={classes4} clicked={() => handleNorth()}>
                  ภาคเหนือ
                </Button>
              </center>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <center>
                <Button btnType={classes5} clicked={() => handleNorthEast()}>
                  ภาคตะวันออกเฉียงเหนือ
                </Button>
              </center>
            </Grid>
          </div>
          <Grid item md={6} sm={12} xs={12}>
            {formElementsArray.map((formElement) => (
              <Paper
                key={formElement.id}
                margin="1rem 0rem"
                children={
                  <Button
                    children={formElement.config.PREBRANCH_NAME_T}
                    btnType={"branch"}
                    textAlign={"left"}
                    clicked={() =>
                      handleBranch(formElement.config.SHORT_NAME_E)
                    }
                  />
                }
              />
            ))}
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            {detail}
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            {show}
          </Grid>
        </Grid>
      </div>
    </PathTree>
  );
};

export default BranchControls;
