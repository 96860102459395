import React from 'react';
import Grid from '@material-ui/core/Grid';
// import Payment from '../payment';
import '../../Apply/Apply.css';
import Button from '../../UI/Button/Button';
import Paper from '../../UI/Paper/Paper';
// import PathTree from '../../../components/UI/PathTree/PathTree';
import Modal from './../../UI/Modal/Modal';
import QRcode from 'qrcode.react';
import Barcode from 'react-barcode';
import { FaBarcode } from "react-icons/fa6";
import Bank from '../bank';


const ManageAgreementQRCode = (props) => {
    const [QRCode, setQRCode] = React.useState(false);
    const [BarCode, setBarCode] = React.useState(false);

    const handleChange = (event) => {
        props.onSelected(event);
    };

    const handleQRCode = () => {
        handleChange("1");
        setQRCode(true);
        setBarCode(false);
    }

    const handleBarCode = () => {
        handleChange("2");
        setQRCode(false);
        setBarCode(true);
    }
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    let classes1;
    let classes2;
    let form;
    let show;
    let text = 'BBS' + props.scancode
    if (QRCode === true) {
        classes1 = "menuclick";
        classes2 = "menunormal";
        form = <center><div onClick={handleOpen}><QRcode value={props.scancode} size={128}></QRcode></div></center>
        show =
            <Modal open={open} close={handleClose} children={
                <center><QRcode value={props.scancode} size={256}></QRcode></center>
            } />
    }
    else if (BarCode === true) {
        classes2 = "menuclick";
        classes1 = "menunormal";
        form = <center>
            <Button classes="barcode" clicked={handleOpen} fontColor="black" border="1px solid" children={<div><FaBarcode style={{ width: '5rem', height: '5rem' }} /><p>กดเพื่อสร้างบาร์โค้ดสำหรับชำระเงิน</p></div>}></Button></center>
        show =
            <Modal open={open} close={handleClose}>
                <div className="flip"><Barcode value={props.scancode} width={1} text={text} displayValue={true} /></div></Modal>
    }
    else {
        classes1 = "menuclick";
        classes2 = "menunormal";
        form = <center><div onClick={handleOpen}><QRcode value={props.scancode} size={128}></QRcode></div></center>
        show =
            <Modal open={open} close={handleClose} color="transparent" children={
                <center><QRcode value={props.scancode} size={256}></QRcode></center>
            } />
    }
    const topic = {
        marginTop: '2rem',
    }
    return (
        <center>
            <Grid item xs={12}>
                <p className="text-bold text-center ask__font__title" style={topic}>QR Code / Bar Code</p>
            </Grid>
            <br />
            {props.children}

        </center>
    );
}

export default ManageAgreementQRCode;
