import React, { useState, useEffect } from "react";
import Product from "../../components/Product/Product.js";
import Header from "../../components/UI/Header/Header.js";

import hp from "../../assets/hp_new.jpg";
import hp_used from "../../assets/hp_used.jpg";
import cl from "../../assets/cl.jpg";
import pl from "../../assets/pl.jpg";
import shb from "../../assets/shb.jpg";
import cml from "../../assets/cml.jpg";

import p_hpnew from "../../assets/p_hpnew.jpg";
import p_hpused from "../../assets/p_hpused.jpg";
import p_cl from "../../assets/p_cl.jpg";
import p_pl from "../../assets/p_pl.jpg";
import p_shb from "../../assets/p_shb.jpg";
import p_cml from "../../assets/p_cml.jpg";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { updateObject } from "../../../src/shared/utility.js";
import { API_MYASK } from "../../shared/constant";
import Loading from "./../../components/UI/Loading/Loading";

const ProductControl = (props) => {
  const [product, setProduct] = useState({
    products: [],
    error: null,
    loading: true,
  });

  const onSelectProduct = (
    product_id,
    name,
    title,
    desc,
    color,
    borderColor
  ) => {
    let img;
    let url_product = "";
    sessionStorage.setItem("product_id", product_id);
    if (product_id === "HP-NEW") {
      img = p_hpnew;
      url_product = "hire-purchase-new";
    } else if (product_id === "HP-USED") {
      img = p_hpused;
      url_product = "hire-purchase-used";
    } else if (product_id === "CL") {
      img = p_cl;
      url_product = "car-loan";
    } else if (product_id === "PL") {
      img = p_pl;
      url_product = "personal-loan";
    } else if (product_id === "SHB") {
      img = p_shb;
      url_product = "sale-and-hire-purchase-back";
    } else if (product_id === "CML") {
      img = p_cml;
      url_product = "commercial-loan";
    }

    props.history.push({
      pathname: "/product-detail/" + url_product,
      name: name,
      descrip: desc,
      title: title,
      color: color,
      borderColor: borderColor,
      product_id: product_id,
      url_product: url_product,
      image: img,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(API_MYASK + "/Products").then((res) => {
      // let updatedFormElement
      let arrayObj = [];
      for (let field in res.data.LIST_DATA) {
        let img;
        if (res.data.LIST_DATA[field].image === "hp_new") {
          img = hp;
        } else if (res.data.LIST_DATA[field].image === "hp_used") {
          img = hp_used;
        } else if (res.data.LIST_DATA[field].image === "cl") {
          img = cl;
        } else if (res.data.LIST_DATA[field].image === "pl") {
          img = pl;
        } else if (res.data.LIST_DATA[field].image === "shb") {
          img = shb;
        } else if (res.data.LIST_DATA[field].image === "cml") {
          img = cml;
        }
        arrayObj[field] = {
          product_id: res.data.LIST_DATA[field].product_id,
          name: res.data.LIST_DATA[field].name_th,
          title: res.data.LIST_DATA[field].title_th,
          descrip: res.data.LIST_DATA[field].desc_th,
          image: img,
        };
      }
      const updatedState = updateObject(product, {
        products: arrayObj,
        loading: false,
      });
      setProduct(updatedState);
    });
  }, []);

  const classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  }));

  const formElementsArray = [];
  for (let key in product.products) {
    formElementsArray.push({
      id: key,
      config: product.products[key],
    });
  }

  let form = formElementsArray.map((formElement) => (
    <Grid item lg={3} md={4} sm={6} xs={12} key={formElement.id}>
      <Product
        {...props}
        name={formElement.config.name}
        title={formElement.config.title}
        descrip={formElement.config.descrip}
        color={formElement.config.color}
        borderColor={formElement.config.borderColor}
        image={formElement.config.image}
        path={formElement.config.path}
        onSelectProduct={() =>
          onSelectProduct(
            formElement.config.product_id,
            formElement.config.name,
            formElement.config.title,
            formElement.config.descrip,
            formElement.config.color,
            formElement.config.borderColor
          )
        }
      />
    </Grid>
  ));
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Header img={hp} text="Product" title="something"></Header>
      </Grid>
      <Container maxWidth="xl">
        {product.loading ? (
          <Grid item xs={12}>
            <center>
              <Loading />
            </center>
          </Grid>
        ) : (
          <Grid container spacing={0}>
            <Grid container className={classes.root} spacing={3}>
              {form}
            </Grid>
          </Grid>
        )}
      </Container>
    </Grid>
  );
};

export default ProductControl;
