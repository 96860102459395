import React from "react";
import Grid from "@material-ui/core/Grid";
import Modal from "./../UI/Modal/Modal";
import QRcode from "qrcode.react";
import Barcode from "react-barcode";
import "../Apply/Apply.css";
import qr from "./../../assets/main/main-qrcode.jpeg";

const qrcode = (props) => {
  return (
    <Grid container>
      <Grid item xs={false} sm={3} md={4}></Grid>
      <Grid item xs={6} sm={3} md={2}>
        <QRcode
          value="|010753700033500 82280624 006250000804001 0"
          size={128}
        ></QRcode>
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <p style={{ color: "#8997A6" }}>เลขอ้างอิงที่ 1</p>
        <p>xxxxxxxxxxxxxx</p>
        <br />
        <p style={{ color: "#8997A6" }}>เลขอ้างอิงที่ 2</p>
        <p>xxxxxxxxxxxxxx</p>
      </Grid>
      <Grid item xs={false} sm={3} md={4}></Grid>
      <Grid item xs={12}>
        <Barcode
          value="|010753700033500 82280624 006250000804001 0"
          width={1}
          text="BBS|010753700033500 82280624 006250000804001 0"
          displayValue={true}
          font="'Prompt-Regular'"
          fontSize={14}
          format="CODE128"
        />
      </Grid>
      <Grid item xs={12}>
        <img
          src={qr}
          style={{ width: "350px", height: "250px", border: "1px solid black" }}
        ></img>
      </Grid>
    </Grid>
  );
};

export default qrcode;
