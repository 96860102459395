import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_MYASK, API_MYASK_HEADER } from "./../../shared/constant";

const DownloadButton = (props) => {
  const data = {
    device: "web",
    userName: "",
    userCode: "C",
    comCode: "",
    pathfile: "",
    pfilename: "DatasubjectForm.pdf",
  };

  const pFile = "DatasubjectForm.pdf";
  const pFileNm = "DatasubjectForm";

  axios
    .get(API_MYASK + "/file-management/" + pFile + "/" + pFileNm)
    .then((res) => {
      const url = API_MYASK + "/file-management/" + pFile + "/" + pFileNm;
      window.open(url, "_blank");
      return this.http.post(url, JSON.stringify(data), { Response: "blob" });
    })
    .catch((err) => {
      console.log(err);
    });
};
export default DownloadButton;
