import React, { useState, useEffect } from "react";
import Main from "../../components/ManageAgreement/main";
import { searchData } from "./../../shared/utility";
import axios from "axios";
import { API_MYASK } from "./../../shared/constant";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loading from "./../../components/UI/Loading/Loading";
import mh from "./../../assets/main/main-header.jpeg";
import * as actions from "./../../store/action/index";
import Header from "../../components/UI/Header/Header.js";
import Grid from "@material-ui/core/Grid";

const ManageAgreementMainControls = (props) => {
  const [user_name] = useState(
    sessionStorage.getItem("userName") || props.userName
  );
  const [token] = useState(sessionStorage.getItem("token") || props.token);

  const [all_agr, setAll_agr] = useState("");
  const [manyagr, setManyagr] = useState("");

  const onSelectAgreement = () => {
    props.history.push({
      pathname: "/agreements",
    });
  };

  const onSelectTracking = () => {
    props.history.push({
      pathname: "/tracking-request",
    });
  };

  const onSelectInsurance = () => {
    // props.history.push({
    //     pathname: '/manageagreement-insurance',
    //     agrtmp: all_agr,
    // })
    // sessionStorage.setItem('agrtmp', all_agr)
  };

  const onSelectPayment = () => {
    // props.history.push({
    //     pathname: '/manageagreement-paymentlist',
    //     manyagr: manyagr,
    //     type: 'agr',
    // })
    // sessionStorage.setItem('manyagr', manyagr)
    // sessionStorage.setItem('type', 'agr')
  };

  //   useEffect(() => {
  //     let agrtmp = "";
  //     let manyagr = "";
  //     let mount = true;
  //     axios
  //       .get(
  //         API_MYASK +
  //           "/ManageAgreement?" +
  //           "user_name=" +
  //           user_name +
  //           "&token=" +
  //           token
  //       )
  //       .then((res) => {
  //         console.log(res);
  //         if (res.data.CODE === "200") {
  //           let form = [];
  //           for (let i in res.data.LIST_DATA) {
  //             if (agrtmp != "") {
  //               agrtmp = agrtmp + ",";
  //               manyagr = "Y";
  //             }
  //             agrtmp = agrtmp + "'" + res.data.LIST_DATA[i].AGR_CODE + "'";
  //             form.push({
  //               fin_type: res.data.LIST_DATA[i].FIN_TYPE,
  //               agr_code: res.data.LIST_DATA[i].AGR_CODE,
  //               cus_code: res.data.LIST_DATA[i].CUS_CODE,
  //               new_card_no: res.data.LIST_DATA[i].NEW_CARD_NO,
  //               body: res.data.LIST_DATA[i].BODY,
  //               brand: res.data.LIST_DATA[i].BRAND,
  //               reg_num: res.data.LIST_DATA[i].REG_NUM,
  //               reg_province: res.data.LIST_DATA[i].REG_PROVINCE,
  //               asset_description: res.data.LIST_DATA[i].ASSET_DESCRIPTION,
  //               install: res.data.LIST_DATA[i].INSTALL,
  //               os_balance: res.data.LIST_DATA[i].OS_BALANCE,
  //               n_due_i: res.data.LIST_DATA[i].N_DUE_I,
  //               flat_rate: res.data.LIST_DATA[i].FLAT_RATE,
  //               disbursement_date: res.data.LIST_DATA[i].DISBURSEMENT_DATE,
  //               ar: res.data.LIST_DATA[i].AR,
  //               terms: res.data.LIST_DATA[i].TERMS,
  //               n_term: res.data.LIST_DATA[i].N_TERM,
  //               scan_code: res.data.LIST_DATA[i].SCAN_CODE,
  //               covid_apply_date: res.data.LIST_DATA[i].COVID_APPLY_DATE,
  //               covid_apply_msg: res.data.LIST_DATA[i].COVID_APPLY_MSG,
  //               covid_approve_date: res.data.LIST_DATA[i].COVID_APPROVE_DATE,
  //               covid_approve_msg: res.data.LIST_DATA[i].COVID_APPROVE_MSG,
  //               mobile_no: res.data.LIST_DATA[i].MOBILE_NO,
  //               email: res.data.LIST_DATA[i].EMAIL,
  //               add1: res.data.LIST_DATA[i].ADD1,
  //               add2: res.data.LIST_DATA[i].ADD2,
  //               post: res.data.LIST_DATA[i].POST,
  //             });
  //           }
  //           if (mount) {
  //             setAll_agr(agrtmp);
  //             setManyagr(manyagr);
  //           }
  //         }
  //       });

  //     return () => {
  //       mount = false;
  //     };
  //   }, []);

  return (
    <div className="ask__font__main">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Header img={mh} textHeader="ข้อมูลของฉัน" />
        </Grid>
        <div style={{ marginTop: "3rem" }}>
          <Main
            onSelectAgreement={() => onSelectAgreement()}
            onSelectTracking={() => onSelectTracking()}
            // onSelectInsurance={() => onSelectInsurance()}
            // onSelectPayment={() => onSelectPayment()}
          />
        </div>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAgreementMainControls);
