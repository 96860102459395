import React, { useState, useEffect } from 'react';
import ManageAgreementChangeAddr from '../../components/ManageAgreement/ManageAgreementChangeAddr/ManageAgreementChangeAddr';
import ChangeAddr from '../../components/ManageAgreement/changeaddr';
import History from '../../components/ManageAgreement/history';
import PathTree from '../../components/UI/PathTree/PathTree';
import axios from "axios";
import { API_MYASK } from "./../../shared/constant";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import * as actions from "./../../store/action/index";

const ManageAgreementControlsChangeAddr = (props) => {
    window.scrollTo(0, 0);
    const [redi, setRedi] = useState(false);

    let redirectpage;
    if (redi === true) {
        redirectpage = <Redirect to="/login" />;
    }

    const path = {
        Path: [
            {
                title: "เปลี่ยนแปลงที่อยู่",
                pathLink: "/manageagreement-changeaddr"
            },
        ]
    };

    return (
        <PathTree list={path.Path}>
            {redirectpage}
            <div className="ask__font__main">
                <ManageAgreementChangeAddr children={<ChangeAddr></ChangeAddr>}></ManageAgreementChangeAddr>
            </div>
        </PathTree>
    );
}


const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userName: state.auth.userName
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAgreementControlsChangeAddr);
