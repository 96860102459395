import * as actionTypes from '../action/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    userId: null,
    shouldLoading: true,
    lineHeader: {
        "Content-Type": "application/json",
        Authorization: ""
    }
}

const setLineProfile = (state, action) => {
    return updateObject(state, { userId: action.userId });
};

const setShouldLoading = (state, action) => {
    return updateObject(state, { shouldLoading: action.shouldLoading });
}

const setLineToken = (state, action) => {
    return updateObject(state, {
        lineHeader: {
            "Content-Type": "application/json",
            Authorization: action.token
        }
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LINE_SETPROFILE: return setLineProfile(state, action);
        case actionTypes.LINE_SHOULDLOADING: return setShouldLoading(state, action);
        case actionTypes.LINE_TOKEN: return setLineToken(state, action);
        default:
            return state;
    }

}

export default reducer;