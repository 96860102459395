import React from 'react';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Chart } from "react-google-charts";
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';

import './Calculate.css';


const theme = createMuiTheme({
    overrides: {
        MuiGrid: {
            'spacing-xs-2': {
                margin: '0',
                width: '100%',
                padding: '0 !important'
            },
        },
    },
});



function Calculate() {
    const orderForm = {
        product: {
            label: 'ประเภทสินเชื่อ:',
            elementType: 'select',
            elementConfig: {
                options: [
                    { value: 'fastest', displayValue: 'Fastest' },
                    { value: 'cheapest', displayValue: 'Cheapest' }
                ]
            },
            // value: 'fastest',
            validation: {},
            valid: true,
        },
        street: {
            label: 'ประเภทการคำนวณ:',
            elementType: 'select',
            elementConfig: {
                options: [
                    { value: 'fastest', displayValue: 'Fastest' },
                    { value: 'cheapest', displayValue: 'Cheapest' }
                ]
            },
            // value: 'fastest',
            validation: {},
            valid: true
        },
        zipCode: {
            label: 'ราคารถ(บาท)',
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'ราคารถ'
            },
            // value: '',
            validation: {
                required: true,
                minLength: 5,
                maxLength: 5,
                isNumeric: true
            },
            valid: false,
            touched: false,
            styleInput: {
                // width: '10rem',
            },
            styleLabel: {
                // marginRight: '1rem',
                // width: '8rem',
                // color: 'red'
            },
            // labelPos: 'left'
        },
    };

    const formElementsArray = [];
    for (let key in orderForm) {
        formElementsArray.push({
            id: key,
            config: orderForm[key]
        });
    }

    return (
        <div className="panel__graph">
            <MuiThemeProvider theme={theme}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <center><h1>คำนวณสินเชื่อ</h1></center>
                    </Grid>
                    {formElementsArray.map(formElement => (
                        <div className="label__pos ask__font__main" key={formElement.id}>
                            <Input
                                key={formElement.id}
                                label={formElement.config.label}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                align={formElement.config.align}
                                styleInput={formElement.config.styleInput}
                                styleLabel={formElement.config.styleLabel}
                                labelPos={formElement.config.labelPos}
                            // changed={event => inputChangedHandler(event, formElement.id)}
                            />
                        </div>
                    ))}

                    <Grid item xs={12}>
                        <center><Button btnType="normal" width="50%">คำนวณ</Button></center>
                    </Grid>
                    <Grid item xs={12}>
                        <center><Button btnType="border" width="50%">ล้างค่า</Button></center>
                    </Grid>
                    <Grid item xs={12}>
                        <center><h1>ระยะเวลาผ่อน (เดือน) ต่อ ค่างวด (บาท)</h1></center>
                    </Grid>
                    <Grid item xs={10}>
                        <Chart
                            width={'100%'}
                            height={'30rem'}
                            chartType="BarChart"
                            data={[
                                ['เดือน', 'month', { role: 'annotation' }, 'บาท', { role: 'annotation' }],
                                ['12 เดือน', 12000, '12 เดือน', 45903, '45,903 บาท'],
                                ['24 เดือน', 13000, '24 เดือน', 23835, '23,835 บาท'],
                                ['36 เดือน', 15000, '36 เดือน', 16478, '16,478 บาท'],
                                ['48 เดือน', 19000, '48 เดือน', 12800, '12,800 บาท'],
                                ['60 เดือน', 24000, '60 เดือน', 10593, '10,593 บาท'],
                                ['72 เดือน', 30000, '72 เดือน', 9122, '9,122 บาท'],
                            ]}
                            options={{
                                legend: { position: 'none', maxLines: 3 },
                                bars: 'horizontal',
                                chartArea: { height: '100%', width: '100%' },
                                axes: {
                                    x: {
                                        0: { side: 'top', label: 'บาท' }
                                    },
                                    y: {
                                        0: { side: 'right' },
                                    },
                                },
                                isStacked: 'percent',
                                tooltip: {
                                    trigger: 'none'
                                },
                                colors: ['#23D659', '#60B4FC'],
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <div className="graph">
                            <Button btnType="graph">สนใจ</Button>
                            <Button btnType="graph">สนใจ</Button>
                            <Button btnType="graph">สนใจ</Button>
                            <Button btnType="graph">สนใจ</Button>
                            <Button btnType="graph">สนใจ</Button>
                            <Button btnType="graph">สนใจ</Button>

                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Button btnType="next" width="100%" color="#22a86d" border="0.1rem solid #22a86d">สมัครสินเชื่อ</Button>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        </div >
    );
}

export default Calculate;