import React, { useEffect, useState } from "react";
import Contact from "../../../components/Contact/Contact/Contact";
import PathTree from "../../../components/UI/PathTree/PathTree";
import Input from "../../../components/UI/Input/Input";
import { connect } from "react-redux";
import {
  updateObject,
  checkValidity,
  checkError,
} from "../../../../src/shared/utility.js";
import { API_MYASK, API_MYASK_HEADER } from "./../../../shared/constant";
// import { Redirect } from "react-router-dom";
import axios from "axios";
import ModalError from "./../../../components/UI/Error/Error";

const ContractCovid19Controls = (props) => {
  const [reren, setReren] = useState("");

  const path = {
    Path: [
      {
        title: "ติดต่อเรา#covid19",
        pathLink: "/contact__covid19",
      },
    ],
  };

  const label = {
    color: "#000",
    // width: '50%',
  };
  const [contractForm, setContractForm] = useState({
    form: {
      type: {
        order: "0",
        label: "ประเภทลูกค้า:",
        labelPos: "left",
        elementType: "select",
        elementConfig: {
          options: [{ value: "2", displayValue: "ลูกค้าปัจจุบัน" }], //fix
        },
        value: "2", //fix
        validation: {
          required: true,
        },
        valid: true, // fix
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "ประเภทลูกค้า",
      },
      name: {
        order: "1",
        label: "ชื่อ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "ชื่อ",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "ชื่อ",
      },
      lastname: {
        order: "2",
        label: "นามสกุล:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "นามสกุล",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "นามสกุล",
      },
      tel: {
        order: "3",
        label: "เบอร์มือถือ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "number",
          placeholder: "เบอร์มือถือ",
        },
        type: "number",
        mode: "numeric",
        value: "",
        maxLength: 10, //ใช้ของ input html
        validation: {
          required: true,
          isNumeric: true,
          minLength: 10, //ใช้ของ utility เพราะต้องการดัก spacebar
          maxLength: 10,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "เบอร์มือถือ",
      },
      cardno: {
        order: "4",
        label: "เลขที่บัตรประชาชน:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "number",
          placeholder: "เลขที่บัตรประชาชน",
        },
        type: "number",
        mode: "numeric",
        value: "",
        maxLength: 13, //ใช้ของ input html
        validation: {
          required: true,
          isNumeric: true,
          minLength: 13, //ใช้ของ utility เพราะต้องการดัก spacebar
          maxLength: 13,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "เลขที่บัตรประชาชน",
      },
      email: {
        order: "5",
        label: "อีเมล:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "อีเมล",
        },
        value: "",
        validation: {
          required: false,
          isEmail: true,
        },
        valid: true,
        touched: false,
        styleLabel: label,
        textInvalid: "",
      },
      number: {
        order: "6",
        label: "เลขที่สัญญาหรือทะเบียนรถ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "เลขที่สัญญาหรือทะเบียนรถ",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "เลขที่สัญญาหรือทะเบียนรถ",
      },
      message: {
        order: "7",
        label: "อาชีพและผลกระทบที่ได้รับ:",
        labelPos: "left",
        elementType: "textarea",
        elementConfig: {
          type: "text",
          placeholder: "อาชีพและผลกระทบที่ได้รับ",
          rows: "10",
        },
        value: "",
        validation: {},
        valid: false,
        touched: false,
        styleLabel: label,
        styleInput: {
          resize: "none",
        },
        textInvalid: "",
      },
    },
    formIsValid: false,
  });

  // useEffect(() => {
  //     let updateState = "";
  //     let updateForm = "";
  //     let updateType = "";
  //     window.scrollTo(0, 0);
  //     axios
  //         .get(API_MYASK + "/Customer_Type")
  //         .then(res => {
  //             let type = contractForm.form.type.elementConfig.options;
  //             for (let i in res.data.LIST_DATA) {
  //                     type.push({
  //                         value: res.data.LIST_DATA[i].ID_CODE,
  //                         displayValue: res.data.LIST_DATA[i].REMARK
  //                     });
  //             }

  //             updateType = updateObject(contractForm.form.type, {
  //                 elementConfig: updateObject(contractForm.form.type.elementConfig, {
  //                     options: type
  //                 })
  //             });

  //             updateForm = updateObject(contractForm.form, {
  //                 type: updateType,
  //             });
  //             updateState = updateObject(contractForm, {
  //                 form: updateForm,
  //             });
  //             setContractForm(updateState);
  //         });

  //     return;
  // }, []);

  const [loading, setLoading] = useState(false);

  const onChangeTel = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    if (isValid || val === "") {
      return val;
    } else {
      return val.substring(val.length - 1, 0);
    }
  };
  const inputChangedHandler = (event, field) => {
    if (field === "tel" || field === "cardno") {
      event = onChangeTel(event);
    }

    const validate = checkValidity(event, contractForm.form[field].validation);
    const text = checkError(
      validate.code,
      contractForm.form[field].labelError,
      contractForm.form[field].validation
    );

    let updatedFormElement;
    if (field === "email") {
      //formIsValid : true
      if (event === "") {
        updatedFormElement = updateObject(contractForm.form[field], {
          value: event,
          valid: validate.isValid,
          textInvalid: text,
          touched: false,
          isEmail: false,
        });
      } else {
        updatedFormElement = updateObject(contractForm.form[field], {
          value: event,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
          isEmail: true,
        });
      }
    } else {
      updatedFormElement = updateObject(contractForm.form[field], {
        value: event,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });
    }

    let updatedForm = updateObject(contractForm.form, {
      [field]: updatedFormElement,
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    const updatedState = updateObject(contractForm, {
      form: updatedForm,
      formIsValid: formIsValid,
    });

    setContractForm(updatedState);
  };
  const checkedValidate = () => {
    if (
      contractForm.form["type"].valid === true &&
      contractForm.form["email"].valid === true &&
      contractForm.form["name"].valid === true &&
      contractForm.form["lastname"].valid === true &&
      contractForm.form["tel"].valid === true
    ) {
      contractForm.formIsValid = true;
    }
    if (!contractForm.formIsValid) {
      let updatedForm;
      let arrayObj = [];
      let updatedFormElement;
      let text;
      for (let field in contractForm.form) {
        const validate = checkValidity(
          contractForm.form[field].value,
          contractForm.form[field].validation
        );
        text = checkError(
          validate.code,
          contractForm.form[field].labelError,
          contractForm.form[field].validation
        );
        updatedFormElement = updateObject(contractForm.form[field], {
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
        arrayObj.push(updatedFormElement);
      }
      const arrayContact = [];
      for (let i in arrayObj) {
        if (arrayObj[i].order === "0") {
          arrayContact[0] = arrayObj[i];
        } else if (arrayObj[i].order === "1") {
          arrayContact[1] = arrayObj[i];
        } else if (arrayObj[i].order === "2") {
          arrayContact[2] = arrayObj[i];
        } else if (arrayObj[i].order === "3") {
          arrayContact[3] = arrayObj[i];
        } else if (arrayObj[i].order === "4") {
          arrayContact[4] = arrayObj[i];
        } else if (arrayObj[i].order === "5") {
          arrayContact[5] = arrayObj[i];
        } else if (arrayObj[i].order === "6") {
          arrayContact[6] = arrayObj[i];
        } else if (arrayObj[i].order === "7") {
          arrayContact[7] = arrayObj[i];
        }
      }
      updatedForm = updateObject(contractForm.form, {
        type: arrayContact[0],
        name: arrayContact[1],
        lastname: arrayContact[2],
        tel: arrayContact[3],
        cardno: arrayContact[4],
        email: arrayContact[5],
        number: arrayContact[6],
        message: arrayContact[7],
      });
      let updatedState = updateObject(contractForm, {
        form: updatedForm,
      });
      setContractForm(updatedState);
    } else {
      sendData();
    }
  };

  const sendData = () => {
    setLoading(true);
    let apply;
    if (props.lineId !== null) {
      apply = "LINE";
    } else if (
      sessionStorage.getItem("src") ===
      "mOZl4zuJDIV9CbjgwaaeyabZZrPGhyyC9ZVKhrSzFA0oZ6mTJPJeaRyc6F"
    ) {
      apply = "MYCAR";
    } else {
      apply = "WEB";
    }
    const data = {
      CUSTOMER_TYPE: contractForm.form.type.value,
      FIRST_NAME: contractForm.form.name.value,
      LAST_NAME: contractForm.form.lastname.value,
      MOBILE_NO: contractForm.form.tel.value,
      EMAIL: contractForm.form.email.value,
      CONTRACT_NO: contractForm.form.number.value,
      INFORMATION: "#covid19 " + contractForm.form.message.value,
      LINE_ID: props.lineId,
      APPLY: apply,
      CARD_NO: contractForm.form.cardno.value,
    };
    axios
      .post(API_MYASK + "/ContactCovid19", data, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {})
      .catch((err) => {
        setReren(
          <ModalError
            title="เกิดข้อผิดพลาด"
            text="กรุณาทำรายการใหม่ในภายหลัง"
            color="red"
            border="0.1rem solid red"
            link="/contact__covid19"
          ></ModalError>
        );
      });

    props.history.push({
      pathname: "/success",
      title: "เราได้รับข้อมูลของท่านแล้ว",
      path: "/",
      text: "จะติดต่อกลับหาท่านโดยเร็วที่สุด",
      // text2: 'ท่านสามารถติดตามผลได้ทาง...'
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    checkedValidate();
  };

  const formElementsArray = [];
  for (let key in contractForm.form) {
    formElementsArray[contractForm.form[key].order] = {
      id: key,
      config: contractForm.form[key],
    };
  }

  return (
    <PathTree list={path.Path}>
      {reren}
      <Contact
        loading={loading}
        title="ข้อมูลผู้เช่าซื้อกระทบ covid19"
        covid={true}
        children={formElementsArray.map((formElement) => (
          <div className="label__pos ask__font__main" key={formElement.id}>
            {/* <label className="label_text">{formElement.config.label}</label > */}
            <Input
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation.required}
              touched={formElement.config.touched}
              max={formElement.config.maxLength}
              min={formElement.config.minLength}
              mode={formElement.config.mode}
              align={formElement.config.align}
              type={formElement.config.type}
              styleInput={formElement.config.styleInput}
              styleLabel={formElement.config.styleLabel}
              textInvalid={formElement.config.textInvalid}
              isEmail={formElement.config.isEmail}
              changed={(event) =>
                inputChangedHandler(event.target.value, formElement.id)
              }
              labelPos={"left"}
              label={formElement.config.label}
            />
          </div>
        ))}
        submitHandler={submitHandler}
      ></Contact>
    </PathTree>
  );
};
const mapStateToProps = (state) => {
  return {
    lineId: state.line.userId,
  };
};

export default connect(mapStateToProps, null)(ContractCovid19Controls);
