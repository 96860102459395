import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  token: null,
  userName: null,
  refOtp: null,
  passOtp: null,
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userName: action.userName,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, { token: null });
};

const otpStart = (state, action) => {
  return updateObject(state, { refOtp: null, passOtp: null });
};

const otpSuccess = (state, action) => {
  return updateObject(state, {
    refOtp: action.refOtp,
    passOtp: action.passOtp,
  });
};

const setConfig = (state, action) => {
  return updateObject(state, {
    lineFriend: action.lineFriend,
    otpTime: action.otpTime,
    telNo: action.telNo,
    mailTo: action.mailTo,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.OTP_START:
      return otpStart(state, action);
    case actionTypes.OTP_SUCCESS:
      return otpSuccess(state, action);
    case actionTypes.SET_CONFIG:
      return setConfig(state, action);
    default:
      return state;
  }
};

export default reducer;
