import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "./../../store/action/index";

const PaymentSuccess = (props) => {
  useEffect(() => {
    props.clearAgreementState();
    props.clearPaymentState();
  }, []);

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  } else {
    redirectpage = <Redirect to="/agreements" />;
  }

  return (
    <div>
      {redirectpage}
      <div className="ask__font__main"></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearAgreementState: () => dispatch(actions.clearAgreementState()),
    clearPaymentState: () => dispatch(actions.clearPaymentState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
