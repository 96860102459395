import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";

import { Button } from "primereact/button";
import Input from "../UI/Input/Input";
import { API_MYASK } from "./../../shared/constant";
import Loading from "../UI/Loading/Loading";
const FormEtax = (props) => {
  const [emailCurrent, setEmailCurrent] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    axios
      .post(API_MYASK + "/getEmailEtax", {
        username: sessionStorage.getItem("userName"),
        token: sessionStorage.getItem("token"),
      })
      .then((res) => {
        setLoading(false);
        if (res.data.CODE == "200") {
          setEmailCurrent(res.data.DATA);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
      });
  }, []);

  const nextPage = () => {
    props.onNextPage();
  };

  let content = null;
  if (loading) {
    content = (
      <center>
        <Loading />
      </center>
    );
  } else {
    content = (
      <Fragment>
        <center>
          {emailCurrent != "" && (
            <p className="ask__font__title" style={{ color: "#EC2323" }}>
              ท่านได้เคยใช้ {emailCurrent} สมัครบริการ eTax/eReceipt
              <br />
              หากต้องการเปลี่ยน email address โปรดระบุข้างล่างใหม่
            </p>
          )}

          {emailCurrent == "" && (
            <p className="ask__font__title">
              โปรดระบุ email address ที่ใช้ในการรับใบเสร็จรับเงิน/ใบกำกับภาษี
              ในรูปแบบอิเล็กทรอนิกส์
            </p>
          )}
        </center>
        <div style={{ marginTop: "8rem" }}></div>
        <div
          className="col-12 sm:col-12 md:col-6 md:col-offset-3 
                lg:col-6 lg:col-offset-3 xl:col-6 xl:col-offset-3 
                my-6 mt-6 ask__font__title"
        >
          <Input
            className="form-control ask__font__title "
            elementType="input"
            type="text"
            elementConfig={props.data.form.email.elementConfig}
            value={props.data.form.email.value}
            invalid={!props.data.form.email.valid}
            touched={props.data.form.email.touched}
            isEmail={props.data.form.email.isEmail}
            shouldValidate={props.data.form.email.validation.required}
            textInvalid={props.data.form.email.textInvalid}
            changed={(event) =>
              props.onChangeEmail(event.target.value, "email")
            }
            styleLabel={{ width: "100%", padding: "0px" }}
            styleInput={{
              fontSize: "1.6rem",
              padding: "1.6rem",
              fontFamily: "Prompt-Regular",
            }}
          />
        </div>
        <div style={{ marginTop: "8rem" }}></div>

        <div className="grid justify-content-between mt-6">
          <Button
            label="ย้อนกลับ"
            className="col-4 sm:col-4  xl:col-3
            p-button-outlined mt-6 p-3"
            style={{
              border: "0.1rem solid",
              borderRadius: "0.5rem",
              borderColor: "#ff9600",
              backgroundColor: "#ffffff",
              color: "#ff9600",
              boxShadow: "",
            }}
            onClick={() => props.onPrevPage()}
          />
          <Button
            label="ถัดไป"
            disabled={
              props.data.formIsValid !== true &&
              sessionStorage.getItem("from_cs") != "true"
            }
            className="col-4 sm:col-4  xl:col-3
            p-button-success mt-6 p-3"
            style={{
              borderRadius: "0px",
              backgroundColor: "#ff9600",
            }}
            onClick={() => nextPage()}
          />
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div
        className="col-12 sm:col-12 md:col-8 md:col-offset-2 
                lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2 mt-6 px-6"
      >
        {content}
      </div>
    </Fragment>
  );
};

export default FormEtax;
