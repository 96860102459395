import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "./../../UI/Button/Button";
import "../Apply.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ApplyStep5 = (props) => {
  let title = props.location.title;
  let path = props.location.path;
  let text = props.location.text;
  // console.log(props)
  // let text2 = props.location.text2;

  // const [orderForm , setOrderForm] = useState({

  // });

  // const [timer, setTimer] = useState(5);

  // useEffect(() => {
  //     const x = setInterval(() => {
  //         if (timer > 0) {
  //             setTimer(timer - 1);
  //         }
  //     }, 1000);

  //     return () => {
  //         clearInterval(x);
  //     }
  // }, [timer]);
  // let redirectpage;
  // if (timer == 0) {
  //     redirectpage = <Redirect to="/products" />;
  // }

  let redirectpage;
  // ใช้ในกรณีที่อัพขึ้นเซิฟเวอร์เทส
  // if (!title && !path && !text) {
  //   redirectpage = <Redirect to="/myask/#/" />;
  // }
  // ใช้ในกรณีที่ใช้ใน localhost หรือ production
  if (!title && !path && !text) {
    redirectpage = <Redirect to="/" />;
  }

  let buttonSubmit = null;
  if (!props.single) {
    // ใช้ในกรณีที่ใช้ใน localhost หรือ production
    let realpath = path;
    // ใช้ในกรณีที่อัพขึ้นเซิฟเวอร์เทส
    // let realpath = "/myask/#" + path;

    buttonSubmit = (
      <Button
        path={realpath}
        btnType="submit"
        children="กลับสู่หน้าหลัก"
        // color="#3BB54A"
        border="none"
      />
    );
  }

  return (
    <div className="panel ask__font__main" style={{ marginTop: "10rem" }}>
      {redirectpage}
      <Grid container spacing={3}>
        <div className="buttonCheck"></div>
        <Grid item xs={12}>
          <p className="text-bold text-center">{title}</p>
        </Grid>
        <Grid item xs={12}>
          <p className="text-bold text-center">{text}</p>
        </Grid>
        {/* <Grid item xs={12}>
                    <p className="text-center">กรุณาคลิกเพื่อกลับไปยังหน้าเดิม</p>
                </Grid> */}
        <Grid item xs={12}>
          <center>{buttonSubmit}</center>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    single: state.global.single,
  };
};

export default connect(mapStateToProps)(ApplyStep5);
