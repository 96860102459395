import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

const ProgressBar = (props) => {

    const containerStyles = {
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        width: '60%',
    }

    const fillerStyles = {
        height: '100%',
        width: `${props.completed}%`,
        backgroundColor: props.bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right',
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold',
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>

            <div style={containerStyles}>
                <div style={fillerStyles}>
                    <span style={labelStyles}></span>
                </div>
            </div>
            <p style={{ marginLeft: '1rem' }}><b>{props.n_term}</b> / {props.terms}</p>
        </div>
    );
};

export default ProgressBar;