import * as actionTypes from '../action/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    firstName: null,
    lastName: null,
}

const setCustInfo = (state, action) => {
    return updateObject(state, {
        firstName: action.firstName,
        lastName: action.lastName,
    });
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CUST_INFO: return setCustInfo(state, action);
        default:
            return state;
    }

}

export default reducer;