import React from "react";
import ReactPaginate from "react-paginate";
import "./Paginate.css";

const Paginate = props => {
  return (
    <div id="react-paginate">
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={props.pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        onPageChange={props.onPageChange}
        forcePage={props.forcePage}
      />
    </div>
  );
};

export default Paginate;
