import * as actionTypes from "./actionTypes";

export const csFilter = (value) => {
  return {
    type: actionTypes.CS_FILTER,
    value: value,
  };
};

export const csFilterStart = () => {
  return {
    type: actionTypes.CS_FILTER_START,
  };
};

export const csFilterSuccess = (value, data) => {
  return {
    type: actionTypes.CS_FILTER_SUCCESS,
    filter: value,
    results: data,
  };
};

export const csFilterFailed = (error) => {
  return {
    type: actionTypes.CS_FILTER_FAILED,
    error: error,
  };
};
