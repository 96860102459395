import React, { useEffect, useState } from "react";
// import Grid from "@material-ui/core/Grid";
import "./question.css";
import axios from "axios";
import Input from "./../../../components/UI/Input/Input";
import { Redirect } from "react-router-dom";
import {
  updateObject,
  randNumber,
  randString,
  logError,
} from "./../../../shared/utility";
import { API_MYASK, API_MYASK_HEADER, API_OTP } from "../../../shared/constant";
import { connect } from "react-redux";
import * as actions from "./../../../store/action/index";
// import DatePicker from "react-datepicker";
// import { getYear } from "date-fns";
import { BrowserView, MobileView } from "react-device-detect";
import Loading from "./../../../components/UI/Loading/Loading";
import Grid from "@material-ui/core/Grid";
// import Button from "./../../../components/UI/Button/Button";
import Error from "./../../../components/UI/Error/Error";

const Question = (props) => {
  let otpRedirect = null;
  if (!props.location.agr_code) {
    otpRedirect = <Redirect to="/login" />;
  }
  const [questionForm, setQuestionForm] = useState({
    question: {
      q1: {
        value: "",
      },
      q2: {
        value: "",
      },
      q3: {
        value: "",
      },
      q4: {
        value: "",
      },
      // q5: {
      //   value: ''
      // },
    },
    textAns: "",
  });

  const [startDate, setStartDate] = useState(new Date());

  const [loading, setLoading] = useState(true);

  const [btnLoading, setBtnLoading] = useState(false);

  // const years = range(50, getYear(new Date()) + 1, 1);
  const years = range(120, 1920);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function range(size, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt);
  }

  // const handleOnBlur = ({ target: { value } }) => {
  //   onChangeHandler(value, "q3")
  // }

  useEffect(() => {
    let mounted = true;
    axios.get(API_MYASK + "/Question").then((res) => {
      let data = res.data.LIST_DATA;
      const updatedState = updateObject(questionForm, {
        question: {
          q1: {
            id_code: data[0].id_code,
            remark: data[0].remark,
            maxlength: 4,
            validation: {
              isNumeric: true,
            },
            value: "",
          },
          q2: {
            id_code: data[1].id_code,
            remark: data[1].remark,
            maxlength: 4,
            validation: {
              isNumeric: true,
            },
            value: "",
          },
          q3: {
            id_code: data[2].id_code,
            remark: data[2].remark,
          },
          q4: {
            id_code: data[3].id_code,
            remark: data[3].remark,
          },
          // q5: {
          //   id_code: data[4].id_code,
          //   remark: data[4].remark,
          // },
        },
      });

      if (mounted) {
        setQuestionForm(updatedState);
        setLoading(false);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  let ques = [];
  if (questionForm.question[0] != null) {
    for (let k in questionForm.question) {
      ques[k] = questionForm.question[k].remark;
    }
  }

  const onChangeHandler = (val, id) => {
    let updatedFormElement;

    if (id === "q1" || id === "q2") {
      val = onChangeTel(val);
    }

    updatedFormElement = updateObject(questionForm.question[id], {
      value: val,
    });

    let updatedForm = updateObject(questionForm.question, {
      [id]: updatedFormElement,
    });

    const updatedState = updateObject(questionForm, {
      question: updatedForm,
      textAns: "",
    });

    setQuestionForm(updatedState);
  };

  useEffect(() => {
    if (props.refOtp) {
      props.history.push({
        pathname: "/otp",
        mobile: props.location.form.mobileNo.value,
        refOtp: props.refOtp,
        otpPass: props.passOtp,
        form: props.location.form,
        status: props.location.status,
      });
    }
  }, [props.refOtp]);

  const onChangeTel = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    if (isValid || val === "") {
      return val;
    } else {
      return val.substring(val.length - 1, 0);
    }
  };

  const [reren, setReren] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();
    const data = questionForm.question;
    const ansData = {
      agr_code: props.location.agr_code,
      card_no: data.q1.value,
      tel: data.q2.value,
      birth: data.q3.value,
      post_no: data.q4.value,
      // sex: data.q5.value
    };

    setBtnLoading(true);

    //--- 7/1/2020 ถ้าจะเอา otp กลับมา ให้ ลบ code นี้ออก ---/
    /*
    axios
      .post(API_MYASK + "/CheckAns", ansData, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        if (res.data.CODE === "200") {
          if (res.data.LIST_DATA[0].checked >= 3) {
            if (props.location.status === "regis") {
              let apply;
              if (props.lineId !== null) {
                apply = "LINE";
              } else if (
                sessionStorage.getItem("src") ===
                "mOZl4zuJDIV9CbjgwaaeyabZZrPGhyyC9ZVKhrSzFA0oZ6mTJPJeaRyc6F"
              ) {
                apply = "MYCAR";
              } else {
                apply = "WEB";
              }
              const data = {
                username: props.location.form.username.value,
                password: props.location.form.password.value,
                mobile_no: props.location.form.mobileNo.value,
                agr_code: props.location.form.agr_code.value,
                email: props.location.form.email.value,
                regis_from: props.lineId === null ? "WEB" : "LINE",
                otp: "",
                ref: "",
                lineId: props.lineId,
                apply: apply,
              };

              axios
                .post(API_MYASK + "/Register", data, {
                  headers: API_MYASK_HEADER,
                })
                .then((res) => {
                  if (res.data.CODE === "200") {
                    // sessionStorage.setItem('token', res.data.MSG);

                    props.onAuthSuccess(res.data.MSG, data.username);
                    props.history.push({
                      pathname: "/agreements",
                    });
                  } else if (res.data.CODE === "500") {
                    setReren(
                      <Error
                        title="เกิดข้อผิดพลาด"
                        text="กรุณาทำรายการใหม่ในภายหลัง"
                        color="red"
                        border="0.1rem solid red"
                        link="/login"
                      ></Error>
                    );
                  } else {
                    setReren(
                      <Error
                        title="เกิดข้อผิดพลาด"
                        text={res.data.MSG}
                        color="red"
                        border="0.1rem solid red"
                        link="/login"
                      ></Error>
                    );
                  }
                })
                .catch((err) => {
                  alert(err);
                });
            } else if (props.location.status === "reset") {
              props.history.push({
                pathname: "/reset-password",
                form: props.location.form,
              });
            }
          } else {
            const updatedState = updateObject(questionForm, {
              textAns:
                "คุณตอบคำถามถูกเพียง " + res.data.LIST_DATA[0].checked + " ข้อ",
            });
            setQuestionForm(updatedState);
            setBtnLoading(false);
          }
        }
      });*/
    //--- End ถ้าจะเอา otp กลับมา ให้ ลบ code นี้ออก ---/

    //------- 7/1/2020 ปิด otp
    axios
      .post(API_MYASK + "/CheckAns", ansData, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        if (res.data.CODE === "200") {
          if (res.data.LIST_DATA[0].checked >= 3) {
            if (props.location.status === "regis") {
              const refOtp = randString(6);
              const passOtp = randNumber(6);
              axios
                .post(API_OTP, {
                  mobile: props.location.form.mobileNo.value,
                  refOtp: refOtp,
                  passOtp: passOtp,
                  sender: "My ASK Connect",
                })
                .then((res) => {
                  // For Test
                  const otpData = {
                    refOtp: refOtp,
                    passOtp: passOtp,
                    mobile: props.location.form.mobileNo.value,
                  };

                  axios
                    .post(API_MYASK + "/SaveOTP", otpData, {
                      headers: API_MYASK_HEADER,
                    })
                    .then((res) => {
                      if (res.data.CODE === "200") {
                        setBtnLoading(false);

                        props.history.push({
                          pathname: "/otp",
                          mobile: "mobile",
                          refOtp: refOtp,
                          otpPass: passOtp,
                          form: props.location.form,
                          status: props.location.status,
                        });
                      } else {
                        logError(
                          "question(1)" + res.data.MSG + " " + res.data.CODE
                        );
                        setReren(
                          <Error
                            title="เกิดข้อผิดพลาด"
                            text="กรุณาทำรายการใหม่ในภายหลัง"
                            color="red"
                            border="0.1rem solid red"
                            link="/question"
                          />
                        );
                      }
                    });
                })
                .catch((err) => {
                  err =
                    "question(1)" +
                    err +
                    "," +
                    props.location.form.mobileNo.value +
                    "," +
                    refOtp +
                    "," +
                    passOtp +
                    ";";
                  logError(err);
                  setReren(
                    <Error
                      title="เกิดข้อผิดพลาด"
                      text="กรุณาทำรายการใหม่ในภายหลัง"
                      color="red"
                      border="0.1rem solid red"
                      link="/question"
                    />
                  );
                });
            } else if (props.location.status === "reset") {
              props.history.push({
                pathname: "/reset-password",
                form: props.location.form,
              });
            }
          } else {
            const updatedState = updateObject(questionForm, {
              textAns:
                "คุณตอบคำถามถูกเพียง " + res.data.LIST_DATA[0].checked + " ข้อ",
            });
            setQuestionForm(updatedState);
            setBtnLoading(false);
          }
        }
      });
    //------- ถ้าจะเปิด แค่เอาคอมเม้นด้านล่างออก แล้วคอมเม้นด้านบน
  };

  return (
    <div className="question__container">
      {otpRedirect}
      {reren}
      {loading ? (
        <Grid item xs={12}>
          <center>
            <Loading />
          </center>
        </Grid>
      ) : (
        <div>
          <h4 className="question__header ask__font__title">คำถาม</h4>
          <form onSubmit={submitHandler}>
            <div className="question__body ask__font__main">
              <div className="question__body__question">
                <label>กรุณาตอบคำถามให้ถูกต้อง อย่างน้อย 3 ข้อ</label>
              </div>
              <div className="question__body__question">
                <label>{questionForm.question.q1.remark}</label>
                <Input
                  className="form-control"
                  elementType="input"
                  type="number"
                  mode="numeric"
                  value={questionForm.question.q1.value}
                  max={questionForm.question.q1.maxlength}
                  changed={(event) => onChangeHandler(event.target.value, "q1")}
                />
              </div>
              <div className="question__body_question">
                <label>{questionForm.question.q2.remark}</label>
                <Input
                  className="form-control"
                  elementType="input"
                  type="number"
                  mode="numeric"
                  value={questionForm.question.q2.value}
                  max={questionForm.question.q2.maxlength}
                  changed={(event) => onChangeHandler(event.target.value, "q2")}
                />
              </div>
              <div className="question__body_question">
                <label>{questionForm.question.q3.remark}</label>
                <BrowserView style={{ width: "100%" }}>
                  <Input
                    elementType="date"
                    styleInput={{ color: "red" }}
                    years={years}
                    months={months}
                    startDate={startDate}
                    changed={(date) => {
                      setStartDate(date);
                      onChangeHandler(date.toLocaleDateString("en-GB"), "q3");
                    }}
                  />
                </BrowserView>
                <MobileView
                  style={{
                    width: "100%",
                    minWidth: "96%",
                    boxSizing: "border-box",
                  }}
                >
                  <Input
                    type="date"
                    changed={(event) =>
                      onChangeHandler(
                        new Date(event.target.value).toLocaleDateString(
                          "en-GB"
                        ),
                        "q3"
                      )
                    }
                  />
                </MobileView>
              </div>
              <div className="question__body_question">
                <label>{questionForm.question.q4.remark}</label>
                <Input
                  className="form-control"
                  elementType="input"
                  type="number"
                  mode="numeric"
                  changed={(event) => onChangeHandler(event.target.value, "q4")}
                />
              </div>
              {/* <div className="question__body_question">
            <Grid item xs={12}>
              <label>{questionForm.question.q5.remark}</label>
            </Grid>
            <div className="radio_button">
              <input type="radio" name="gender" value="M" onChange={event => onChangeHandler(event.target.value, "q5")} /> เพศชาย
            </div>
            <div className="radio_button">
              <input type="radio" name="gender" value="F" onChange={event => onChangeHandler(event.target.value, "q5")} /> เพศหญิง
            </div>
          </div> */}
              <div className="question__body_question">
                {btnLoading ? (
                  <div className="loader"></div>
                ) : (
                  <label className="text__invalid">
                    {questionForm.textAns}
                  </label>
                )}
                <button
                  className="btn-submit"
                  style={{ fontFamily: "Prompt-Regular" }}
                >
                  ส่งคำตอบ
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    refOtp: state.auth.refOtp,
    passOtp: state.auth.passOtp,
    lineId: state.line.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendOTP: (mobile) => dispatch(actions.sendOTP(mobile)),
    onAuthSuccess: (token, userName) => dispatch(actions.auth(token, userName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);
