import React from "react";
// import { Link } from "react-router-dom";
// import "./PathTree.css";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { FONT_SIZE } from "./../../../shared/constant";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap",
  },
  paper: {
    padding: theme.spacing(1, 2),
    margin: "1rem",
  },
  ol: {
    fontSize: FONT_SIZE,
    color: "black",
  },
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const theme = createMuiTheme({
  typography: {
    fontFamily: "inherit !important",
  },
});

function handleClick(event) {
  event.preventDefault();
}

function lastItem(key, item) {
  const divStyle = {
    fontSize: FONT_SIZE,
  };

  return (
    <Typography style={divStyle} key={key} color="textSecondary">
      {item.title}
    </Typography>
  );
}

function listItem(key, item) {
  return (
    <LinkRouter key={key} color="inherit" to={item.pathLink}>
      {item.title}
    </LinkRouter>
  );
}

function listTree(item, index, pathLen) {
  if (index + 1 === pathLen) {
    return lastItem(index, item);
  } else {
    return listItem(index, item);
  }
}

const PathTree = (props) => {
  let path = [];
  for (let nav in props.list) {
    path.push({
      title: props.list[nav].title,
      pathLink: props.list[nav].pathLink,
    });
  }

  const pathLen = path.length;

  const classes = useStyles();

  // path = ['ข้อมูลสินเชื่อของฉัน'];

  let breadcrumbs = null;
  if (!props.single) {
    breadcrumbs = (
      <MuiThemeProvider theme={theme}>
        <Breadcrumbs aria-label="breadcrumb" classes={{ ol: classes.ol }}>
          <LinkRouter color="inherit" to="/">
            หน้าแรก
          </LinkRouter>

          {path.map((el, i) => listTree(el, i, pathLen))}
        </Breadcrumbs>
      </MuiThemeProvider>
    );
  }

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        {breadcrumbs}
      </Paper>
      {props.children}
    </div>
    // <div className="path__container">
    //   <ul className="path__list">
    //     <li>
    //       <Link to="/">หน้าแรก</Link>
    //     </li>
    //     {path.map(el => (
    //         <li key={el.title} className="path__list__link">
    //           <Link to={el.pathLink}>{el.title}</Link>
    //         </li>
    //     ))}
    //   </ul>
    //   {props.children}
    // </div>
  );
};

const mapStateToProps = (state) => {
  return {
    single: state.global.single,
  };
};

export default connect(mapStateToProps)(PathTree);
