import React, { useState, useEffect } from "react";
import ManageAgreementCloseAcc from "../../components/ManageAgreement/ManageAgreementCloseAcc/ManageAgreementCloseAcc";
import CloseAcc from "../../components/ManageAgreement/closeacc";
import History from "../../components/ManageAgreement/history";
import PathTree from "../../components/UI/PathTree/PathTree";
import axios from "axios";
import { API_MYASK } from "./../../shared/constant";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import * as actions from "./../../store/action/index";

const ManageAgreementControlsCloseAcc = (props) => {
  let data = "";
  let click = "";
  const [fin_type] = React.useState(
    props.location.fin_type || sessionStorage.getItem("fin_type") || ""
  );
  const [agr_code] = React.useState(
    props.location.agr_code || sessionStorage.getItem("agr_code") || ""
  );
  const [body] = React.useState(
    props.location.body || sessionStorage.getItem("body") || ""
  );
  const [brand] = React.useState(
    props.location.brand || sessionStorage.getItem("brand") || ""
  );
  const [reg_num] = React.useState(
    props.location.reg_num || sessionStorage.getItem("reg_num") || ""
  );
  const [reg_province] = React.useState(
    props.location.reg_province || sessionStorage.getItem("reg_province") || ""
  );
  const [asset_description] = React.useState(
    props.location.asset_description ||
      sessionStorage.getItem("asset_description") ||
      ""
  );
  const [install] = React.useState(
    props.location.install || sessionStorage.getItem("install") || ""
  );
  const [os_balance] = React.useState(
    props.location.os_balance || sessionStorage.getItem("os_balance") || ""
  );
  const [n_due_i] = React.useState(
    props.location.n_due_i || sessionStorage.getItem("n_due_i") || ""
  );
  const [flat_rate] = React.useState(
    props.location.flat_rate || sessionStorage.getItem("flat_rate") || ""
  );
  const [disbursement_date] = React.useState(
    props.location.disbursement_date ||
      sessionStorage.getItem("disbursement_date") ||
      ""
  );
  const [ar] = React.useState(
    props.location.ar || sessionStorage.getItem("ar") || ""
  );
  const [terms] = React.useState(
    props.location.terms || sessionStorage.getItem("terms") || ""
  );
  const [scan_code] = React.useState(
    props.location.scan_code || sessionStorage.getItem("scan_code") || ""
  );

  window.scrollTo(0, 0);
  const [redi, setRedi] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("fin_type", fin_type);
    sessionStorage.setItem("agr_code", agr_code);
    sessionStorage.setItem("body", body);
    sessionStorage.setItem("brand", brand);
    sessionStorage.setItem("reg_num", reg_num);
    sessionStorage.setItem("reg_province", reg_province);
    sessionStorage.setItem("asset_description", asset_description);
    sessionStorage.setItem("install", install);
    sessionStorage.setItem("os_balance", os_balance);
    sessionStorage.setItem("n_due_i", n_due_i);
    sessionStorage.setItem("flat_rate", flat_rate);
    sessionStorage.setItem("disbursement_date", disbursement_date);
    sessionStorage.setItem("ar", ar);
    sessionStorage.setItem("terms", terms);
    sessionStorage.setItem("scan_code", scan_code);

    let mount = true;
    axios
      .get(
        API_MYASK +
          "/Payment_History?" +
          "agr_code=" +
          sessionStorage.getItem("agr_code") +
          "&user_name=" +
          sessionStorage.getItem("userName") +
          "&token=" +
          sessionStorage.getItem("token")
      )
      .then((res) => {
        if (res.data.CODE === "200") {
          let form = [];
          for (let i in res.data.LIST_DATA) {
            form.push({
              last_paid_dt: res.data.LIST_DATA[i].LAST_PAID_DT,
              sum_paid: res.data.LIST_DATA[i].SUM_PAID,
            });
          }
          if (mount) {
            setManageAgrForm2(form);
          }
        } else if (res.data.CODE === "404") {
          if (mount) {
            setManageAgrForm2([]);
          }
        } else {
          if (mount) {
            sessionStorage.removeItem("userName");
            sessionStorage.removeItem("token");
            props.onLogout();
            setRedi(true);
          }
        }
      });

    return () => {
      mount = false;
    };
  }, [agr_code]);

  const onSelectQRCode = (agr_code, scan_code) => {
    props.history.push({
      pathname: "/manageagreement-paymentlist",
      agr_code: agr_code,
      scan_code: scan_code,
    });
  };

  let redirectpage;
  if (redi === true) {
    redirectpage = <Redirect to="/login" />;
  }

  const path = {
    Path: [
      {
        title: "ข้อมูลสินเชื่อของฉัน",
        pathLink: "/manageagreement",
      },
      {
        title: agr_code,
        pathLink: "/manageagreement-detail",
      },
      {
        title: "ปิดบัญชี",
        pathLink: "/manageagreement-closeacc",
      },
    ],
  };

  const [manageAgrForm2, setManageAgrForm2] = useState([]);

  const formElementsArray2 = [];
  for (let key in manageAgrForm2) {
    formElementsArray2.push({
      id: key,
      config: manageAgrForm2[key],
    });
  }

  const [manageAgrForm] = useState([
    {
      fin_type: fin_type,
      agr_code: agr_code,
      body: body,
      brand: brand,
      reg_num: reg_num,
      reg_province: reg_province,
      asset_description: asset_description,
      install: install,
      os_balance: os_balance,
      n_due_i: n_due_i,
      flat_rate: flat_rate,
      disbursement_date: disbursement_date,
      ar: ar,
      terms: terms,
      scan_code: scan_code,
    },
  ]);

  const formElementsArray = [];
  for (let key in manageAgrForm) {
    formElementsArray.push({
      id: key,
      config: manageAgrForm[key],
    });
  }

  const [tab1, setTab1] = React.useState(false);
  const [tab2, setTab2] = React.useState(false);

  const handleChange = (event) => {
    click = event;
    if (click === "1") {
      setTab1(true);
      setTab2(false);
    } else if (click === "2") {
      setTab2(true);
      setTab1(false);
    }
  };

  if (tab2 === true) {
    data = formElementsArray2.map((formElement) => (
      <History
        key={formElement.id}
        date={formElement.config.last_paid_dt}
        price={formElement.config.sum_paid}
      />
    ));
  } else {
    data = formElementsArray.map((formElement) => (
      <CloseAcc
        key={formElement.id}
        number={formElement.config.agr_code}
        cartype={formElement.config.body}
        carbrand={formElement.config.brand}
        description={formElement.config.asset_description}
        carnumber={formElement.config.reg_num}
        carcountry={formElement.config.reg_province}
        type={formElement.config.fin_type}
        interest={formElement.config.flat_rate}
        firstdate={formElement.config.disbursement_date}
        total={formElement.config.ar}
        term={formElement.config.terms}
        pricepermonth={formElement.config.install}
        balance={formElement.config.os_balance}
        date={formElement.config.n_due_i}
        onSelectQRCode={() =>
          onSelectQRCode(
            formElement.config.agr_code,
            formElement.config.scan_code
          )
        }
      />
    ));
  }

  return (
    <PathTree list={path.Path}>
      {redirectpage}
      <div className="ask__font__main">
        <ManageAgreementCloseAcc
          children={data}
          onSelected={handleChange}
        ></ManageAgreementCloseAcc>
      </div>
    </PathTree>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAgreementControlsCloseAcc);
