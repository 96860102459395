import React from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

import Stepper from "../UI/Steppers/Stepper";
import { numberWithCommas } from "../../shared/utility";

const trackingRequest = (props) => {
  const { request } = props;

  return (
    <Card
      style={{
        color: "#000000de",
        border: "1px solid",
        borderRadius: "3px",
        borderColor: "#ffffff",
        fontFamily: "Prompt-Regular",
      }}
    >
      <div className="grid ask__font__main">
        <div className="col-6">
          <p className="font-bold">{request.ref_code}</p>
        </div>
        <div className="col-6">
          <p className="font-bold text-right">{request.reg_num}</p>
        </div>
        <Divider />
        <div className="col-12">
          <Stepper status={request.result_task.toLowerCase()}></Stepper>
        </div>
        <div className="col-12">
          <p className="ask__font__main font-bold">{request.fin_type}</p>
        </div>
        <div className="col-12">
          <p className="ask__font__main font-bold">
            ยอดสินเชื่อ:{" "}
            {numberWithCommas(
              parseFloat(Math.round(request.fin_amt * 100) / 100).toFixed(2)
            )}{" "}
            บาท
          </p>
        </div>
        <div className="col-12">
          <p className="ask__font__main font-bold">
            จำนวนงวดทั้งหมด: {request.terms} งวด
          </p>
        </div>
        {request.body ? (
          <div className="col-12">
            <p>{request.body}</p>
          </div>
        ) : (
          <p></p>
        )}
        {request.brand ? (
          <div className="col-12">
            <p>
              {request.brand} - {request.asset_description}
            </p>
          </div>
        ) : (
          <p></p>
        )}
      </div>
    </Card>
  );
};

export default trackingRequest;

// ref_code(pin):"106209H00122"
// result_task(pin):"CANCEL"
// fin_type(pin):"สินเชื่อรถยนต์"
// agr_code(pin):""
// new_tr(pin):"N"
// fin_ty(pin):"1"
// fin_amt(pin):"2080500"
// down_amt(pin):"109500"
// terms(pin):"48"
// body(pin):"รถโดยสารเพื่อการพาณิชย์ : (Commercial Bus)"
// asset_description(pin):"COASTER"
// brand(pin):"TOYOTA"
// reg_num(pin):" "
// model_name(pin):"COASTER"
// disburse_date(pin):""
