import React from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import "../Apply/Apply.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const history = (props) => {
  const style = {
    padding: props.padding,
  };
  // const removelink = {
  //     textDecoration: 'none',
  // }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div style={style}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p>{props.date}</p>
        </Grid>
        {/* <Grid item xs={6} sm={6} md={8}>
                    <p className="text-bold">ค่างวด</p>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <div className="text-right"><Link style={{
                        fontSize: "1.6rem",
                        color: "#ff9600",
                    }} component="button" variant="body2" onClick={props.onSelectNumber}>
                        <u>ดาวน์โหลดเอกสาร</u>
                    </Link></div>
                </Grid> */}
        {/* <Grid item xs={6}>
                    <p className="text-left">วันที่ชำระค่างวด</p>
                </Grid>
                <Grid item xs={6}>
                    <p className="text-right">{props.date}</p>
                </Grid> */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid item xs={6}>
                <p className="text-left">จำนวนที่ชำระ</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text-right">
                  {numberWithCommas(
                    parseFloat(Math.round(props.price * 100) / 100).toFixed(2)
                  )}{" "}
                  บาท
                </p>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <p className="text-left">ค่างวด</p>
                </Grid>
                <Grid item xs={6}>
                  <p className="text-right">
                    {numberWithCommas(
                      parseFloat(
                        Math.round(props.price * 100) / 100 -
                          Math.round(props.price * 7) / 100
                      ).toFixed(2)
                    )}{" "}
                    บาท
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p className="text-left">VAT ค่างวด 7%</p>
                </Grid>
                <Grid item xs={6}>
                  <p className="text-right">
                    {numberWithCommas(
                      parseFloat(Math.round(props.price * 7) / 100).toFixed(2)
                    )}{" "}
                    บาท
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p className="text-left">ช่องทาง</p>
                </Grid>
                <Grid item xs={6}>
                  <p className="text-right text-bold">โมบายแบงก์กิ้ง</p>
                </Grid>
                <Grid item xs={6}>
                  <p className="text-left">เลขที่ใบเสร็จ</p>
                </Grid>
                <Grid item xs={6}>
                  <p className="text-right text-bold">xxxxxxxxx</p>
                </Grid>
                <Grid item xs={6}>
                  <p className="text-left">วันที่ออกใบเสร็จ</p>
                </Grid>
                <Grid item xs={6}>
                  <p className="text-right text-bold">{props.date}</p>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={6} sm={6} md={8}></Grid>
        <Grid item xs={6} sm={6} md={4}>
          <div className="text-right">
            <Link
              style={{
                fontSize: "1.6rem",
                color: "#ff9600",
              }}
              component="button"
              variant="body2"
              onClick={props.onSelectNumber}
            >
              <u>ดาวน์โหลดเอกสาร</u>
            </Link>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="hline"></div>
        </Grid>
      </Grid>
    </div>
  );
};

export default history;
