import React, { useState, useEffect } from "react";
import Button from "./../../../components/UI/Button/Button";
// import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";
// import Input from "./../../../components/UI/Input/Input";
import "./otp.css";
import { updateObject } from "./../../../shared/utility";
import { API_MYASK, API_MYASK_HEADER } from "./../../../shared/constant";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { connect } from "react-redux";
import * as actions from "./../../../store/action/index";
import OTPInput from "otp-input-react";
import ModalError from "./../../../components/UI/Error/Error";

const ConfirmOTP = (props) => {
  const [otp, setOtp] = useState({
    val: "",
    length: 6,
    respText: "",
  });

  const [timer, setTimer] = useState(props.otpTime);

  const [info, setInfo] = useState({
    mobileNo: "",
    username: "",
  });

  const [status, setStatus] = useState({
    value: "",
  });
  // const [OTP, setOTP] = useState("");
  const [reren, setReren] = useState("");

  useEffect(() => {
    let updateInfo;
    let updateStatus;

    if (props.location.status === "regis") {
      updateInfo = updateObject(info, {
        username: props.location.form.username.value,
        password: props.location.form.password.value,
        mobileNo: props.location.form.mobileNo.value,
        agr_code: props.location.form.agr_code.value,
        email: props.location.form.email.value,
        regis_from: props.lineId === null ? "WEB" : "LINE",
        otp: otp.val,
        ref: props.location.refOtp,
        lineId: props.lineId,
      });
      updateStatus = updateObject(status, {
        value: props.location.status,
      });
    } else {
      updateInfo = updateObject(info, {
        mobileNo: props.location.mobileNo,
        username: props.location.username,
        otp: otp.val,
        ref: props.location.refOtp,
      });
      updateStatus = updateObject(status, {
        value: props.location.status,
      });
    }
    setStatus(updateStatus);
    setInfo(updateInfo);
  }, []);

  useEffect(() => {
    const x = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(x);
    };
  }, [timer]);

  useEffect(() => {
    if (props.refOtp) {
      if (props.location.status === "regis") {
        props.history.push({
          mobile: props.location.form.mobileNo.value,
          refOtp: props.refOtp,
          otpPass: props.passOtp,
          form: props.location.form,
        });
        setTimer(props.otpTime);
      } else {
        props.history.push({
          mobile: props.location.mobileNo,
          refOtp: props.refOtp,
          otpPass: props.passOtp,
        });
        setTimer(props.otpTime);
      }
    }
  }, [props.refOtp]);

  const onChangeHandler = (val) => {
    // val = onChangeNumber(val);
    const updateState = updateObject(otp, {
      value: val,
      respText: "",
    });
    setOtp(updateState);
  };

  const onChangeNumber = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    if (isValid || val === "") {
      return val;
    } else {
      return val.substring(val.length - 1, 0);
    }
  };

  let otpRedirect = null;
  if (!props.location.refOtp) {
    otpRedirect = <Redirect to="/login" />;
  }

  const onSubmitHandler = () => {
    if (props.location.otpPass === otp.val) {
      if (status.value === "regis") {
        let apply;
        if (props.lineId !== null) {
          apply = "LINE";
        } else if (
          sessionStorage.getItem("src") ===
          "mOZl4zuJDIV9CbjgwaaeyabZZrPGhyyC9ZVKhrSzFA0oZ6mTJPJeaRyc6F"
        ) {
          apply = "MYCAR";
        } else {
          apply = "WEB";
        }
        const data = {
          username: info.username,
          password: info.password,
          mobile_no: info.mobileNo,
          agr_code: info.agr_code,
          email: info.email,
          regis_from: info.lineId,
          otp: otp.val,
          ref: props.location.refOtp,
          lineId: info.lineId,
          apply: apply,
        };

        Axios.post(API_MYASK + "/Register", data, {
          headers: API_MYASK_HEADER,
        })
          .then((res) => {
            if (res.data.CODE === "200") {
              // sessionStorage.setItem('token', res.data.MSG);

              props.onAuthSuccess(res.data.MSG, data.username);
              props.history.push({
                pathname: "/agreements",
              });
            } else if (res.data.CODE === "500") {
              setReren(
                <ModalError
                  title="เกิดข้อผิดพลาด"
                  text="กรุณาทำรายการใหม่ในภายหลัง"
                  color="red"
                  border="0.1rem solid red"
                  link="/login"
                ></ModalError>
              );
            } else {
              const updateState = updateObject(otp, {
                respText: res.data.MSG,
              });
              setOtp(updateState);
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else if (status.value === "forgot") {
        const data = {
          mobile_no: info.mobileNo,
          ref: props.location.refOtp,
          otp: otp.val,
        };
        Axios.post(API_MYASK + "/ConfirmForgetUsernameAPI", data, {
          headers: API_MYASK_HEADER,
        })
          .then((res) => {
            if (res.data.CODE === "200") {
              props.history.push({
                pathname: "/success",
                title: "ชื่อผู้ใช้งานของคุณคือ",
                text: info.username,
                path: "/login",
              });
            } else if (res.data.CODE === "500") {
              setReren(
                <ModalError
                  title="เกิดข้อผิดพลาด"
                  text="กรุณาทำรายการใหม่ในภายหลัง"
                  color="red"
                  border="0.1rem solid red"
                  link="/login"
                ></ModalError>
              );
            } else {
              const updateState = updateObject(otp, {
                respText: res.data.MSG,
              });
              setOtp(updateState);
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    } else {
      const updateState = updateObject(otp, {
        respText: "รหัส OTP ไม่ถูกต้อง",
      });
      setOtp(updateState);
    }
  };

  const onResendOtp = () => {
    const updateState = updateObject(otp, {
      val: "",
      respText: "",
    });
    setOtp(updateState);
    props.onSendOTP(info.mobileNo);
  };

  const onSetOTP = (val) => {
    const updateState = updateObject(otp, {
      val: val,
      respText: "",
    });
    setOtp(updateState);
  };

  return (
    <div className="otp__container">
      {reren}
      {otpRedirect}
      <div className="text-center otp">
        <h4 className="otp__header ask__font__title">ยืนยันรหัส</h4>
        <div className="otp__body ask__font__main">
          <div>ระบบจะส่งรหัสไปที่หมายเลข</div>
          {/* {props.location.status === 'regis'
            ? <div>{otpRedirect === null ? props.location.form.mobileNo.value : null}</div>
            : <div>{otpRedirect === null ? props.location.mobileNo : null} </div>} */}
          <div>{otpRedirect === null ? info.mobileNo : null} </div>
          <div className="text-bold">
            หมายเลขอ้างอิง: {props.location.refOtp}
          </div>
          {/* <div className="text-bold">
            (for test) รหัส OTP: {props.location.otpPass}
          </div> */}
          <label className="text__invalid">{otp.respText}</label>
          {/* <Input
            elementType="input"
            type="number"
            elementConfig={otp}
            max={otp.maxLength}
            min={otp.minLength}
            align="center"
            value={otp.value}
            mode="numeric"
            changed={event => onChangeHandler(event.target.value)} /> */}

          <OTPInput
            value={otp.val}
            onChange={onSetOTP}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            inputStyles={{
              marginRight: "1rem",
              height: "3.5rem",
              width: "3.5rem",
              fontSize: "2rem",
              border: "none",
              borderBottom: "1px solid #ccc",
            }}
          />

          <Button
            btnType="submit"
            width="100%"
            children="ส่งข้อมูล"
            clicked={onSubmitHandler}
          />
          {timer > 0 ? (
            <small>รหัส OTP ของคุณจะหมดอายุภายใน : {timer} วินาที</small>
          ) : (
            <Button
              btnType="border"
              width="100%"
              children="รับรหัส OTP ใหม่"
              clicked={onResendOtp}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    refOtp: state.auth.refOtp,
    passOtp: state.auth.passOtp,
    lineId: state.line.userId,
    otpTime: state.auth.otpTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendOTP: (mobile) => dispatch(actions.sendOTP(mobile)),
    onAuthSuccess: (token, userName) => dispatch(actions.auth(token, userName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOTP);
