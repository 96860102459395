import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "../UI/Paper/Paper";
import Button from "./../../components/UI/Button/Button";
import mli from "./../../assets/main/main-loaninfo.jpeg";
import mtk from "./../../assets/main/main-tracking.jpeg";
import mii from "./../../assets/main/main-insuranceinfo.jpeg";
import mp from "./../../assets/main/main-payment.jpeg";
import "../Apply/Apply.css";
import { faMarsStrokeV } from "@fortawesome/free-solid-svg-icons";

const main = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <center>
          <Paper
            margin="1rem"
            classes="width100"
            borderRadius="1rem"
            padding="0rem"
            align="left"
            border="1px solid"
            borderColor="#001f3f"
            children={
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <img
                    src={mli}
                    width="100%"
                    height="170"
                    style={{ borderRadius: "1rem 1rem 0rem 0rem" }}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: "2rem" }}>
                  <p className="text-bold">จัดการสินเชื่อ</p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                >
                  <p>
                    ดูรายละเอียดสัญญาแต่ละประเภทของท่าน
                    และชำระค่างวดได้อย่างยืดหยุ่น
                  </p>
                </Grid>
                <Grid item xs={12} style={{ padding: "2rem" }}>
                  <center>
                    <Button
                      btnType="border"
                      children="เข้าใช้งาน"
                      fontColor="#001f3f"
                      borderColor="#001f3f"
                      borderRadius="0rem"
                      clicked={props.onSelectAgreement}
                    ></Button>
                  </center>
                </Grid>
              </Grid>
            }
          />
        </center>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <center>
          <Paper
            margin="1rem"
            classes="width100"
            borderRadius="1rem"
            padding="0rem"
            align="left"
            border="1px solid"
            borderColor="#001f3f"
            children={
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <img
                    src={mtk}
                    width="100%"
                    height="170"
                    style={{ borderRadius: "1rem 1rem 0rem 0rem" }}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: "2rem" }}>
                  <p className="text-bold">ติดตามคำร้องขอ</p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                >
                  <p>ตรวจสอบสถานะคำร้องขอสินเชื่อของท่าน</p>
                </Grid>
                <Grid item xs={12} style={{ padding: "2rem" }}>
                  <center>
                    <Button
                      btnType="border"
                      children="เข้าใช้งาน"
                      fontColor="#001f3f"
                      borderColor="#001f3f"
                      borderRadius="0rem"
                      clicked={props.onSelectTracking}
                    ></Button>
                  </center>
                </Grid>
              </Grid>
            }
          />
        </center>
      </Grid>
      {/* <Grid item xs={12} sm={12} md={4}>
        <center>
          <Paper
            margin="1rem"
            classes="width100"
            borderRadius="1rem"
            padding="0rem"
            align="left"
            border="1px solid"
            borderColor="#001f3f"
            children={
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <img
                    src={mii}
                    width="100%"
                    height="170"
                    style={{ borderRadius: "1rem 1rem 0rem 0rem" }}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: "2rem" }}>
                  <p className="text-bold">ข้อมูลประกัน</p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                >
                  <p>
                    ตรวจสอบข้อมูลประกัน ท่านสามารถต่อประกัน และจ่ายค่าประกันได้
                  </p>
                </Grid>
                <Grid item xs={12} style={{ padding: "2rem" }}>
                  <center>
                    <Button
                      btnType="border"
                      children="เข้าใช้งาน"
                      fontColor="#001f3f"
                      borderColor="#001f3f"
                      borderRadius="0rem"
                      clicked={props.onSelectInsurance}
                    ></Button>
                  </center>
                </Grid>
              </Grid>
            }
          />
        </center>
      </Grid> */}
      {/* <Grid item xs={12} sm={12} md={4}>
        <center>
          <Paper
            margin="1rem"
            classes="width100"
            borderRadius="1rem"
            padding="0rem"
            align="left"
            border="1px solid"
            borderColor="#001f3f"
            children={
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <img
                    src={mp}
                    width="100%"
                    height="170"
                    style={{ borderRadius: "1rem 1rem 0rem 0rem" }}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: "2rem" }}>
                  <p className="text-bold">ชำระค่างวด</p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                >
                  <p>
                    ท่านสามารถจัดการและเลือกจ่ายค่างวดทีละหลายสัญญาในครั้งเดียวได้
                  </p>
                </Grid>
                <Grid item xs={12} style={{ padding: "2rem" }}>
                  <center>
                    <Button
                      btnType="border"
                      children="เข้าใช้งาน"
                      fontColor="#001f3f"
                      borderColor="#001f3f"
                      borderRadius="0rem"
                      clicked={props.onSelectPayment}
                    ></Button>
                  </center>
                </Grid>
              </Grid>
            }
          />
        </center>
      </Grid> */}
    </Grid>
  );
};

export default main;
