import React, { Fragment } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "primereact/button";

const PolicyEtax = (props) => {
  return (
    <Fragment>
      <div
        className="col-12 sm:col-12 md:col-8 md:col-offset-2 
                lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2  mt-6 px-6"
      >
        <center>
          <p
            className="text-bold ask__font__title"
            style={{ color: "#EC2323" }}
          >
            เงื่อนไขการขอรับใบเสร็จรับเงิน/ใบกำกับภาษี
            ในรูปแบบข้อมูลอิเล็กทรอนิกส์
          </p>
        </center>
        <div className="my-4 mt-6 ask__font__title">
          <p className="text-light mt-6">
            1. บริษัท เอเชียเสริมกิจ ลีสซิ่ง จำกัด (มหาชน)
            (บริษัทฯ)จะจัดส่งเอกสารใบเสร็จรับเงิน/ใบกำกับภาษี
            ในรูปแบบอิเล็กทรอนิกส์ ซึ่งท่านได้ทำธุรกรรมในทุกสัญญา
            ของสินเชื่อทุกประเภท ผ่านไปรษณีย์อิเล็กทรอนิกส์ (E-Mail)
            ที่ท่านได้ลงทะเบียนไว้ โดยการขอรับเอกสารในรูปแบบอิเล็กทรอนิกส์นี้
            ถือว่าท่านประสงค์ยกเลิก บริการการส่งใบเสร็จรับเงิน/ใบกำกับภาษี
            ทางไปรษณีย์ตามที่อยู่ที่เคยแจ้งไว้กับบริษัทฯ
          </p>
          <br />
          <p>
            2. บริษัทฯ
            จะเริ่มส่งเอกสารในรูปแบบอิเล็กทรอนิกส์ให้ท่านในรอบบิลถัดไป
            ภายหลังจากที่ท่านทำการยืนยันตอบรับเรียบร้อยแล้ว
          </p>
        </div>
        <div style={{ marginTop: "8rem" }}></div>
        <div className="my-4 mt-6 ask__font__title flex align-items-start">
          <Checkbox
            className="mr-2"
            checked={props.accept}
            onChange={() => props.onAccept(!props.accept)}
            value={1}
            style={{
              color: "#ff9600",
              transform: "scale(2)",
            }}
          />
          <div className="font-bold">
            ข้าพเจ้าได้อ่านแล้ว
            ตกลงยินยอมและยืนยันการรับใบเสร็จรับเงิน/ใบกำกับภาษี
            ในรูปแบบอิเล็กทรอนิกส์
          </div>
        </div>
        <div className="grid justify-content-end mt-6">
          <Button
            disabled={props.accept !== true}
            label="ถัดไป"
            className="col-4 sm:col-4  xl:col-3
             p-button-success mt-6 p-3"
            style={{
              borderRadius: "0px",
              backgroundColor: "#ff9600",
            }}
            onClick={() => props.onNextPage()}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default PolicyEtax;
