import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import QRcode from "qrcode.react";
import Barcode from "react-barcode";

import * as actions from "./../../../../store/action/index";
import * as actionTypes from "../../../../store/action/actionTypes";
import Loading from "./../../../../components/UI/Loading/Loading";
import Input from "./../../../../components/UI/Input/Input";
import AgreementItem from "../../../../components/Personal/Agreements/List/AgreementItem";
import Modal from "./../../../../components/UI/Modal/Modal";
const MyAgreements = (props) => {
  const toast = useRef();
  let history = useHistory();
  const [agreementsFilter, setAgreementFilter] = useState(null);
  const [scanCode, setScanCode] = useState("");
  const [scanCodeV, setScanCodeV] = useState("");
  const [ref1, setRef1] = useState("");
  const [ref2, setRef2] = useState("");
  const [tabSelect, setTabSelect] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (props.filter != null && props.agreements != null) {
      let result = [];
      result = props.agreements.filter((data) => {
        return (
          data.agr_code.search(props.filter) > -1 ||
          (data.reg_num != null && data.reg_num.search(props.filter) > -1)
        );
      });

      setAgreementFilter(result);
    } else {
      setAgreementFilter(props.agreements);
    }
  }, [props.agreements, props.filter]);

  const handleFilter = (event) => {
    let value = event.target.value.toLowerCase();
    props.filterAgreements(value);
  };

  const findIndexInPayment = (agr_code) => {
    const index = props.payment_agreements.findIndex(
      (p) => p.agreement.agr_code === agr_code
    );
    return index;
  };

  const onPayment = (agreement) => {
    let canInsert = false;

    const index = findIndexInPayment(agreement.agr_code);
    if (index === -1) {
      canInsert = true;
    }
    if (canInsert) {
      let items = [];
      for (let i in agreement.items) {
        let itemData = agreement.items[i];
        let item = {
          key: itemData.key,
          sub_key: itemData.sub_key,
          title: itemData.title,
          amount: itemData.amount.replaceAll(",", ""),
        };
        items.push(item);
      }

      props.insertAgreementPayList(agreement, agreement.agr_code, items, false);

      showToast(
        "success",
        "สัญญา " + agreement.agr_code,
        "เพิ่มลงในสินเชื่อที่จะชำระ!"
      );
    } else {
      showToast(
        "error",
        "สัญญา " + agreement.agr_code,
        "อยู่ในสินเชื่อที่จะชำระอยู่แล้ว"
      );
    }
  };

  const onViewDetail = useCallback(
    (agr_code, com_code) => {
      history.push({
        pathname: "/agreement-detail",
        search: "?agr=" + agr_code + "&comCode=" + com_code,
      });
    },
    [props.agreements]
  );

  const onClosedAgreementDetail = useCallback(
    (agr_code, com_code) => {
      let canInsert = false;

      const index = props.payment_agreements.findIndex(
        (p) => p.agreement.agr_code === agr_code
      );

      if (index === -1) {
        canInsert = true;
      }
      if (canInsert) {
        history.push({
          pathname: "/agreement-closed",
          search: "?agr=" + agr_code + "&comCode=" + com_code + "&action=y",
        });
      } else {
        showToast(
          "error",
          "สัญญา " + agr_code,
          "อยู่ในสินเชื่อที่จะชำระอยู่แล้ว"
        );
      }
    },
    [props.agreements, props.payment_agreements]
  );

  const onRefresh = () => {
    props.getAgreements();
    props.clearPayment();
  };

  const showToast = (severity, summary, msg) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: msg,
      life: 3000,
    });
  };

  const onInsertPayData = useCallback(
    (agr_code, com_code) => {
      props.insertPayData(agr_code, com_code, props.runNo);
    },
    [props.agreements]
  );

  const onRemove = useCallback(
    (agr_code) => {
      props.removeAgreementPayList(agr_code);
    },
    [props.agreements]
  );

  const onOpenQR = (scan_code, ref_1, ref_2, isQR) => {
    setScanCode(scan_code);
    let vArr = scan_code.split(" ");
    let v = "";
    for (var i = 0; i < vArr.length; i++) {
      v = v + vArr[i] + "\n";
    }
    setScanCodeV(v);
    setRef1(ref_1);
    setRef2(ref_2);
    if (isQR === true) {
      handleQRTab();
    } else {
      handleBarCodeTab();
    }
    handleOpenModal();
  };

  const handleQRTab = () => {
    setTabSelect(0);
  };

  const handleBarCodeTab = () => {
    setTabSelect(1);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  let modal = null;
  if (tabSelect === 0) {
    modal = (
      <Modal
        open={openModal}
        close={handleCloseModal}
        children={
          <center>
            <QRcode value={scanCodeV} size={256} level="M"></QRcode>
          </center>
        }
      />
    );
  } else if (tabSelect === 1) {
    modal = (
      <Modal
        open={openModal}
        close={handleCloseModal}
        children={
          <center>
            <div
              style={{
                transform: window.innerWidth < 720 ? "rotate(270deg)" : "none",
              }}
              className="p-p-6"
            >
              <Barcode
                value={scanCodeV}
                width={window.innerWidth < 720 ? 1 : 2}
                text={"BBS" + scanCode}
                displayValue={true}
                font="'Prompt-Regular'"
                fontSize={14}
                format="CODE128"
              />
            </div>
          </center>
        }
      />
    );
  }
  return (
    <div>
      {modal}
      <Toast ref={toast} />
      {props.agreements != null && props.agreements.length === 0 && (
        <center>
          <p className="ask__font__main">ไม่พบข้อมูล</p>
        </center>
      )}
      {props.agreementsState === actionTypes.VIEW_STATE.LOADING && (
        <center>
          <Loading />
        </center>
      )}
      {props.agreements != null &&
        props.agreements.length > 0 &&
        agreementsFilter != null && (
          <div>
            <center>
              <Input
                elementType="search"
                width="80%"
                inside="ค้นหาจากเลขสัญญา / เลขทะเบียนรถ"
                onChange={handleFilter}
                value={props.filter}
              ></Input>
            </center>
            <div className="ask__font__main my-6 flex justify-content-between">
              <p>สัญญาที่พบ: {agreementsFilter.length}</p>
              <div>
                <Button
                  label="โหลดใหม่"
                  icon="pi pi-refresh"
                  iconPos="left"
                  onClick={() => onRefresh()}
                />
              </div>
            </div>
          </div>
        )}

      <div className="grid">
        {agreementsFilter != null &&
          agreementsFilter.length > 0 &&
          agreementsFilter.map((agreement, index) => (
            <div
              className="col-12 sm:col-12 md:col-6 lg:col-4 xs:col-4"
              key={"div_agree_" + index}
            >
              <AgreementItem
                agreement={agreement}
                run_no={props.runNo}
                key={"agree_" + index}
                onPayment={() => onPayment(agreement)}
                onView={onViewDetail}
                onClosedAgreement={onClosedAgreementDetail}
                canInsert={findIndexInPayment(agreement.agr_code) === -1}
                onRemove={() => onRemove(agreement.agr_code)}
                onInsertPayData={(agr_code, com_code) =>
                  onInsertPayData(agr_code, com_code)
                }
                onOpenQR={onOpenQR}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    agreements: state.agreement.agreements,
    agreementsState: state.agreement.agreementsState,
    filter: state.agreement.search,
    payment_agreements: state.payment.payment_agreements,
    runNo: state.agreement.run_no,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
    getAgreements: () => dispatch(actions.getAgreements()),
    filterAgreements: (value) => dispatch(actions.filterAgreements(value)),
    insertAgreementPayList: (agreement, agr_code, items, is_closed) =>
      dispatch(
        actions.insertAgreementPayList(agreement, agr_code, items, is_closed)
      ),
    removeAgreementPayList: (agr_code) =>
      dispatch(actions.removeAgreementPayList(agr_code)),
    insertPayData: (agr_code, com_code, run_no) =>
      dispatch(actions.insertPayData(agr_code, com_code, run_no)),
    clearPayment: () => dispatch(actions.clearPaymentState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAgreements);
