import React from 'react';
import Paper from '@material-ui/core/Paper';
import './Paper.css';

const paper = (props) => {

    const style = {
        display: props.display,
        padding: props.padding,
        width: props.width,
        height: props.height,
        margin: props.margin,
        fontSize: props.fontSize,
        textAlign: props.align,
        border: props.border,
        backgroundColor: props.color,
        color: props.fontColor,
        borderColor: props.borderColor,
        borderRadius: props.borderRadius,
    }

    return (
        <Paper className={props.classes} style={style}>
            {props.children}
        </Paper>
    );
};

export default paper;