import React from "react";
import { Card } from "primereact/card";

import { numberWithCommas } from "../../../shared/utility";

const Insurance = (props) => {
  const { insurance } = props;

  return (
    <Card
      style={{
        color: "#000000de",
        border: "1px solid",
        borderRadius: "3px",
        fontFamily: "Prompt-Regular",
      }}
    >
      <div className="grid ask__font__main">
        <div className="col-12">
          <p
            className="font-bold text-center"
            style={{ color: "#970093", fontSize: "1.8rem" }}
          >
            <u>{insurance.ins_type}</u>
          </p>
        </div>
        <div className="col-6">
          <p>ยี่ห้อ / รุ่น :</p>
        </div>
        <div className="col-6">
          <p className="text-right">
            {insurance.brand} - {insurance.model_name}
          </p>
        </div>
        <div className="col-6">
          <p>ทะเบียน :</p>
        </div>
        <div className="col-6">
          <p className="text-right">{insurance.reg_num}</p>
        </div>
        <div className="col-6">
          <p>ปีรุ่น :</p>
        </div>
        <div className="col-6">
          <p className="text-right">{insurance.year}</p>
        </div>
        <div className="col-6">
          <p>ชื่อบริษัทประกัน :</p>
        </div>
        <div className="col-6">
          <p className="text-right">{insurance.ins_com}</p>
        </div>
        <div className="col-6">
          <p>ทุนประกัน :</p>
        </div>
        <div className="col-6">
          <p className="text-right">
            {numberWithCommas(
              parseFloat(Math.round(insurance.cov_amt * 100) / 100).toFixed(2)
            )}{" "}
            บาท
          </p>
        </div>
        <div className="col-6">
          <p>เบี้ยประกัน :</p>
        </div>
        <div className="col-6">
          <p className="text-right">
            {numberWithCommas(
              parseFloat(Math.round(insurance.total_prem * 100) / 100).toFixed(
                2
              )
            )}{" "}
            บาท
          </p>
        </div>
        <div className="col-6">
          <p>หมายเลขกรมธรรม์ :</p>
        </div>
        <div className="col-6">
          <p className="text-right">{insurance.pol_num}</p>
        </div>
        <div className="col-6">
          <p>ระยะเวลาคุ้มครอง :</p>
        </div>
        <div className="col-6">
          <p className="text-right">
            {insurance.ins_date_th} - {insurance.ins_exp_th}
          </p>
        </div>
        <div className="col-6">
          <p className="text-bold">เลขที่สัญญาเช่าซื้อ :</p>
        </div>
        <div className="col-6">
          <p className="text-right">{insurance.agr_code}</p>
        </div>
      </div>
    </Card>
  );
};

export default Insurance;
