import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Redirect } from "react-router-dom";

import * as actions from "./../../../../../store/action/index";
import * as actionTypes from "../../../../../store/action/actionTypes";
import Loading from "./../../../../../components/UI/Loading/Loading";
import { numberWithCommas } from "../../../../../shared/utility";
import { Fragment } from "react";

const AgreementDetail = (props) => {
  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (query.get("agr") && query.get("comCode")) {
      props.getAgreementDetail(
        query.get("agr"),
        query.get("comCode"),
        props.runNo
      );
    }
  }, []);

  const openInsurance = (agr_code) => {
    history.push({
      pathname: "/agreement-insurance",
      search: "?agr=" + agr_code,
    });
  };

  const openPaymentHistory = (agr_code) => {
    history.push({
      pathname: "/agreement-payment-history",
      search: "?agr=" + agr_code,
    });
  };

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  if (props.runNo == null || props.runNo === "") {
    redirectpage = <Redirect to="/agreements" />;
  }

  return (
    <div className="ask__font__main">
      {redirectpage}
      <Button className="p-button-text m-4" onClick={() => history.goBack()}>
        <span style={{ color: "#ff9600" }}> {"< ย้อนกลับ"}</span>
      </Button>

      <div className="grid mt-2" style={{ width: "100%" }}>
        <div className="col-12 mb-4">
          <center>
            <p className="font-bold ask__font__title">รายละเอียดสัญญา</p>
          </center>
        </div>
        <div
          className="grid col-12 branchpanel mx-3 justify-content-center"
          style={{ paddingTop: "1rem" }}
        >
          {props.detail == null &&
            props.detailState === actionTypes.VIEW_STATE.SUCCESS && (
              <center>
                <p className="ask__font__main">ไม่พบข้อมูล</p>
              </center>
            )}
          {props.detailState === actionTypes.VIEW_STATE.LOADING && (
            <center>
              <Loading />
            </center>
          )}
          {props.detail != null && (
            <div className="grid col-12  justify-content-center">
              <div className="col-12 sm:col-12 md:col-8">
                <Card
                  style={{
                    color: "#000000de",
                    border: "1px solid",
                    borderRadius: "3px",
                    borderColor: "#000000",
                    fontFamily: "Prompt-Regular",
                  }}
                >
                  <div className="p-3 ask__font__main">
                    <div className="font-bold" style={{ color: "#ff9600" }}>
                      เลขที่สัญญา {props.detail.agr_code}
                    </div>

                    {props.detail.brand ||
                    props.detail.reg_num ||
                    props.detail.reg_province ? (
                      <Divider
                        className="mt-6 ask__font__main"
                        align="left"
                        style={{ fontFamily: "Prompt-Regular" }}
                      >
                        <div className="font-bold  mb-2">ข้อมูลทรัพย์สิน</div>
                      </Divider>
                    ) : null}

                    <div className="flex">
                      {props.detail.brand ? (
                        <p className="font-normal mr-4">{props.detail.brand}</p>
                      ) : null}
                      {props.detail.reg_num ? (
                        <p className="font-normal mr-4">
                          {props.detail.reg_num}
                        </p>
                      ) : null}
                      {props.detail.reg_province ? (
                        <p className="font-normal mr-4">
                          {props.detail.reg_province}
                        </p>
                      ) : null}
                      {props.detail.brand === "" &&
                        props.detail.reg_num === "" &&
                        props.detail.reg_province === "" && (
                          <p className="font-bold mr-4">
                            <br />
                          </p>
                        )}
                    </div>

                    <Divider
                      className="mt-6 ask__font__main"
                      align="left"
                      style={{ fontFamily: "Prompt-Regular" }}
                    >
                      <div className="font-bold">ข้อมูลสัญญา</div>
                    </Divider>
                    <div className="grid ">
                      <div className="grid col-12  mt-2">
                        <div className="col-6">
                          <p>ประเภทสินเชื่อ</p>
                        </div>
                        <div className="col-6">
                          <p className="text-right">{props.detail.fin_type}</p>
                        </div>
                      </div>
                      <div className="grid col-12 mt-2">
                        <div className="col-6">
                          <p>วันที่ทำสัญญา</p>
                        </div>
                        <div className="col-6">
                          <p className="text-right">
                            {props.detail.disbursement_date}
                          </p>
                        </div>
                      </div>
                      <div className="grid col-12 mt-2">
                        <div className="col-6">
                          <p>วันที่เริ่มกำหนดชำระงวดแรก</p>
                        </div>
                        <div className="col-6">
                          <p className="text-right">
                            {props.detail.first_paid_date}
                          </p>
                        </div>
                      </div>
                    </div>

                    <Divider
                      className="mt-6 ask__font__main"
                      align="left"
                      style={{ fontFamily: "Prompt-Regular" }}
                    >
                      <div className="font-bold">ข้อมูลสินเชื่อ</div>
                    </Divider>

                    <div className="grid ">
                      {props.detail.x_condition !== "" && (
                        <div
                          className="col-12  my-6 py-6 align-items-center"
                          style={{ color: "#296DD6", textAlign: "center" }}
                        >
                          {props.detail.x_condition}
                        </div>
                      )}
                      {props.detail.x_condition === "" && (
                        <Fragment>
                          <div className="grid col-12 mt-2">
                            <div className="col-6">
                              <p>ยอดสินเชื่อทั้งหมด</p>
                            </div>
                            <div className="col-6">
                              <p className="text-right">
                                {numberWithCommas(
                                  parseFloat(
                                    Math.round(
                                      props.detail.original_amount * 100
                                    ) / 100
                                  ).toFixed(2)
                                )}{" "}
                                บาท
                              </p>
                            </div>
                          </div>
                          <div className="grid col-12 mt-2">
                            <div className="col-6">
                              <p>ชำระแล้ว</p>
                            </div>
                            <div className="col-6">
                              <p className="text-right">
                                {numberWithCommas(
                                  parseFloat(
                                    Math.round(props.detail.paid_amount * 100) /
                                      100
                                  ).toFixed(2)
                                )}{" "}
                                บาท
                              </p>
                            </div>
                          </div>
                          <div className="grid col-12 mt-2">
                            <div className="col-6">
                              <p>คงเหลือ</p>
                            </div>
                            <div className="col-6">
                              <p className="text-right">
                                {numberWithCommas(
                                  parseFloat(
                                    Math.round(props.detail.os_balance * 100) /
                                      100
                                  ).toFixed(2)
                                )}{" "}
                                บาท
                              </p>
                            </div>
                          </div>
                          <div className="grid col-12 mt-2">
                            <div className="col-6">
                              <p>จำนวนงวด</p>
                            </div>
                            <div className="col-6">
                              <p className="text-right">
                                {props.detail.paid_terms +
                                  "/" +
                                  props.detail.terms}{" "}
                                งวด
                              </p>
                            </div>
                          </div>
                          <div className="grid col-12 mt-2">
                            <div className="col-6">
                              <p>ค่างวด</p>
                            </div>
                            <div className="col-6">
                              <p className="text-right">
                                {numberWithCommas(
                                  props.detail.installment_i_vat
                                )}{" "}
                                บาท
                              </p>
                            </div>
                          </div>
                          <div className="grid col-12 mt-2">
                            <div className="col-6">
                              <p>ค่างวด ครบกำหนด</p>
                            </div>
                            <div className="col-6">
                              <p className="text-right">
                                {props.detail.n_due_i}
                              </p>
                            </div>
                          </div>
                          <div className="grid col-12 mt-3">
                            <div className="col-12">
                              <p className="font-bold">เจ้าหน้าที่ดูแล</p>
                            </div>
                            <div className="col-12">
                              <p className="text-left">
                                {props.detail.officer}
                              </p>
                            </div>
                          </div>
                        </Fragment>
                      )}
                      <div
                        className="flex mt-6 justify-content-around"
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Button
                            className="p-button-text"
                            onClick={() => openInsurance(query.get("agr"))}
                          >
                            <span style={{ color: "#ff9600" }}>
                              <u>ข้อมูลประกัน</u>
                            </span>
                          </Button>
                        </div>
                        <div>
                          <Button
                            className="p-button-text"
                            onClick={() => openPaymentHistory(query.get("agr"))}
                          >
                            <span style={{ color: "#ff9600" }}>
                              <u>ประวัติการชำระเงิน</u>
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="col-12 sm:col-12 md:col-8">
                <Card
                  style={{
                    color: "#000000de",
                    border: "1px solid",
                    borderRadius: "3px",
                    borderColor: "#000000",
                    fontFamily: "Prompt-Regular",
                  }}
                >
                  <div className="p-3 ask__font__main">
                    <div className="font-bold">ที่อยู่ในการจัดส่งเอกสาร</div>

                    <Divider />

                    <div className="grid ">
                      <div className="col-12 mt-3">
                        <p className="font-bold text-center">
                          ที่อยู่ปัจจุบัน
                          {props.detail.is_person === "Y"
                            ? "(ที่อยู่ในใบกำกับภาษี)"
                            : ""}
                        </p>
                      </div>
                      <div className="col-12 mt-2">
                        <p className="text-center">{props.detail.address}</p>
                      </div>
                      <div className="col-12 mt-3">
                        <p className="font-bold text-center">
                          ที่อยู่ส่งเอกสาร
                          {props.detail.is_person === "Y"
                            ? "(ที่อยู่ในใบเสร็จ)"
                            : ""}
                        </p>
                      </div>
                      <div className="col-12 mt-2">
                        <p className="text-center">{props.detail.address_m}</p>
                      </div>
                      <div className="col-12 mt-3">
                        <p className="font-bold text-center">
                          ที่อยู่ตามบัตร
                          {props.detail.is_person === "N"
                            ? "(ที่อยู่ในใบเสร็จและใบกำกับภาษี)"
                            : ""}
                        </p>
                      </div>
                      <div className="col-12 mt-2">
                        <p className="text-center">{props.detail.address_c}</p>
                      </div>
                      <div className="col-12 mt-6">
                        <p className="text-center">{props.detail.email}</p>
                      </div>
                      <div className="col-12 mt-2">
                        <p className="text-center">{props.detail.mobile_no}</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    detail: state.agreement.detail,
    detailState: state.agreement.detailState,
    runNo: state.agreement.run_no,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAgreementDetail: (agr_code, com_code, run_no) =>
      dispatch(actions.getAgreementDetail(agr_code, com_code, run_no)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementDetail);
