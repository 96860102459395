import React, { Fragment } from "react";
import { Button } from "primereact/button";
import CheckImage from "../../../assets/image/check.png";

import { useHistory } from "react-router-dom";

const ForgotResult = (props) => {
  const history = useHistory();

  const onClickButton = () => {
    history.push({ pathname: "/login" });
  };
  return (
    <Fragment>
      <div
        className="col-12 sm:col-12 md:col-8 md:col-offset-2 
                  lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2 mt-6 px-6"
      >
        <center>
          <img
            src={CheckImage}
            style={{ width: "128px", height: "128px" }}
            alt="success"
          ></img>
        </center>
        <div style={{ marginTop: "6rem" }}>
          <center>
            <div className="ask__font__title">
              ชื่อผู้งานของท่านคือ{" "}
              <div className="text-bold" style={{ fontSize: "2.5rem" }}>
                {props.username}
              </div>
            </div>
            <div className="ask__font__title mt-6">
              รหัสผ่านของท่านคือ{" "}
              <div className="text-bold" style={{ fontSize: "2.5rem" }}>
                {props.password}
              </div>
            </div>
          </center>
        </div>

        <div className="grid justify-content-center mt-6">
          <Button
            label="กลับไปหน้า เข้าสู่ระบบ"
            className="col-5 sm:col-5  xl:col-3
                p-button-success mt-6 p-3"
            style={{
              borderRadius: "0px",
              backgroundColor: "#ff9600",
            }}
            onClick={onClickButton}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotResult;
