import { put, delay } from "redux-saga/effects";
import axios from "axios";

import * as actions from "../action/index";
import * as actionTypes from "../action/actionTypes";
import { API_MYASK, API_MYASK_HEADER } from "../../shared/constant";

export function* csFilterSaga(action) {
  yield put(actions.csFilterStart());

  let url = API_MYASK + "/csFilter";

  let filterStr = action.value;
  const req = {
    value: filterStr,
  };

  try {
    const response = yield axios.post(url, req, {
      headers: API_MYASK_HEADER,
    });
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let filters = [];

      for (let i in response.data.LIST_DATA) {
        let obj = response.data.LIST_DATA[i];
        let data = {
          com_code: obj.com_code,
          agr_code: obj.agr_code,
          new_tr: obj.new_tr,
          username: obj.username,
          password: obj.password,
          cus_name: obj.cus_name,
          reg_num: obj.reg_num,
          reg_prv: obj.reg_prv,
          new_card_no: obj.new_card_no,
        };

        filters.push(data);
      }
      yield put(actions.csFilterSuccess(filterStr, filters));
    } else {
      yield put(actions.csFilterFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(actions.csFilterFailed(error.response.data.message));
    } else if (error.message != undefined) {
      yield put(actions.csFilterFailed(error.message));
    } else {
      yield put(actions.csFilterFailed("Error Something wrong."));
    }
  }
}
