import * as actionTypes from "./actionTypes";
import * as Util from "./../../shared/utility";
import axios from "axios";
import { API_MYASK, API_MYASK_HEADER, API_OTP } from "./../../shared/constant";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userName) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userName: userName,
  };
};

// export const authFail = (error) => {
//     return {
//         type: actionTypes.AUTH_FAIL,
//         error: error
//     };
// };

export const logout = (lineId, userName) => {
  return (dispatch) => {
    if (lineId) {
      const data = {
        lineid: lineId,
        username: userName,
      };
      axios
        .post(API_MYASK + "/Logout", data, {
          header: API_MYASK_HEADER,
        })
        .then((res) => {
          dispatch(logoutSuccess());
        });
    } else {
      dispatch(logoutSuccess());
    }
  };
};

export const logoutSuccess = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("userName");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const auth = (token, userName) => {
  return (dispatch) => {
    // dispatch(authStart());
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("userName", userName);
    dispatch(authSuccess(token, userName));
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token));
    }
  };
};

//------------- OTP ----------------//

export const otpStart = () => {
  return {
    type: actionTypes.OTP_START,
    refOtp: null,
    passOtp: null,
  };
};

export const otpSuccess = (otp) => {
  return {
    type: actionTypes.OTP_SUCCESS,
    refOtp: otp.refOtp,
    passOtp: otp.passOtp,
  };
};

export const sendOTP = (mobile) => {
  return (dispatch) => {
    const refOtp = Util.randString(6);
    const passOtp = Util.randNumber(6);
    axios
      .post(API_OTP, {
        mobile: mobile,
        refOtp: refOtp,
        passOtp: passOtp,
      })
      .then((res) => {
        // For Test
        const otpData = {
          refOtp: refOtp,
          passOtp: passOtp,
          mobile: mobile,
        };
        axios
          .post(API_MYASK + "/SaveOTP", otpData, {
            headers: API_MYASK_HEADER,
          })
          .then((res) => {
            dispatch(otpStart());
            if (res.data.CODE === "200") {
              dispatch(otpSuccess(otpData));
            } else {
              alert("พบข้อผิดพลาดขณะทำรายการ (101)");
            }
          });
      })
      .catch((err) => {
        alert("พบข้อผิดพลาดขณะทำรายการ (102)");
      });
  };
};

export const setConfig = (lineFriend, otpTime, telNo, mailTo) => {
  return {
    type: actionTypes.SET_CONFIG,
    lineFriend: lineFriend,
    otpTime: otpTime,
    telNo: telNo,
    mailTo: mailTo,
  };
};
