import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Panel } from "primereact/panel";
import styled from "styled-components";

import * as actions from "./../../../../store/action/index";
import * as actionTypes from "../../../../store/action/actionTypes";
import Loading from "./../../../../components/UI/Loading/Loading";
import { numberWithCommas, updateObject } from "../../../../shared/utility";
import EditInstallment from "./EditInstallment/EditInstallment";

const CheckboxContainer = styled.div`
  /* .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #ff960;
    background: #ff960;
  } */
`;

const PaymentItemList = (props) => {
  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  let { paymentItems, payment_agreements, agreements, runNo } = props;
  let [agreement, setAgreement] = useState(null);
  let [agrCode, setAgrCode] = useState(null);
  let [paymentItemUser, setPaymentItemUser] = useState(null);
  let [amount, setAmount] = useState(0.0);
  let [installment, setInstallment] = useState(0.0);
  let [showEditInstallment, setShowEditInstallment] = useState(false);
  let [originAmount01, setOriginAmount01] = useState(0.0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (query.get("agr")) {
      let agreementCurrent = null;
      if (agreements != null && agreements.length > 0) {
        agreementCurrent = agreements.find(
          (obj) => obj.agr_code === query.get("agr")
        );
      }
      setAgreement(agreementCurrent);
      setAgrCode(query.get("agr"));
    }
  }, []);

  useEffect(() => {
    if (agreement != null) {
      if (agreement.paymentDetailOrigin == null) {
        props.getPaymentDetailOrigin(
          agreement.agr_code,
          agreement.com_code,
          runNo
        );
      } else {
        props.getPaymentItem(agreement.agr_code, agreement.com_code, runNo);
      }
    }
  }, [agreement]);

  useEffect(() => {
    if (query.get("agr")) {
      let agreementCurrent = null;
      if (agreements != null && agreements.length > 0) {
        agreementCurrent = agreements.find(
          (obj) => obj.agr_code === query.get("agr")
        );
      }

      if (
        agreementCurrent != null &&
        agreementCurrent.paymentDetailOrigin != null &&
        agreement != null &&
        agreement.paymentDetailOrigin == null
      ) {
        setAgreement(agreementCurrent);
      }
    }
  }, [agreements]);

  useEffect(() => {
    if (paymentItems != null) {
      let updatedDetail = [];

      let paymentAgr = null;
      if (payment_agreements != null) {
        paymentAgr = payment_agreements.find(
          (obj) => obj.agreement.agr_code === agrCode
        );
      }

      paymentItems.map((item, index) => {
        let detailKey = null;
        if (paymentAgr != null) {
          detailKey = paymentAgr.payment_detail.find(
            (obj) => obj.key === item.key && obj.sub_key === item.sub_key
          );
        }

        if (item.key === "01" && item.sub_key === "0") {
          setOriginAmount01(item.amount);
        }

        let pNewItem = {
          key: item.key,
          sub_key: item.sub_key,
          amount:
            paymentAgr != null && detailKey != null
              ? detailKey.amount
              : item.amount,
          checked: detailKey != null,
        };
        updatedDetail.push(pNewItem);
      });
      const updated = updateObject(paymentItemUser, {
        payment_detail: updatedDetail,
      });
      setPaymentItemUser(updated);
    }
  }, [paymentItems]);

  useEffect(() => {
    calculateAmount();
  }, [paymentItemUser]);

  const getAmountDisplay = (key, sub_key, val) => {
    if (paymentItemUser != null) {
      let index = getIndexPaymentItemUser(key, sub_key);
      if (index !== -1) {
        return paymentItemUser.payment_detail[index].amount;
      }
    }
    return val;
  };

  const getIndexPaymentItemUser = (key, sub_key) => {
    if (paymentItemUser != null) {
      let index = paymentItemUser.payment_detail.findIndex(
        (obj) => obj.key === key && obj.sub_key === sub_key
      );
      if (index !== -1) {
        return index;
      }
    }
    return -1;
  };

  const togglePaymentItemUser = (key, sub_key) => {
    let updatedDetail = [];
    if (paymentItemUser != null) {
      updatedDetail = [...paymentItemUser.payment_detail];
    }

    let index = getIndexPaymentItemUser(key, sub_key);
    updatedDetail[index].checked = !updatedDetail[index].checked;

    const updated = updateObject(paymentItemUser, {
      payment_detail: updatedDetail,
    });
    setPaymentItemUser(updated);
  };

  const calculateAmount = () => {
    let newAmount = 0.0;

    if (paymentItemUser != null) {
      paymentItemUser.payment_detail.map((item, index) => {
        if (item.checked) {
          newAmount = newAmount + parseFloat(item.amount);
        }
      });
    }
    setAmount(newAmount);
  };

  const updatePaymentList = () => {
    const newArray = paymentItemUser.payment_detail.filter((item) => {
      return item.checked === true;
    });

    let paymentAgr = null;
    if (payment_agreements != null) {
      paymentAgr = payment_agreements.find(
        (obj) => obj.agreement.agr_code === agrCode
      );
    }

    let agr_data = {
      agr_code: agrCode,
      body: "",
      brand: paymentAgr.brand,
      reg_num: paymentAgr.reg_num,
      reg_province: paymentAgr.reg_province,
    };
    props.updateAgreementPayList(agr_data, newArray);
    history.goBack();
  };

  const onShowEditInstallment = (key, sub_key, val) => {
    setInstallment(parseFloat(getAmountDisplay(key, sub_key, val)));
    setShowEditInstallment(true);
  };
  const onHideEditInstallment = () => {
    setShowEditInstallment(false);
  };

  const confirmEditInstallment = (updatedAmount) => {
    setShowEditInstallment(false);
    let updatedDetail = [];
    if (paymentItemUser != null) {
      updatedDetail = [...paymentItemUser.payment_detail];
    }

    let index = getIndexPaymentItemUser("01", "0");
    updatedDetail[index].amount = updatedAmount;

    const updated = updateObject(paymentItemUser, {
      payment_detail: updatedDetail,
    });
    setPaymentItemUser(updated);
  };

  const getInstallmentItemView = () => {
    if (paymentItems != null && paymentItems.length > 0) {
      let item = null;
      item = paymentItems.find((item) => {
        return item.key == "01";
      });
      if (item != null) {
        return (
          <div
            className="grid"
            key={"div_pitem_" + item.key + "_" + item.sub_key}
          >
            <div className="col-8 flex align-items-center">
              <Checkbox
                inputId="cb1"
                value={item.key + "-" + item.sub_key}
                onChange={(e) => togglePaymentItemUser(item.key, item.sub_key)}
                checked={
                  paymentItemUser != null &&
                  paymentItemUser.payment_detail.findIndex(
                    (obj) =>
                      obj.key === item.key &&
                      obj.sub_key === item.sub_key &&
                      obj.checked === true
                  ) !== -1
                }
              ></Checkbox>

              <div className="ml-4">{item.title}</div>
            </div>
            <div className="col-4">
              <p className="text-right">
                {numberWithCommas(
                  parseFloat(
                    Math.round(
                      getAmountDisplay(item.key, item.sub_key, item.amount) *
                        100
                    ) / 100
                  ).toFixed(2)
                )}{" "}
                <span style={{ color: "black" }}>บาท</span>
              </p>
            </div>

            <div className="col-12 flex justify-content-end">
              <ButtonBase
                focusRipple
                style={{
                  alignItems: "baseline",
                  fontFamily: "Prompt-Regular",
                }}
                onClick={() =>
                  onShowEditInstallment(item.key, item.sub_key, item.amount)
                }
              >
                <div className="flex flex-column p-3 ask__font__main">
                  <div style={{ color: "#ff9600" }}>แก้ไขยอด</div>
                </div>
              </ButtonBase>
            </div>

            <Divider />
          </div>
        );
      }
    }

    return null;
  };

  const getGroupItemView = (key) => {
    if (paymentItems != null && paymentItems.length > 0) {
      let items = [];
      items = paymentItems.filter((item) => {
        return item.key == key;
      });
      if (items.length > 0) {
        return (
          <Panel
            header={
              key == "02" ? "ค่าเบี้ยประกัน" : key == "03" ? "ค่าทะเบียน" : key
            }
            toggleable
            className="ask__font__main mt-4"
            style={{ fontFamily: "Prompt-Regular" }}
          >
            {items.map((item, index) => (
              <div
                className="grid"
                key={"div_pitem_" + item.key + "_" + item.sub_key}
              >
                <div className="col-8 flex align-items-center">
                  <Checkbox
                    inputId="cb1"
                    value={item.key + "-" + item.sub_key}
                    onChange={(e) =>
                      togglePaymentItemUser(item.key, item.sub_key)
                    }
                    checked={
                      paymentItemUser != null &&
                      paymentItemUser.payment_detail.findIndex(
                        (obj) =>
                          obj.key === item.key &&
                          obj.sub_key === item.sub_key &&
                          obj.checked === true
                      ) !== -1
                    }
                  ></Checkbox>

                  <div className="ml-4">{item.title}</div>
                </div>
                <div className="col-4">
                  <p className="text-right">
                    {numberWithCommas(
                      parseFloat(
                        Math.round(
                          getAmountDisplay(
                            item.key,
                            item.sub_key,
                            item.amount
                          ) * 100
                        ) / 100
                      ).toFixed(2)
                    )}{" "}
                    <span style={{ color: "black" }}>บาท</span>
                  </p>
                </div>
                <Divider />
              </div>
            ))}
          </Panel>
        );
      }
    }

    return null;
  };

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  if (agreements == null || agreements.length === 0) {
    redirectpage = <Redirect to="/agreements" />;
  }

  return (
    <div className="ask__font__main">
      {redirectpage}
      <Button className="p-button-text m-4" onClick={() => history.goBack()}>
        <span style={{ color: "#ff9600" }}> {"< ย้อนกลับ"}</span>
      </Button>

      <EditInstallment
        agreement={agreement}
        visible={showEditInstallment}
        onHide={onHideEditInstallment}
        originAmount={originAmount01}
        currentAmount={installment}
        onConfirmAmount={(updatedAmount) =>
          confirmEditInstallment(updatedAmount)
        }
      />

      <div className="grid mt-2" style={{ width: "100%" }}>
        <div className="col-12 mb-4">
          <center>
            <p className="font-bold ask__font__title">รายการชำระเงิน</p>
          </center>
        </div>
        <div
          className="grid col-12 branchpanel mx-3 justify-content-center	"
          style={{ paddingTop: "1rem" }}
        >
          <div className="col-12 sm:col-12 md:col-8">
            <Card
              style={{
                color: "#000000de",
                border: "1px solid",
                borderRadius: "3px",
                borderColor: "#000000",
                fontFamily: "Prompt-Regular",
              }}
            >
              <div className="p-3 ask__font__main">
                <div className="font-bold" style={{ textAlign: "center" }}>
                  เลขที่สัญญา {agrCode}
                </div>
                {agreement != null &&
                  (agreement.brand ||
                    agreement.reg_num ||
                    agreement.reg_province) && (
                    <div className="flex justify-content-center mt-4">
                      {agreement.brand ? (
                        <p className="font-normal mr-4">{agreement.brand}</p>
                      ) : null}
                      {agreement.reg_num ? (
                        <p className="font-normal mr-4">{agreement.reg_num}</p>
                      ) : null}
                      {agreement.reg_province ? (
                        <p className="font-normal">{agreement.reg_province}</p>
                      ) : null}
                      {agreement.brand === "" &&
                        agreement.reg_num === "" &&
                        agreement.reg_province === "" && (
                          <p className="font-bold mr-4">
                            <br />
                          </p>
                        )}
                    </div>
                  )}
                <Divider />
                {paymentItems != null && paymentItems.length === 0 && (
                  <center>
                    <p className="ask__font__main">ไม่พบข้อมูล</p>
                  </center>
                )}

                {props.paymentItemsState === actionTypes.VIEW_STATE.LOADING && (
                  <div className="col-12">
                    <center>
                      <Loading />
                    </center>
                  </div>
                )}

                {getInstallmentItemView()}
                {getGroupItemView("02")}
                {getGroupItemView("03")}
                {paymentItems != null &&
                  props.paymentItemsState ===
                    actionTypes.VIEW_STATE.SUCCESS && (
                    <div className="grid mt-4">
                      <div className="col-6">
                        <div className="font-bold">รวม</div>
                      </div>
                      <div className="col-6">
                        <p className="text-right">
                          <b>
                            <u style={{ textDecorationColor: "red" }}>
                              <span style={{ color: "red" }}>
                                {numberWithCommas(
                                  parseFloat(
                                    Math.round(amount * 100) / 100
                                  ).toFixed(2)
                                )}
                              </span>
                            </u>
                          </b>
                          {"  "}
                          บาท
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="flex justify-content-center">
                          <Button
                            label="บันทึกยอดชำระนี้"
                            className="p-button-raised p-button-success mt-4 p-3"
                            style={{
                              borderRadius: "0px",
                              backgroundColor: "#3bb54a",
                            }}
                            onClick={updatePaymentList}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    payment_agreements: state.payment.payment_agreements,
    paymentItems: state.payment.paymentItems,
    paymentItemsState: state.payment.paymentItemsState,
    paymentItemsStateMsg: state.payment.paymentItemsStateMsg,
    agreements: state.agreement.agreements,
    runNo: state.agreement.run_no,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentItem: (agr_code, com_code, run_no) =>
      dispatch(actions.getPaymentItem(agr_code, com_code, run_no)),
    updateAgreementPayList: (agr_data, payment_detail) =>
      dispatch(actions.updateAgreementPayList(agr_data, payment_detail)),
    getPaymentDetailOrigin: (agr_code, com_code, run_no) =>
      dispatch(actions.getPaymentDetailOrigin(agr_code, com_code, run_no)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentItemList);

/*

import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";
import ButtonBase from "@material-ui/core/ButtonBase";

import * as actions from "./../../../../store/action/index";
import * as actionTypes from "../../../../store/action/actionTypes";
import Loading from "./../../../../components/UI/Loading/Loading";
import { numberWithCommas, updateObject } from "../../../../shared/utility";
import EditInstallment from "./EditInstallment/EditInstallment";

const PaymentItemList = (props) => {
  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  let { paymentItems, payment_agreements, agreements, runNo } = props;
  let [agreement, setAgreement] = useState(null);
  let [agrCode, setAgrCode] = useState(null);
  let [paymentItemUser, setPaymentItemUser] = useState(null);
  let [amount, setAmount] = useState(0.0);
  let [installment, setInstallment] = useState(0.0);
  let [showEditInstallment, setShowEditInstallment] = useState(false);
  let [originAmount01, setOriginAmount01] = useState(0.0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (query.get("agr")) {
      let agreementCurrent = null;
      if (agreements != null && agreements.length > 0) {
        agreementCurrent = agreements.find(
          (obj) => obj.agr_code === query.get("agr")
        );
      }
      setAgreement(agreementCurrent);
      setAgrCode(query.get("agr"));
    }
  }, []);

  useEffect(() => {
    if (agreement != null) {
      if (agreement.paymentDetailOrigin == null) {
        props.getPaymentDetailOrigin(
          agreement.agr_code,
          agreement.com_code,
          runNo
        );
      } else {
        props.getPaymentItem(agreement.agr_code, agreement.com_code, runNo);
      }
    }
  }, [agreement]);

  useEffect(() => {
    if (query.get("agr")) {
      let agreementCurrent = null;
      if (agreements != null && agreements.length > 0) {
        agreementCurrent = agreements.find(
          (obj) => obj.agr_code === query.get("agr")
        );
      }

      if (
        agreementCurrent.paymentDetailOrigin != null &&
        agreement != null &&
        agreement.paymentDetailOrigin == null
      ) {
        setAgreement(agreementCurrent);
      }
    }
  }, [agreements]);

  useEffect(() => {
    if (paymentItems != null) {
      let updatedDetail = [];

      let paymentAgr = null;
      if (payment_agreements != null) {
        paymentAgr = payment_agreements.find(
          (obj) => obj.agreement.agr_code === agrCode
        );
      }

      paymentItems.map((item, index) => {
        let detailKey = null;
        if (paymentAgr != null) {
          detailKey = paymentAgr.payment_detail.find(
            (obj) => obj.key === item.key && obj.sub_key === item.sub_key
          );
        }

        if (item.key === "01" && item.sub_key === "0") {
          setOriginAmount01(item.amount);
        }

        let pNewItem = {
          key: item.key,
          sub_key: item.sub_key,
          amount:
            paymentAgr != null && detailKey != null
              ? detailKey.amount
              : item.amount,
          checked: detailKey != null,
        };
        updatedDetail.push(pNewItem);
      });
      const updated = updateObject(paymentItemUser, {
        payment_detail: updatedDetail,
      });
      setPaymentItemUser(updated);
    }
  }, [paymentItems]);

  useEffect(() => {
    calculateAmount();
  }, [paymentItemUser]);

  const getAmountDisplay = (key, sub_key, val) => {
    if (paymentItemUser != null) {
      let index = getIndexPaymentItemUser(key, sub_key);
      if (index !== -1) {
        return paymentItemUser.payment_detail[index].amount;
      }
    }
    return val;
  };

  const getIndexPaymentItemUser = (key, sub_key) => {
    if (paymentItemUser != null) {
      let index = paymentItemUser.payment_detail.findIndex(
        (obj) => obj.key === key && obj.sub_key === sub_key
      );
      if (index !== -1) {
        return index;
      }
    }
    return -1;
  };

  const togglePaymentItemUser = (key, sub_key) => {
    let updatedDetail = [];
    if (paymentItemUser != null) {
      updatedDetail = [...paymentItemUser.payment_detail];
    }

    let index = getIndexPaymentItemUser(key, sub_key);
    updatedDetail[index].checked = !updatedDetail[index].checked;

    const updated = updateObject(paymentItemUser, {
      payment_detail: updatedDetail,
    });
    setPaymentItemUser(updated);
  };

  const calculateAmount = () => {
    let newAmount = 0.0;

    if (paymentItemUser != null) {
      paymentItemUser.payment_detail.map((item, index) => {
        if (item.checked) {
          newAmount = newAmount + parseFloat(item.amount);
        }
      });
    }
    setAmount(newAmount);
  };

  const updatePaymentList = () => {
    const newArray = paymentItemUser.payment_detail.filter((item) => {
      return item.checked === true;
    });

    let paymentAgr = null;
    if (payment_agreements != null) {
      paymentAgr = payment_agreements.find(
        (obj) => obj.agreement.agr_code === agrCode
      );
    }

    let agr_data = {
      agr_code: agrCode,
      body: "",
      brand: paymentAgr.brand,
      reg_num: paymentAgr.reg_num,
      reg_province: paymentAgr.reg_province,
    };
    props.updateAgreementPayList(agr_data, newArray);
    history.goBack();
  };

  const onShowEditInstallment = (key, sub_key, val) => {
    setInstallment(parseFloat(getAmountDisplay(key, sub_key, val)));
    setShowEditInstallment(true);
  };
  const onHideEditInstallment = () => {
    setShowEditInstallment(false);
  };

  const confirmEditInstallment = (updatedAmount) => {
    setShowEditInstallment(false);
    let updatedDetail = [];
    if (paymentItemUser != null) {
      updatedDetail = [...paymentItemUser.payment_detail];
    }

    let index = getIndexPaymentItemUser("01", "0");
    updatedDetail[index].amount = updatedAmount;

    const updated = updateObject(paymentItemUser, {
      payment_detail: updatedDetail,
    });
    setPaymentItemUser(updated);
  };

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  if (agreements == null || agreements.length === 0) {
    redirectpage = <Redirect to="/agreements" />;
  }

  return (
    <div className="ask__font__main">
      {redirectpage}
      <Button className="p-button-text m-4" onClick={() => history.goBack()}>
        <span style={{ color: "#ff9600" }}> {"< ย้อนกลับ"}</span>
      </Button>

      <EditInstallment
        agreement={agreement}
        visible={showEditInstallment}
        onHide={onHideEditInstallment}
        originAmount={originAmount01}
        currentAmount={installment}
        onConfirmAmount={(updatedAmount) =>
          confirmEditInstallment(updatedAmount)
        }
      />

      <div className="grid mt-2" style={{ width: "100%" }}>
        <div className="col-12 mb-4">
          <center>
            <p className="font-bold ask__font__title">รายการชำระเงิน</p>
          </center>
        </div>
        <div
          className="grid col-12 branchpanel mx-3 justify-content-center	"
          style={{ paddingTop: "1rem" }}
        >
          <div className="col-12 sm:col-12 md:col-8">
            <Card
              style={{
                color: "#000000de",
                border: "1px solid",
                borderRadius: "3px",
                borderColor: "#000000",
                fontFamily: "Prompt-Regular",
              }}
            >
              <div className="p-3 ask__font__main">
                <div className="font-bold" style={{ textAlign: "center" }}>
                  เลขที่สัญญา {agrCode}
                </div>
                {agreement != null &&
                  (agreement.brand ||
                    agreement.reg_num ||
                    agreement.reg_province) && (
                    <div className="flex justify-content-center mt-4">
                      {agreement.brand ? (
                        <p className="font-normal mr-4">{agreement.brand}</p>
                      ) : null}
                      {agreement.reg_num ? (
                        <p className="font-normal mr-4">{agreement.reg_num}</p>
                      ) : null}
                      {agreement.reg_province ? (
                        <p className="font-normal">{agreement.reg_province}</p>
                      ) : null}
                      {agreement.brand === "" &&
                        agreement.reg_num === "" &&
                        agreement.reg_province === "" && (
                          <p className="font-bold mr-4">
                            <br />
                          </p>
                        )}
                    </div>
                  )}
                <Divider />
                {paymentItems != null && paymentItems.length === 0 && (
                  <center>
                    <p className="ask__font__main">ไม่พบข้อมูล</p>
                  </center>
                )}

                {props.paymentItemsState === actionTypes.VIEW_STATE.LOADING && (
                  <div className="col-12">
                    <center>
                      <Loading />
                    </center>
                  </div>
                )}

                {paymentItems != null &&
                  paymentItems.length > 0 &&
                  paymentItems.map((item, index) => (
                    <div className="grid" key={"div_pitem_" + index}>
                      <div className="col-8 flex align-items-center">
                        <Checkbox
                          inputId="cb1"
                          value={item.key + "-" + item.sub_key}
                          onChange={(e) =>
                            togglePaymentItemUser(item.key, item.sub_key)
                          }
                          checked={
                            paymentItemUser != null &&
                            paymentItemUser.payment_detail.findIndex(
                              (obj) =>
                                obj.key === item.key &&
                                obj.sub_key === item.sub_key &&
                                obj.checked === true
                            ) !== -1
                          }
                        ></Checkbox>
                        <div className="ml-4">{item.title}</div>
                      </div>
                      <div className="col-4">
                        <p className="text-right">
                          {numberWithCommas(
                            parseFloat(
                              Math.round(
                                getAmountDisplay(
                                  item.key,
                                  item.sub_key,
                                  item.amount
                                ) * 100
                              ) / 100
                            ).toFixed(2)
                          )}{" "}
                          <span style={{ color: "black" }}>บาท</span>
                        </p>
                      </div>
                      {item.key === "01" && item.sub_key === "0" && (
                        <div className="col-12 flex justify-content-end">
                          <ButtonBase
                            focusRipple
                            style={{
                              alignItems: "baseline",
                              fontFamily: "Prompt-Regular",
                            }}
                            onClick={() =>
                              onShowEditInstallment(
                                item.key,
                                item.sub_key,
                                item.amount
                              )
                            }
                          >
                            <div className="flex flex-column p-3 ask__font__main">
                              <div style={{ color: "#ff9600" }}>แก้ไขยอด</div>
                            </div>
                          </ButtonBase>
                        </div>
                      )}

                      <Divider />
                    </div>
                  ))}
                {paymentItems != null &&
                  props.paymentItemsState ===
                    actionTypes.VIEW_STATE.SUCCESS && (
                    <div className="grid">
                      <div className="col-6">
                        <div className="font-bold">รวม</div>
                      </div>
                      <div className="col-6">
                        <p className="text-right">
                          <b>
                            <u style={{ textDecorationColor: "red" }}>
                              <span style={{ color: "red" }}>
                                {numberWithCommas(
                                  parseFloat(
                                    Math.round(amount * 100) / 100
                                  ).toFixed(2)
                                )}
                              </span>
                            </u>
                          </b>
                          {"  "}
                          บาท
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="flex justify-content-center">
                          <Button
                            label="บันทึกยอดชำระนี้"
                            className="p-button-raised p-button-success mt-4 p-3"
                            style={{
                              borderRadius: "0px",
                              backgroundColor: "#3bb54a",
                            }}
                            onClick={updatePaymentList}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    payment_agreements: state.payment.payment_agreements,
    paymentItems: state.payment.paymentItems,
    paymentItemsState: state.payment.paymentItemsState,
    paymentItemsStateMsg: state.payment.paymentItemsStateMsg,
    agreements: state.agreement.agreements,
    runNo: state.agreement.run_no,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentItem: (agr_code, com_code, run_no) =>
      dispatch(actions.getPaymentItem(agr_code, com_code, run_no)),
    updateAgreementPayList: (agr_data, payment_detail) =>
      dispatch(actions.updateAgreementPayList(agr_data, payment_detail)),
    getPaymentDetailOrigin: (agr_code, com_code, run_no) =>
      dispatch(actions.getPaymentDetailOrigin(agr_code, com_code, run_no)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentItemList);


*/
