import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button } from "primereact/button";
import { Paper } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ButtonBase from "@material-ui/core/ButtonBase";

import * as actions from "./../../store/action/index";
import { numberWithCommas } from "../../shared/utility";
import creditcard from "./../../assets/creditcard.png";
import bbl from "./../../assets/bank/bbl.png";
import scb from "./../../assets/bank/scb.png";
import kbank from "./../../assets/bank/kbank.png";
import bay from "./../../assets/bank/bay.png";
import ktb from "./../../assets/bank/ktb.png";
import qrcode from "./../../assets/qrcode.png";
import PathTree from "../../components/UI/PathTree/PathTree";

const PaymentChannal = (props) => {
  let history = useHistory();
  const { payment_agreements } = props;
  const [paymentAmount, setPaymentAmount] = useState(0);

  const path = {
    Path: [
      {
        title: "จัดการสินเชื่อ",
        pathLink: "/agreements",
      },
      {
        title: "ชำระเงิน",
        pathLink: "/payment-channal",
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let sum = 0.0;
    for (let i = 0; i < payment_agreements.length; i++) {
      let obj = payment_agreements[i];
      sum = sum + parseFloat(obj.payment_amount);
    }
    setPaymentAmount(sum);
  }, [payment_agreements]);

  const openQR = () => {
    history.push({
      pathname: "/payment-qr",
    });
  };

  const openBanking = (bank) => {
    let url = null;
    if (bank === "BBL") {
      url = "https://ibanking.bangkokbank.com/SignOn.aspx";
    } else if (bank === "KBANK") {
      url = "https://online.kasikornbankgroup.com/K-Online/";
    } else if (bank === "SCB") {
      url = "https://www.scbeasy.com/v1.4/site/presignon/index.asp";
    } else if (bank === "BAY") {
      url = "https://www.krungsrionline.com/BAY.KOL.WebSite/Common/Login.aspx";
    } else if (bank === "KTB") {
      url = "https://www.ktbnetbank.com/consumer/";
    }

    if (url != null) {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  return (
    <PathTree list={path.Path}>
      {redirectpage}
      <div className="ask__font__main">
        <Button className="p-button-text m-4" onClick={() => history.goBack()}>
          <span style={{ color: "#ff9600" }}> {"< ย้อนกลับ"}</span>
        </Button>

        <div className="grid mt-2" style={{ width: "100%" }}>
          <div className="col-12 mb-4">
            <center>
              <p className="font-bold ask__font__title">ชำระเงิน</p>
            </center>
          </div>
          <div
            className="col-12 justify-content-center"
            style={{ paddingTop: "1rem" }}
          >
            {payment_agreements == null ||
              (payment_agreements.length === 0 && (
                <center>
                  <p className="ask__font__main">ไม่พบข้อมูล</p>
                </center>
              ))}
            {payment_agreements != null && payment_agreements.length > 0 && (
              <center>
                <p className="ask__font__main">
                  ขณะนี้ทางบริษัทได้อยู่ในระหว่างปรับปรุงรูปแบบการชำระเงิน
                </p>
                <br />
                <p className="ask__font__main">
                  เรามีความปรารถนาที่จะให้ลูกค้าสะดวกและประทับใจในการใช้บริการ
                  และขออภัยท่านในความไม่สะดวก ณ ที่นี่
                </p>
                <br />
              </center>
              /*<div className="grid  mx-3">
                <Paper
                  className="col-12 sm:col-12 md:col-8 md:col-offset-2 
                lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2"
                  style={{
                    padding: "2rem",
                    border: "1px solid #001F3F",
                    borderRadius: "0px",
                    backgroundColor: "#001F3F",
                  }}
                  elevation={16}
                  children={
                    <div className="grid">
                      <div className="col-6">
                        <p style={{ color: "white" }}>ยอดชำระรวม</p>
                      </div>
                      <div className="col-6">
                        <p
                          className="text-right text-bold"
                          style={{ color: "white" }}
                        >
                          {numberWithCommas(
                            parseFloat(paymentAmount).toFixed(2)
                          )}{" "}
                          บาท
                        </p>
                      </div>
                    </div>
                  }
                ></Paper>
                <Paper
                  className="mt-3 col-12 sm:col-12 md:col-8 md:col-offset-2 
                lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2"
                  style={{
                    padding: "2rem",
                    border: "1px solid #909090",
                    borderRadius: "0px",
                  }}
                  elevation={3}
                  children={
                    <div className="grid">
                      <div className="col-12 mb-3">
                        <p className="font-bold text-left">ชำระเงินผ่าน</p>
                      </div>
                      <div className="col-4">
                        <center>
                          <ButtonBase
                            focusRipple
                            style={{
                              alignItems: "baseline",
                              fontFamily: "Prompt-Regular",
                            }}
                            className="p-4"
                            onClick={() => openBanking("BBL")}
                          >
                            <div className="ask__font__main">
                              <img
                                src={bbl}
                                className="bank-icon bbl"
                                alt="ธนาคารกรุงเทพ"
                              ></img>
                              <p style={{ color: "#3f51b5" }}>ธนาคารกรุงเทพ</p>
                            </div>
                          </ButtonBase>
                        </center>
                      </div>
                      <div className="col-4">
                        <center>
                          <ButtonBase
                            focusRipple
                            style={{
                              alignItems: "baseline",
                              fontFamily: "Prompt-Regular",
                            }}
                            className="p-4"
                            onClick={() => openBanking("KBANK")}
                          >
                            <div className="ask__font__main">
                              <img
                                src={kbank}
                                className="bank-icon kbank"
                                alt="ธนาคารกสิกรไทย"
                              ></img>
                              <p style={{ color: "#3f51b5" }}>ธนาคารกสิกรไทย</p>
                            </div>
                          </ButtonBase>
                        </center>
                      </div>
                      <div className="col-4">
                        <center>
                          <ButtonBase
                            focusRipple
                            style={{
                              alignItems: "baseline",
                              fontFamily: "Prompt-Regular",
                            }}
                            className="p-4"
                            onClick={() => openBanking("SCB")}
                          >
                            <div className="ask__font__main">
                              <img
                                src={scb}
                                className="bank-icon scb"
                                alt="ธนาคารไทยพานิชย์"
                              ></img>
                              <p style={{ color: "#3f51b5" }}>
                                ธนาคารไทยพานิชย์
                              </p>
                            </div>
                          </ButtonBase>
                        </center>
                      </div>
                      <div className="col-4">
                        <center>
                          <ButtonBase
                            focusRipple
                            style={{
                              alignItems: "baseline",
                              fontFamily: "Prompt-Regular",
                            }}
                            className="p-4"
                            onClick={() => openBanking("BAY")}
                          >
                            <div className="ask__font__main">
                              <img
                                src={bay}
                                className="bank-icon bay"
                                alt="ธนาคารกรุงศรีอยุธยา"
                              ></img>
                              <p style={{ color: "#3f51b5" }}>
                                ธนาคารกรุงศรีอยุธยา
                              </p>
                            </div>
                          </ButtonBase>
                        </center>
                      </div>
                      <div className="col-4">
                        <center>
                          <ButtonBase
                            focusRipple
                            style={{
                              alignItems: "baseline",
                              fontFamily: "Prompt-Regular",
                            }}
                            className="p-4"
                            onClick={() => openBanking("KTB")}
                          >
                            <div className="ask__font__main">
                              <img
                                src={ktb}
                                className="bank-icon ktb"
                                alt="ธนาคารกรุงไทย"
                              ></img>
                              <p style={{ color: "#3f51b5" }}>ธนาคารกรุงไทย</p>
                            </div>
                          </ButtonBase>
                        </center>
                      </div>
                      <div className="col-4">
                        <center>
                          <ButtonBase
                            focusRipple
                            style={{
                              alignItems: "baseline",
                              fontFamily: "Prompt-Regular",
                            }}
                            className="p-4"
                            onClick={() => openQR()}
                          >
                            <div className="ask__font__main">
                              <img
                                src={qrcode}
                                style={{ width: "60px", height: "60px" }}
                                alt="QR Code/Bar Code"
                              ></img>
                              <p style={{ color: "#3f51b5" }}>
                                QR Code/Bar Code
                              </p>
                            </div>
                          </ButtonBase>
                        </center>
                      </div>
                    </div>
                  }
                ></Paper>
              </div>*/
            )}
          </div>
        </div>
      </div>
    </PathTree>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    payment_agreements: state.payment.payment_agreements,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentChannal);
