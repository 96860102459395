import React from 'react';
import './Button.css';

let type;
let style;

const button = (props) => {

    style = {
        width: props.width,
        backgroundColor: props.color,
        color: props.fontColor,
        borderColor: props.borderColor,
        margin: props.margin,
        padding: props.padding,
        border: props.border,
        fontSize: props.fontSize,
        textAlign: props.textAlign,
        borderRadius: props.borderRadius
    };


    if (props.btnType === "back") {
        type = "btn__main__back";
    }
    else if (props.btnType === "next") {
        type = "btn__main__next";
    }
    else if (props.btnType === "submit") {
        type = "btn__main__submit";
    }
    else if (props.btnType === "choose") {
        type = "btn__main__choose";
    }
    else if (props.btnType === "close") {
        type = "btn__main__close";
    }
    else if (props.btnType === "normal") {
        type = "btn__main__next";
    }
    else if (props.btnType === "border") {
        type = "btn__main__back";
    }
    else if (props.btnType === "graph") {
        type = "btn__graph";
    }
    else if (props.btnType === "menuclick") {
        type = "btn__menu__click";
    }
    else if (props.btnType === "menunormal") {
        type = "btn__menu__normal";
    }
    else if (props.btnType === "branch") {
        type = "btn__branch";
    }

    let form;
    if (props.load === "load") {
        form = <div className="btn__main btn__main__next apply" style={style} onClick={props.clicked}>
            <input type="checkbox" id="check" />
            <label className="btn-label">
                <p className="btn-text">ส่งข้อมูล</p>
            </label>
        </div>;
    }
    else {
        form = <a href={props.path} target={props.target} {...props.download}><div
            disabled={props.disabled}
            className={["btn__main", [type], [props.classes]].join(' ')}
            style={style}
            onClick={props.clicked}>{props.children}</div></a>;
    }

    return (
        <div className="ask__font__main">
            {form}
        </div>
    );
};

export default button;