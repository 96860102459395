import React from 'react';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
import { withRouter } from 'react-router';

const carousel = (props) => {
    let _imageGallery = React.createRef();
    const click = () => {
        // window.location.href = props.image[_imageGallery.getCurrentIndex()].path;
        props.history.push({
            pathname: props.image[_imageGallery.getCurrentIndex()].path,
            product_id: props.image[_imageGallery.getCurrentIndex()].product_id,
            url_product: props.image[_imageGallery.getCurrentIndex()].url
            // text2: 'ท่านสามารถติดตามผลได้ทาง...'
        })
        // window.location.href = "/product-detail/SHB";
    }


    return (
        <div style={{ cursor: "pointer" }}>
            <ImageGallery
                ref={i => _imageGallery = i}
                items={props.image}
                showThumbnails={false}
                infinite={true}
                showFullscreenButton={false}
                showPlayButton={false}
                showBullets={true}
                autoPlay={true}
                slideInterval={5000}
                onClick={() => click()}
            />
        </div>
    );
}

export default withRouter(carousel);
