import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from './../Button/Button';
// import '../Apply.css';
import { Redirect } from 'react-router-dom'

const BlankPage = () => {

    return (
        <div className="panel ask__font__main" style={{ marginTop: '10rem' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <p className="text-bold text-center" style={{ fontSize: '3rem' }}>ขออภัยค่ะ ไม่พบหน้าเว็บไซต์ที่ท่านต้องการ</p>
                </Grid>
                <Grid item xs={12}>
                    <center>
                        <Button
                            path="/"
                            btnType="submit"
                            children="กลับสู่หน้าหลัก"
                            // color="#3BB54A"
                            border="none"
                        />
                    </center>
                </Grid>
            </Grid>
        </div >
    );
}

export default BlankPage;
