import React from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

import SteppersCovid19 from "../UI/SteppersCovid19/StepperCovid19";
import { numberWithCommas } from "../../shared/utility";

const trackingRequestCovid = (props) => {
  const { request } = props;

  return (
    <Card
      style={{
        color: "#000000de",
        border: "1px solid",
        borderRadius: "3px",
        borderColor: "#ffffff",
        fontFamily: "Prompt-Regular",
      }}
    >
      <div className="grid ask__font__main">
        <div className="col-5">
          <p className="font-bold">เลขที่สัญญา</p>
        </div>
        <div className="col-7">
          <p className="font-bold text-right">{request.agr_code}</p>
        </div>
        <Divider />
        <div className="col-12">
          <SteppersCovid19
            apply_date={request.covid_apply_date}
            apply_msg={request.covid_apply_msg}
            approve_date={request.covid_approve_date}
            approve_msg={request.covid_approve_msg}
          ></SteppersCovid19>
        </div>
        <div className="col-12">
          <p className="font-bold">ข้อมูลทรัพย์สิน</p>
        </div>
        {request.body ? (
          <div className="col-12">
            <p>{request.body}</p>
          </div>
        ) : null}
        {request.brand || request.asset_description ? (
          <div className="col-12">
            <p>
              {request.brand} - {request.asset_description}
            </p>
          </div>
        ) : null}
        {request.reg_num || request.reg_province ? (
          <div className="col-12">
            <p>
              {request.reg_num} {request.reg_province}
            </p>
          </div>
        ) : null}
        {!request.body &&
          !request.brand &&
          !request.asset_description &&
          !request.reg_num &&
          !request.reg_province && (
            <div className="col-12">
              <p>-</p>
            </div>
          )}
      </div>
    </Card>
  );
};

export default trackingRequestCovid;

// ref_code(pin):"106209H00122"
// result_task(pin):"CANCEL"
// fin_type(pin):"สินเชื่อรถยนต์"
// agr_code(pin):""
// new_tr(pin):"N"
// fin_ty(pin):"1"
// fin_amt(pin):"2080500"
// down_amt(pin):"109500"
// terms(pin):"48"
// body(pin):"รถโดยสารเพื่อการพาณิชย์ : (Commercial Bus)"
// asset_description(pin):"COASTER"
// brand(pin):"TOYOTA"
// reg_num(pin):" "
// model_name(pin):"COASTER"
// disburse_date(pin):""
