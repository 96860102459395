import * as actionTypes from "./actionTypes";
export const setProfile = profile => {
    return {
        type: actionTypes.LINE_SETPROFILE,
        userId: profile.userId
    }
}

export const line = (profile) => {
    return dispatch => {
        dispatch(setProfile(profile));
    }
}

export const setShouldLoading = (should) => {
    return {
        type: actionTypes.LINE_SHOULDLOADING,
        shouldLoading: should
    }
}

export const setLineToken = (token) => {
    return {
        type: actionTypes.LINE_TOKEN,
        token: token
    }
}
