import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Redirect } from "react-router-dom";

import * as actions from "./../../../../../store/action/index";
import * as actionTypes from "../../../../../store/action/actionTypes";
import Loading from "./../../../../../components/UI/Loading/Loading";
import Insurance from "../../../../../components/Personal/Insurances/Insurance";

const InsuranceAgreement = (props) => {
  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (query.get("agr")) {
      props.getInsuranceAgreement(query.get("agr"));
    }
  }, []);

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  return (
    <div className="ask__font__main">
      {redirectpage}
      <Button className="p-button-text m-4" onClick={() => history.goBack()}>
        <span style={{ color: "#ff9600" }}> {"< ย้อนกลับ"}</span>
      </Button>
      <div className="grid mt-2" style={{ width: "100%" }}>
        <div className="col-12 mb-4">
          <center>
            <p className="font-bold ask__font__title">ข้อมูลประกัน</p>
          </center>
        </div>
        <div
          className="col-12 justify-content-center"
          style={{
            paddingTop: "1rem",
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
            marginTop: "0.5rem",
            width: "100%",
          }}
        >
          {props.insurances != null &&
            props.insurances.length === 0 &&
            props.insurancesState === actionTypes.VIEW_STATE.SUCCESS && (
              <center>
                <p className="ask__font__main">ไม่พบข้อมูล</p>
              </center>
            )}
          {props.insurancesState === actionTypes.VIEW_STATE.LOADING && (
            <center>
              <Loading />
            </center>
          )}

          {props.insurances != null &&
            props.insurances.length > 0 &&
            props.insurances.map((insurance, index) => (
              <div
                className="col-12 sm:col-12 md:col-8 md:col-offset-2 
              lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2"
                key={"div_ins_" + index}
              >
                <Insurance insurance={insurance} key={"ins_" + index} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    insurances: state.agreement.insurances,
    insurancesState: state.agreement.insurancesState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInsuranceAgreement: (agr_code) =>
      dispatch(actions.getInsuranceAgreement(agr_code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceAgreement);
