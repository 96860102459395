import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '../UI/Paper/Paper';
import './StepCovid19.css'
import SteppersCovid19 from '../UI/SteppersCovid19/StepperCovid19';

const stepCovid19 = (props) => {
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <Grid item xs={12} sm={12} md={6}>
            <center>
                <Paper margin="1rem" classes="width100" borderRadius="1rem" padding="1.5rem" align="left"
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p className="text-bold">{props.text}</p>
                            </Grid>
                            <Grid item xs={5}>
                                <p className="text-bold">เลขที่สัญญา</p>
                            </Grid>
                            <Grid item xs={7}>
                                <p className="text-bold text-right">{props.number}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="hline" />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <center><img src={props.step} style={{ width: '70%' }} alt="" /></center> */}
                                <SteppersCovid19 apply_date={props.apply_date} apply_msg={props.apply_msg} approve_date={props.approve_date} approve_msg={props.approve_msg}></SteppersCovid19>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="text-bold">ข้อมูลทรัพย์สิน</p>
                            </Grid>
                            {props.cartype
                                ? <Grid item md={12} sm={12} xs={12}>
                                    <p>{props.cartype}</p>
                                </Grid>
                                : null}
                            {props.carbrand || props.description
                                ? <Grid item md={12} sm={12} xs={12}>
                                    <p>{props.carbrand} - {props.description}</p>
                                </Grid>
                                : null}
                            {props.carnumber || props.carcountry
                                ? <Grid item md={12} sm={12} xs={12}>
                                    <p>{props.carnumber} {props.carcountry}</p>
                                </Grid>
                                : null}
                            {!props.cartype && !props.carbrand && !props.description && !props.carnumber && !props.carcountry
                                ? <Grid item md={12} sm={12} xs={12}><p>-</p>
                                </Grid>
                                : null}
                        </Grid>
                    }
                />
            </center>
        </Grid>
    );
};

export default stepCovid19;