import React from "react";
import Grid from "@material-ui/core/Grid";
import Credit from "../../../assets/image/credit.jpg";
import Header from "../../UI/Header/Header";
// import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import Loading from './../../UI/Loading/Loading';
import "../Apply.css";

const ApplyForm = (props) => {
    const topic = {
        marginTop: "3rem",
        marginBottom: "2rem"
    };

    return (

        <div>
            <Header img={Credit} text="สมัครสินเชื่อ" />
            {props.loadPage
                ? <Grid item xs={12}>
                    <br></br>
                    <center><Loading /></center>
                </Grid>
                : <div className="panel">
                    <form onSubmit={props.submitHandler}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <p className="text-bold text-center ask__font__title" style={topic}>
                                    สนใจสมัครสินเชื่อ (กรุณากรอกข้อมูล เพื่อให้เราติดต่อกลับ)
            </p>
                            </Grid>
                            {props.formElementsArray.map(formElement => (
                                <div className="label__pos ask__font__main" key={formElement.id}>
                                    {/* <label className="label_text">{formElement.config.label}</label> */}
                                    <Input
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        invalid={!formElement.config.valid}
                                        shouldValidate={formElement.config.validation.required}
                                        touched={formElement.config.touched}
                                        align={formElement.config.align}
                                        max={formElement.config.maxLength}
                                        min={formElement.config.minLength}
                                        mode={formElement.config.mode}
                                        type={formElement.config.type}
                                        styleInput={formElement.config.styleInput}
                                        styleLabel={formElement.config.styleLabel}
                                        textInvalid={formElement.config.textInvalid}
                                        isEmail={formElement.config.isEmail}
                                        changed={event => props.inputChangedHandler(event.target.value, formElement.id)}
                                        label={formElement.config.label}
                                        labelPos={'left'}
                                        changedNumber={(values) => {
                                            const { formattedValue, value } = values;
                                            props.inputChangedHandler(value, formElement.id)
                                        }}
                                    />
                                </div>
                            ))}
                            {props.loading
                                ? <Grid item xs={12}>
                                    <center><Loading /></center>
                                </Grid>
                                : <Grid
                                    item
                                    xs={12}
                                >
                                    <button
                                        className="btn-submit btn_apply_submit">ส่งข้อมูล</button>
                                </Grid>
                            }
                        </Grid>
                    </form>
                </div>
            }

        </div>
    );
};

export default ApplyForm;
