import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import axios from "axios";

import * as actions from "./../../../../../store/action/index";
import * as actionTypes from "./../../../../../store/action/actionTypes";
import { API_MYASK, API_MYASK_HEADER } from "../../../../../shared/constant";
import { numberWithCommas } from "../../../../../shared/utility";
import Loading from "./../../../../../components/UI/Loading/Loading";

const EditInstallment = (props) => {
  let [amountPaid, setAmountPaid] = useState(0.0);
  let [errorMsg, setErrorMsg] = useState(null);
  let [currentPaymentDetail, setCurrentPaymentDetail] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.visible === true) {
      setAmountPaid(props.currentAmount);
      setErrorMsg(null);
      setupCurrentPaymentDetail(props.currentAmount);
    }
  }, [props.visible, props.agreement]);

  useEffect(() => {
    setAmountPaid(props.currentAmount);
  }, [props.currentAmount]);

  // useEffect(() => {
  //   console.log("trigger agreement effect");
  //   setupCurrentPaymentDetail(amountPaid);
  // }, [props.agreement]);

  const setupCurrentPaymentDetail = (amount) => {
    if (props.agreement != null) {
      if (
        props.originAmount == amount &&
        props.agreement.paymentDetailOrigin != null
      ) {
        setCurrentPaymentDetail(props.agreement.paymentDetailOrigin);
      } else {
        callOffsetingRule(amount);
      }
    }
  };

  const onConfirmAmount = (amount) => {
    if (amount == null || isNaN(amount)) {
      alert("โปรดระบุ ยอดที่จะชำระ");
      setErrorMsg("โปรดระบุ ยอดที่จะชำระ");
      return;
    }
    props.onConfirmAmount(
      parseFloat(Math.round(amount * 100) / 100).toFixed(2)
    );
  };

  const onCalculate = (amount) => {
    if (amount == null || isNaN(amount)) {
      alert("โปรดระบุ ยอดที่จะชำระ");
      setErrorMsg("โปรดระบุ ยอดที่จะชำระ");
      return;
    }
    callOffsetingRule(amount);
  };

  const callOffsetingRule = (amount) => {
    setLoading(true);
    const username = sessionStorage.getItem("userName");
    const token = sessionStorage.getItem("token");

    const req = {
      user_name: username,
      token: token,
      agr_code: props.agreement.agr_code,
      com_code: props.agreement.com_code,
      run_no: props.runNo,
      amount: amount,
    };

    let url = API_MYASK + "/offsetingRule";

    axios
      .post(url, req, {
        headers: API_MYASK_HEADER,
      })
      .then((response) => {
        setLoading(false);
        if (response.data.CODE === "200" || response.data.CODE === "204") {
          let paymentDetailOrigin = [];
          for (let i in response.data.LIST_DATA) {
            let obj = response.data.LIST_DATA[i];
            let item = {
              i_order: obj.I_ORDER,
              pay_flag: obj.PAY_FLAG,
              description: obj.DESCRIPTION,
              ori_amt: obj.ORI_AMT,
              paid_amt: obj.PAID_AMT,
            };

            paymentDetailOrigin.push(item);
          }
          setCurrentPaymentDetail(paymentDetailOrigin);
        }
      })
      .catch((err) => {
        setCurrentPaymentDetail(null);
      });
  };
  return (
    <Dialog
      header="แก้ไขยอดชำระ"
      style={{ width: "90vw" }}
      visible={props.visible}
      onHide={() => props.onHide()}
      dismissableMask
    >
      <div>
        <div className="font-bold mt-4" style={{ textAlign: "center" }}>
          <u>
            เลขที่สัญญา {props.agreement != null && props.agreement.agr_code}
          </u>
        </div>
        <div className="flex justify-content-center mt-4">
          <p className="font-normal mr-4">
            {props.agreement != null && props.agreement.brand}
          </p>
          <p className="font-normal mr-4">
            {props.agreement != null && props.agreement.reg_num}
          </p>
          <p className="font-normal mr-4">
            {props.agreement != null && props.agreement.reg_province}
          </p>
        </div>
        <p className="font-bold mt-4 mb-2">
          <u>ชำระค่างวด</u>
        </p>
        <div className="fluid grid mt-4 align-items-center">
          <div className="field grid formgrid col-12">
            <div
              className="col-12 md:col-6 font-medium"
              style={{ alignSelf: "center" }}
            >
              ยอดชำระเต็มจำนวน
            </div>
            <div
              className="col-11 md:col-5 pr-3"
              style={{ textAlign: "end", alignSelf: "center" }}
            >
              {numberWithCommas(
                parseFloat(Math.round(props.originAmount * 100) / 100).toFixed(
                  2
                )
              )}{" "}
            </div>
            <div
              className="col-1 md:col-1"
              style={{ textAlign: "end", alignSelf: "center" }}
            >
              บาท
            </div>
          </div>
          <div className="field grid formgrid col-12">
            <div
              className="col-12 md:col-6 font-medium"
              style={{ alignSelf: "center" }}
            >
              ยอดที่จะชำระ
            </div>
            <div className="col-11 md:col-5">
              <InputNumber
                style={{ width: "100%" }}
                inputStyle={{ textAlign: "end" }}
                value={amountPaid}
                onValueChange={(e) => setAmountPaid(e.value)}
                mode="decimal"
                minFractionDigits={2}
                maxFracionDigits={2}
              />
              <p style={{ color: "red", textAlign: "end" }}> {errorMsg}</p>
            </div>
            <div
              className="col-1 md:col-1"
              style={{ textAlign: "end", alignSelf: "center" }}
            >
              บาท
            </div>
          </div>
        </div>
        <div className="mt-4" style={{ textAlign: "end" }}>
          <Button
            label="คำนวณยอด"
            className="p-button-raised p-button-warning mt-4 p-3 mr-2"
            style={{
              borderRadius: "0px",
              display: "none",
            }}
            onClick={() => onCalculate(amountPaid)}
          />
          <Button
            label="ยืนยันยอดที่จะชำระ"
            className="p-button-raised p-button-success mt-4 p-3"
            style={{
              borderRadius: "0px",
              backgroundColor: "#3bb54a",
            }}
            onClick={() => onConfirmAmount(amountPaid)}
          />
        </div>
        <Divider />

        <div className="grid mt-4">
          {(props.agreement != null &&
            props.agreement.paymentDetailState ===
              actionTypes.VIEW_STATE.LOADING) ||
            (loading && (
              <div className="col-12">
                <center>
                  <Loading />
                </center>
              </div>
            ))}

          {props.agreement != null &&
            props.agreement.paymentDetailState != null &&
            props.agreement.paymentDetailState ===
              actionTypes.VIEW_STATE.SUCCESS && (
              <Fragment>
                <div
                  className="col-6"
                  style={{
                    textAlign: "center",
                    borderStyle: "solid",
                    borderWidth: "1px 1px 1px 1px",
                  }}
                ></div>
                <div
                  className="col-6"
                  style={{
                    textAlign: "center",
                    borderStyle: "solid",
                    borderWidth: "1px 1px 1px 0px",
                  }}
                >
                  <p className="font-bold">ยอดเต็ม</p>
                </div>
                <div
                  className="col-4"
                  style={{
                    textAlign: "center",
                    borderStyle: "solid",
                    borderWidth: "1px 1px 1px 0px",
                    display: "none",
                  }}
                >
                  <p className="font-bold" style={{ color: "#ff9600" }}>
                    ยอดชำระ
                  </p>
                </div>
                {currentPaymentDetail != null &&
                  currentPaymentDetail.length > 0 &&
                  currentPaymentDetail.map((item, index) => (
                    <Fragment key={"i" + index}>
                      <div
                        className="col-6"
                        style={{
                          textAlign: "start",
                          borderStyle: "solid",
                          borderWidth: "0px 1px 1px 1px",
                        }}
                      >
                        {item.description}
                      </div>
                      <div
                        className="col-6"
                        style={{
                          textAlign: "end",
                          borderStyle: "solid",
                          borderWidth: "0px 1px 1px 0px",
                        }}
                      >
                        {item.ori_amt}
                      </div>
                      <div
                        className="col-4"
                        style={{
                          textAlign: "end",
                          borderStyle: "solid",
                          borderWidth: "0px 1px 1px 0px",
                          display: "none",
                        }}
                      >
                        <div style={{ color: "#ff9600" }}>
                          {props.originAmount == amountPaid
                            ? item.ori_amt
                            : item.paid_amt}
                        </div>
                      </div>
                    </Fragment>
                  ))}
              </Fragment>
            )}
          <div className="col-12 mt-4">
            <p className="font-bold">
              * บริษัทขอสงวนสิทธิ์ในการหักชำระเบี้ยปรับ ค่าติดตามทวงถาม
              ค่าใช้จ่ายและค่าธรรมเนียมต่างๆที่ท่านค้างชำระอยู่กับบริษัทตามความเหมาะสม
            </p>
          </div>
          <div className="col-12 mt-4">
            <p className="font-bold">เจ้าหน้าที่ดูแล</p>
          </div>
          <div className="col-12">
            <p>{props.agreement != null && props.agreement.officer}</p>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    agreements: state.agreement.agreements,
    runNo: state.agreement.run_no,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInstallment);
