import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  filter: "",
  results: null,
  resultsState: actionTypes.VIEW_STATE.INITIAL,
  resultsStateMsg: "",
};

const getCsFilterStart = (state, action) => {
  return updateObject(state, {
    results: null,
    resultsState: actionTypes.VIEW_STATE.LOADING,
    resultsStateMsg: "",
  });
};

const getCsFilterSuccess = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    results: action.results,
    resultsState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getCsFilterFailed = (state, action) => {
  return updateObject(state, {
    resultsState: actionTypes.VIEW_STATE.ERROR,
    resultsStateMsg: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CS_FILTER_START:
      return getCsFilterStart(state, action);
    case actionTypes.CS_FILTER_SUCCESS:
      return getCsFilterSuccess(state, action);
    case actionTypes.CS_FILTER_FAILED:
      return getCsFilterFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
