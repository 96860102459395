import React, { useEffect } from 'react';
import ManageAgreementPayment from '../../components/ManageAgreement/ManageAgreementPayment/ManageAgreementPayment';
import Payment from '../../components/ManageAgreement/payment';
import PathTree from '../../components/UI/PathTree/PathTree';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Button from './../../components/UI/Button/Button';
import Input from './../../components/UI/Input/Input';
import Modal from './../../components/UI/Modal/Modal';
import Paper from './../../components/UI/Paper/Paper';
import Grid from '@material-ui/core/Grid';

import * as actions from "./../../store/action/index";

const ManageAgreementControlsPayment = (props) => {

    let click = "";
    const [fin_type] = React.useState(props.location.fin_type || sessionStorage.getItem('fin_type') || '');
    const [agr_code] = React.useState(props.location.agr_code || sessionStorage.getItem('agr_code') || '');
    const [body] = React.useState(props.location.body || sessionStorage.getItem('body') || '');
    const [brand] = React.useState(props.location.brand || sessionStorage.getItem('brand') || '');
    const [reg_num] = React.useState(props.location.reg_num || sessionStorage.getItem('reg_num') || '');
    const [reg_province] = React.useState(props.location.reg_province || sessionStorage.getItem('reg_province') || '');
    const [asset_description] = React.useState(props.location.asset_description || sessionStorage.getItem('asset_description') || '');
    const [install] = React.useState(props.location.install || sessionStorage.getItem('install') || '');
    const [os_balance] = React.useState(props.location.os_balance || sessionStorage.getItem('os_balance') || '');
    const [n_due_i] = React.useState(props.location.n_due_i || sessionStorage.getItem('n_due_i') || '');
    const [flat_rate] = React.useState(props.location.flat_rate || sessionStorage.getItem('flat_rate') || '');
    const [disbursement_date] = React.useState(props.location.disbursement_date || sessionStorage.getItem('disbursement_date') || '');
    const [ar] = React.useState(props.location.ar || sessionStorage.getItem('ar') || '');
    const [terms] = React.useState(props.location.terms || sessionStorage.getItem('terms') || '');
    const [scan_code] = React.useState(props.location.scan_code || sessionStorage.getItem('scan_code') || '');

    const amount = {
        paddingTop: '2.35rem',
        color: 'red',
        marginTop: '-3rem',
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    let redirectpage;
    if (!sessionStorage.getItem('token')) {
        redirectpage = <Redirect to="/login" />;
        sessionStorage.removeItem('userName')
        sessionStorage.removeItem('token')
        props.onLogout();
    }

    window.scrollTo(0, 0);

    useEffect(() => {
        sessionStorage.setItem('fin_type', fin_type);
        sessionStorage.setItem('agr_code', agr_code);
        sessionStorage.setItem('body', body);
        sessionStorage.setItem('brand', brand);
        sessionStorage.setItem('reg_num', reg_num);
        sessionStorage.setItem('reg_province', reg_province);
        sessionStorage.setItem('asset_description', asset_description);
        sessionStorage.setItem('install', install);
        sessionStorage.setItem('os_balance', os_balance);
        sessionStorage.setItem('n_due_i', n_due_i);
        sessionStorage.setItem('flat_rate', flat_rate);
        sessionStorage.setItem('disbursement_date', disbursement_date);
        sessionStorage.setItem('ar', ar);
        sessionStorage.setItem('terms', terms);
        sessionStorage.setItem('scan_code', scan_code);
    }, [agr_code]);

    const path = {
        Path: [
            {
                title: "ข้อมูลสินเชื่อของฉัน",
                pathLink: "/manageagreement"
            },
            {
                title: "รายการชำระเงิน",
                pathLink: "/manageagreement-paymentlist"
            },
            {
                title: "ชำระเงิน",
                pathLink: "/manageagreement-payment"
            },
        ]
    };

    const [openDetail, setOpenDetail] = React.useState(false);

    const handleOpenDetail = () => {
        setOpenDetail(true);
    }

    const handleCloseDetail = () => {
        setOpenDetail(false);
    }


    const [tab1, setTab1] = React.useState(false);
    const [tab2, setTab2] = React.useState(false);

    const handleChange = (event) => {
        click = event;
        if (click === "1") {
            setTab1(true);
            setTab2(false);
        }
        else if (click === "2") {
            setTab2(true);
            setTab1(false);
        }
    };

    const onSelectQRCode = (agr_code, scan_code) => {
        props.history.push({
            pathname: '/manageagreement-qrcode',
            agr_code: agr_code,
            scan_code: scan_code,
        })
    }

    return (

        <PathTree list={path.Path}>
            {redirectpage}
            <div className="ask__font__main">
                <ManageAgreementPayment scancode={scan_code} children={
                    <Payment
                        key={agr_code}
                        number={agr_code}
                        amount={install}
                        open={openDetail}
                        handleOpen={() => handleOpenDetail()}
                        close={() => handleCloseDetail()}
                        onSelectQRCode={() => onSelectQRCode()}
                    />
                } onSelected={handleChange}></ManageAgreementPayment>
            </div>
        </PathTree>
    );
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userName: state.auth.userName
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAgreementControlsPayment);
