import React, { useState } from "react";
import "./forgot.css";
import Input from "./../../../components/UI/Input/Input";
import {
  updateObject,
  checkValidity,
  checkError,
} from "./../../../shared/utility";
import axios from "axios";
import { API_MYASK } from "../../../shared/constant";
import { Redirect } from "react-router-dom";

const ResetPassword = (props) => {
  const [resetPassForm, setResetPassForm] = useState({
    form: {
      password: {
        order: "0",
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
          minLength: 4,
          maxLength: 50,
        },
        valid: false,
        touched: false,
        textInvalid: "",
        label: "รหัสผ่าน",
        labelError: "รหัสผ่าน",
      },
      confirmPassword: {
        order: "1",
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "",
        },
        value: "",
        validation: {
          required: true,
          minLength: 4,
          maxLength: 50,
        },
        valid: false,
        touched: false,
        textInvalid: "กรุณายืนยัน รหัสผ่าน",
        label: "ยืนยันรหัสผ่าน",
        checkPass: false,
        labelError: "ยืนยันรหัสผ่าน",
      },
    },
    formIsValid: false,
  });

  const onChangeHandler = (val, id) => {
    const validate = checkValidity(val, resetPassForm.form[id].validation);
    let text = checkError(
      validate.code,
      resetPassForm.form[id].labelError,
      resetPassForm.form[id].validation
    );
    let checkConfirmpass;
    let updatedFormElement;
    let updatedConfirmpass;
    let updatedPassword;
    if (id === "email") {
      if (val === "") {
        updatedFormElement = updateObject(resetPassForm.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: false,
          isEmail: false,
        });
      } else {
        updatedFormElement = updateObject(resetPassForm.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
          isEmail: true,
        });
      }
    } else if (id === "confirmPassword") {
      if (val === "") {
        updatedFormElement = updateObject(resetPassForm.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
          checkPass: false,
        });
      } else if (resetPassForm.form.password.value !== val) {
        text = "รหัสผ่าน และ ยืนยันรหัสผ่านไม่ตรงกัน";
        updatedFormElement = updateObject(resetPassForm.form[id], {
          value: val,
          valid: !validate.isValid,
          textInvalid: text,
          touched: true,
          checkPass: false,
        });
      } else if (resetPassForm.form.password.value === val) {
        updatedFormElement = updateObject(resetPassForm.form[id], {
          value: val,
          valid: validate.isValid,
          touched: true,
          checkPass: true,
          textInvalid: "",
        });
      }
    } else if (
      id === "password" &&
      resetPassForm.form.confirmPassword.value !== val &&
      resetPassForm.form.confirmPassword.touched === true
    ) {
      let textConfirmpass;
      if (resetPassForm.form.confirmPassword.value === "") {
        textConfirmpass = "กรุณาระบุยืนยันรหัสผ่าน";
        updatedConfirmpass = updateObject(resetPassForm.form.confirmPassword, {
          valid: false,
          textInvalid: textConfirmpass,
          touched: true,
          checkPass: false,
        });
      } else {
        textConfirmpass = "รหัสผ่าน และ ยืนยันรหัสผ่านไม่ตรงกัน";
        updatedConfirmpass = updateObject(resetPassForm.form.confirmPassword, {
          valid: false,
          textInvalid: textConfirmpass,
          touched: true,
          checkPass: false,
        });
      }
      updatedPassword = updateObject(resetPassForm.form.password, {
        value: val,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });
      checkConfirmpass = true;
    } else if (
      id === "password" &&
      resetPassForm.form.confirmPassword.value === val &&
      resetPassForm.form.confirmPassword.touched === true
    ) {
      let textConfirmpass;
      if (resetPassForm.form.confirmPassword.value === "") {
        textConfirmpass = "กรุณาระบุยืนยันรหัสผ่าน";
        updatedConfirmpass = updateObject(resetPassForm.form.confirmPassword, {
          valid: false,
          textInvalid: textConfirmpass,
          touched: true,
          checkPass: false,
        });
      } else {
        updatedConfirmpass = updateObject(resetPassForm.form.confirmPassword, {
          valid: true,
          touched: true,
          checkPass: true,
          textInvalid: "",
        });
      }
      updatedPassword = updateObject(resetPassForm.form.password, {
        value: val,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });
      checkConfirmpass = true;
    } else {
      updatedFormElement = updateObject(resetPassForm.form[id], {
        value: val,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });
    }
    let updatedForm;
    if (checkConfirmpass) {
      updatedForm = updateObject(resetPassForm.form, {
        confirmPassword: updatedConfirmpass,
        password: updatedPassword,
      });
    } else {
      updatedForm = updateObject(resetPassForm.form, {
        [id]: updatedFormElement,
      });
    }
    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    const updatedState = updateObject(resetPassForm, {
      form: updatedForm,
      formIsValid: formIsValid,
    });
    setResetPassForm(updatedState);
  };

  const checkedValidate = () => {
    if (
      !resetPassForm.formIsValid ||
      !resetPassForm.form.confirmPassword.checkPass
    ) {
      let updatedForm;
      let arrayObj = [];
      let updatedFormElement;
      let text;
      for (let field in resetPassForm.form) {
        const validate = checkValidity(
          resetPassForm.form[field].value,
          resetPassForm.form[field].validation
        );
        if (
          field === "confirmPassword" &&
          resetPassForm.form.confirmPassword.checkPass === false
        ) {
          if (
            (resetPassForm.form.password.value === "" &&
              resetPassForm.form.confirmPassword.value === "") ||
            (resetPassForm.form.password.value !== "" &&
              resetPassForm.form.confirmPassword.value === "")
          ) {
            text = "กรุณาระบุยืนยันรหัสผ่าน";
          } else {
            text = "รหัสผ่าน และ ยืนยันรหัสผ่านไม่ตรงกัน";
          }
          updatedFormElement = updateObject(resetPassForm.form[field], {
            valid: false,
            touched: true,
            textInvalid: text,
          });
        } else {
          text = checkError(
            validate.code,
            resetPassForm.form[field].labelError,
            resetPassForm.form[field].validation
          );
          updatedFormElement = updateObject(resetPassForm.form[field], {
            valid: validate.isValid,
            textInvalid: text,
            touched: true,
          });
        }
        arrayObj.push(updatedFormElement);
      }

      let arrayResetPW = [];
      for (let i in arrayObj) {
        if (arrayObj[i].order === "0") {
          arrayResetPW[0] = arrayObj[i];
        } else if (arrayObj[i].order === "1") {
          arrayResetPW[1] = arrayObj[i];
        }
      }

      updatedForm = updateObject(resetPassForm.form, {
        password: arrayResetPW[0],
        confirmPassword: arrayResetPW[1],
      });
      let updatedState = updateObject(resetPassForm, {
        form: updatedForm,
      });
      setResetPassForm(updatedState);
    } else {
      resetPass();
    }
  };

  let otpRedirect = null;
  if (!props.location.form) {
    otpRedirect = <Redirect to="/forgot" />;
  }

  const resetPass = () => {
    axios
      .get(
        API_MYASK +
          "/ResetPassword?password=" +
          resetPassForm.form.password.value +
          "&username=" +
          props.location.form.username.value
      )
      .then((res) => {
        if (res.data.CODE === "200") {
          props.history.push({
            pathname: "/success",
            title: "เปลี่ยนรหัสผ่านเสร็จสมบูรณ์",
            path: "/login",
          });
        }
      });
  };

  const submitHandler = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    checkedValidate();
  };

  const [type, setType] = useState("password");
  const [showPW, setShowPW] = useState(false);
  const onShowPW = () => {
    if (type === "password") {
      setType("input");
      setShowPW(true);
    } else {
      setType("password");
      setShowPW(false);
    }
  };

  return (
    <div className="forgot__container">
      {otpRedirect}
      <h4 className="forgot__header ask__font__title">รีเซ็ตรหัสผ่าน</h4>
      <form onSubmit={submitHandler}>
        <div className="forgot__body ask__font__main">
          <div className="forgot__body__firstname">
            {/* <label>รหัสผ่านใหม่ *</label> */}
            <Input
              className="form-control"
              elementType="input"
              type={type}
              value={resetPassForm.form.password.value}
              invalid={!resetPassForm.form.password.valid}
              touched={resetPassForm.form.password.touched}
              shouldValidate={resetPassForm.form.password.validation.required}
              textInvalid={resetPassForm.form.password.textInvalid}
              changed={(event) =>
                onChangeHandler(event.target.value, "password")
              }
              label={resetPassForm.form.password.label}
              styleLabel={{ width: "100%", padding: "0px" }}
              labelPos={"top"}
              onClick={() => onShowPW()}
              show={showPW}
            />
          </div>
          <div className="forgot__body_email">
            {/* <label>ยืนยันรหัสผ่านใหม่ *</label> */}
            <Input
              className="form-control"
              elementType="input"
              type={type}
              value={resetPassForm.form.confirmPassword.value}
              invalid={!resetPassForm.form.confirmPassword.valid}
              touched={resetPassForm.form.confirmPassword.touched}
              shouldValidate={
                resetPassForm.form.confirmPassword.validation.required
              }
              textInvalid={resetPassForm.form.confirmPassword.textInvalid}
              checkPass={resetPassForm.form.confirmPassword.checkPass}
              changed={(event) =>
                onChangeHandler(event.target.value, "confirmPassword")
              }
              label={resetPassForm.form.confirmPassword.label}
              styleLabel={{ width: "100%", padding: "0px" }}
              labelPos={"top"}
              onClick={() => onShowPW()}
              show={showPW}
            />
          </div>

          <div className="forgot__body_submit ask__font__main">
            <button
              className="btn-submit"
              style={{ fontFamily: "Prompt-Regular" }}
            >
              ตั้งรหัสผ่านใหม่
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
