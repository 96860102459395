import React from "react";
import Button from "../Button/Button";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import "./Input.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from "date-fns";
import { fontSize, borderRadius } from "@material-ui/system";
import { isMobile, isAndroid } from 'react-device-detect';
import NumberFormat from 'react-number-format';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

// import { log } from "util";

const input = props => {
  let inputElement = null;
  let inputClasses = "input__element";
  let labelPos = props.labelPos;
  const inputContainer = "input";
  let align = props.align;
  let textinvalid = "";
  let chkTextValid = false;

  if (props.textInvalid !== "" && props.touched && props.shouldValidate) {
    inputClasses = "input__element invalid";
    textinvalid = props.textInvalid;
    chkTextValid = true;
  }

  if (props.isEmail && props.invalid && props.touched) {
    inputClasses = "input__element invalid";
    textinvalid = props.textInvalid;
    chkTextValid = true;
  }

  if (props.checkPass && props.invalid && props.touched) {
    inputClasses = "input__element invalid";
    textinvalid = props.textInvalid;
    chkTextValid = true;
  }

  const style = {
    padding: "0rem 1.5rem",
    marginBottom: "1rem",
    display: "flex",
    alignItems: "center",
    width: props.width,
    height: "3.5rem"
  };

  const setfontsize = {
    fontSize: props.fontSize,
    marginTop: props.marginTop,
    fontFamily: "'Prompt-Regular'",
    paddingTop: ".8rem",
    display: "block",
    color: "black",
    flex: "1"
  }

  if (align === "center") {
    align = "text__align__center";
  } else if (align === "right") {
    align = "text__align__right";
  } else {
    align = "text__align__left";
  }

  if (labelPos === "left") {
    labelPos = "label__left";
  } else {
    labelPos = "label__top";
  }

  switch (props.elementType) {
    case "input":
      if (props.type === "number") {
        inputElement = (
          <input
            className={[inputClasses, align].join(" ")}
            {...props.elementConfig}
            value={props.value}
            style={props.styleInput}
            maxLength={props.max}
            minLength={props.min}
            inputMode={isAndroid === true ? props.mode : null}
            type="text"
            pattern="\d*"
            onChange={props.changed}
          />
        );
      }
      else if (props.type === "cash") {
        inputElement = (
          <NumberFormat type="tel" className={[inputClasses, align].join(" ")} placeholder={props.placeholder
          } thousandSeparator={true} decimalSeparator={"."} decimalScale={2} fixedDecimalScale={true} value={props.value} onValueChange={props.changedNumber} style={props.styleInput}
          />);
      } else {
        inputElement = (
          <div>
            <input
              className={[inputClasses, align].join(" ")}
              {...props.elementConfig}
              value={props.value}
              style={props.styleInput}
              maxLength={props.max}
              minLength={props.min}
              type={props.type}
              inputMode={isAndroid === true ? props.mode : null}
              onChange={props.changed}
            />
            {props.onClick
              ? props.show
                ? <FaEye className="field-icon" onClick={props.onClick}></FaEye>
                : <FaEyeSlash className="field-icon" onClick={props.onClick}></FaEyeSlash>
              : null}
          </div>
        );
      }

      break;
    case "file":
      inputElement = (
        <div className="uploadbtn">
          <input type="file" />
          <Button btnType="choose">{props.text}</Button>
        </div>
      );
      break;
    case "checkbox":
      inputElement = (
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox
              checked={props.check}
              onChange={props.changed}
              value={props.value}
              disabled={props.disabled}
              style={{ paddingTop: "1.5rem", marginTop: props.marginTop, color: "#ff9600", transform: "scale(1.2)" }}
            />}
            label={<Typography style={setfontsize}>{props.text}</Typography>}
          />
        </FormGroup>
      );
      break;
    case "search":
      inputElement = (
        <Paper style={style}>
          <input
            type="text"
            value={props.value}
            onChange={props.onChange}
            className="search remove"
            placeholder={props.inside}
            style={{
              border: "none",
              outline: "none"
            }}
          />
          <IconButton aria-label="search" className="icon">
            <SearchIcon />
          </IconButton>
        </Paper>
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          className={[inputClasses, align].join(" ")}
          {...props.elementConfig}
          value={props.value}
          style={props.styleInput}
          onChange={props.changed}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          className={[inputClasses, align].join(" ")}
          value={props.value}
          style={props.styleInput}
          onChange={props.changed}
        >
          {props.elementConfig.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    case "date":
      inputElement = (
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <select style={{ borderRadius: "0.5rem", margin: "0.1rem", width: "35%" }}
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {props.years.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select style={{ borderRadius: "0.5rem", margin: "0.1rem", width: "65%" }}
                  value={props.months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(props.months.indexOf(value))
                  }
                >
                  {props.months.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          onBlur={props.blur}
          selected={props.startDate}
          onChange={props.changed}
          dateFormat="dd/MM/yyyy"
          className={[inputClasses, align].join(" ")}
          {...props.elementConfig}
          style={props.styleInput}
        />
      );
      break;
    default:
      inputElement = (
        <input
          className={[inputClasses, align].join(" ")}
          {...props.elementConfig}
          value={props.value}
          style={props.styleInput}
          type={props.type}
          onChange={props.changed}
        />
      );
  }

  return (
    <div
      className={[inputContainer, labelPos].join(" ")}
      style={props.styleContainer}
    >
      <label className="label" style={props.styleLabel}>
        {props.label}
        {props.shouldValidate ? <p style={{ color: 'red', display: 'inline' }}> *</p> : null}
      </label>
      <div style={{ flex: '2' }}>
        {chkTextValid ? <label className="text__invalid">{textinvalid}</label> : null}
        {inputElement}
      </div>
    </div>
  );
};

export default input;
