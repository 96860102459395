import React, { useState, useEffect } from "react";
import axios from "axios";

import { API_MYASK_HEADER } from "../../shared/constant";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import Loading from "../../components/UI/Loading/Loading";
import { RadioButton } from "primereact/radiobutton";

const DummyPage = (props) => {
  const [urlInput, setUrlInput] = useState("");
  const [authInput, setAuthInput] = useState("");
  const [method, setMethod] = useState("POST");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [bodyData, setBodyData] = useState(
    JSON.stringify(
      {
        contacts: ["25-6410006", "22-6400521", "22-6400662"],
        from: "01-01-2021",
        to: "01-12-2021",
      },
      null,
      "\t"
    )
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const callApi = () => {
    setResponse("");
    setLoading(true);

    if (method == "GET") {
      axios
        .get(urlInput, {
          headers: API_MYASK_HEADER,
        })
        .then((res) => {
          setLoading(false);
          setResponse(JSON.stringify(res.data));
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
          setResponse(err.message);
        });
    } else if (method == "POST") {
      const req = JSON.parse(bodyData);

      // const req = {
      //   contacts: ["25-6410006", "22-6400521", "22-6400662"],
      //   from: "01-01-2021",
      //   to: "01-12-2021",
      // };

      axios
        .post(urlInput, req, {
          headers: API_MYASK_HEADER,
        })
        .then((res) => {
          setLoading(false);
          setResponse(JSON.stringify(res.data));
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
          setResponse(err.message);
        });
    }
  };

  const callAuthorize = () => {
    setLoading(true);
    axios
      .post(
        "https://api.ask.co.th/TEST/MYASK/api/v1/oauth2/token",
        {
          client_id: "XGz2VZ9dB0Ri0FJQiakZVyHZFF6Sbpf9",
          client_secret: "fxw87NCCtHzVTJ0o85wbappgcH8Qef5p",
          grant_type: "client_credentials",
          scope: "read",
        },
        {
          headers: API_MYASK_HEADER,
        }
      )
      .then((res) => {
        setLoading(false);
        var token = res.data.access_token;
        var tokenType = res.data.token_type;
        setAuthInput(tokenType + " " + token);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <div
      className="ask__font__main"
      style={{ width: "100%", fontSize: "2rem" }}
    >
      <div className="card mt-6 p-4">
        <h5>Form For Post Data</h5>
        <div className="field grid  mt-4">
          <div
            className="col-12 mb-2 md:col-2 md:mb-0"
            style={{ fontSize: "1.6rem" }}
          >
            Production
          </div>
          <div className="col-12 md:col-10">
            <b>
              https://asia-east2-myask-b2656.cloudfunctions.net/myaskAPI/payments/histories
            </b>
          </div>
        </div>
        <div className="field grid  mt-4">
          <div
            className="col-12 mb-2 md:col-2 md:mb-0"
            style={{ fontSize: "1.6rem" }}
          >
            Develop
          </div>
          <div className="col-12 md:col-10">
            <b>
              https://asia-east2-myask-dev.cloudfunctions.net/myaskAPI/payments/histories
            </b>
          </div>
        </div>
        <div className="field grid  mt-4">
          <div
            className="col-12 mb-2 md:col-2 md:mb-0"
            style={{ fontSize: "1.6rem" }}
          >
            Url
          </div>
          <div className="col-12 md:col-10">
            <InputText
              className="inputfield w-full"
              style={{ fontSize: "1.6rem" }}
              value={urlInput}
              onChange={(e) => setUrlInput(e.target.value)}
            />
          </div>
        </div>
        <div className="field grid  mt-4">
          <div
            className="col-12 mb-2 md:col-2 md:mb-0"
            style={{ fontSize: "1.6rem" }}
          >
            Method
          </div>
          <div className="col-12 md:col-10">
            <div className="field-radiobutton">
              <RadioButton
                inputId="methodget"
                name="method"
                value="GET"
                onChange={(e) => setMethod("GET")}
                checked={method === "GET"}
              />
              <label htmlFor="methodget">GET</label>
            </div>
            <div className="field-radiobutton">
              <RadioButton
                inputId="methodpost"
                name="method"
                value="POST"
                onChange={(e) => setMethod("POST")}
                checked={method === "POST"}
              />
              <label htmlFor="methodpost">POST</label>
            </div>
          </div>
        </div>
        <div className="field grid  mt-4">
          <div
            className="col-12 mb-2 md:col-2 md:mb-0"
            style={{ fontSize: "1.6rem" }}
          >
            Authorize
          </div>
          <div className="col-12 md:col-10">
            <InputText
              className="inputfield w-full"
              style={{ fontSize: "1.6rem" }}
              value={authInput}
              onChange={(e) => setAuthInput(e.target.value)}
              placeholder="Bearer xxxxxxxxx"
            />
            <Button
              label="Gen Token"
              className="p-button-raised p-button-warning mt-4 p-3"
              onClick={() => callAuthorize()}
            />
          </div>
        </div>
        <div className="field grid  mt-4">
          <div
            className="col-12 mb-2 md:col-2 md:mb-0"
            style={{ fontSize: "1.6rem" }}
          >
            Body JSON
          </div>
          <div className="col-12 md:col-10">
            <InputTextarea
              rows={12}
              className="inputfield w-full"
              style={{ fontSize: "1.6rem" }}
              value={bodyData}
              onChange={(e) => setBodyData(e.target.value)}
            />
          </div>
        </div>
        <div className="col-6 sm:col-6 md:col-3 lg:col-3 xl:col-3">
          <Button
            label="Call API"
            className="p-button-raised p-button-success mt-4 p-3"
            style={{
              width: "100%",
              borderRadius: "0px",
              backgroundColor: "#3bb54a",
            }}
            onClick={() => callApi()}
          />
        </div>
        {loading == true ? <Loading /> : null}
        <div className=" mt-6 p-4">
          <span className="font-bold">Result:</span>
          <br />
          {response}
        </div>
      </div>
    </div>
  );
};

export default DummyPage;
