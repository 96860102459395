import React from 'react';
import './Product.css';


let style;

const Product = (props) => {
    if (props.color !== "" || props.borderColor !== "") {
        style = {
            color: props.color,
            borderColor: props.borderColor,
        };
    }

    return (
        <div className="card" style={style} onClick={props.onSelectProduct}>
            <img className="image__card" alt={props.title} src={props.image} />
            <div className="text__card">
                <h2><b>{props.name}</b></h2>
                <p className="box__descrip">{props.title}</p>
            </div>
        </div>
    );
}


export default Product;
