import React, { useEffect } from "react";
import "./home.css";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";

import banner1 from "../../assets/banner1.jpg";
import banner2 from "../../assets/banner2.jpg";

import hp from "../../assets/p_hpnew.jpg";
import hp_used from "../../assets/p_hpused.jpg";
import cl from "../../assets/p_cl.jpg";
import pl from "../../assets/p_pl.jpg";
import shb from "../../assets/p_shb.jpg";
import cml from "../../assets/p_cml.jpg";

import { withStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Carousel from "../../components/UI/Carousel/Carousel";
import { Redirect, Link } from "react-router-dom";
// import Error from './../../components/UI/Error/Error';

const styles = {
  root: {
    textAlign: "center",
    fontFamily: "Prompt-Regular",
  },
  title: {
    fontSize: "2rem",
    lineHeight: "6rem",
  },
  imgFullHeight: {
    height: "20rem",
    width: "100%",
  },
  gridList: {
    width: "100%",
    margin: "0px !important",
    padding: "2rem",
  },
  tile: {
    padding: "1rem !important",
  },
};

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

const Home = (props) => {
  const { classes } = props;
  const width = useWidth();
  const getGridListCols = () => {
    if (width === "xs") {
      return 1;
    } else {
      return 2;
    }
  };

  const openWeb = () => {
    window.open("https://www.mycarmytruck.com/");
  };

  const images = [
    {
      original: hp,
      path: "/product-detail/hire-purchase-new",
      product_id: "HP-NEW",
      url: "hire-purchase-new",
      originalAlt: "สินเชื่อเช่าซื้อรถใหม่",
    },
    {
      original: cl,
      path: "/product-detail/car-loan",
      product_id: "CL",
      url: "car-loan",
      originalAlt: "สินเชื่อทะเบียนรถยนต์-ไม่โอนเล่ม",
    },
    {
      original: hp_used,
      path: "/product-detail/hire-purchase-used",
      product_id: "HP-USED",
      url: "hire-purchase-used",
      originalAlt: "สินเชื่อเช่าซื้อรถใช้แล้ว",
    },
    {
      original: pl,
      path: "/product-detail/personal-loan",
      product_id: "PL",
      url: "personal-loan",
      originalAlt: "สินเชื่อส่วนบุคคล",
    },
    {
      original: shb,
      path: "/product-detail/sale-and-hire-purchase-back",
      product_id: "SHB",
      url: "sale-and-hire-purchase-back",
      originalAlt: "สินเชื่อทะเบียนรถยนต์-โอนเล่ม",
    },
    {
      original: cml,
      path: "/product-detail/commercial-loan",
      product_id: "CML",
      url: "commercial-loan",
      originalAlt: "สินเชื่อเพื่อการพาณิชย์",
    },
  ];

  let redirect = "";

  useEffect(() => {
    window.scrollTo(0, 0);

    if (sessionStorage.getItem("src") === null) {
      const query = new URLSearchParams(props.location.search);
      const src = {};
      for (let param of query.entries()) {
        src[param[0]] = param[1];
      }
      sessionStorage.setItem("src", src.src);
    }
  }, []);

  if (sessionStorage.getItem("src") !== null) {
    redirect = <Redirect to="/" />;
  }

  useEffect(() => {
    sessionStorage.removeItem("product_id");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("descrip");
    sessionStorage.removeItem("title");
    sessionStorage.removeItem("image");
  });

  const onClickHandler = (e) => {
    if (!sessionStorage.getItem("token")) {
      e.preventDefault();
      props.history.push({
        pathname: "/login",
      });
    }
  };

  return (
    <div>
      {redirect}
      {/* <Error/> */}
      <Carousel image={images} />
      <GridList
        spacing={10}
        cellHeight={180}
        cols={getGridListCols()}
        className={classes.gridList}
      >
        <GridListTile classes={{ root: classes.tile }}>
          <Link to="/agreements" onClick={onClickHandler}>
            <img
              src={banner1}
              alt="ลูกค้าปัจจุบัน"
              className={classes.imgFullHeight}
            />
            <GridListTileBar
              title="ลูกค้าปัจจุบัน"
              classes={{
                root: classes.root,
                titlePositionBottom: classes.titlePositionBottom,
                title: classes.title,
              }}
            />
          </Link>
        </GridListTile>
        <GridListTile classes={{ root: classes.tile }}>
          <a className="cursor" onClick={openWeb}>
            <img
              src={banner2}
              className={classes.imgFullHeight}
              alt="ตัวแทนจำหน่าย"
            />
            <GridListTileBar
              title="ตัวแทนจำหน่าย"
              classes={{
                root: classes.root,
                titlePositionBottom: classes.titlePositionBottom,
                title: classes.title,
              }}
            />
          </a>
        </GridListTile>
      </GridList>
    </div>
  );
};

export default withStyles(styles)(Home);
