import React, { useState, useEffect } from "react";
import ManageAgreementInfo from "../../components/ManageAgreement/ManageAgreementInfo/ManageAgreementInfo";
import Info from "../../components/ManageAgreement/info";
import Step from "./../../components/Tracking/Step";
import StepCovid19 from "./../../components/TrackingCovid19/StepCovid19";
import { searchData } from "./../../shared/utility";
import axios from "axios";
import { API_MYASK } from "./../../shared/constant";
// import apply from './../../assets/tracking/Apply.png';
// import approve from './../../assets/tracking/Approve.png';
// import disburse from './../../assets/tracking/Disburse.png';
// import done from './../../assets/tracking/Done.png';
// import cancel from './../../assets/tracking/Cancel.png';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// import LazyLoad from 'react-lazyload';
import Loading from "./../../components/UI/Loading/Loading";

import * as actions from "./../../store/action/index";
import stepCovid19 from "../../components/TrackingCovid19/StepCovid19";

const ManageAgreementControls = (props) => {
  const [user_name] = useState(
    sessionStorage.getItem("userName") || props.userName
  );
  const [token] = useState(sessionStorage.getItem("token") || props.token);
  const [isDefault] = useState(props.location.isDefault);
  const [loading, setLoading] = React.useState(true);
  const [loadingFinance, setLoadingFinance] = React.useState(true);
  const [loadingStatus, setLoadingStatus] = React.useState(true);

  const onSelectNumber = (
    fin_type,
    agr_code,
    body,
    brand,
    reg_num,
    reg_province,
    asset_description,
    install,
    os_balance,
    n_due_i,
    flat_rate,
    disbursement_date,
    ar,
    terms,
    scan_code,
    mobile_no,
    email,
    add1,
    add2,
    post
  ) => {
    props.history.push({
      pathname: "/manageagreement-detail",
      fin_type: fin_type,
      agr_code: agr_code,
      body: body,
      brand: brand,
      reg_num: reg_num,
      reg_province: reg_province,
      asset_description: asset_description,
      install: install,
      os_balance: os_balance,
      n_due_i: n_due_i,
      flat_rate: flat_rate,
      disbursement_date: disbursement_date,
      ar: ar,
      terms: terms,
      scan_code: scan_code,
      mobile_no: mobile_no,
      email: email,
      add1: add1,
      add2: add2,
      post: post,
    });
  };

  const onSelectCloseAcc = (
    fin_type,
    agr_code,
    body,
    brand,
    reg_num,
    reg_province,
    asset_description,
    install,
    os_balance,
    n_due_i,
    flat_rate,
    disbursement_date,
    ar,
    terms,
    scan_code,
    mobile_no,
    email,
    add1,
    add2,
    post
  ) => {
    props.history.push({
      pathname: "/manageagreement-closeacc",
      fin_type: fin_type,
      agr_code: agr_code,
      body: body,
      brand: brand,
      reg_num: reg_num,
      reg_province: reg_province,
      asset_description: asset_description,
      install: install,
      os_balance: os_balance,
      n_due_i: n_due_i,
      flat_rate: flat_rate,
      disbursement_date: disbursement_date,
      ar: ar,
      terms: terms,
      scan_code: scan_code,
      mobile_no: mobile_no,
      add1: add1,
      add2: add2,
      post: post,
    });
  };

  const onSelectQRCode = (
    fin_type,
    agr_code,
    body,
    brand,
    reg_num,
    reg_province,
    asset_description,
    install,
    os_balance,
    n_due_i,
    flat_rate,
    disbursement_date,
    ar,
    terms,
    scan_code,
    mobile_no,
    email
  ) => {
    props.history.push({
      pathname: "/manageagreement-paymentlist",
      fin_type: fin_type,
      agr_code: agr_code,
      body: body,
      brand: brand,
      reg_num: reg_num,
      reg_province: reg_province,
      asset_description: asset_description,
      install: install,
      os_balance: os_balance,
      n_due_i: n_due_i,
      flat_rate: flat_rate,
      disbursement_date: disbursement_date,
      ar: ar,
      terms: terms,
      scan_code: scan_code,
      mobile_no: mobile_no,
      email: email,
      manyagr: "",
      type: "agr",
    });
    sessionStorage.setItem("manyagr", "");
    sessionStorage.setItem("type", "agr");
  };

  const [manageAgrForm, setManageAgrForm] = useState([]);

  const [manageAgrForm2, setManageAgrForm2] = useState([]);
  const [manageAgrForm3, setManageAgrForm3] = useState([]);
  const [redi, setRedi] = useState(false);

  let sum = 0;
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  useEffect(() => {
    let mount = true;
    axios
      .get(
        API_MYASK +
          "/ManageAgreement?" +
          "user_name=" +
          user_name +
          "&token=" +
          token
      )
      .then((res) => {
        if (res.data.CODE === "200") {
          let form = [];
          let form3 = [];
          console.log(res.data.LIST_DATA);
          for (let i in res.data.LIST_DATA) {
            form.push({
              fin_type: res.data.LIST_DATA[i].FIN_TYPE,
              agr_code: res.data.LIST_DATA[i].AGR_CODE,
              cus_code: res.data.LIST_DATA[i].CUS_CODE,
              new_card_no: res.data.LIST_DATA[i].NEW_CARD_NO,
              body: res.data.LIST_DATA[i].BODY,
              brand: res.data.LIST_DATA[i].BRAND,
              reg_num: res.data.LIST_DATA[i].REG_NUM,
              reg_province: res.data.LIST_DATA[i].REG_PROVINCE,
              asset_description: res.data.LIST_DATA[i].ASSET_DESCRIPTION,
              install: res.data.LIST_DATA[i].INSTALL,
              os_balance: res.data.LIST_DATA[i].OS_BALANCE,
              n_due_i: res.data.LIST_DATA[i].N_DUE_I,
              flat_rate: res.data.LIST_DATA[i].FLAT_RATE,
              disbursement_date: res.data.LIST_DATA[i].DISBURSEMENT_DATE,
              ar: res.data.LIST_DATA[i].AR,
              terms: res.data.LIST_DATA[i].TERMS,
              n_term: res.data.LIST_DATA[i].N_TERM,
              scan_code: res.data.LIST_DATA[i].SCAN_CODE,
              covid_apply_date: res.data.LIST_DATA[i].COVID_APPLY_DATE,
              covid_apply_msg: res.data.LIST_DATA[i].COVID_APPLY_MSG,
              covid_approve_date: res.data.LIST_DATA[i].COVID_APPROVE_DATE,
              covid_approve_msg: res.data.LIST_DATA[i].COVID_APPROVE_MSG,
              mobile_no: res.data.LIST_DATA[i].MOBILE_NO,
              email: res.data.LIST_DATA[i].EMAIL,
              add1: res.data.LIST_DATA[i].ADD1,
              add2: res.data.LIST_DATA[i].ADD2,
              post: res.data.LIST_DATA[i].POST,
            });
            if (res.data.LIST_DATA[i].COVID_APPLY_DATE !== "") {
              form3.push({
                fin_type: res.data.LIST_DATA[i].FIN_TYPE,
                agr_code: res.data.LIST_DATA[i].AGR_CODE,
                cus_code: res.data.LIST_DATA[i].CUS_CODE,
                new_card_no: res.data.LIST_DATA[i].NEW_CARD_NO,
                body: res.data.LIST_DATA[i].BODY,
                brand: res.data.LIST_DATA[i].BRAND,
                reg_num: res.data.LIST_DATA[i].REG_NUM,
                reg_province: res.data.LIST_DATA[i].REG_PROVINCE,
                asset_description: res.data.LIST_DATA[i].ASSET_DESCRIPTION,
                install: res.data.LIST_DATA[i].INSTALL,
                os_balance: res.data.LIST_DATA[i].OS_BALANCE,
                n_due_i: res.data.LIST_DATA[i].N_DUE_I,
                flat_rate: res.data.LIST_DATA[i].FLAT_RATE,
                disbursement_date: res.data.LIST_DATA[i].DISBURSEMENT_DATE,
                ar: res.data.LIST_DATA[i].AR,
                terms: res.data.LIST_DATA[i].TERMS,
                n_term: res.data.LIST_DATA[i].N_TERM,
                scan_code: res.data.LIST_DATA[i].SCAN_CODE,
                covid_apply_date: res.data.LIST_DATA[i].COVID_APPLY_DATE,
                covid_apply_msg: res.data.LIST_DATA[i].COVID_APPLY_MSG,
                covid_approve_date: res.data.LIST_DATA[i].COVID_APPROVE_DATE,
                covid_approve_msg: res.data.LIST_DATA[i].COVID_APPROVE_MSG,
                mobile_no: res.data.LIST_DATA[i].MOBILE_NO,
                email: res.data.LIST_DATA[i].EMAIL,
                add1: res.data.LIST_DATA[i].ADD1,
                add2: res.data.LIST_DATA[i].ADD2,
                post: res.data.LIST_DATA[i].POST,
              });
            }
          }
          if (mount) {
            sessionStorage.removeItem("fin_type");
            sessionStorage.removeItem("agr_code");
            sessionStorage.removeItem("body");
            sessionStorage.removeItem("brand");
            sessionStorage.removeItem("reg_num");
            sessionStorage.removeItem("reg_province");
            sessionStorage.removeItem("asset_description");
            sessionStorage.removeItem("install");
            sessionStorage.removeItem("os_balance");
            sessionStorage.removeItem("n_due_i");
            sessionStorage.removeItem("flat_rate");
            sessionStorage.removeItem("disbursement_date");
            sessionStorage.removeItem("ar");
            sessionStorage.removeItem("terms");
            sessionStorage.removeItem("scan_code");
            sessionStorage.removeItem("mobile_no");
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("add1");
            sessionStorage.removeItem("add2");
            sessionStorage.removeItem("post");
            setCount(form.length);
            setCount3(form3.length);
            sum = count;
            setManageAgrForm(form);
            setManageAgrForm3(form3);
            setLoadingFinance(false);
          }
        } else {
          if (mount) {
            sessionStorage.removeItem("userName");
            sessionStorage.removeItem("token");
            props.onLogout();
            setRedi(true);
          }
        }
      });

    axios
      .get(
        API_MYASK +
          "/Current_Task?" +
          "user_name=" +
          user_name +
          "&token=" +
          token
      )
      .then((res) => {
        let form2 = [];
        let step;
        let status;
        let date;
        for (let i in res.data.LIST_DATA) {
          if (res.data.LIST_DATA[i].RESULT_TASK === "APPLY") {
            step = "apply";
            status = "รอการตรวจสอบ";
            date = "";
          } else if (res.data.LIST_DATA[i].RESULT_TASK === "APPROVE") {
            step = "approve";
            status = "รอการอนุมัติ";
            date = "";
          } else if (res.data.LIST_DATA[i].RESULT_TASK === "DISBURSE") {
            step = "disburse";
            status = "รอจ่ายเงิน";
            date = res.data.LIST_DATA[i].DISBURSE_DATE;
          } else if (res.data.LIST_DATA[i].RESULT_TASK === "DONE") {
            step = "done";
            status = "โอนแล้วเมื่อวันที่";
            date = res.data.LIST_DATA[i].DISBURSE_DATE;
          } else if (res.data.LIST_DATA[i].RESULT_TASK === "CANCEL") {
            step = "cancel";
            status = "Cancel";
            date = "";
          }
          form2.push({
            ref_code: res.data.LIST_DATA[i].REF_CODE,
            fin_type: res.data.LIST_DATA[i].FIN_TYPE,
            result_task: status,
            disburse_date: date,
            step: step,
            brand_name: res.data.LIST_DATA[i].BRANDNAME,
            bdy: res.data.LIST_DATA[i].BDY,
            model_name: res.data.LIST_DATA[i].MODEL_NAME,
            reg_num: res.data.LIST_DATA[i].REG_NUM,
            fin_amt: res.data.LIST_DATA[i].FIN_AMT,
            terms: res.data.LIST_DATA[i].TERMS,
          });
        }
        if (mount) {
          setCount2(form2.length);
          setManageAgrForm2(form2);
          setLoadingStatus(false);
        }
      });

    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    let mount = true;
    if (loadingFinance === false && loadingStatus === false) {
      if (mount) {
        setLoading(false);
      }
    }

    return () => {
      mount = false;
    };
  }, [loadingFinance, loadingStatus]);

  const [check, setCheck] = useState(0);
  useEffect(() => {
    if (isDefault === true) {
      setTab2(true);
      setTab1(false);
      setTab3(false);
      setCheck(2);
    }
  }, [isDefault]);

  let redirectpage;
  if (redi === true) {
    redirectpage = <Redirect to="/login" />;
  }

  const [filter, setFilter] = useState("");

  const handleFilter = (value) => {
    setCurrPageAgreement(1);
    setCurrPageStep(1);
    setFilter(value);
  };

  const formElementsArray = searchData(filter, manageAgrForm);
  const formElementsArray2 = searchData(filter, manageAgrForm2);
  const formElementsArray3 = searchData(filter, manageAgrForm3);

  const [tab1, setTab1] = React.useState(false);
  const [tab2, setTab2] = React.useState(false);
  const [tab3, setTab3] = React.useState(false);
  let click = "";

  const handleChange = (event) => {
    click = event;
    if (click === "1") {
      setTab1(true);
      setTab2(false);
      setTab3(false);
    } else if (click === "2") {
      setTab2(true);
      setTab1(false);
      setTab3(false);
    } else if (click === "3") {
      setTab3(true);
      setTab1(false);
      setTab2(false);
    }
  };

  const [agrPerPage] = useState(4);
  const [currPageAgreement, setCurrPageAgreement] = useState(1);
  const [currPageStep, setCurrPageStep] = useState(1);
  const [currPageStepCovid19, setCurrPageStepCovid19] = useState(1);

  const handlePageClick = (data) => {
    if (tab2 === true) {
      setCurrPageStep(data.selected + 1);
    } else if (tab3 === true) {
      setCurrPageStepCovid19(data.selected + 1);
    } else {
      setCurrPageAgreement(data.selected + 1);
    }
  };

  let show;
  let currentPage;
  if (tab2 === true) {
    sum = formElementsArray2.length;

    currentPage = currPageStep;
    const agrcodePerPage = agrPerPage;
    const indexOfLastAgr = currentPage * agrcodePerPage;
    const indexOfFirstAgr = indexOfLastAgr - agrcodePerPage;
    const currentAgr = formElementsArray2.slice(
      indexOfFirstAgr,
      indexOfLastAgr
    );

    show = currentAgr.map((formElement) => (
      <Step
        key={formElement.ref_code}
        fin_type={formElement.fin_type}
        ref_code={formElement.ref_code}
        result_task={formElement.result_task}
        disburse_date={formElement.disburse_date}
        carbrand={formElement.brand_name}
        cartype={formElement.bdy}
        description={formElement.model_name}
        regnum={formElement.reg_num}
        amount={formElement.fin_amt}
        terms={formElement.terms}
        step={formElement.step}
      />
    ));
  } else if (tab3 === true) {
    sum = formElementsArray3.length;

    currentPage = currPageStepCovid19;
    const agrcodePerPage = agrPerPage;
    const indexOfLastAgr = currentPage * agrcodePerPage;
    const indexOfFirstAgr = indexOfLastAgr - agrcodePerPage;
    const currentAgr = formElementsArray3.slice(
      indexOfFirstAgr,
      indexOfLastAgr
    );

    show = currentAgr.map((formElement) => (
      <StepCovid19
        key={formElement.agr_code}
        type={formElement.fin_type}
        number={formElement.agr_code}
        cartype={formElement.body}
        carbrand={formElement.brand}
        description={formElement.asset_description}
        carnumber={formElement.reg_num}
        carcountry={formElement.reg_province}
        apply_date={formElement.covid_apply_date}
        apply_msg={formElement.covid_apply_msg}
        approve_date={formElement.covid_approve_date}
        approve_msg={formElement.covid_approve_msg}
      />
    ));
  } else {
    sum = formElementsArray.length;

    currentPage = currPageAgreement;
    const agrcodePerPage = agrPerPage;
    const indexOfLastAgr = currentPage * agrcodePerPage;
    const indexOfFirstAgr = indexOfLastAgr - agrcodePerPage;
    const currentAgr = formElementsArray.slice(indexOfFirstAgr, indexOfLastAgr);
    const colorInfo = "";

    show = currentAgr.map((formElement) => (
      <Info
        key={formElement.agr_code}
        type={formElement.fin_type}
        number={formElement.agr_code}
        cartype={formElement.body}
        carbrand={formElement.brand}
        description={formElement.asset_description}
        carnumber={formElement.reg_num}
        carcountry={formElement.reg_province}
        ar={formElement.ar}
        pricepermonth={formElement.install}
        balance={formElement.os_balance}
        terms={formElement.terms}
        n_term={formElement.n_term}
        date={formElement.n_due_i}
        onSelectNumber={() =>
          onSelectNumber(
            formElement.fin_type,
            formElement.agr_code,
            formElement.body,
            formElement.brand,
            formElement.reg_num,
            formElement.reg_province,
            formElement.asset_description,
            formElement.install,
            formElement.os_balance,
            formElement.n_due_i,
            formElement.flat_rate,
            formElement.disbursement_date,
            formElement.ar,
            formElement.terms,
            formElement.scan_code,
            formElement.mobile_no,
            formElement.email,
            formElement.add1,
            formElement.add2,
            formElement.post
          )
        }
        onSelectCloseAcc={() =>
          onSelectCloseAcc(
            formElement.fin_type,
            formElement.agr_code,
            formElement.body,
            formElement.brand,
            formElement.reg_num,
            formElement.reg_province,
            formElement.asset_description,
            formElement.install,
            formElement.os_balance,
            formElement.n_due_i,
            formElement.flat_rate,
            formElement.disbursement_date,
            formElement.ar,
            formElement.terms,
            formElement.scan_code,
            formElement.mobile_no,
            formElement.email,
            formElement.add1,
            formElement.add2,
            formElement.post
          )
        }
        // เปิดปุ่ม จ่ายค่างวด เอา comment code ด้านล่างนี้ออก
        onSelectQRCode={() =>
          onSelectQRCode(
            formElement.fin_type,
            formElement.agr_code,
            formElement.body,
            formElement.brand,
            formElement.reg_num,
            formElement.reg_province,
            formElement.asset_description,
            formElement.install,
            formElement.os_balance,
            formElement.n_due_i,
            formElement.flat_rate,
            formElement.disbursement_date,
            formElement.ar,
            formElement.terms,
            formElement.scan_code,
            formElement.mobile_no,
            formElement.email
          )
        }
      />
    ));
  }

  return (
    <div className="ask__font__main">
      {redirectpage}
      <ManageAgreementInfo
        loading={loading}
        count={sum}
        count1={count}
        count2={count2}
        count3={count3}
        children={show}
        check={check}
        onSelected={(e) => handleFilter(e)}
        onSelected2={handleChange}
        onPageChange={handlePageClick}
        pageCount={Math.ceil(sum / agrPerPage)}
        forcePage={currentPage - 1}
      ></ManageAgreementInfo>
      {/* <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={5}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                // onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAgreementControls);
