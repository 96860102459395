import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../../UI/Button/Button';
import '../../Apply/Apply.css';
import { FONT_SIZE } from '../../../shared/constant';

const branch = (props) => {
    const divStyle = {
        fontSize: FONT_SIZE
    };

    return (
        <Grid container spacing={2} style={divStyle}>
            <Grid item xs={12}>
                <p className="text-orange text-bold">สาขา{props.branchs}</p>
            </Grid>
            <Grid item xs={12}>
                <div className="text-bold">ที่อยู่</div>
            </Grid>
            <Grid item xs={12}>
                <div>{props.no} {props.soi} {props.rd} {props.dis}</div>
            </Grid>
            <Grid item xs={12}>
                <div>{props.amp} {props.prv} {props.zip}</div>
            </Grid>
            <Grid item xs={12}>
                <div className="text-bold">ติดต่อ</div>
            </Grid>
            <Grid item xs={12}>
                <div>โทรศัพท์ {props.tel} โทรสาร {props.fax}</div>
            </Grid>
            <Grid item xs={12}>
                <div className="text-bold">เวลาทำการ</div>
            </Grid>
            <Grid item xs={12}>
                <div>{props.time}</div>
            </Grid>
            <Grid item xs={12}>
                <center><Button path={props.map} target="_blank" btnType="border" width="70%" children="ขอเส้นทาง"></Button></center>
            </Grid>
        </Grid>
    );
}

export default branch;
