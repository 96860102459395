import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./signup.css";
import {
  updateObject,
  checkValidity,
  checkError,
} from "./../../../shared/utility";
import Input from "./../../../components/UI/Input/Input";
import Loading from "../../../components/UI/Loading/Loading";
import axios from "axios";
import * as actions from "./../../../store/action/index";
import {
  API_MYASK,
  API_MYASK_HEADER,
  ERROR_MSG_USER,
  ERROR_MSG_AGRCODE,
  ERROR_MSG_TEL,
  ERROR_MSG_AGRCODE2,
} from "../../../shared/constant";

import NewOTP from "../../newotp/newotp";
import DialogScroll from "./../../../components/UI/DialogScroll/DialogScroll";
import SignupResult from "./signupresult";

const Signup = (props) => {
  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoding, setIsLoding] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [signup, setSignup] = useState({
    form: {
      idcard: {
        order: "0",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "a-z,A-Z,0-9,@-_.",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        textInvalid: "",
        label: "เลขบัตรประชาชน / เอกสารแสดงตน",
        labelError: "เลขบัตรประชาชน / เอกสารแสดงตน",
      },
      username: {
        order: "1",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "a-z,A-Z,0-9,@-_.",
        },
        value: "",
        validation: {
          required: true,
          minLength: 4,
          maxLength: 50,
        },
        valid: false,
        touched: false,
        textInvalid: "",
        label: "ชื่อผู้ใช้งาน / Username",
        labelError: "ชื่อผู้ใช้งาน / Username",
      },
      password: {
        order: "2",
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "a-z,A-Z,0-9,@-_.",
        },
        value: "",
        validation: {
          required: true,
          minLength: 4,
          maxLength: 50,
        },
        valid: false,
        touched: false,
        textInvalid: "",
        label: "รหัสผ่าน / Password",
        labelError: "รหัสผ่าน / Password",
      },
      confirmPassword: {
        order: "3",
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "a-z,A-Z,0-9,@-_.",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        textInvalid: "กรุณายืนยัน รหัสผ่าน",
        label: "ยืนยันรหัสผ่าน / Confirm Password",
        checkPass: false,
        labelError: "ยืนยันรหัสผ่าน / Confirm Password",
      },
      email: {
        order: "4",
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "",
        },
        value: "",
        validation: {
          required: false,
          isEmail: true,
        },
        isEmail: false,
        valid: true,
        touched: false,
        textInvalid: "E-mail ไม่ถูกต้อง",
        label: "E-mail",
        labelError: "E-mail",
      },
    },
    formIsValid: false,
    formCheckErr: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheck = () => {
    if (checked == true) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const handleOpen = () => {
    if (open == false) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (open == true) {
      setOpen(false);
    }
  };

  const onChangeHandler = (val, id) => {
    // if (id === "idcard") {
    //   val = onChangeNumber(val);
    // }

    if (
      id === "username" ||
      id === "password" ||
      id === "confirmPassword" ||
      id === "idcard"
    ) {
      val = val.replace(/[^A-Za-z0-9@_.-]/gi, "");
    }

    const validate = checkValidity(val, signup.form[id].validation);
    let text = checkError(
      validate.code,
      signup.form[id].label,
      signup.form[id].validation
    );
    let checkConfirmpass;
    let updatedFormElement;
    let updatedConfirmpass;
    let updatedPassword;

    if (id === "idcard") {
      if (val === "") {
        updatedFormElement = updateObject(signup.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: false,
        });
      } else {
        updatedFormElement = updateObject(signup.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
      }
    } else if (id === "email") {
      if (val === "") {
        updatedFormElement = updateObject(signup.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: false,
          isEmail: false,
        });
      } else {
        updatedFormElement = updateObject(signup.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
          isEmail: true,
        });
      }
    } else if (id === "confirmPassword") {
      if (val === "") {
        updatedFormElement = updateObject(signup.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
          checkPass: false,
        });
      } else if (signup.form.password.value !== val) {
        text = "รหัสผ่าน และ ยืนยันรหัสผ่านไม่ตรงกัน";
        updatedFormElement = updateObject(signup.form[id], {
          value: val,
          // valid: !validate.isValid,
          textInvalid: text,
          touched: true,
          checkPass: false,
        });
      } else if (signup.form.password.value === val) {
        updatedFormElement = updateObject(signup.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: "",
          touched: true,
          checkPass: true,
        });
      }
    } else if (
      id === "password" &&
      signup.form.confirmPassword.value !== val &&
      signup.form.confirmPassword.touched === true
    ) {
      let textConfirmpass;
      if (signup.form.confirmPassword.value === "") {
        textConfirmpass = "กรุณาระบุยืนยันรหัสผ่าน";
        updatedConfirmpass = updateObject(signup.form.confirmPassword, {
          valid: false,
          textInvalid: textConfirmpass,
          touched: true,
          checkPass: false,
        });
      } else {
        textConfirmpass = "รหัสผ่าน และ ยืนยันรหัสผ่านไม่ตรงกัน";
        updatedConfirmpass = updateObject(signup.form.confirmPassword, {
          valid: false,
          textInvalid: textConfirmpass,
          touched: true,
          checkPass: false,
        });
      }
      updatedPassword = updateObject(signup.form.password, {
        value: val,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });
      checkConfirmpass = true;
    } else if (
      id === "password" &&
      signup.form.confirmPassword.value === val &&
      signup.form.confirmPassword.touched === true
    ) {
      let textConfirmpass;
      if (signup.form.confirmPassword.value === "") {
        textConfirmpass = "กรุณาระบุยืนยันรหัสผ่าน";
        updatedConfirmpass = updateObject(signup.form.confirmPassword, {
          valid: false,
          textInvalid: textConfirmpass,
          touched: true,
          checkPass: false,
        });
      } else {
        updatedConfirmpass = updateObject(signup.form.confirmPassword, {
          valid: true,
          touched: true,
          checkPass: true,
          textInvalid: "",
        });
      }
      updatedPassword = updateObject(signup.form.password, {
        value: val,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });
      checkConfirmpass = true;
    } else {
      updatedFormElement = updateObject(signup.form[id], {
        value: val,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });
    }

    let updatedForm;
    if (checkConfirmpass) {
      updatedForm = updateObject(signup.form, {
        confirmPassword: updatedConfirmpass,
        password: updatedPassword,
      });
    } else {
      updatedForm = updateObject(signup.form, {
        [id]: updatedFormElement,
      });
    }

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    const updatedState = updateObject(signup, {
      form: updatedForm,
      formIsValid: formIsValid,
      formCheckErr: false,
    });
    setSignup(updatedState);
  };

  const checkedValidate = () => {
    if (!signup.formIsValid || !signup.form.confirmPassword.checkPass) {
      let updatedForm;
      let arrayObj = [];
      let updatedFormElement;
      let text;
      for (let field in signup.form) {
        const validate = checkValidity(
          signup.form[field].value,
          signup.form[field].validation
        );
        if (
          field === "confirmPassword" &&
          signup.form.confirmPassword.checkPass === false
        ) {
          if (
            (signup.form.password.value === "" &&
              signup.form.confirmPassword.value === "") ||
            (signup.form.password.value !== "" &&
              signup.form.confirmPassword.value === "")
          ) {
            text = "กรุณาระบุยืนยันรหัสผ่าน";
          } else {
            text = "รหัสผ่าน และ ยืนยันรหัสผ่านไม่ตรงกัน";
          }
          updatedFormElement = updateObject(signup.form[field], {
            valid: false,
            touched: true,
            textInvalid: text,
          });
        } else {
          text = checkError(
            validate.code,
            signup.form[field].labelError,
            signup.form[field].validation
          );
          updatedFormElement = updateObject(signup.form[field], {
            valid: validate.isValid,
            textInvalid: text,
            touched: true,
          });
        }

        arrayObj.push(updatedFormElement);
      }

      let arraySignup = [];
      for (let i in arrayObj) {
        if (arrayObj[i].order === "0") {
          arraySignup[0] = arrayObj[i];
        } else if (arrayObj[i].order === "1") {
          arraySignup[1] = arrayObj[i];
        } else if (arrayObj[i].order === "2") {
          arraySignup[2] = arrayObj[i];
        } else if (arrayObj[i].order === "3") {
          arraySignup[3] = arrayObj[i];
        } else if (arrayObj[i].order === "4") {
          arraySignup[4] = arrayObj[i];
        }
      }

      updatedForm = updateObject(signup.form, {
        idcard: arraySignup[0],
        username: arraySignup[1],
        password: arraySignup[2],
        confirmPassword: arraySignup[3],
        email: arraySignup[4],
      });

      let updatedState = updateObject(signup, {
        form: updatedForm,
      });

      setSignup(updatedState);
    } else {
      //register();
      checkUsernameAndIdCard();
    }
  };

  const goBackRegisterForm = () => {
    setPage(1);
  };

  const goToOtpPage = () => {
    setPage(2);
  };

  const goToSuccessPage = () => {
    setPage(3);
  };

  const checkUsernameAndIdCard = () => {
    const data = {
      id_card: signup.form.idcard.value,
      username: signup.form.username.value,
    };
    setIsLoding(true);
    axios
      .post(API_MYASK + "/validateRegister", data, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        setIsLoding(false);
        if (res.data.CODE === "200") {
          goToOtpPage();
        } else {
          let err = res.data.DATA;
          let updatedState;

          let username = { ...signup.form.username };
          let idcard = { ...signup.form.idcard };

          if (err.username_valid === false) {
            username = updateObject(username, {
              textInvalid: err.username_msg,
              touched: true,
            });
          }

          if (err.idcard_valid === false) {
            idcard = updateObject(idcard, {
              textInvalid: err.idcard_msg,
              touched: true,
            });
          }

          updatedState = updateObject(signup, {
            form: updateObject(signup.form, {
              username,
              idcard,
            }),
          });
          console.log(updatedState);
          setSignup(updatedState);
        }
      })
      .catch((err) => {
        setIsLoding(false);
        setErrorMsg(err);
      });
  };

  const submitHandler = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    checkedValidate();
  };

  const onChangeNumber = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    if (isValid || val === "") {
      return val;
    } else {
      return val.substring(val.length - 1, 0);
    }
  };

  const [type, setType] = useState("password");
  const [showPW, setShowPW] = useState(false);
  const onShowPW = () => {
    if (type === "password") {
      setType("input");
      setShowPW(true);
    } else {
      setType("password");
      setShowPW(false);
    }
  };

  const onRegister = (mobile_no, ref_otp, pwd_otp) => {
    let data = {
      id_card: signup.form.idcard.value,
      username: signup.form.username.value,
      password: signup.form.password.value,
      email: signup.form.email.value,
      lineId: props.lineId,
      mobile_no: mobile_no,
      ref_otp: ref_otp,
      pwd_otp: pwd_otp,
    };
    setIsLoding(true);
    axios
      .post(API_MYASK + "/newRegister", data, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        setIsLoding(false);
        if (res.data.CODE == "200") {
          props.onAuthSuccess(res.data.DATA, signup.form.username.value);
          goToSuccessPage();
        } else {
          setErrorMsg(res.data.MSG);
        }
      })
      .catch((err) => {
        setIsLoding(false);
        setErrorMsg(err.message);
      });
  };

  return (
    <div className="signup__container">
      <Dialog visible={isLoding} style={{ width: "60vw" }}>
        <center>
          <Loading />
        </center>
      </Dialog>
      <Dialog
        visible={errorMsg != ""}
        style={{ width: "80vw" }}
        onHide={() => setErrorMsg("")}
        dismissableMask
      >
        <center>
          <p className="mt-4" style={{ color: "red", fontSize: "1.8rem" }}>
            {errorMsg}
          </p>
          <div className="mt-6">
            <Button label="ตกลง" onClick={() => setErrorMsg("")} autoFocus />
          </div>
        </center>
      </Dialog>
      {(page == 1 || page == 2) && (
        <Fragment>
          <h4 className="signup__header ask__font__title">ลงทะเบียน</h4>
          {page == 1 && (
            <form onSubmit={submitHandler}>
              <div className="signup__body ask__font__main">
                <div className="signup__body_idcard">
                  {/* <label>เลขบัตรประชาชน *</label> */}
                  <Input
                    className="form-control"
                    elementType="input"
                    elementConfig={signup.form.idcard.elementConfig}
                    type="text"
                    value={signup.form.idcard.value}
                    invalid={!signup.form.idcard.valid}
                    touched={signup.form.idcard.touched}
                    max={signup.form.idcard.maxLength}
                    shouldValidate={signup.form.idcard.validation.required}
                    textInvalid={signup.form.idcard.textInvalid}
                    changed={(event) => {
                      // onChangeNumber(event.target.value);
                      onChangeHandler(event.target.value, "idcard");
                    }}
                    label={signup.form.idcard.label}
                    labelPos={"top"}
                    styleLabel={{ width: "100%", padding: "0px" }}
                  />
                </div>
                <div className="signup__body__firstname">
                  {/* <label>ชื่อผู้ใช้งาน *</label> */}
                  <Input
                    id="username"
                    className="form-control"
                    elementType="input"
                    elementConfig={signup.form.username.elementConfig}
                    type="text"
                    value={signup.form.username.value}
                    invalid={!signup.form.username.valid}
                    touched={signup.form.username.touched}
                    shouldValidate={signup.form.username.validation.required}
                    textInvalid={signup.form.username.textInvalid}
                    changed={(event) =>
                      onChangeHandler(event.target.value, "username")
                    }
                    label={signup.form.username.label}
                    labelPos={"top"}
                    styleLabel={{ width: "100%", padding: "0px" }}
                  />
                </div>
                <div className="signup__body_password">
                  {/* <label>รหัสผ่าน *</label> */}
                  <Input
                    className="form-control"
                    elementType="input"
                    elementConfig={signup.form.password.elementConfig}
                    type={type}
                    value={signup.form.password.value}
                    invalid={!signup.form.password.valid}
                    touched={signup.form.password.touched}
                    shouldValidate={signup.form.password.validation.required}
                    textInvalid={signup.form.password.textInvalid}
                    changed={(event) =>
                      onChangeHandler(event.target.value, "password")
                    }
                    label={signup.form.password.label}
                    labelPos={"top"}
                    styleLabel={{ width: "100%", padding: "0px" }}
                    onClick={() => onShowPW()}
                    show={showPW}
                  />
                </div>
                <div className="signup__body_password">
                  {/* <label>ยืนยันรหัสผ่าน *</label> */}
                  <Input
                    className="form-control"
                    elementType="input"
                    elementConfig={signup.form.confirmPassword.elementConfig}
                    type={type}
                    value={signup.form.confirmPassword.value}
                    invalid={!signup.form.confirmPassword.valid}
                    touched={signup.form.confirmPassword.touched}
                    shouldValidate={
                      signup.form.confirmPassword.validation.required
                    }
                    textInvalid={signup.form.confirmPassword.textInvalid}
                    checkPass={signup.form.confirmPassword.checkPass}
                    changed={(event) =>
                      onChangeHandler(event.target.value, "confirmPassword")
                    }
                    label={signup.form.confirmPassword.label}
                    labelPos={"top"}
                    styleLabel={{ width: "100%", padding: "0px" }}
                    onClick={() => onShowPW()}
                    show={showPW}
                  />
                </div>
                <div className="signup__body_email">
                  {/* <label>E-Mail</label> */}
                  <Input
                    className="form-control"
                    elementType="input"
                    type="text"
                    value={signup.form.email.value}
                    invalid={!signup.form.email.valid}
                    touched={signup.form.email.touched}
                    isEmail={signup.form.email.isEmail}
                    shouldValidate={signup.form.email.validation.required}
                    textInvalid={signup.form.email.textInvalid}
                    changed={(event) =>
                      onChangeHandler(event.target.value, "email")
                    }
                    label={signup.form.email.label}
                    labelPos={"top"}
                    styleLabel={{ width: "100%", padding: "0px" }}
                  />
                </div>
                <div className="flex flex-row align-items-center mt-4 ">
                  <input
                    type="checkbox"
                    onChange={handleCheck}
                    checked={checked}
                  />
                  <p
                    className="ml-2"
                    style={{ fontSize: "1.4rem", marginTop: "0.2rem" }}
                  >
                    ยอมรับ
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={handleOpen}
                    >
                      ข้อตกลงในการใช้บริการของ askconnect
                    </a>
                  </p>
                </div>
                <DialogScroll
                  title="เงื่อนไขในการสมัครสมาชิกเพื่อใช้บริการ askconnect"
                  open={open}
                  onClose={handleClose}
                  // ref={descriptionElementRef}
                  text={
                    <div
                      style={{
                        fontSize: "1.6rem",
                        fontFamily: "Prompt-Regular",
                      }}
                    >
                      บริษัท เอเซียเสริมกิจลีสซิ่ง จำกัด (มหาชน)
                      ซึ่งต่อไปนี้จะเรียกว่า “บริษัท” จัดทำบริการ askconnect นี้
                      เพื่อให้ข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการต่าง ๆ ของบริษัท
                      เพื่ออำนวยความสะดวกในการติดต่อสื่อสาร ระหว่างท่านกับบริษัท
                      ทั้งนี้ ไม่ว่าในขณะใดขณะหนึ่งก็ตาม
                      บริษัทสามารถแก้ไขปรับปรุงข้อกำหนดและเงื่อนไขต่าง ๆ ใน
                      askconnect นี้ได้ อีกทั้ง
                      บริษัทขอสงวนสิทธิ์ของบริษัทในการเปลี่ยนแปลง askconnect
                      ข้อกำหนดและเงื่อนไขต่าง ๆ ใน askconnect นี้ได้
                      โดยไม่ต้องแจ้งให้ท่านทราบแต่อย่างใด “ผู้ขอสมัครสมาชิก”
                      ซึ่งต่อไปนี้จะเรียกว่า “ผู้สมัคร” กรุณาอ่านข้อความตกลง
                      ข้อกำหนด และเงื่อนไขด้านล่างนี้โดยละเอียด
                      เพื่อรักษาสิทธิประโยชน์ในการเป็นสมาชิกของผู้สมัคร ทั้งนี้
                      ในขั้นตอนการกรอกแบบฟอร์มใบสมัครนั้นสมาชิกจะต้องกรอกข้อมูลต่าง
                      ๆ ตามความเป็นจริง
                      โดยกรอกชื่อ-นามสกุลจริงของผู้สมัครลงในแบบฟอร์มลงทะเบียนสมาชิก
                      <br />
                      <br />
                      1. ผู้สมัครจะต้องกรอกข้อมูลรายละเอียดต่าง ๆ
                      ตามจริงให้ครบถ้วน ข้อมูลใด ๆ
                      ที่ผู้สมัครกรอกเพื่อสมัครใช้บริการ askconnect นี้
                      บริษัทจะถือว่าเป็นข้อมูลของตัวผู้สมัครเองทั้งสิ้น ทั้งนี้
                      เพื่อประโยชน์แก่ตัวผู้สมัคร
                      หากตรวจพบว่าข้อมูลของผู้สมัครไม่เป็นความจริง
                      บริษัทจะระงับการใช้บริการของผู้สมัครโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                      <br />
                      <br />
                      2. ผู้ใดแอบอ้างหรือกระทำการใด ๆ
                      อันเป็นการละเมิดสิทธิส่วนบุคคลโดยใช้ข้อมูลของผู้อื่นมาแอบอ้างสมัครใช้บริการ
                      askconnect นี้ เพื่อให้ได้มาซึ่งสิทธิในการใช้บริการ
                      askconnect
                      ของบริษัทถือเป็นความผิดต้องรับโทษตามที่กฎหมายกำหนดไว้
                      และต้องรับผิดต่อบริษัท และ/หรือบุคคลใด ๆ
                      ที่ได้รับความเสียหายด้วย
                      <br />
                      <br />
                      3. ผู้สมัครจะต้องเป็นผู้ใช้งานบัญชี Line
                      ซึ่งผู้สมัครได้สมัครใช้บริการ askconnect
                      นี้แต่เพียงผู้เดียว
                      ห้ามมิให้บุคคลอื่นเข้าถึง/เข้าใช้งานโดยเด็ดขาด ทั้งนี้
                      เมื่อมีการใช้งานบัญชี Line ซึ่งผู้สมัครได้สมัครใช้บริการ
                      askconnect นี้ ในการใช้บริการ askconnect
                      ของบริษัทจะถือว่าผู้สมัครเป็นผู้แสดงตนหรือเป็นผู้ใช้งานด้วยตนเองทุกครั้ง
                      <br />
                      <br />
                      4. เพื่อเป็นการปกป้องหรือป้องกันข้อมูลส่วนบุคคลใด ๆ
                      ของผู้สมัครเอง ซึ่งเป็นข้อมูลที่ได้รับจากบริษัท
                      ผู้สมัครจะต้องตั้งรหัสป้องกันการเข้าถึง/เข้าใช้งานบัญชี
                      Line ซึ่งผู้สมัครได้สมัครใช้บริการ askconnect นี้
                      ของผู้สมัครเอง
                      และห้ามมิให้บุคคลอื่นใดเข้าถึง/เข้าใช้งานบัญชี Line
                      ซึ่งผู้สมัครได้สมัครใช้บริการ askconnect นี้
                      ของผู้สมัครเป็นอันขาด
                      <br />
                      <br />
                      5. ผู้สมัครต้องปฏิบัติตามข้อกำหนด
                      และเงื่อนไขการให้บริการใน askconnect
                      ของบริษัทโดยเคร่งครัดเพื่อความปลอดภัยในข้อมูลส่วนบุคคลของผู้สมัคร
                      ในกรณีที่ข้อมูลส่วนบุคคลดังกล่าวถูกโจรกรรมโดยวิธีการทางอิเล็กทรอนิกส์
                      หรือสูญหาย เสียหาย หรือถูกเปิดเผย
                      อันเนื่องจากสาเหตุสุดวิสัย หรือเหตุไม่ปฏิบัติตามข้อกำหนด
                      และเงื่อนไขการให้บริการใน askconnect
                      ของบริษัทหรือไม่ว่ากรณีใด ๆ ทั้งสิ้น
                      บริษัทขอสงวนสิทธิในการปฏิเสธความรับผิดจากเหตุดังกล่าวทั้งหมด
                      <br />
                      <br />
                      6.
                      ข้อมูลส่วนบุคคลของผู้สมัครที่ได้ลงทะเบียนหรือผ่านการใช้งานใน
                      askconnect ของบริษัททั้งหมดนั้น
                      ผู้สมัครยอมรับและตกลงว่าเป็นสิทธิของบริษัท
                      ซึ่งผู้สมัครตกลงอนุญาตให้บริษัทใช้ข้อมูลดังกล่าวของผู้สมัครเพื่อเป็นประโยชน์ในงาน
                      กิจการ หรือการประกอบธุรกิจตามวัตถุประสงค์ของบริษัท
                      <br />
                      <br />
                      7. การให้ข้อมูลต่าง ๆ ต่อบริษัทโดยผ่านทาง askconnect
                      ของบริษัทจะถือว่าเป็นทรัพย์สินของบริษัท
                      บริษัทสามารถนำข้อมูลดังกล่าวไปใช้ได้ตามวัตถุประสงค์
                      แนวความคิด และวิธีการที่บริษัทพิจารณาเป็นว่าเป็นการเหมาะสม
                      และเป็นประโยชน์ต่อทั้งผู้สมัคร บริษัท
                      หรือลูกค้าท่านอื่นของบริษัท
                      รวมทั้งการใช้ข้อมูลดังกล่าวจะอยู่ภายในขอบเขตแห่งวัตถุประสงค์ของผลิตภัณฑ์
                      และบริการต่าง ๆ ของบริษัท
                      <br />
                      <br />
                      8. บริษัทขอรับรองว่าจะเก็บข้อมูลของผู้สมัครไว้เป็นความลับ
                      จะไม่นำไปเปิดเผยที่ใด โดยไม่ได้รับอนุญาต
                      นอกจากจะได้รับหมายศาล หรือหนังสือทางราชการ
                      ซึ่งขึ้นอยู่กับดุลพินิจของบริษัท
                      <br />
                      <br />
                      9. ข้อความ ภาพนิ่ง เสียง หรือภาพวิดีโอต่าง ๆ
                      ที่ปรากฏอยู่ใน askconnect ของบริษัท
                      อันเนื่องมาจากการใช้บริการของผู้สมัครถือว่าเป็นทรัพย์สินหรือลิขสิทธิ์ของบริษัททั้งสิ้น
                      และบริษัทขอสงวนลิขสิทธิ์ในข้อความ ภาพนิ่ง เสียง
                      และภาพวิดีโอเหล่านั้น ห้ามมิให้ผู้สมัครนำไปเผยแพร่
                      ทำการลอกเลียนแบบ นำออกแสดง เรียกถึงข้อมูล ให้อนุญาต
                      เปลี่ยนแปลง ตีพิมพ์ นำออกประกาศซ้ำ นำไปผลิตใหม่ นำไปใช้ซ้ำ
                      ขาย ถ่ายโอน
                      นำไปฟ้องร้องดำเนินคดีเพื่อให้ได้มาซึ่งงานที่ไม่ใช้ต้นฉบับ
                      หรือดำเนินการอื่นใดในลักษณะเดียวกันโดยเด็ดขาด
                      <br />
                      <br />
                      10. บริษัทไม่มีความรับผิดในความเสียหายใด ๆ
                      รวมตลอดถึงความเสียหายทางตรง ความเสียหายทางอ้อม
                      ความเสียหายพิเศษ ความเสียหายโดยบังเอิญ
                      หรือความเสียหายเกี่ยวเนื่อง ความสูญหาย หรือค่าใช้จ่ายใด ๆ
                      ที่เกิดขึ้นอันเกี่ยวเนื่องกับการไม่สามารถดำเนินการข้อผิดพลาด
                      การงดเว้นการกระทำ การสอดแทรก การบกพร่อง
                      หรือความล่าช้าในการดำเนินการ หรือการถ่ายโอนข้อมูล ไวรัส
                      การไม่ทำงานของเครือข่าย หรือระบบ ทั้งนี้ ถึงแม้ว่าบริษัท
                      หรือตัวแทนของบริษัทจะได้รับแจ้งถึงความเป็นได้ในการเกิดความเสียหาย
                      ความสูญหาย หรือค่าใช้จ่ายต่าง ๆ ดังกล่าวแล้วก็ตาม
                      <br />
                      <br />
                      11. หากมีการเปลี่ยนแปลงข้อตกลงในการให้บริการ askconnect
                      ของบริษัท บริษัทจะแจ้งให้ผู้สมัครทราบ
                      โดยขึ้นหน้าจอข้อตกลงใหม่ เพื่อให้ผู้สมัครรับทราบ
                      และผู้สมัครจะใช้บริการของบริษัทต่อไปได้เมื่อผู้สมัครยอมรับข้อตกลงใหม่ของบริษัท
                      โดยการเลือก “ยอมรับ”
                      <br />
                      <br />
                      12. ผู้สมัครมีสิทธิที่จะยกเลิกการใช้บริการ askconnect
                      ของบริษัทได้โดยตลอด โดยการแจ้งยกเลิกผ่านทาง Call Center
                      ของบริษัท ที่หมายเลขโทรศัพท์ 02-679-6226
                      <br />
                      <br />
                      13. ในกรณีที่จะต้องมีการตีความข้อความตกลง ข้อกำหนด
                      และเงื่อนไขที่ปรากฏใน askconnect ของบริษัท
                      หรือกรณีที่มีข้อพิพาทอันเนื่องมาจากการใช้บริการ askconnect
                      ของบริษัท กฎหมายไทยจะเป็นกฎหมายที่นำมาใช้บังคับในการตีความ
                      หรือระงับข้อพิพาทดังกล่าว
                      ข้าพเจ้าผู้สมัครได้อ่านข้อความตกลง ข้อกำหนด และเงื่อนไข
                      <br />
                      <br />
                    </div>
                  }
                />
                <div className="signup__body_submit ask__font__main">
                  {checked ? (
                    <button
                      className="btn-submit"
                      style={{ fontFamily: "Prompt-Regular" }}
                    >
                      ลงทะเบียน
                    </button>
                  ) : (
                    <button
                      className="btn-submit"
                      style={{
                        fontFamily: "Prompt-Regular",
                        backgroundColor: "#ccc",
                        cursor: "no-drop",
                      }}
                      disabled={!checked}
                    >
                      ลงทะเบียน
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
          {page == 2 && (
            <div className="signup__body ask__font__main">
              <NewOTP
                goback={goBackRegisterForm}
                mode="regis"
                idcard={signup.form.idcard.value}
                onSubmit={onRegister}
              />
            </div>
          )}
        </Fragment>
      )}
      {page == 3 && <SignupResult />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lineId: state.line.userId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAuthSuccess: (token, userName) => dispatch(actions.auth(token, userName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
