import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

let style;

const Modal1 = (props) => {

    const useStyles = makeStyles(theme => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }));
    const classes = useStyles();
    // style = {
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)', //'translate(-50%, -50%)'
    //     position: 'absolute',
    //     width: props.width,
    //     backgroundColor: props.color,
    //     borderRadius: props.borderRadius,
    //     padding: props.padding,
    //     outline: 'none',
    //     overflowY: props.scroll,
    //     height: props.height,
    //     verticalAlign: props.verticalAlign,
    // }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={props.close}
            closeAfterTransition
            disableScrollLock
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div style={{ outline: '0' }}>
                {props.children}
            </div>
        </Modal>
        // <Modal
        //     open={props.open}
        //     onClose={props.close}
        //     className={props.classes}> 
        //     <div style={style} >
        //         {props.children}
        //     </div>           
        // </Modal>
    );
};

export default Modal1;