import * as actionTypes from "./actionTypes";

export const getTrackingRequest = () => {
  return {
    type: actionTypes.GET_TRACKING_REQUEST,
  };
};

export const getTrackingRequestStart = () => {
  return {
    type: actionTypes.GET_TRACKING_REQUEST_START,
  };
};

export const getTrackingRequestSuccess = (requests) => {
  return {
    type: actionTypes.GET_TRACKING_REQUEST_SUCCESS,
    requests: requests,
  };
};

export const getTrackingRequestFailed = (error) => {
  return {
    type: actionTypes.GET_TRACKING_REQUEST_FAILED,
    error: error,
  };
};

export const getTrackingRequestCovid = () => {
  return {
    type: actionTypes.GET_TRACKING_REQUEST_COVID,
  };
};

export const getTrackingRequestCovidStart = () => {
  return {
    type: actionTypes.GET_TRACKING_REQUEST_COVID_START,
  };
};

export const getTrackingRequestCovidSuccess = (requests_covid) => {
  return {
    type: actionTypes.GET_TRACKING_REQUEST_COVID_SUCCESS,
    requests_covid: requests_covid,
  };
};

export const getTrackingRequestCovidFailed = (error) => {
  return {
    type: actionTypes.GET_TRACKING_REQUEST_COVID_FAILED,
    error: error,
  };
};

export const clearTrackingState = () => {
  return {
    type: actionTypes.CLEAR_TRACKING_STATE,
  };
};
