import React, { useState, useEffect, useRef, useMemo } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import styled from "styled-components";
import axios from "axios";
import { API_MYASK, API_MYASK_HEADER } from "../../shared/constant";
import Loading from "../../components/UI/Loading/Loading";
import * as actions from "./../../store/action/index";
import * as actionTypes from "./../../store/action/actionTypes";

const FilterContainer = styled.div`
  .p-datatable,
  .p-inputtext {
    font-size: 1.6rem;
    font-family: "Prompt-Regular" !important;
  }
  .p-multiselect-label-container {
    font-size: 1.6rem;
    font-family: "Prompt-Regular" !important;
  }
  .p-paginator-bottom,
  .p-paginator-top {
    font-size: 1.6rem;
    font-family: "Prompt-Regular" !important;
  }

  .p-paginator {
    /* background: #ff9600; */
    margin-top: 2rem;
  }
  .p-paginator-current,
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last,
  .p-paginator .p-paginator-pages .p-paginator-page {
    color: #000;
  }
  .p-paginator .p-paginator-pages .p-paginator-page {
    font-size: 1.6rem;
    font-family: "Prompt-Regular" !important;
    margin: 1rem;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #ff9600;
  }
  .p-paginator .p-dropdown {
    height: 3rem;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    font-size: 1.6rem;
    font-family: "Prompt-Regular" !important;
    padding-left: 1rem;
    padding-right: 1rem;
    align-self: center;
  }
  .pi {
    font-size: 1.5rem;
  }
`;

const FilterPage = (props) => {
  const tableRef = useRef();
  const history = useHistory();
  const columns = [
    { field: "com_code", header: "บริษัท" },
    { field: "agr_code", header: "เลขที่สัญญา" },
    { field: "new_tr", header: "Product" },
    { field: "username", header: "ชื่อผู้ใช้งาน" },
    { field: "cus_name", header: "ชื่อลูกค้า" },
    { field: "reg_num", header: "เลขทะเบียน" },
    { field: "reg_prv", header: "จังหวัด" },
  ];
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    props.onLogout(null, sessionStorage.getItem("userName"));
    sessionStorage.removeItem("from_cs");
  }, []);

  // useEffect(() => {
  //   if (props.results != null) {
  //     setData(props.results);
  //   }

  //   return () => {};
  // }, [props.results]);

  // useEffect(() => {
  //   if (props.filter) {
  //     setFilter(props.filter);
  //   }
  // }, [props.filter]);

  // useEffect(() => {
  //   if (props.fullSummaryPaymentStateMsg) {
  //     setErrorMsg(props.fullSummaryPaymentStateMsg);
  //   }
  // }, [props.fullSummaryPaymentStateMsg]);

  const callFilter = () => {
    tableRef.current.reset();
    //props.onCallCsFilter(filter);
    if (filter.trim() == "") {
      setData([]);
    } else {
      setIsLoading(true);
      axios
        .post(
          API_MYASK + "/csFilter",
          {
            value: filter,
          },
          {
            headers: API_MYASK_HEADER,
          }
        )
        .then((res) => {
          setIsLoading(false);
          if (res.data.CODE == "200") {
            let filters = [];
            for (let i in res.data.LIST_DATA) {
              let obj = res.data.LIST_DATA[i];
              let data = {
                com_code: obj.com_code,
                agr_code: obj.agr_code,
                new_tr: obj.new_tr,
                username: obj.username,
                password: obj.password,
                cus_name: obj.cus_name,
                reg_num: obj.reg_num,
                reg_prv: obj.reg_prv,
                new_card_no: obj.new_card_no,
              };

              filters.push(data);
            }
            setData(filters);
          } else {
            setErrorMsg(res.data.MSG);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setErrorMsg(err.message);
        });
    }
  };

  const onLogin = (username, password) => {
    const data_login = {
      username: username,
      password: password,
      lineid: null,
      store: false,
    };

    axios
      .post(API_MYASK + "/Login", data_login, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        if (res.data.CODE === "200") {
          sessionStorage.setItem("from_cs", true);
          props.onAuth(res.data.MSG, username);
          history.push({
            pathname: "/agreements",
          });
        } else {
          setErrorMsg(res.data.MSG);
        }
      });
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const onRowSelect = (event) => {
    onLogin(event.data.username, event.data.password);
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      Column:{" "}
      <MultiSelect
        value={selectedColumns}
        options={columns}
        optionLabel="header"
        onChange={onColumnToggle}
        style={{ width: "70em" }}
      />
    </div>
  );
  const onGetFilterValueColumn = (column) => {
    let json = JSON.parse(window.sessionStorage.getItem("csfilter-custom"));
    if (json == null || json.filters[column].value == null) {
      return null;
    } else {
      return json.filters[column].value;
    }
  };

  const onCustomSaveState = (state) => {
    window.sessionStorage.setItem("csfilter-custom", JSON.stringify(state));
  };

  const onCustomRestoreState = () => {
    return JSON.parse(window.sessionStorage.getItem("csfilter-custom"));
  };

  const filters = {
    com_code: {
      value: onGetFilterValueColumn("com_code"),
      matchMode: FilterMatchMode.CONTAINS,
    },
    agr_code: {
      value: onGetFilterValueColumn("agr_code"),
      matchMode: FilterMatchMode.CONTAINS,
    },
    new_tr: {
      value: onGetFilterValueColumn("new_tr"),
      matchMode: FilterMatchMode.CONTAINS,
    },
    username: {
      value: onGetFilterValueColumn("username"),
      matchMode: FilterMatchMode.CONTAINS,
    },
    cus_name: {
      value: onGetFilterValueColumn("cus_name"),
      matchMode: FilterMatchMode.CONTAINS,
    },
    reg_num: {
      value: onGetFilterValueColumn("reg_num"),
      matchMode: FilterMatchMode.CONTAINS,
    },
    reg_prv: {
      value: onGetFilterValueColumn("reg_prv"),
      matchMode: FilterMatchMode.CONTAINS,
    },
  };

  const matchModes = [{ label: "ประกอบด้วย", value: FilterMatchMode.CONTAINS }];

  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        filter
        filterMatchModeOptions={matchModes}
        showFilterMenu={false}
        showClearButton={true}
      />
    );
  });

  return (
    <FilterContainer
      className="ask__font__main"
      style={{ width: "100%", fontSize: "2rem" }}
    >
      <Dialog visible={isLoading} style={{ width: "60vw" }}>
        <center>
          <Loading />
        </center>
      </Dialog>
      <Dialog
        visible={errorMsg != ""}
        style={{ width: "80vw" }}
        onHide={() => setErrorMsg("")}
        dismissableMask
      >
        <center>
          <p className="mt-4" style={{ color: "red", fontSize: "1.8rem" }}>
            {errorMsg}
          </p>
          <div className="mt-6">
            <Button label="ตกลง" onClick={() => setErrorMsg("")} autoFocus />
          </div>
        </center>
      </Dialog>
      <div className="mt-6 p-4">
        <div className="flex overflow-hidden my-4">
          <div className="flex-grow-1 flex align-items-center justify-content-center">
            <div className="font-bold">ค้นหา : </div>
            <InputText
              className="flex-1 ml-2"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="เลขที่สัญญา/ชื่อผู้ใช้งาน/ชื่อลูกค้า/เลขทะเบียน/เลขบัตรประชาชน"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  callFilter();
                }
              }}
            />
          </div>
          <div className="flex-none flex align-items-center justify-content-center">
            <Button
              style={{ width: "50px" }}
              icon="pi pi-search"
              className="p-button-success mx-2"
              aria-label="Search"
              onClick={() => callFilter()}
            />
          </div>
        </div>

        <DataTable
          ref={tableRef}
          value={data}
          // header={header}
          paginator
          showGridlines
          stripedRows
          removableSort
          filters={filters}
          filterDisplay="row"
          // stateStorage="custom"
          // stateKey="csfilter-custom"
          // customSaveState={onCustomSaveState}
          // customRestoreState={onCustomRestoreState}
          paginatorPosition="both"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          responsiveLayout="scroll"
          selectionMode="single"
          onRowSelect={onRowSelect}
        >
          {columnComponents}
        </DataTable>
      </div>
    </FilterContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    results: state.cs.results,
    filter: state.cs.filter,
    resultsState: state.cs.resultsState,
    resultsStateMsg: state.cs.resultsStateMsg,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (token, userName) => dispatch(actions.auth(token, userName)),
    onLogout: (lineId, userName) => dispatch(actions.logout(lineId, userName)),
    onCallCsFilter: (value) => dispatch(actions.csFilter(value)),
  };
};

export default connect(null, mapDispatchToProps)(FilterPage);
