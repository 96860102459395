import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import PathTree from "../../components/UI/PathTree/PathTree";
import PolicyEtax from "../../components/EtaxEreceipt/PolicyEtax";
import FormEtax from "../../components/EtaxEreceipt/FormEtax";
import ConfirmEmailEtax from "../../components/EtaxEreceipt/ConfirmEmailEtax";
import CompleteEtax from "../../components/EtaxEreceipt/CompleteEtax";

import {
  updateObject,
  checkValidity,
  checkError,
} from "./../../shared/utility";

const RegisterEtax = (props) => {
  let history = useHistory();
  const [indexPage, setIndexPage] = useState(0); // 0 = policy , 1 = form , 2 = confirm , 3 = success
  const [acceptPolicy, setAcceptPolicy] = useState(false); // 0 = policy , 1 = form , 2 = confirm , 3 = success

  /* Form Page */
  const [formEtax, setFormEtax] = useState({
    form: {
      email: {
        order: "1",
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "email address",
        },
        value: "",
        validation: {
          required: false,
          isEmail: true,
        },
        isEmail: false,
        valid: true,
        touched: false,
        textInvalid: "E-mail ไม่ถูกต้อง",
        label: "E-mail",
        labelError: "E-mail",
      },
    },
    formIsValid: false,
  });

  /* End Form Page */

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  const path = {
    Path: [
      {
        title: "จัดการสินเชื่อ",
        pathLink: "/agreements",
      },
      {
        title: "e-tax e-receipt",
        pathLink: "/etax_ereceipt",
      },
    ],
  };

  useEffect(() => {
    setInitialData();
    return () => {};
  }, []);

  const setInitialData = () => {
    setIndexPage(0);
    setAcceptPolicy(false);
    setFormEtax({
      form: {
        email: {
          order: "1",
          elementType: "input",
          elementConfig: {
            type: "email",
            placeholder: "email address",
          },
          value: "",
          validation: {
            required: false,
            isEmail: true,
          },
          isEmail: false,
          valid: true,
          touched: false,
          textInvalid: "E-mail ไม่ถูกต้อง",
          label: "E-mail",
          labelError: "E-mail",
        },
      },
      formIsValid: false,
    });
  };

  const nextPage = () => {
    if (indexPage === 0) {
      goToFormPage();
    } else if (indexPage === 1) {
      goToConfirmEmailPage();
    } else if (indexPage === 2) {
      goToCompletePage(3);
    }
    window.scrollTo(0, 0);
  };

  const prevPage = () => {
    if (indexPage === 1) {
      goToPolicyPage();
    } else if (indexPage === 2) {
      goToFormPage();
    }
    window.scrollTo(0, 0);
  };

  const goToMainPage = () => {
    history.push({
      pathname: "/agreements",
    });
  };

  const goToPolicyPage = () => {
    setIndexPage(0);
  };

  const goToFormPage = () => {
    setIndexPage(1);
  };

  const goToConfirmEmailPage = () => {
    setIndexPage(2);
  };

  const goToCompletePage = () => {
    setIndexPage(3);
  };

  const onChangeEmailHandler = (val) => {
    const validate = checkValidity(val, { isEmail: true });
    let text = "กรุณาระบุ E-mail ให้ถูกต้อง";
    let updatedFormElement;

    if (val === "") {
      updatedFormElement = updateObject(formEtax.form["email"], {
        value: val,
        valid: validate.isValid,
        textInvalid: text,
        touched: false,
        isEmail: false,
      });
    } else {
      updatedFormElement = updateObject(formEtax.form["email"], {
        value: val,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
        isEmail: true,
      });
    }

    let updatedForm;
    updatedForm = updateObject(formEtax.form, {
      ["email"]: updatedFormElement,
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    const updatedState = updateObject(formEtax, {
      form: updatedForm,
      formIsValid: formIsValid,
      formCheckErr: false,
    });
    setFormEtax(updatedState);
  };

  return (
    <PathTree list={path.Path}>
      <div>
        {redirectpage}
        <div className="grid mt-2" style={{ width: "100%" }}>
          <div className="col-12 mb-4">
            <center>
              <p className="font-bold ask__font__title">
                สมัครบริการ eTax/eReceipt
              </p>
            </center>
          </div>
          <div className="grid col-12 mx-3" style={{ paddingTop: "1rem" }}>
            {indexPage === 0 && (
              <PolicyEtax
                accept={acceptPolicy}
                onAccept={setAcceptPolicy}
                onNextPage={nextPage}
              />
            )}
            {indexPage === 1 && (
              <FormEtax
                onPrevPage={prevPage}
                onNextPage={nextPage}
                data={formEtax}
                onChangeEmail={onChangeEmailHandler}
              />
            )}
            {indexPage === 2 && (
              <ConfirmEmailEtax
                onPrevPage={prevPage}
                onNextPage={nextPage}
                email={formEtax.form.email.value}
              />
            )}
            {indexPage === 3 && <CompleteEtax onClickHandler={goToMainPage} />}
          </div>
        </div>
      </div>
    </PathTree>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
  };
};

export default connect(mapStateToProps)(RegisterEtax);
