import React from 'react';
import Grid from '@material-ui/core/Grid';
import Header from '../../components/UI/Header/Header.js';
import Button from '../../components/UI/Button/Button';
// import Modal from '../../components/UI/Modal/Modal';
// import Calculate from '../../components/Calculate/Calculate';
import './Product.css';
// import banner1 from '../../assets/banner1.jpg'
// import hp from '../../assets/hp_new.jpg'
// import hp_used from '../../assets/hp_used.jpg'
// import cl from '../../assets/cl.jpg'
// import pl from '../../assets/pl.jpg'
// import shb from '../../assets/shb.jpg'
// import cml from '../../assets/cml.jpg'
import Loading from './../UI/Loading/Loading';

let style;

// function getModalStyle() {
//     return {
//         margintop: '5%',
//         top: '50%',
//         left: '50%',
//         transform: `translate(-50%, -50%)`,
//     };
// }

const ProductDetail = (props) => {
    if (props.color !== "" || props.borderColor !== "") {
        style = {
            color: props.color,
            borderColor: props.borderColor,
        };
    }



    // const [modalStyle] = React.useState(getModalStyle);
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => {
    //     setOpen(true);
    // };
    // const handleClose = () => {
    //     setOpen(false);

    return (
        <Grid container spacing={0} >
            {/* <Modal
                open={open}
                onClose={handleClose}
                scroll="scroll"
                width="60%"
                height="90%"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                color="#fff"
                children={
                    <Grid item xs={12}>
                        <Button click={() => handleClose()} btnType='normal' borderColor="#fff" fontColor="red" color="#fff" >ปิด X</Button>
                        <Calculate></Calculate></Grid>}
            >
            </Modal> */}
            {props.loading
                ? <Grid item xs={12}><center><Loading /></center></Grid>
                : <Grid container><Grid item xs={12}>
                    <Header
                        img={props.image}
                        text={props.name}
                        title={props.title}></Header>
                </Grid>
                    <div className="card__detail">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className="text__container">
                                    <h2 style={style}>{props.name}</h2>
                                    <p >{props.descrip}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <center>
                                    <div className="btn_product">
                                        <Button btnType="normal" border={["0.1rem solid", [props.borderColor]].join(' ')} color={props.color} width="100%" clicked={props.onClicked}>สนใจสมัครสินเชื่อ</Button>
                                    </div>
                                </center>
                            </Grid>
                            {/* <Grid item xs={6}>
                        <Button btnType="border" padding="1rem 2rem" fontColor={props.color} width="100%" path="/contract">สอบถามเพิ่มเติม</Button>
                    </Grid> */}

                            {/* -------------Calculate------------- */}
                            {/* <Grid item xs={6}>
                    <Button btnType="border" width="100%" clicked={() => handleOpen()}>คำนวณค่างวด</Button>
                </Grid> */}
                        </Grid>
                    </div>
                </Grid>
            }
        </Grid>
    );

}
export default ProductDetail;