import React, { useState, useEffect } from "react";
import ManageAgreementInsurance from "../../components/ManageAgreement/ManageAgreementInsurance/ManageAgreementInsurance";
import Insurance from "../../components/ManageAgreement/insurance";
import PathTree from "../../components/UI/PathTree/PathTree";
import axios from "axios";
import { API_MYASK } from "./../../shared/constant";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import * as actions from "./../../store/action/index";

const ManageAgreementControlsInsurance = (props) => {
  let data = "";
  let click = "";
  const [agrtmp] = React.useState(
    props.location.agrtmp || sessionStorage.getItem("agrtmp") || ""
  );
  const [fin_type] = React.useState(
    props.location.fin_type || sessionStorage.getItem("fin_type") || ""
  );
  const [agr_code] = React.useState(
    props.location.agr_code || sessionStorage.getItem("agr_code") || ""
  );
  const [body] = React.useState(
    props.location.body || sessionStorage.getItem("body") || ""
  );
  const [brand] = React.useState(
    props.location.brand || sessionStorage.getItem("brand") || ""
  );
  const [reg_num] = React.useState(
    props.location.reg_num || sessionStorage.getItem("reg_num") || ""
  );
  const [reg_province] = React.useState(
    props.location.reg_province || sessionStorage.getItem("reg_province") || ""
  );
  const [asset_description] = React.useState(
    props.location.asset_description ||
      sessionStorage.getItem("asset_description") ||
      ""
  );
  const [install] = React.useState(
    props.location.install || sessionStorage.getItem("install") || ""
  );
  const [os_balance] = React.useState(
    props.location.os_balance || sessionStorage.getItem("os_balance") || ""
  );
  const [n_due_i] = React.useState(
    props.location.n_due_i || sessionStorage.getItem("n_due_i") || ""
  );
  const [flat_rate] = React.useState(
    props.location.flat_rate || sessionStorage.getItem("flat_rate") || ""
  );
  const [disbursement_date] = React.useState(
    props.location.disbursement_date ||
      sessionStorage.getItem("disbursement_date") ||
      ""
  );
  const [ar] = React.useState(
    props.location.ar || sessionStorage.getItem("ar") || ""
  );
  const [terms] = React.useState(
    props.location.terms || sessionStorage.getItem("terms") || ""
  );
  const [scan_code] = React.useState(
    props.location.scan_code || sessionStorage.getItem("scan_code") || ""
  );

  window.scrollTo(0, 0);
  const [redi, setRedi] = useState(false);
  const [insurance, setInsurance] = useState([]);

  const [count, setCount] = useState(0);

  const onSelectHistory = (
    fin_type,
    agr_code,
    body,
    brand,
    reg_num,
    reg_province,
    asset_description,
    install,
    os_balance,
    n_due_i,
    flat_rate,
    disbursement_date,
    ar,
    terms,
    scan_code
  ) => {
    props.history.push({
      pathname: "/manageagreement-history",
      fin_type: fin_type,
      agr_code: agr_code,
      body: body,
      brand: brand,
      reg_num: reg_num,
      reg_province: reg_province,
      asset_description: asset_description,
      install: install,
      os_balance: os_balance,
      n_due_i: n_due_i,
      flat_rate: flat_rate,
      disbursement_date: disbursement_date,
      ar: ar,
      terms: terms,
      scan_code: scan_code,
    });
  };

  const onSelectPayment = () => {
    props.history.push({
      pathname: "/manageagreement-paymentlist",
      agrtmp: agrtmp,
      manyagr: "",
      type: "ins",
    });
    sessionStorage.setItem("manyagr", "");
    sessionStorage.setItem("type", "ins");
  };

  useEffect(() => {
    let agr;
    if (agrtmp != "") {
      agr = agrtmp;
    } else {
      if (agr_code != "") {
        agr = "'" + agr_code + "'";
      }
    }
    // else {

    // }
    let mount = true;
    axios.get(API_MYASK + "/Insurance?" + "agr_code=" + agr).then((res) => {
      let form = [];
      console.log(res);
      if (res.data.CODE === "200") {
        for (let i in res.data.LIST_DATA) {
          form.push({
            com_code: res.data.LIST_DATA[i].COM_CODE,
            agr_code: res.data.LIST_DATA[i].AGR_CODE,
            ref_code: res.data.LIST_DATA[i].REF_CODE,
            ins_type: res.data.LIST_DATA[i].INS_TYPE,
            ins_com: res.data.LIST_DATA[i].INS_COM,
            ins_date: res.data.LIST_DATA[i].INS_DATE,
            ins_exp: res.data.LIST_DATA[i].INS_EXP,
            ins_date_th: res.data.LIST_DATA[i].INS_DATE_TH,
            ins_exp_th: res.data.LIST_DATA[i].INS_EXP_TH,
            pol_num: res.data.LIST_DATA[i].POL_NUM,
            paid_amount: res.data.LIST_DATA[i].PAID_AMOUNT,
            premium: res.data.LIST_DATA[i].PREMIUM,
            cov_amt: res.data.LIST_DATA[i].COV_AMT,
            total_prem: res.data.LIST_DATA[i].TOTAL_PREM,
            reg_num: res.data.LIST_DATA[i].REG_NUM,
            year: res.data.LIST_DATA[i].YEAR,
            brand: res.data.LIST_DATA[i].BRAND,
            model_name: res.data.LIST_DATA[i].MODEL_NAME,
          });
        }
      }
      if (mount) {
        setCount(form.length);
        setInsurance(form);
      }
    });

    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("fin_type", fin_type);
    sessionStorage.setItem("agr_code", agr_code);
    sessionStorage.setItem("body", body);
    sessionStorage.setItem("brand", brand);
    sessionStorage.setItem("reg_num", reg_num);
    sessionStorage.setItem("reg_province", reg_province);
    sessionStorage.setItem("asset_description", asset_description);
    sessionStorage.setItem("install", install);
    sessionStorage.setItem("os_balance", os_balance);
    sessionStorage.setItem("n_due_i", n_due_i);
    sessionStorage.setItem("flat_rate", flat_rate);
    sessionStorage.setItem("disbursement_date", disbursement_date);
    sessionStorage.setItem("ar", ar);
    sessionStorage.setItem("terms", terms);
    sessionStorage.setItem("scan_code", scan_code);
  }, [agr_code]);

  const onSelectQRCode = (agr_code, scan_code) => {
    props.history.push({
      pathname: "/manageagreement-paymentlist",
      agr_code: agr_code,
      scan_code: scan_code,
    });
  };

  let redirectpage;
  if (redi === true) {
    redirectpage = <Redirect to="/login" />;
  }

  const path = {
    Path: [
      {
        title: "ข้อมูลสินเชื่อของฉัน",
        pathLink: "/manageagreement",
      },
      {
        title: "ประกันของฉัน",
        pathLink: "/manageagreement-insurance",
      },
    ],
  };

  const [manageAgrForm2, setManageAgrForm2] = useState([]);

  const formElementsArray2 = [];
  for (let key in manageAgrForm2) {
    formElementsArray2.push({
      id: key,
      config: manageAgrForm2[key],
    });
  }

  const [manageAgrForm] = useState([
    {
      fin_type: fin_type,
      agr_code: agr_code,
      body: body,
      brand: brand,
      reg_num: reg_num,
      reg_province: reg_province,
      asset_description: asset_description,
      install: install,
      os_balance: os_balance,
      n_due_i: n_due_i,
      flat_rate: flat_rate,
      disbursement_date: disbursement_date,
      ar: ar,
      terms: terms,
      scan_code: scan_code,
    },
  ]);

  const formElementsArray = [];
  for (let key in insurance) {
    formElementsArray.push({
      id: key,
      config: insurance[key],
    });
  }

  return (
    <PathTree list={path.Path}>
      {redirectpage}
      <div className="ask__font__main">
        <ManageAgreementInsurance
          children={formElementsArray.map((formElement) => (
            <Insurance
              key={formElement.id}
              com_code={formElement.config.com_code}
              agr_code={formElement.config.agr_code}
              ref_code={formElement.config.ref_code}
              ins_type={formElement.config.ins_type}
              ins_com={formElement.config.ins_com}
              ins_date={formElement.config.ins_date}
              ins_exp={formElement.config.ins_exp}
              ins_date_th={formElement.config.ins_date_th}
              ins_exp_th={formElement.config.ins_exp_th}
              pol_num={formElement.config.pol_num}
              paid_amount={formElement.config.paid_amount}
              premium={formElement.config.premium}
              cov_amt={formElement.config.cov_amt}
              total_prem={formElement.config.total_prem}
              reg_num={formElement.config.reg_num}
              year={formElement.config.year}
              brand={formElement.config.brand}
              model_name={formElement.config.model_name}
              onSelectNumber={() =>
                onSelectHistory(
                  formElement.fin_type,
                  formElement.agr_code,
                  formElement.body,
                  formElement.brand,
                  formElement.reg_num,
                  formElement.reg_province,
                  formElement.asset_description,
                  formElement.install,
                  formElement.os_balance,
                  formElement.n_due_i,
                  formElement.flat_rate,
                  formElement.disbursement_date,
                  formElement.ar,
                  formElement.terms,
                  formElement.scan_code
                )
              }
              onSelectHistory={() =>
                onSelectHistory(
                  formElement.fin_type,
                  formElement.agr_code,
                  formElement.body,
                  formElement.brand,
                  formElement.reg_num,
                  formElement.reg_province,
                  formElement.asset_description,
                  formElement.install,
                  formElement.os_balance,
                  formElement.n_due_i,
                  formElement.flat_rate,
                  formElement.disbursement_date,
                  formElement.ar,
                  formElement.terms,
                  formElement.scan_code
                )
              }
            />
          ))}
          onSelectPayment={() => onSelectPayment()}
          count={count}
        ></ManageAgreementInsurance>
      </div>
    </PathTree>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAgreementControlsInsurance);
