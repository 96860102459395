import React from "react";
import { Card } from "primereact/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import ButtonBase from "@material-ui/core/ButtonBase";
import { numberWithCommas } from "../../../../shared/utility";

const payment = (props) => {
  const { payment } = props;

  return (
    <Card
      style={{
        color: "#000000de",
        border: "2px solid",
        borderRadius: "3px",
        borderColor: payment.closed ? "#f44336" : "#3bb54a",
        fontFamily: "Prompt-Regular",
      }}
    >
      <div className="ask__font__main">
        <div className="flex justify-content-end">
          <ButtonBase
            className="ask__font__main"
            focusRipple
            style={{
              alignItems: "baseline",
              fontFamily: "Prompt-Regular",
              padding: "1.5rem",
            }}
            onClick={() => props.onRemove(payment.agreement.agr_code)}
          >
            <p className="text-right font-normal" style={{ color: "#f44336" }}>
              <u>นำออกจากรายการ</u>
            </p>
          </ButtonBase>
        </div>
        <p className="font-bold">
          เลขที่สัญญา {payment.agreement.agr_code}
          {payment.closed ? " (ปิดบัญชี)" : ""}
        </p>

        <div className="flex mt-5">
          {payment.agreement.brand ? (
            <p className="font-bold mr-4">{payment.agreement.brand}</p>
          ) : null}
          {payment.agreement.reg_num ? (
            <p className="font-bold mr-4">{payment.agreement.reg_num}</p>
          ) : null}
          {payment.agreement.reg_province ? (
            <p className="font-bold mr-4">{payment.agreement.reg_province}</p>
          ) : null}
          {payment.agreement.brand === "" &&
            payment.agreement.reg_num === "" &&
            payment.agreement.reg_province === "" && (
              <p className="font-bold mr-4">
                <br />
              </p>
            )}
        </div>
        <div className="flex mt-6 justify-content-end">
          <div
            className="grid align-items-center align-self-center"
            style={{ width: "100%", margin: "0rem" }}
          >
            <div className="col-6">
              <p
                className="font-bold "
                style={{ color: "#f44336", textAlign: "end" }}
              >
                ยอดที่จะชำระ :
              </p>
            </div>
            <div className="col-6">
              <p className="font-bold text-right" style={{ color: "#f44336" }}>
                {numberWithCommas(
                  parseFloat(
                    Math.round(payment.payment_amount * 100) / 100
                  ).toFixed(2)
                )}
                {"  "}
                บาท
              </p>
            </div>
          </div>
          {payment.closed ? (
            <ButtonBase
              className="ask__font__main"
              focusRipple
              style={{
                alignItems: "baseline",
                fontFamily: "Prompt-Regular",
                padding: "1.5rem",
              }}
              onClick={() =>
                props.onViewClosedAgreement(
                  payment.agreement.agr_code,
                  payment.agreement.com_code
                )
              }
            >
              <FontAwesomeIcon icon={faEye} size="lg" color="#ff9600" />
            </ButtonBase>
          ) : (
            <ButtonBase
              className="ask__font__main"
              focusRipple
              style={{
                alignItems: "baseline",
                fontFamily: "Prompt-Regular",
                padding: "1.5rem",
              }}
              onClick={() => props.onEditSummary(payment.agreement)}
            >
              <FontAwesomeIcon icon={faEdit} size="lg" color="#ff9600" />
            </ButtonBase>
          )}
        </div>
      </div>
    </Card>
  );
};

export default payment;
