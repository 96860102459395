import React, { useEffect, useState } from "react";
import Contact from "../../components/Contact/Contact/Contact";
import Complaint from "../../components/Complaint/Complaint";
import PathTree from "../../components/UI/PathTree/PathTree";
import Input from "../../components/UI/Input/Input";
import { connect } from "react-redux";
import {
  updateObject,
  checkValidity,
  checkError,
} from "../../../src/shared/utility.js";
import { API_MYASK, API_MYASK_HEADER } from "./../../shared/constant";
// import { Redirect } from "react-router-dom";
import axios from "axios";
import ModalError from "./../../components/UI/Error/Error";

const ComplaintControls = (props) => {
  console.log(props.location);

  const [reren, setReren] = useState("");
  const [usr, setUsr] = useState(props.location.search.substring(1) || "C");

  const path = {
    Path: [
      {
        title: "แบบฟอร์มการร้องเรียน",
        pathLink: "/complaint",
      },
    ],
  };

  const label = {
    color: "#000",
    // width: '50%',
  };

  let notice_type = "";

  const [noticetypeState, setnoticetypeState] = useState({
    notice1: {
      checked: false,
    },
    notice2: {
      checked: false,
    },
    notice3: {
      checked: false,
    },
    notice4: {
      checked: false,
    },
    notice5: {
      checked: false,
    },
    notice6: {
      checked: false,
    },
    notice7: {
      checked: false,
    },
    notice8: {
      checked: false,
    },
  });

  const [complaintForm, setcomplaintForm] = useState({
    form: {
      name: {
        order: "0",
        label: "ชื่อ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "ชื่อ",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "ชื่อ",
      },
      lastname: {
        order: "1",
        label: "นามสกุล:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "นามสกุล",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "นามสกุล",
      },
      tel: {
        order: "2",
        label: "เบอร์มือถือ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "number",
          placeholder: "เบอร์มือถือ",
        },
        type: "number",
        mode: "numeric",
        value: "",
        maxLength: 10, //ใช้ของ input html
        validation: {
          required: true,
          isNumeric: true,
          minLength: 10, //ใช้ของ utility เพราะต้องการดัก spacebar
          maxLength: 10,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "เบอร์มือถือ",
      },
      email: {
        order: "3",
        label: "อีเมล:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "อีเมล",
        },
        value: "",
        validation: {
          required: false,
          isEmail: true,
        },
        valid: true,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "อีเมล",
      },
      producttype: {
        order: "4",
        label: "ประเภทสินเชื่อ:",
        labelPos: "left",
        elementType: "select",
        elementConfig: {
          options: [{ value: "", displayValue: "กรุณาเลือก" }],
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "ประเภทสินเชื่อ",
      },
      custype: {
        order: "5",
        label: "สถานะลูกค้า:",
        labelPos: "left",
        elementType: "select",
        elementConfig: {
          options: [
            { value: "", displayValue: "กรุณาเลือก" },
            { value: "N", displayValue: "ยังไม่เป็นลูกค้า" },
            { value: "Y", displayValue: "ลูกค้าปัจจุบัน" },
            { value: "E", displayValue: "เคยเป็นลูกค้า" },
          ],
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "สถานะลูกค้า",
      },
      agrcode: {
        order: "6",
        label: "เลขที่สัญญา:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "เลขที่สัญญา",
        },
        value: "",
        validation: {},
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
      },
      noticetype: {
        order: "7",
        label: "เรื่องที่ต้องการร้องเรียน:",
        labelPos: "left",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {
          required: true,
        },
        text: "",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "เรื่องที่ต้องการร้องเรียน",
      },
      noticetype2: {
        order: "8",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "เรื่องที่ต้องการร้องเรียน",
      },
      noticetype3: {
        order: "9",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "เรื่องที่ต้องการร้องเรียน",
      },
      noticetype4: {
        order: "10",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "เรื่องที่ต้องการร้องเรียน",
      },
      noticetype5: {
        order: "11",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "เรื่องที่ต้องการร้องเรียน",
      },
      noticetype6: {
        order: "12",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "เรื่องที่ต้องการร้องเรียน",
      },
      noticetype7: {
        order: "13",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "เรื่องที่ต้องการร้องเรียน",
      },
      noticetype8: {
        order: "14",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "เรื่องที่ต้องการร้องเรียน",
      },
      message: {
        order: "15",
        label: "รายละเอียดเรื่องที่ต้องการร้องเรียน:",
        labelPos: "left",
        elementType: "textarea",
        elementConfig: {
          type: "text",
          placeholder: "รายละเอียดเรื่องที่ต้องการร้องเรียน",
          rows: "10",
        },
        value: "",
        validation: {
          required: false,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        styleInput: {
          resize: "none",
        },
        textInvalid: "",
        labelError: "รายละเอียดเรื่องที่ต้องการร้องเรียน",
      },
      callback: {
        order: "16",
        label: "ช่องทางการติดต่อกลับ:",
        labelPos: "left",
        elementType: "select",
        elementConfig: {
          options: [
            { value: "", displayValue: "กรุณาเลือก" },
            { value: "02", displayValue: "โทรศัพท์" },
            { value: "04", displayValue: "จดหมาย" },
            { value: "03", displayValue: "Email" },
            { value: "01", displayValue: "ไม่ประสงค์ให้ติดต่อกลับ" },
          ],
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "ช่องทางการติดต่อกลับ",
      },
      callbacktext: {
        order: "17",
        label: "ข้อมูลช่องทางการติดต่อกลับ (ที่อยู่):",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "ข้อมูลช่องทางการติดต่อกลับ (ที่อยู่)",
        },
        value: "",
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        styleLabel: label,
        textInvalid: "ข้อมูลช่องทางการติดต่อกลับ (ที่อยู่)",
        labelError: "ที่อยู่",
      },
    },
    formIsValid: false,
  });

  useEffect(() => {
    let mount = true;
    window.scrollTo(0, 0);
    axios.get(API_MYASK + "/GetNoticeType").then((res) => {
      axios.get(API_MYASK + "/GetProductType").then((res2) => {
        console.log(res2);
        let type = complaintForm.form.producttype.elementConfig.options;
        for (let i in res2.data.LIST_DATA) {
          type.push({
            value: res2.data.LIST_DATA[i].product_code,
            displayValue: res2.data.LIST_DATA[i].product_name,
          });
        }

        let updateProduct = updateObject(complaintForm.form.producttype, {
          elementConfig: updateObject(
            complaintForm.form.producttype.elementConfig,
            {
              options: type,
            }
          ),
        });

        let updateType = updateObject(complaintForm.form.noticetype, {
          value: res.data.LIST_DATA[0].id_code,
          text: res.data.LIST_DATA[0].remark,
        });
        let updateType2 = updateObject(complaintForm.form.noticetype2, {
          value: res.data.LIST_DATA[1].id_code,
          text: res.data.LIST_DATA[1].remark,
        });
        let updateType3 = updateObject(complaintForm.form.noticetype3, {
          value: res.data.LIST_DATA[2].id_code,
          text: res.data.LIST_DATA[2].remark,
        });
        let updateType4 = updateObject(complaintForm.form.noticetype4, {
          value: res.data.LIST_DATA[3].id_code,
          text: res.data.LIST_DATA[3].remark,
        });
        let updateType5 = updateObject(complaintForm.form.noticetype5, {
          value: res.data.LIST_DATA[4].id_code,
          text: res.data.LIST_DATA[4].remark,
        });
        let updateType6 = updateObject(complaintForm.form.noticetype6, {
          value: res.data.LIST_DATA[5].id_code,
          text: res.data.LIST_DATA[5].remark,
        });
        let updateType7 = updateObject(complaintForm.form.noticetype7, {
          value: res.data.LIST_DATA[6].id_code,
          text: res.data.LIST_DATA[6].remark,
        });
        let updateType8 = updateObject(complaintForm.form.noticetype8, {
          value: res.data.LIST_DATA[7].id_code,
          text: res.data.LIST_DATA[7].remark,
        });

        let updateForm = updateObject(complaintForm.form, {
          noticetype: updateType,
          noticetype2: updateType2,
          noticetype3: updateType3,
          noticetype4: updateType4,
          noticetype5: updateType5,
          noticetype6: updateType6,
          noticetype7: updateType7,
          noticetype8: updateType8,
          producttype: updateProduct,
        });
        let updateState = updateObject(complaintForm, {
          form: updateForm,
        });

        if (mount) {
          setcomplaintForm(updateState);
        }
      });
    });
    return () => {
      mount = false;
    };
  }, []);

  const [loading, setLoading] = useState(false);

  const onChangeTel = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    if (isValid || val === "") {
      return val;
    } else {
      return val.substring(val.length - 1, 0);
    }
  };

  const inputChangedHandler = (event, field) => {
    let updatedFormElement;
    let updatedFormElement2;
    let updatedFormElement3;

    console.log(field + " " + event);

    if (field === "tel") {
      event = onChangeTel(event);
    }

    let updateChecked;

    if (field === "noticetype") {
      if (noticetypeState.notice1.checked === false) {
        updateChecked = updateObject(noticetypeState.notice1, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice1, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice1: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype2") {
      if (noticetypeState.notice2.checked === false) {
        updateChecked = updateObject(noticetypeState.notice2, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice2, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice2: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype3") {
      if (noticetypeState.notice3.checked === false) {
        updateChecked = updateObject(noticetypeState.notice3, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice3, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice3: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype4") {
      if (noticetypeState.notice4.checked === false) {
        updateChecked = updateObject(noticetypeState.notice4, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice4, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice4: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype5") {
      if (noticetypeState.notice5.checked === false) {
        updateChecked = updateObject(noticetypeState.notice5, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice5, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice5: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype6") {
      if (noticetypeState.notice6.checked === false) {
        updateChecked = updateObject(noticetypeState.notice6, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice6, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice6: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype7") {
      if (noticetypeState.notice7.checked === false) {
        updateChecked = updateObject(noticetypeState.notice7, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice7, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice7: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype8") {
      if (noticetypeState.notice8.checked === false) {
        updateChecked = updateObject(noticetypeState.notice8, {
          checked: true,
        });

        let updateFormValidate = updateObject(
          complaintForm.form["message"].validation,
          {
            required: true,
          }
        );

        updatedFormElement = updateObject(complaintForm.form["message"], {
          valid: false,
          validation: updateFormValidate,
        });

        let updatedForm = updateObject(complaintForm.form, {
          ["message"]: updatedFormElement,
        });

        const updatedState = updateObject(complaintForm, {
          form: updatedForm,
        });

        let updateNoticeState = updateObject(noticetypeState, {
          notice8: updateChecked,
        });

        setcomplaintForm(updatedState);
        setnoticetypeState(updateNoticeState);
      } else {
        updateChecked = updateObject(noticetypeState.notice8, {
          checked: false,
        });

        let updateFormValidate = updateObject(
          complaintForm.form["message"].validation,
          {
            required: false,
          }
        );

        updatedFormElement = updateObject(complaintForm.form["message"], {
          valid: false,
          validation: updateFormValidate,
        });

        let updatedForm = updateObject(complaintForm.form, {
          ["message"]: updatedFormElement,
        });

        const updatedState = updateObject(complaintForm, {
          form: updatedForm,
        });

        let updateNoticeState = updateObject(noticetypeState, {
          notice8: updateChecked,
        });

        setcomplaintForm(updatedState);
        setnoticetypeState(updateNoticeState);
      }
    } else if (field === "callback" && event === "03") {
      let updateFormValidate = updateObject(
        complaintForm.form["email"].validation,
        {
          required: true,
        }
      );

      updatedFormElement = updateObject(complaintForm.form["email"], {
        valid: false,
        validation: updateFormValidate,
      });

      let updateFormValidate2 = updateObject(
        complaintForm.form["callbacktext"].validation,
        {
          required: false,
        }
      );

      updatedFormElement3 = updateObject(complaintForm.form["callbacktext"], {
        valid: true,
        validation: updateFormValidate2,
      });

      const validate = checkValidity(
        event,
        complaintForm.form[field].validation
      );
      const text = checkError(
        validate.code,
        complaintForm.form[field].labelError,
        complaintForm.form[field].validation
      );

      updatedFormElement2 = updateObject(complaintForm.form[field], {
        value: event,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });

      let updatedForm = updateObject(complaintForm.form, {
        ["email"]: updatedFormElement,
        ["callbacktext"]: updatedFormElement3,
        [field]: updatedFormElement2,
      });

      const updatedState = updateObject(complaintForm, {
        form: updatedForm,
      });

      setcomplaintForm(updatedState);
      console.log(complaintForm);
    } else if (field === "callback" && event === "04") {
      let updateFormValidate = updateObject(
        complaintForm.form["callbacktext"].validation,
        {
          required: true,
        }
      );

      updatedFormElement = updateObject(complaintForm.form["callbacktext"], {
        valid: false,
        validation: updateFormValidate,
      });

      let updateFormValidate2 = updateObject(
        complaintForm.form["email"].validation,
        {
          required: false,
        }
      );

      updatedFormElement3 = updateObject(complaintForm.form["email"], {
        valid: true,
        validation: updateFormValidate2,
      });

      const validate = checkValidity(
        event,
        complaintForm.form[field].validation
      );
      const text = checkError(
        validate.code,
        complaintForm.form[field].labelError,
        complaintForm.form[field].validation
      );

      updatedFormElement2 = updateObject(complaintForm.form[field], {
        value: event,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });

      let updatedForm = updateObject(complaintForm.form, {
        ["email"]: updatedFormElement3,
        ["callbacktext"]: updatedFormElement,
        [field]: updatedFormElement2,
      });

      const updatedState = updateObject(complaintForm, {
        form: updatedForm,
      });

      setcomplaintForm(updatedState);

      console.log(complaintForm);
    } else if (field === "callback" && event !== "03" && event !== "04") {
      let updateFormValidate = updateObject(
        complaintForm.form["email"].validation,
        {
          required: false,
        }
      );

      updatedFormElement = updateObject(complaintForm.form["email"], {
        valid: true,
        validation: updateFormValidate,
      });

      let updateFormValidate2 = updateObject(
        complaintForm.form["callbacktext"].validation,
        {
          required: false,
        }
      );

      updatedFormElement3 = updateObject(complaintForm.form["callbacktext"], {
        valid: true,
        validation: updateFormValidate2,
      });

      const validate = checkValidity(
        event,
        complaintForm.form[field].validation
      );
      const text = checkError(
        validate.code,
        complaintForm.form[field].labelError,
        complaintForm.form[field].validation
      );

      updatedFormElement2 = updateObject(complaintForm.form[field], {
        value: event,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });

      let updatedForm = updateObject(complaintForm.form, {
        ["email"]: updatedFormElement,
        ["callbacktext"]: updatedFormElement3,
        [field]: updatedFormElement2,
      });

      const updatedState = updateObject(complaintForm, {
        form: updatedForm,
      });

      setcomplaintForm(updatedState);
    } else {
      const validate = checkValidity(
        event,
        complaintForm.form[field].validation
      );
      const text = checkError(
        validate.code,
        complaintForm.form[field].labelError,
        complaintForm.form[field].validation
      );

      if (field === "email") {
        //formIsValid : true
        if (event === "") {
          updatedFormElement = updateObject(complaintForm.form[field], {
            value: event,
            valid: false,
            textInvalid: text,
            touched: false,
            isEmail: false,
          });
        } else {
          updatedFormElement = updateObject(complaintForm.form[field], {
            value: event,
            valid: true,
            textInvalid: text,
            touched: true,
            isEmail: true,
          });
        }
      } else {
        updatedFormElement = updateObject(complaintForm.form[field], {
          value: event,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
      }

      let updatedForm = updateObject(complaintForm.form, {
        [field]: updatedFormElement,
      });

      let formIsValid = true;
      for (let inputIdentifier in updatedForm) {
        formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
      }

      const updatedState = updateObject(complaintForm, {
        form: updatedForm,
        formIsValid: formIsValid,
      });

      setcomplaintForm(updatedState);
    }
  };

  const checkedValidate = () => {
    if (
      complaintForm.form["callback"].valid === true &&
      !(
        noticetypeState.notice1.checked === false &&
        noticetypeState.notice2.checked === false &&
        noticetypeState.notice3.checked === false &&
        noticetypeState.notice4.checked === false &&
        noticetypeState.notice5.checked === false &&
        noticetypeState.notice6.checked === false &&
        noticetypeState.notice7.checked === false &&
        noticetypeState.notice8.checked === false
      ) &&
      complaintForm.form["custype"].valid === true &&
      complaintForm.form["producttype"].valid === true &&
      complaintForm.form["name"].valid === true &&
      complaintForm.form["lastname"].valid === true &&
      complaintForm.form["tel"].valid === true &&
      complaintForm.form["email"].valid === true &&
      complaintForm.form["callback"].valid === true &&
      complaintForm.form["callbacktext"].valid === true
    ) {
      complaintForm.formIsValid = true;
      sendData();
    }
    if (!complaintForm.formIsValid) {
      let updatedForm;
      let arrayObj = [];
      let updatedFormElement;
      let text;
      for (let field in complaintForm.form) {
        const validate = checkValidity(
          complaintForm.form[field].value,
          complaintForm.form[field].validation
        );
        text = checkError(
          validate.code,
          complaintForm.form[field].labelError,
          complaintForm.form[field].validation
        );
        updatedFormElement = updateObject(complaintForm.form[field], {
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
        arrayObj.push(updatedFormElement);
      }
      const arrayComplaint = [];
      for (let i in arrayObj) {
        if (arrayObj[i].order === "0") {
          arrayComplaint[0] = arrayObj[i];
        } else if (arrayObj[i].order === "1") {
          arrayComplaint[1] = arrayObj[i];
        } else if (arrayObj[i].order === "2") {
          arrayComplaint[2] = arrayObj[i];
        } else if (arrayObj[i].order === "3") {
          arrayComplaint[3] = arrayObj[i];
        } else if (arrayObj[i].order === "4") {
          arrayComplaint[4] = arrayObj[i];
        } else if (arrayObj[i].order === "5") {
          arrayComplaint[5] = arrayObj[i];
        } else if (arrayObj[i].order === "6") {
          arrayComplaint[6] = arrayObj[i];
        } else if (arrayObj[i].order === "7") {
          arrayComplaint[7] = arrayObj[i];
        } else if (arrayObj[i].order === "8") {
          arrayComplaint[8] = arrayObj[i];
        } else if (arrayObj[i].order === "9") {
          arrayComplaint[9] = arrayObj[i];
        } else if (arrayObj[i].order === "10") {
          arrayComplaint[10] = arrayObj[i];
        } else if (arrayObj[i].order === "11") {
          arrayComplaint[11] = arrayObj[i];
        } else if (arrayObj[i].order === "12") {
          arrayComplaint[12] = arrayObj[i];
        } else if (arrayObj[i].order === "13") {
          arrayComplaint[13] = arrayObj[i];
        } else if (arrayObj[i].order === "14") {
          arrayComplaint[14] = arrayObj[i];
        } else if (arrayObj[i].order === "15") {
          arrayComplaint[15] = arrayObj[i];
        } else if (arrayObj[i].order === "16") {
          arrayComplaint[16] = arrayObj[i];
        } else if (arrayObj[i].order === "17") {
          arrayComplaint[17] = arrayObj[i];
        }
      }
      updatedForm = updateObject(complaintForm.form, {
        name: arrayComplaint[0],
        lastname: arrayComplaint[1],
        tel: arrayComplaint[2],
        email: arrayComplaint[3],
        producttype: arrayComplaint[4],
        custype: arrayComplaint[5],
        agrcode: arrayComplaint[6],
        noticetype: arrayComplaint[7],
        noticetype2: arrayComplaint[8],
        noticetype3: arrayComplaint[9],
        noticetype4: arrayComplaint[10],
        noticetype5: arrayComplaint[11],
        noticetype6: arrayComplaint[12],
        noticetype7: arrayComplaint[13],
        noticetype8: arrayComplaint[14],
        message: arrayComplaint[15],
        callback: arrayComplaint[16],
        callbacktext: arrayComplaint[17],
      });
      let updatedState = updateObject(complaintForm, {
        form: updatedForm,
      });
      setcomplaintForm(updatedState);
    }
  };

  const sendData = () => {
    setLoading(true);

    if (noticetypeState.notice1.checked === true) {
      notice_type = "01";
    }
    if (noticetypeState.notice2.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "02";
      } else {
        notice_type = "02";
      }
    }
    if (noticetypeState.notice3.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "03";
      } else {
        notice_type = "03";
      }
    }
    if (noticetypeState.notice4.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "04";
      } else {
        notice_type = "04";
      }
    }
    if (noticetypeState.notice5.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "05";
      } else {
        notice_type = "05";
      }
    }
    if (noticetypeState.notice6.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "06";
      } else {
        notice_type = "06";
      }
    }
    if (noticetypeState.notice7.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "07";
      } else {
        notice_type = "07";
      }
    }
    if (noticetypeState.notice8.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "08";
      } else {
        notice_type = "08";
      }
    }

    let sourceby = "";
    if (props.lineId !== null) {
      sourceby = "02";
    } else if (usr !== "C") {
      sourceby = "04";
    } else {
      sourceby = "01";
    }

    const data = {
      FIRST_NAME: complaintForm.form.name.value,
      LAST_NAME: complaintForm.form.lastname.value,
      TEL1: complaintForm.form.tel.value,
      TEL2: "",
      EMAIL: complaintForm.form.email.value,
      PRODUCT_TYPE: complaintForm.form.producttype.value,
      CUS_TYPE: complaintForm.form.custype.value,
      AGR_CODE: complaintForm.form.agrcode.value,
      NOTICE_TYPE: notice_type,
      NOTICE_DETAIL: complaintForm.form.message.value,
      CALL_BACK_CHANNEL: complaintForm.form.callback.value,
      CALL_BACK_MAIL: complaintForm.form.callbacktext.value,
      USER: usr,
      SOURCEBY: sourceby,
    };
    axios
      .post(API_MYASK + "/SaveComplaint", data, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        console.log(res);
        const data2 = {
          device: "web",
          userName: "",
          userCode: usr,
          comCode: "",
          pTaskCode: "CMP-02",
          pChannelMail: "01",
          pToemail: complaintForm.form.email.value,
          pBccemail: "",
          pSumresp: "",
        };
        axios
          .post(API_MYASK + "/PostSendMail", data2, {
            headers: API_MYASK_HEADER,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            setReren(
              <ModalError
                title="เกิดข้อผิดพลาด"
                text="กรุณาทำรายการใหม่ในภายหลัง"
                color="red"
                border="0.1rem solid red"
                link="/complaint"
              ></ModalError>
            );
          });
      })
      .catch((err) => {
        setReren(
          <ModalError
            title="เกิดข้อผิดพลาด"
            text="กรุณาทำรายการใหม่ในภายหลัง"
            color="red"
            border="0.1rem solid red"
            link="/complaint"
          ></ModalError>
        );
      });

    // props.history.push({
    //   // ใช้ในกรณีเซิฟเวอร์เทส
    //   pathname: "/success",
    //   title: "เราได้รับข้อมูลของท่านแล้ว",
    //   path: "/myask/#/",
    //   text: "จะติดต่อกลับหาท่านโดยเร็วที่สุด",
    //   // text2: 'ท่านสามารถติดตามผลได้ทาง...'
    // });

    props.history.push({
      // ใช้ในกรณี localhost, production
      pathname: "/success",
      title: "เราได้รับข้อมูลของท่านแล้ว",
      path: "/",
      text: "จะติดต่อกลับหาท่านโดยเร็วที่สุด",
      // text2: 'ท่านสามารถติดตามผลได้ทาง...'
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    checkedValidate();
  };

  const formElementsArray = [];
  for (let key in complaintForm.form) {
    formElementsArray[complaintForm.form[key].order] = {
      id: key,
      config: complaintForm.form[key],
    };
  }

  return (
    <PathTree list={path.Path}>
      {reren}
      <Complaint
        loading={loading}
        title="แบบฟอร์มการร้องเรียน"
        children={formElementsArray.map((formElement) => (
          <div className="label__pos ask__font__main" key={formElement.id}>
            {/* <label className="label_text">{formElement.config.label}</label > */}
            <Input
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation.required}
              touched={formElement.config.touched}
              max={formElement.config.maxLength}
              min={formElement.config.minLength}
              mode={formElement.config.mode}
              align={formElement.config.align}
              type={formElement.config.type}
              styleInput={formElement.config.styleInput}
              styleLabel={formElement.config.styleLabel}
              textInvalid={formElement.config.textInvalid}
              isEmail={formElement.config.isEmail}
              changed={(event) =>
                inputChangedHandler(event.target.value, formElement.id)
              }
              labelPos={"left"}
              fontSize={"1.2rem"}
              label={formElement.config.label}
              text={formElement.config.text}
            />
          </div>
        ))}
        submitHandler={submitHandler}
      ></Complaint>
    </PathTree>
  );
};
const mapStateToProps = (state) => {
  return {
    lineId: state.line.userId,
  };
};

export default connect(mapStateToProps, null)(ComplaintControls);
