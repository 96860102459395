import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  payment_agreements: [],
  paymentItems: null,
  paymentItemsState: actionTypes.VIEW_STATE.INITIAL,
  paymentItemsStateMsg: "",
  fullSummaryPayment: null,
  fullSummaryPaymentState: actionTypes.VIEW_STATE.INITIAL,
  fullSummaryPaymentStateMsg: "",
  batchNo: null,
  generateBatchState: actionTypes.VIEW_STATE.INITIAL,
  generateBatchStateMsg: "",
};

const insertAgreementPayList = (state, action) => {
  const newArray = [...state.payment_agreements];
  let amount = 0.0;
  for (let i in action.items) {
    let itemData = action.items[i];
    amount += parseFloat(itemData.amount);
  }
  const payment_item = {
    agreement: action.agreement,
    payment_amount: amount,
    closed: action.is_closed,
    payment_detail: action.items,
  };
  newArray.push(payment_item);
  return updateObject(state, {
    payment_agreements: newArray,
  });
};

const removeAgreementPayList = (state, action) => {
  const newArray = state.payment_agreements.filter((payment) => {
    return payment.agreement.agr_code !== action.agr_code;
  });
  return updateObject(state, {
    payment_agreements: newArray,
  });
};

const updateAgreementPayList = (state, action) => {
  let objAgr = null;
  let index = -1;
  if (state.payment_agreements != null && state.payment_agreements.length > 0) {
    index = state.payment_agreements.findIndex((payment) => {
      return payment.agreement.agr_code === action.agreement.agr_code;
    });

    objAgr = { ...state.payment_agreements[index] };
  }

  if (objAgr == null) {
    objAgr = {};
    objAgr.agreement = action.agreement;
  }

  const newArray = state.payment_agreements.filter((payment) => {
    return true;
  });

  let payment_detail = action.payment_detail;
  let payment_amount = 0;
  payment_detail.map((item) => {
    payment_amount = payment_amount + parseFloat(item.amount);
  });

  objAgr.payment_amount = payment_amount;
  objAgr.payment_detail = payment_detail;

  newArray.splice(index, 1, objAgr);

  return updateObject(state, {
    payment_agreements: newArray,
  });
};

const getPaymentItemStart = (state, action) => {
  return updateObject(state, {
    paymentItems: null,
    paymentItemsState: actionTypes.VIEW_STATE.LOADING,
    paymentItemsStateMsg: "",
  });
};

const getPaymentItemSuccess = (state, action) => {
  return updateObject(state, {
    paymentItems: action.paymentItems,
    paymentItemsState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getPaymentItemFailed = (state, action) => {
  return updateObject(state, {
    paymentItemsState: actionTypes.VIEW_STATE.ERROR,
    paymentItemsStateMsg: action.error,
  });
};

const getFullSummaryPaymentStart = (state, action) => {
  return updateObject(state, {
    fullSummaryPayment: null,
    fullSummaryPaymentState: actionTypes.VIEW_STATE.LOADING,
    fullSummaryPaymentStateMsg: "",
  });
};

const getFullSummaryPaymentSuccess = (state, action) => {
  return updateObject(state, {
    fullSummaryPayment: action.detail,
    fullSummaryPaymentState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getFullSummaryPaymentFailed = (state, action) => {
  return updateObject(state, {
    fullSummaryPaymentState: actionTypes.VIEW_STATE.ERROR,
    fullSummaryPaymentStateMsg: action.error,
  });
};

const clearPaymentState = (state, action) => {
  return initialState;
};

const generateBatchStart = (state, action) => {
  return updateObject(state, {
    batchNo: null,
    generateBatchState: actionTypes.VIEW_STATE.LOADING,
    generateBatchStateMsg: "",
  });
};

const generateBatchSuccess = (state, action) => {
  return updateObject(state, {
    batchNo: action.batchNo,
    generateBatchState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const generateBatchFailed = (state, action) => {
  return updateObject(state, {
    generateBatchState: actionTypes.VIEW_STATE.ERROR,
    generateBatchStateMsg: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INSERT_AGREEMENT_PAY_LIST:
      return insertAgreementPayList(state, action);
    case actionTypes.REMOVE_AGREEMENT_PAY_LIST:
      return removeAgreementPayList(state, action);
    case actionTypes.UPDATE_AGREEMENT_PAY_LIST:
      return updateAgreementPayList(state, action);
    case actionTypes.GET_PAYMENT_ITEM_START:
      return getPaymentItemStart(state, action);
    case actionTypes.GET_PAYMENT_ITEM_SUCCESS:
      return getPaymentItemSuccess(state, action);
    case actionTypes.GET_PAYMENT_ITEM_FAILED:
      return getPaymentItemFailed(state, action);
    case actionTypes.GET_FULL_SUMMARY_PAYMENT_START:
      return getFullSummaryPaymentStart(state, action);
    case actionTypes.GET_FULL_SUMMARY_PAYMENT_SUCCESS:
      return getFullSummaryPaymentSuccess(state, action);
    case actionTypes.GET_FULL_SUMMARY_PAYMENT_FAILED:
      return getFullSummaryPaymentFailed(state, action);
    case actionTypes.CLEAR_PAYMENT_STATE:
      return clearPaymentState(state, action);
    case actionTypes.GENERATE_BATCH_START:
      return generateBatchStart(state, action);
    case actionTypes.GENERATE_BATCH_SUCCESS:
      return generateBatchSuccess(state, action);
    case actionTypes.GENERATE_BATCH_FAILED:
      return generateBatchFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
