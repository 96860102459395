import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import QRcode from "qrcode.react";
import Barcode from "react-barcode";

import * as actions from "./../../store/action/index";
import { numberWithCommas } from "../../shared/utility";
import howtopaid from "./../../assets/main/main-qrcode.jpeg";
import PathTree from "../../components/UI/PathTree/PathTree";
import ButtonTab from "../../components/UI/Button/Button";
import Modal from "./../../components/UI/Modal/Modal";

const BarcodeAndQR = (props) => {
  let history = useHistory();
  const { payment_agreements } = props;
  const [scanCode, setScanCode] = useState("");
  const [scanCodeV, setScanCodeV] = useState("");
  const [ref1, setRef1] = useState("");
  const [ref2, setRef2] = useState("");
  const [tabSelect, setTabSelect] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const path = {
    Path: [
      {
        title: "จัดการสินเชื่อ",
        pathLink: "/agreements",
      },
      {
        title: "ชำระเงิน",
        pathLink: "/payment-channal",
      },
      {
        title: "คิวอาร์โค้ดและบาร์โค้ด",
        pathLink: "/payment-qr",
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (payment_agreements.length > 0) {
      setScanCode(payment_agreements[0].agreement.scan_code);

      let vArr = payment_agreements[0].agreement.scan_code.split(" ");
      let v = "";
      for (var i = 0; i < vArr.length; i++) {
        v = v + vArr[i] + "\n";
      }
      setScanCodeV(v);

      setRef1(payment_agreements[0].agreement.ref_1);
      setRef2(payment_agreements[0].agreement.ref_2);
    }
  }, []);

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  const handleQRTab = () => {
    setTabSelect(0);
  };

  const handleBarCodeTab = () => {
    setTabSelect(1);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  let modal = null;

  if (tabSelect === 0) {
    modal = (
      <Modal
        open={openModal}
        close={handleCloseModal}
        children={
          <center>
            <QRcode value={scanCodeV} size={256} level="M"></QRcode>
          </center>
        }
      />
    );
  } else if (tabSelect === 1) {
    modal = (
      <Modal
        open={openModal}
        close={handleCloseModal}
        children={
          <center>
            <div style={{ transform: "rotate(270deg)" }}>
              <Barcode
                value={scanCodeV}
                width={2}
                text={"BBS" + scanCode}
                displayValue={true}
                font="'Prompt-Regular'"
                fontSize={14}
                format="CODE128"
              />
            </div>
          </center>
        }
      />
    );
  }

  return (
    <PathTree list={path.Path}>
      {redirectpage}
      <div className="ask__font__main">
        <Button className="p-button-text m-4" onClick={() => history.goBack()}>
          <span style={{ color: "#ff9600" }}> {"< ย้อนกลับ"}</span>
        </Button>

        <div className="grid mt-2" style={{ width: "100%" }}>
          <div className="col-12 mb-4">
            <center>
              <p className="text-bold ask__font__title">QR Code / Bar Code</p>
            </center>
          </div>
          <div
            className="col-12 justify-content-center"
            style={{ paddingTop: "1rem" }}
          >
            {modal}
            {scanCode !== "" && (
              <div
                className="grid col-12 sm:col-12 md:col-8 md:col-offset-2 
                lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2 align-items-center"
              >
                <div className="col-6">
                  <center>
                    <ButtonTab
                      btnType={tabSelect === 0 ? "menuclick" : "menunormal"}
                      clicked={() => handleQRTab()}
                    >
                      QR Code
                    </ButtonTab>
                  </center>
                </div>
                <div className="col-6">
                  <center>
                    <ButtonTab
                      btnType={tabSelect === 1 ? "menuclick" : "menunormal"}
                      clicked={() => handleBarCodeTab()}
                    >
                      Bar Code
                    </ButtonTab>
                  </center>
                </div>

                <div className="col-12 my-4" style={{ background: "#F8F9FA" }}>
                  {tabSelect === 0 && (
                    <Fragment>
                      <div
                        className="col-12 align-items-center"
                        style={{ textAlign: "center" }}
                        onClick={() => handleOpenModal()}
                      >
                        <QRcode value={scanCodeV} size={128} level="M"></QRcode>
                      </div>
                      <div
                        className="col-12 align-items-center"
                        style={{ textAlign: "center" }}
                      >
                        <p style={{ color: "#8997A6" }}>เลขอ้างอิงที่ 1</p>
                        <p>{ref1}</p>
                        <br />
                        <p style={{ color: "#8997A6" }}>เลขอ้างอิงที่ 2</p>
                        <p>{ref2}</p>
                      </div>
                    </Fragment>
                  )}
                  {tabSelect === 1 && (
                    <Fragment>
                      <div
                        className="col-12 mt-3"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          height: "auto",
                        }}
                        // onClick={() => handleOpenModal()}
                      >
                        <Barcode
                          value={scanCodeV}
                          width={1}
                          text={"BBS" + scanCode}
                          displayValue={true}
                          font="'Prompt-Regular'"
                          fontSize={14}
                          format="CODE128"
                        />
                      </div>
                      <div
                        className="col-12 align-items-center"
                        style={{ textAlign: "center" }}
                      >
                        <p style={{ color: "#8997A6" }}>เลขอ้างอิงที่ 1</p>
                        <p>{ref1}</p>
                        <br />
                        <p style={{ color: "#8997A6" }}>เลขอ้างอิงที่ 2</p>
                        <p>{ref2}</p>
                      </div>
                    </Fragment>
                  )}
                </div>

                {/* <div
                  className="col-12 mt-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    src={howtopaid}
                    style={{
                      width: "350px",
                      height: "250px",
                      border: "1px solid black",
                    }}
                  ></img>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </PathTree>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    payment_agreements: state.payment.payment_agreements,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeAndQR);
