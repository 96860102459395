import React, { useEffect, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from './../Button/Button';
import { Grid, Typography } from "@material-ui/core";

const DialogScroll = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <center><DialogTitle id="scroll-dialog-title"><div className="ask__font__title" style={{ fontFamily: "Prompt-Regular" }}>{props.title}</div></DialogTitle></center>            
            <DialogContent dividers>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={props.ref}
                    tabIndex={-1}
                >{props.text}
                </DialogContentText>                
            </DialogContent>
            <DialogActions>
                <Button btnType="close" clicked={props.onClose} children="ปิดหน้าต่าง"></Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogScroll;