import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '../../UI/Input/Input';
import '../../Apply/Apply.css'
import PathTree from '../../../components/UI/PathTree/PathTree';
import Button from './../../UI/Button/Button';
import Loading from './../../UI/Loading/Loading';
import Paginate from './../../UI/Paginate/Paginate';
import { POINT_CONVERSION_COMPRESSED } from 'constants';
import Header from './../../UI/Header/Header.js';
import mli from './../../../assets/main/main-loaninfo.jpeg';

function ManageAgreementInfo(props) {


    const [check, setCheck] = useState(0)
    useEffect(() => {
        setCheck(props.check);
    }, [props.check]);

    const handleChange = (event) => {
        props.onSelected(event.target.value);
    };

    const topic = {
        marginTop: '2rem',
    }
    const path = {
        Path: [
            {
                title: "ข้อมูลสินเชื่อของฉัน",
                pathLink: "/manageagreement"
            },
        ]
    };

    const [Info, setInfo] = React.useState(false);
    const [Status, setStatus] = React.useState(false);
    const [StatusCovid19, setStatusCovid19] = React.useState(false);

    const handleTab = (event) => {
        props.onSelected2(event);
    };

    const handleInfo = () => {
        handleTab("1");
        setInfo(true);
        setStatus(false);
        setStatusCovid19(false);
        setCheck(1);
    }

    const handleStatus = () => {
        handleTab("2");
        setInfo(false);
        setStatus(true);
        setStatusCovid19(false);
    }

    const handleStatusCovid19 = () => {
        handleTab("3");
        setInfo(false);
        setStatus(false);
        setStatusCovid19(true);
    }

    let classes1;
    let classes2;
    let classes3;
    if (Info === true) {
        classes1 = "menuclick";
        classes2 = "menunormal";
        classes3 = "menunormal";
    }
    else if (Status === true || check === 2) {
        classes2 = "menuclick";
        classes1 = "menunormal";
        classes3 = "menunormal";
    }
    else if (StatusCovid19 === true || check === 3) {
        classes3 = "menuclick";
        classes1 = "menunormal";
        classes2 = "menunormal";
    }
    else {
        classes1 = "menuclick";
        classes2 = "menunormal";
        classes3 = "menunormal";
    }

    return (
        <PathTree list={path.Path}>
            <Grid item xs={12}>
                <Header img={mli} textHeader="ข้อมูลสินเชื่อ" />
            </Grid>
            <div className="branchpanel" style={{ paddingTop: '1rem' }}>
                <Grid container spacing={3}>
                    {props.loading
                        ? <Grid item xs={12}>
                            <center><Loading /></center>
                        </Grid>
                        : <Grid container>

                            <Grid item xl={4} lg={4} md={4} xs={12} sm={4}>
                                <center><Button btnType={classes1} clicked={() => handleInfo()}>สินเชื่อของฉัน({props.count1})</Button></center>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} xs={12} sm={4}>
                                <center><Button btnType={classes2} clicked={() => handleStatus()}>ตรวจสอบสถานะ({props.count2})</Button></center>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} xs={12} sm={4}>
                                <center><Button btnType={classes3} clicked={() => handleStatusCovid19()}>ตรวจสอบสถานะ#Covid19({props.count3})</Button></center>
                            </Grid>
                            <Grid item sm={2} md={2}>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8}>
                                <center><Input elementType="search" width="80%" inside="ค้นหา" onChange={handleChange}></Input></center>
                            </Grid>
                            <Grid item sm={2} md={2}>
                            </Grid>
                            <Grid item xs={12}>
                                สัญญาทั้งหมด: {props.count}
                            </Grid>
                            {props.count
                                ? <Grid item xs={12}>
                                    <center>
                                        <Paginate
                                            onPageChange={props.onPageChange}
                                            pageCount={props.pageCount}
                                            forcePage={props.forcePage} />
                                    </center>
                                </Grid>
                                : <Grid item xs={12}><center><p>ไม่พบข้อมูล</p></center></Grid>
                            }
                            {props.children}
                            {props.count
                                ? <Grid item xs={12}>
                                    <center>
                                        <Paginate
                                            onPageChange={props.onPageChange}
                                            pageCount={props.pageCount}
                                            forcePage={props.forcePage} />
                                    </center>
                                </Grid>
                                : <Grid item xs={12}></Grid>
                            }
                        </Grid>}
                </Grid>
            </div >
        </PathTree>
    );
}

export default ManageAgreementInfo;
