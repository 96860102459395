import { put, delay } from "redux-saga/effects";
import axios from "axios";

import * as actions from "../action/index";
import { API_MYASK, API_MYASK_HEADER } from "../../shared/constant";

export function* getTrackingRequestSaga(action) {
  yield put(actions.getTrackingRequestStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");

  let url =
    API_MYASK + "/trackingRequest?user_name=" + username + "&token=" + token;

  try {
    const response = yield axios.get(url);
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let requests = [];

      for (let i in response.data.LIST_DATA) {
        let obj = response.data.LIST_DATA[i];
        let request = {
          ref_code: obj.REF_CODE,
          result_task: obj.RESULT_TASK,
          fin_type: obj.FIN_TYPE,
          agr_code: obj.AGR_CODE,
          new_tr: obj.NEW_TR,
          fin_ty: obj.FIN_TY,
          fin_amt: obj.FIN_AMT,
          down_amt: obj.DOWN_AMT,
          terms: obj.TERMS,
          body: obj.BODY,
          asset_description: obj.ASSET_DESCRIPTION,
          brand: obj.BRAND,
          reg_num: obj.REG_NUM,
          model_name: obj.MODEL_NAME,
          disburse_date: obj.DISBURSE_DATE,
        };

        requests.push(request);
      }

      yield put(actions.getTrackingRequestSuccess(requests));
    } else {
      yield put(actions.getTrackingRequestFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(actions.getTrackingRequestFailed(error.response.data.message));
    } else if (error.message != undefined) {
      yield put(actions.getTrackingRequestFailed(error.message));
    } else {
      yield put(actions.getTrackingRequestFailed("Error Something wrong."));
    }
  }
}

export function* getTrackingRequestCovidSaga(action) {
  yield put(actions.getTrackingRequestCovidStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");

  let url =
    API_MYASK +
    "/trackingRequestCovid19?user_name=" +
    username +
    "&token=" +
    token;

  try {
    const response = yield axios.get(url);
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let requests = [];

      for (let i in response.data.LIST_DATA) {
        let obj = response.data.LIST_DATA[i];
        let request = {
          fin_type: obj.FIN_TYPE,
          agr_code: obj.AGR_CODE,
          cus_code: obj.CUS_CODE,
          reg_num: obj.REG_NUM,
          reg_province: obj.REG_PROVINCE,
          asset_description: obj.ASSET_DESCRIPTION,
          brand: obj.BRAND,
          body: obj.BODY,
          covid_apply_date: obj.COVID_APPLY_DATE,
          covid_apply_msg: obj.COVID_APPLY_MSG,
          covid_approve_date: obj.COVID_APPROVE_DATE,
          covid_approve_msg: obj.COVID_APPROVE_MSG,
        };

        requests.push(request);
      }

      yield put(actions.getTrackingRequestCovidSuccess(requests));
    } else {
      yield put(actions.getTrackingRequestCovidFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(
        actions.getTrackingRequestCovidFailed(error.response.data.message)
      );
    } else if (error.message != undefined) {
      yield put(actions.getTrackingRequestCovidFailed(error.message));
    } else {
      yield put(
        actions.getTrackingRequestCovidFailed("Error Something wrong.")
      );
    }
  }
}

export function* clearStateTrackingSaga(action) {
  yield put(actions.clearTrackingState());
}
