import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import * as actions from "./../../store/action/index";
import Button from "../../components/UI/Button/Button";
import PathTree from "../../components/UI/PathTree/PathTree";
import TrackingRequests from "./TrackingRequests";
import TrackingRequestsCovid from "./TrackingRequestsCovid";

const Tracking = (props) => {
  const path = {
    Path: [
      {
        title: "จัดการสินเชื่อ",
        pathLink: "/agreements",
      },
      {
        title: "ติดตามคำร้องขอ",
        pathLink: "/tracking-request",
      },
    ],
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleTrackingRequestTab = () => {
    setTabIndex(0);
  };

  const handleTrackingRequestCovidTab = () => {
    setTabIndex(1);
  };

  useEffect(() => {
    props.getTrackingRequest();
    props.getTrackingRequestCovid();

    return () => {};
  }, []);

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  return (
    <PathTree list={path.Path}>
      {redirectpage}
      <div className="grid mt-2" style={{ width: "100%" }}>
        <div className="col-12 mb-4">
          <center>
            <p className="font-bold ask__font__title">ติดตามคำร้องขอ</p>
          </center>
        </div>
        <div
          className="grid col-12 branchpanel mx-3"
          style={{ paddingTop: "1rem" }}
        >
          <div className="col-6">
            <center>
              <Button
                btnType={tabIndex === 0 ? "menuclick" : "menunormal"}
                clicked={() => handleTrackingRequestTab()}
              >
                ตรวจสอบสถานะ
                {props.requests != null
                  ? "(" + props.requests.length + ")"
                  : ""}
              </Button>
            </center>
          </div>
          <div className="col-6">
            <center>
              <Button
                btnType={tabIndex === 1 ? "menuclick" : "menunormal"}
                clicked={() => handleTrackingRequestCovidTab()}
              >
                ตรวจสอบสถานะ#Covid19
                {props.requestsCovid != null
                  ? "(" + props.requestsCovid.length + ")"
                  : ""}
              </Button>
            </center>
          </div>

          <div className="col-12 my-4" style={{ background: "#F8F9FA" }}>
            {tabIndex === 0 && <TrackingRequests />}
            {tabIndex === 1 && <TrackingRequestsCovid />}
          </div>
        </div>
      </div>
    </PathTree>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    requests: state.tracking.requests,
    requestsCovid: state.tracking.requestsCovid,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
    getTrackingRequest: () => dispatch(actions.getTrackingRequest()),
    getTrackingRequestCovid: () => dispatch(actions.getTrackingRequestCovid()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tracking);
