import { put, delay } from "redux-saga/effects";
import axios from "axios";

import * as actions from "../action/index";
import * as actionTypes from "../action/actionTypes";
import { API_MYASK, API_MYASK_HEADER } from "../../shared/constant";

export function* getAgreementsSaga(action) {
  yield put(actions.getAgreementsStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");

  let url =
    API_MYASK +
    "/getAgreementsCustomer?user_name=" +
    username +
    "&token=" +
    token;

  try {
    const response = yield axios.get(url);
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let agreements = [];
      let runNo = response.data.DATA.RUN_NO;

      for (let i in response.data.DATA.AGREEMENTS) {
        let obj = response.data.DATA.AGREEMENTS[i];
        let agree = {
          com_code: obj.COM_CODE,
          fin_type: obj.FIN_TYPE,
          agr_code: obj.AGR_CODE,
          cus_code: obj.CUS_CODE,
          body: obj.BODY,
          brand: obj.BRAND,
          reg_num: obj.REG_NUM,
          reg_province: obj.REG_PROVINCE,
          asset_description: obj.ASSET_DESCRIPTION,
          original_amount: obj.ORIGINAL_AMOUNT,
          paid_amount: obj.PAID_AMOUNT,
          os_balance: obj.OS_BALANCE,
          terms: obj.TERMS,
          os_terms: obj.OS_TERMS,
          paid_terms: obj.PAID_TERMS,
          installment_i_vat: null /*obj.INSTALLMENT_I_VAT*/,
          x_condition: obj.X_CONDITION,
          n_due_i: null /*obj.N_DUE_I*/,
          b_type: obj.B_TYPE,
          officer: obj.OFFICER,
          overdue_amount: null,
          n_due_i_date: null /*obj.N_DUE_I_DATE*/,
          pay_data_status: actionTypes.VIEW_STATE.INITIAL,
          paymentDetailOrigin: null,
          paymentDetailState: actionTypes.VIEW_STATE.INITIAL,
          scan_code: obj.SCAN_CODE,
          ref_1: obj.REF_1,
          ref_2: obj.REF_2,
        };

        agreements.push(agree);
      }

      yield put(actions.getAgreementsSuccess(runNo, agreements));
    } else {
      yield put(actions.getAgreementsFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(actions.getAgreementsFailed(error.response.data.message));
    } else if (error.message != undefined) {
      yield put(actions.getAgreementsFailed(error.message));
    } else {
      yield put(actions.getAgreementsFailed("Error Something wrong."));
    }
  }
}

export function* insertPayDataSaga(action) {
  yield put(actions.insertPayDataStart(action.agr_code));

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");

  const req = {
    user_name: username,
    token: token,
    agr_code: action.agr_code,
    com_code: action.com_code,
    run_no: action.run_no,
  };
  let url = API_MYASK + "/insertPayData";

  try {
    const response = yield axios.post(url, req, {
      headers: API_MYASK_HEADER,
    });
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let obj = response.data.DATA;

      let paymentItems = [];
      for (let i in obj.items) {
        let itemData = obj.items[i];
        let item = {
          key: itemData.KEY,
          sub_key: itemData.SUB_KEY,
          title: itemData.TITLE,
          amount: itemData.AMOUNT.replaceAll(",", ""),
        };

        paymentItems.push(item);
      }

      let data = {
        overdue_amount: obj.overdue_balance,
        installment_i_vat: obj.installment,
        n_due_i: obj.next_due,
        n_due_i_date: obj.n_due_i,
        items: paymentItems,
      };

      yield put(actions.insertPayDataSuccess(action.agr_code, data));
    } else {
      yield put(
        actions.insertPayDataFailed(action.agr_code, response.data.message)
      );
    }
  } catch (error) {
    if (null == error.response) {
      return;
    }
    if (error.response.data.message != undefined) {
      yield put(
        actions.insertPayDataFailed(
          action.agr_code,
          error.response.data.message
        )
      );
    } else if (error.message != undefined) {
      yield put(actions.insertPayDataFailed(action.agr_code, error.message));
    } else {
      yield put(
        actions.insertPayDataFailed(action.agr_code, "Error Something wrong.")
      );
    }
  }
}

export function* getAgreementDetailSaga(action) {
  yield put(actions.getAgreementDetailStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");

  const req = {
    user_name: username,
    token: token,
    agr_code: action.agr_code,
    com_code: action.com_code,
    run_no: action.run_no,
  };
  let url = API_MYASK + "/getAgreementDetail";

  try {
    const response = yield axios.post(url, req, {
      headers: API_MYASK_HEADER,
    });
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let detail = null;

      if (response.data.DATA) {
        let obj = response.data.DATA;

        detail = {
          fin_type: obj.FIN_TYPE,
          agr_code: obj.AGR_CODE,
          cus_code: obj.CUS_CODE,
          body: obj.BODY,
          brand: obj.BRAND,
          reg_num: obj.REG_NUM,
          reg_province: obj.REG_PROVINCE,
          asset_description: obj.ASSET_DESCRIPTION,
          terms: obj.TERMS,
          os_terms: obj.OS_TERMS,
          paid_terms: obj.PAID_TERMS,
          disbursement_date: obj.DISBURSEMENT_DATE,
          first_paid_date: obj.FIRST_PAID_DATE,
          original_amount: obj.ORIGINAL_AMOUNT,
          paid_amount: obj.PAID_AMOUNT,
          os_balance: obj.OS_BALANCE,
          installment_i_vat: obj.INSTALLMENT_I_VAT,
          x_condition: obj.X_CONDITION,
          officer: obj.OFFICER,
          n_due_i: obj.N_DUE_I,
          mobile_no: obj.MOBILE_NO,
          email: obj.EMAIL,
          is_person: obj.IS_PERSON,
          address: obj.ADDRESS,
          address_m: obj.ADDRESS_M,
          address_c: obj.ADDRESS_C,
        };
      }

      yield put(actions.getAgreementDetailSuccess(detail));
    } else {
      yield put(actions.getAgreementDetailFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(actions.getAgreementDetailFailed(error.response.data.message));
    } else if (error.message != undefined) {
      yield put(actions.getAgreementDetailFailed(error.message));
    } else {
      yield put(actions.getAgreementDetailFailed("Error Something wrong."));
    }
  }
}

export function* getInsuranceAgreementSaga(action) {
  yield put(actions.getInsuranceAgreementStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");
  const agr_code = action.agr_code;

  let url =
    API_MYASK +
    "/getInsuranceAgreement?user_name=" +
    username +
    "&agr_code=" +
    agr_code +
    "&token=" +
    token;

  try {
    const response = yield axios.get(url);
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let insurances = [];

      for (let i in response.data.LIST_DATA) {
        let obj = response.data.LIST_DATA[i];
        let insurance = {
          com_code: obj.COM_CODE,
          agr_code: obj.AGR_CODE,
          ref_code: obj.REF_CODE,
          ins_type: obj.INS_TYPE,
          ins_com: obj.INS_COM,
          ins_date: obj.INS_DATE,
          ins_exp: obj.INS_EXP,
          ins_date_th: obj.INS_DATE_TH,
          ins_exp_th: obj.INS_EXP_TH,
          pol_num: obj.POL_NUM,
          paid_amount: obj.PAID_AMOUNT,
          premium: obj.PREMIUM,
          cov_amt: obj.COV_AMT,
          total_prem: obj.TOTAL_PREM,
          reg_num: obj.REG_NUM,
          year: obj.YEAR,
          brand: obj.BRAND,
          model_name: obj.MODEL_NAME,
        };

        insurances.push(insurance);
      }

      yield put(actions.getInsuranceAgreementSuccess(insurances));
    } else {
      yield put(actions.getInsuranceAgreementFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(
        actions.getInsuranceAgreementFailed(error.response.data.message)
      );
    } else if (error.message != undefined) {
      yield put(actions.getInsuranceAgreementFailed(error.message));
    } else {
      yield put(actions.getInsuranceAgreementFailed("Error Something wrong."));
    }
  }
}

export function* getPaymentAgreementSaga(action) {
  yield put(actions.getPaymentAgreementStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");
  const agr_code = action.agr_code;

  let url =
    API_MYASK +
    "/getPaymentAgreement?user_name=" +
    username +
    "&agr_code=" +
    agr_code +
    "&token=" +
    token;

  try {
    const response = yield axios.get(url);
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let payment_historys = [];

      for (let i in response.data.LIST_DATA) {
        let obj = response.data.LIST_DATA[i];
        let history = {
          last_paid_dt: obj.LAST_PAID_DT,
          last_paid_dt_th: obj.LAST_PAID_DT_TH,
          sum_paid: obj.SUM_PAID,
        };

        payment_historys.push(history);
      }

      yield put(actions.getPaymentAgreementSuccess(payment_historys));
    } else {
      yield put(actions.getPaymentAgreementFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(actions.getPaymentAgreementFailed(error.response.data.message));
    } else if (error.message != undefined) {
      yield put(actions.getPaymentAgreementFailed(error.message));
    } else {
      yield put(actions.getPaymentAgreementFailed("Error Something wrong."));
    }
  }
}

export function* getClosedAgreementDetailSaga(action) {
  yield put(actions.getClosedAgreementDetailStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");

  const req = {
    user_name: username,
    token: token,
    agr_code: action.agr_code,
    com_code: action.com_code,
    run_no: action.run_no,
  };
  let url = API_MYASK + "/closedAgreementDetail";

  try {
    const response = yield axios.post(url, req, {
      headers: API_MYASK_HEADER,
    });
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let detail = null;

      if (response.data.DATA) {
        let obj = response.data.DATA;

        let items = [];

        for (let i in obj.ITEMS) {
          let data = obj.ITEMS[i];
          let item = {
            i_order: data.I_ORDER,
            pay_flag: data.PAY_FLAG,
            description: data.DESCRIPTION,
            ori_amt: data.ORI_AMT,
            paid_amt: data.PAID_AMT,
          };

          items.push(item);
        }

        detail = {
          fin_type: obj.FIN_TYPE,
          fin_ty: obj.FIN_TY,
          b_type: obj.B_TYPE,
          agr_code: obj.AGR_CODE,
          cus_code: obj.CUS_CODE,
          new_tr: obj.NEW_TR,
          body: obj.BODY,
          brand: obj.BRAND,
          reg_num: obj.REG_NUM,
          reg_province: obj.REG_PROVINCE,
          asset_description: obj.ASSET_DESCRIPTION,
          agr_flg: obj.AGR_FLG,
          terms: obj.TERMS,
          os_terms: obj.OS_TERMS,
          paid_terms: obj.PAID_TERMS,
          original_amount: obj.ORIGINAL_AMOUNT,
          paid_amount: obj.PAID_AMOUNT,
          os_balance: obj.OS_BALANCE,
          disbursement_date: obj.DISBURSEMENT_DATE,
          installment_i_vat: obj.INSTALLMENT_I_VAT,
          n_due_i: obj.N_DUE_I,
          x_condition: obj.X_CONDITION,
          officer: obj.OFFICER,
          items: items,
          sum_paid_all: obj.SUM_PAID_ALL.replaceAll(",", ""),
        };
      }

      yield put(actions.getClosedAgreementDetailSuccess(detail));
    } else {
      yield put(actions.getClosedAgreementDetailFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(
        actions.getClosedAgreementDetailFailed(error.response.data.message)
      );
    } else if (error.message != undefined) {
      yield put(actions.getClosedAgreementDetailFailed(error.message));
    } else {
      yield put(
        actions.getClosedAgreementDetailFailed("Error Something wrong.")
      );
    }
  }
}

export function* clearStateAgreementSaga(action) {
  yield put(actions.clearAgreementState());
}

export function* getPaymentDetailOriginSaga(action) {
  yield put(actions.getPaymentDetailOriginStart(action.agr_code));

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");

  const req = {
    user_name: username,
    token: token,
    agr_code: action.agr_code,
    com_code: action.com_code,
    run_no: action.run_no,
  };

  let url = API_MYASK + "/getPaymentDetail";

  try {
    const response = yield axios.post(url, req, {
      headers: API_MYASK_HEADER,
    });
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let paymentDetailOrigin = [];
      for (let i in response.data.LIST_DATA) {
        let obj = response.data.LIST_DATA[i];
        let item = {
          i_order: obj.I_ORDER,
          pay_flag: obj.PAY_FLAG,
          description: obj.DESCRIPTION,
          ori_amt: obj.ORI_AMT,
          paid_amt: obj.PAID_AMT,
        };

        paymentDetailOrigin.push(item);
      }

      yield put(
        actions.getPaymentDetailOriginSuccess(
          action.agr_code,
          paymentDetailOrigin
        )
      );
    } else {
      yield put(actions.getPaymentDetailOriginFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(
        actions.getPaymentDetailOriginFailed(error.response.data.message)
      );
    } else if (error.message != undefined) {
      yield put(actions.getPaymentDetailOriginFailed(error.message));
    } else {
      yield put(actions.getPaymentDetailOriginFailed("Error Something wrong."));
    }
  }
}
