import React from 'react';
import Grid from '@material-ui/core/Grid';
// import Button from '../../UI/Button/Button';
import Icon from './../UI/Icon/Icon';
import './../Apply/Apply.css';
import Loading from './../UI/Loading/Loading';
// import { PATH_TEL, PATH_LINE, PATH_MAIL } from './../../../shared/constant';
// import * as actions from "./../../../store/action/index";
import { connect } from "react-redux";

const Complaint = (props) => {
    const topic = {
        marginTop: '0.5rem',
        marginBottom: '2rem',
    }

    return (
        <div className="panel">
            <form onSubmit={props.submitHandler}>
                <Grid container spacing={3}>
                    {/* <Grid item xs={2} sm={2} md={1}>
                    <Icon icon="facebook" target="_blank" path="https://www.facebook.com/ask.shb/" />
                </Grid> */}
                    <Grid item xs={12}>
                        <p className="text-bold text-center ask__font__title" style={topic}>{props.title}</p>
                    </Grid>
                    {props.children}

                    {props.loading
                        ? <Grid item xs={12}>
                            <center><Loading /></center>
                        </Grid>
                        :
                        <Grid
                            item
                            xs={12}
                        >
                            <button
                                className="btn-submit btn_apply_submit"
                            >ส่งข้อมูล</button>
                        </Grid>}
                </Grid>
            </form>
        </div >
    );
}
const mapStateToProps = state => {
    return {
        lineFriend: state.auth.lineFriend,
        telNo: state.auth.telNo,
        mailTo: state.auth.mailTo,
    };
};

export default connect(
    mapStateToProps,
    null)(Complaint);
