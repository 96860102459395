import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '../UI/Paper/Paper';
import Link from '@material-ui/core/Link';
import Button from './../UI/Button/Button';
import Input from './../UI/Input/Input';
import Modal from './../UI/Modal/Modal';
import '../Apply/Apply.css';

const paymentlist = (props) => {

    const text = 'ค่าเบี้ย' + props.ins_type;

    const amount = {
        paddingTop: '2.35rem',
        color: 'red',
        marginTop: '-3rem',
    }

    const total = parseFloat(props.amount) + parseFloat(props.ins1) + parseFloat(props.ins2) + parseFloat(props.ins3);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const onChangeHandler = (val, id) => {
        return;
    }
    return (
        <div>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                {props.type == "agr"
                    ? <Paper padding="1.5rem" align="left" border="1px solid" width="90%" borderColor="#909090" borderRadius="0px"
                        children={
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className="text-bold text-left"><u>เลขที่สัญญา {props.number}</u></p>
                                        </Grid>
                                        {props.carcountry || props.carnumber || props.carbrand
                                            ? <Grid item xs={12}>
                                                <p className="text-bold text-left">{props.carbrand} {props.carnumber} {props.carcountry}</p>
                                            </Grid>
                                            : <Grid item xs={12}>
                                                <p className="text-bold text-left"><br /></p>
                                            </Grid>
                                        }
                                        <Grid item xs={6}>
                                            <Input elementType="checkbox" text="ค่างวด" fontSize="1.4rem" marginTop="-3rem" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(props.amount * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                        </Grid>
                                        {/* <Grid item xs={6}>
                                            <Input elementType="checkbox" text="ค่าเบี้ยปรับล่าช้าสะสม" fontSize="1.4rem" marginTop="-3rem" />
                                        </Grid>        
                                        <Grid item xs={6}>
                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(2000 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                        </Grid> */}
                                        {props.ins1 > 0
                                            ? <Grid item xs={6}>
                                                <Input elementType="checkbox" text="ค่าเบี้ยประกันรถยนต์ ภาคสมัครใจ" fontSize="1.4rem" marginTop="-3rem" />
                                            </Grid>
                                            : <div></div>
                                        }
                                        {props.ins1 > 0
                                            ? <Grid item xs={6}>
                                                <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(props.ins1 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                            </Grid>
                                            : <div></div>
                                        }
                                        {props.ins2 > 0
                                            ? <Grid item xs={6}>
                                                <Input elementType="checkbox" text="ค่าเบี้ยประกันรถยนต์ ภาคบังคับ" fontSize="1.4rem" marginTop="-3rem" />
                                            </Grid>
                                            : <div></div>
                                        }
                                        {props.ins2 > 0
                                            ? <Grid item xs={6}>
                                                <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(props.ins2 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                            </Grid>
                                            : <div></div>
                                        }
                                        {props.ins3 > 0
                                            ? <Grid item xs={6}>
                                                <Input elementType="checkbox" text="ค่าเบี้ยประกันชีวิต" fontSize="1.4rem" marginTop="-3rem" />
                                            </Grid>
                                            : <div></div>
                                        }
                                        {props.ins3 > 0
                                            ? <Grid item xs={6}>
                                                <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(props.ins3 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                            </Grid>
                                            : <div></div>
                                        }
                                        <Grid item xs={6}>
                                            <p className="text-bold">รวม</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-right text-bold" style={{ color: 'red' }} ><u>{numberWithCommas(parseFloat(Math.round(total * 100) / 100).toFixed(2))}</u> <span style={{ color: 'black' }}>บาท</span></p>
                                        </Grid>
                                        <Grid item xs={false} sm={false} md={3}>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <center><Button btnType="normal" width="70%" children="แก้ไขยอดชำระ" borderRadius="0px" clicked={props.handleOpen}></Button></center>
                                        </Grid>
                                        <Grid item xs={false} sm={false} md={3}>
                                        </Grid>
                                        <Modal open={props.open} close={props.close} top="50%" left="50%" width="100%" transform="translate(-50%, -50%)" children={
                                            <div style={{ fontSize: '1.6rem' }}>
                                                <center>
                                                    <Grid item xs={false} sm={false} md={3}>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6}>
                                                        <Paper padding="1.5rem" align="left" width="90%" borderRadius="0px"
                                                            children={
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <p className="text-bold text-left"><u>เลขที่สัญญา {props.number}</u></p>
                                                                    </Grid>
                                                                    {props.carcountry || props.carnumber || props.carbrand
                                                                        ? <Grid item xs={12}>
                                                                            <p className="text-bold text-left">{props.carbrand} {props.carnumber} {props.carcountry}</p>
                                                                        </Grid>
                                                                        : <Grid item xs={12}>
                                                                            <p className="text-bold text-left"><br /></p>
                                                                        </Grid>
                                                                    }
                                                                    <Grid item xs={6}>
                                                                        <Input elementType="checkbox" text="ค่างวด" fontSize="1.4rem" marginTop="-3rem" check={true} disabled={true} />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <span style={{ color: 'black', float: 'right' }}> บาท</span><Input elementType="input" type="cash" value={props.amount} fontSize="1.6rem" onValueChange={event => onChangeHandler(event.target.value, "amount")}
                                                                            styleInput={{ width: '60%', float: 'right', marginTop: '-2rem', color: 'red', borderRadius: '0px', border: '0.5px solid #989898', fontFamily: "'Prompt-Regular'" }} />
                                                                        {/* <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(2000 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p> */}
                                                                    </Grid>
                                                                    {/* <Grid item xs={6}>
                                                                        <Input elementType="checkbox" text="ค่าเบี้ยปรับล่าช้าสะสม" fontSize="1.4rem" marginTop="-3rem" check={true} disabled={true} />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(2000 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                                                    </Grid> */}
                                                                    {props.ins1 > 0
                                                                        ? <Grid item xs={6}>
                                                                            <Input elementType="checkbox" text="ค่าเบี้ยประกันรถยนต์ ภาคสมัครใจ" fontSize="1.4rem" marginTop="-3rem" check={true} disabled={true} />
                                                                        </Grid>
                                                                        : <div></div>
                                                                    }
                                                                    {props.ins1 > 0
                                                                        ? <Grid item xs={6}>
                                                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(props.ins1 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                                                        </Grid>
                                                                        : <div></div>
                                                                    }
                                                                    {props.ins2 > 0
                                                                        ? <Grid item xs={6}>
                                                                            <Input elementType="checkbox" text="ค่าเบี้ยประกันรถยนต์ ภาคบังคับ" fontSize="1.4rem" marginTop="-3rem" check={true} disabled={true} />
                                                                        </Grid>
                                                                        : <div></div>
                                                                    }
                                                                    {props.ins2 > 0
                                                                        ? <Grid item xs={6}>
                                                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(props.ins2 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                                                        </Grid>
                                                                        : <div></div>
                                                                    }
                                                                    {props.ins3 > 0
                                                                        ? <Grid item xs={6}>
                                                                            <Input elementType="checkbox" text="ค่าเบี้ยประกันชีวิต" fontSize="1.4rem" marginTop="-3rem" check={true} disabled={true} />
                                                                        </Grid>
                                                                        : <div></div>
                                                                    }
                                                                    {props.ins3 > 0
                                                                        ? <Grid item xs={6}>
                                                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(props.ins3 * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                                                        </Grid>
                                                                        : <div></div>
                                                                    }
                                                                    <Grid item xs={6}>
                                                                        <p className="text-bold">รวม</p>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <p className="text-right text-bold" style={{ color: 'red' }} ><u>{numberWithCommas(parseFloat(Math.round(total * 100) / 100).toFixed(2))}</u> <span style={{ color: 'black' }}>บาท</span></p>
                                                                    </Grid>
                                                                    <Grid item xs={false} sm={false} md={3}>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={6}>
                                                                        <center><Button btnType="submit" width="70%" children="ยืนยันยอดชำระ" borderRadius="0px" clicked={props.handleOpen}></Button></center>
                                                                    </Grid>
                                                                    <Grid item xs={false} sm={false} md={3}>
                                                                    </Grid>
                                                                </Grid>
                                                            }>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={false} sm={false} md={3}>
                                                    </Grid>

                                                </center>
                                            </div>
                                        }>
                                        </Modal>
                                    </Grid >
                                </Grid>
                            </Grid>
                        } />
                    : <Paper padding="1.5rem" align="left" border="1px solid" width="90%" borderColor="#909090" borderRadius="0px"
                        children={
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <p className="text-bold text-left"><u>หมายเลขกรมธรรม์ {props.pol_num}</u></p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p className="text-left">{props.ins_type}</p>
                                        </Grid>
                                        <br />
                                        <br />
                                        <Grid item xs={6}>
                                            <Input elementType="checkbox" text={text} fontSize="1.4rem" marginTop="-3rem" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="text-right" style={amount} >{numberWithCommas(parseFloat(Math.round(props.total_prem * 100) / 100).toFixed(2))} <span style={{ color: 'black' }}>บาท</span></p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        } />
                }
            </Grid>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <br />
        </div>
    );
}

export default paymentlist;
