import React from 'react';
import Grid from '@material-ui/core/Grid';
// import Description from '../description';
import '../../Apply/Apply.css'

const ManageAgreementCloseAcc = (props) => {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <center>
                    {props.children}
                </center>
            </Grid>
        </Grid>
    );
}

export default ManageAgreementCloseAcc;
