import React, { useEffect } from "react";
import ManageAgreementPaymentList from "../../components/ManageAgreement/ManageAgreementPayment/ManageAgreementPaymentList";
import PaymentList from "../../components/ManageAgreement/paymentlist";
import PathTree from "../../components/UI/PathTree/PathTree";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { API_MYASK } from "./../../shared/constant";

import * as actions from "./../../store/action/index";

const ManageAgreementControlsPaymentList = (props) => {
  let click = "";
  const [agrtmp] = React.useState(
    props.location.agrtmp || sessionStorage.getItem("agrtmp") || ""
  );
  const [fin_type] = React.useState(
    props.location.fin_type || sessionStorage.getItem("fin_type") || ""
  );
  const [agr_code] = React.useState(
    props.location.agr_code || sessionStorage.getItem("agr_code") || ""
  );
  const [body] = React.useState(
    props.location.body || sessionStorage.getItem("body") || ""
  );
  const [brand] = React.useState(
    props.location.brand || sessionStorage.getItem("brand") || ""
  );
  const [reg_num] = React.useState(
    props.location.reg_num || sessionStorage.getItem("reg_num") || ""
  );
  const [reg_province] = React.useState(
    props.location.reg_province || sessionStorage.getItem("reg_province") || ""
  );
  const [asset_description] = React.useState(
    props.location.asset_description ||
      sessionStorage.getItem("asset_description") ||
      ""
  );
  const [install] = React.useState(
    props.location.install || sessionStorage.getItem("install") || ""
  );
  const [os_balance] = React.useState(
    props.location.os_balance || sessionStorage.getItem("os_balance") || ""
  );
  const [n_due_i] = React.useState(
    props.location.n_due_i || sessionStorage.getItem("n_due_i") || ""
  );
  const [flat_rate] = React.useState(
    props.location.flat_rate || sessionStorage.getItem("flat_rate") || ""
  );
  const [disbursement_date] = React.useState(
    props.location.disbursement_date ||
      sessionStorage.getItem("disbursement_date") ||
      ""
  );
  const [ar] = React.useState(
    props.location.ar || sessionStorage.getItem("ar") || ""
  );
  const [terms] = React.useState(
    props.location.terms || sessionStorage.getItem("terms") || ""
  );
  const [scan_code] = React.useState(
    props.location.scan_code || sessionStorage.getItem("scan_code") || ""
  );
  const [user_name] = React.useState(
    sessionStorage.getItem("userName") || props.userName
  );
  const [token] = React.useState(
    sessionStorage.getItem("token") || props.token
  );
  const [manyagr] = React.useState(
    props.location.manyagr || sessionStorage.getItem("manyagr") || ""
  );
  const [type] = React.useState(
    props.location.type || sessionStorage.getItem("type") || ""
  );

  const [paymentlist, setPaymentlist] = React.useState([]);

  const [totalamt, setTotalamt] = React.useState(0);

  let redirectpage;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("token");
    props.onLogout();
  }

  const onSelectPayment = () => {
    props.history.push({
      pathname: "/manageagreement-payment",
      install: install,
    });
  };

  window.scrollTo(0, 0);

  useEffect(() => {
    sessionStorage.setItem("agrtmp", agrtmp);
    sessionStorage.setItem("fin_type", fin_type);
    sessionStorage.setItem("agr_code", agr_code);
    sessionStorage.setItem("body", body);
    sessionStorage.setItem("brand", brand);
    sessionStorage.setItem("reg_num", reg_num);
    sessionStorage.setItem("reg_province", reg_province);
    sessionStorage.setItem("asset_description", asset_description);
    sessionStorage.setItem("install", install);
    sessionStorage.setItem("os_balance", os_balance);
    sessionStorage.setItem("n_due_i", n_due_i);
    sessionStorage.setItem("flat_rate", flat_rate);
    sessionStorage.setItem("disbursement_date", disbursement_date);
    sessionStorage.setItem("ar", ar);
    sessionStorage.setItem("terms", terms);
    sessionStorage.setItem("scan_code", scan_code);
    sessionStorage.setItem("type", type);
    sessionStorage.setItem("manyagr", manyagr);
  }, [agr_code]);

  useEffect(() => {
    let form = [];
    let total = 0;
    let mount = true;
    let ins1 = 0;
    let ins2 = 0;
    let ins3 = 0;
    if (manyagr == "Y") {
      axios
        .get(
          API_MYASK +
            "/ManageAgreement?" +
            "user_name=" +
            user_name +
            "&token=" +
            token
        )
        .then((res) => {
          console.log(res);
          if (res.data.CODE === "200") {
            for (let i in res.data.LIST_DATA) {
              total =
                parseFloat(total) + parseFloat(res.data.LIST_DATA[i].INSTALL);
              axios
                .get(
                  API_MYASK +
                    "/Insurance?" +
                    "agr_code=" +
                    "'" +
                    res.data.LIST_DATA[i].AGR_CODE +
                    "'"
                )
                .then((res2) => {
                  ins1 = 0;
                  ins2 = 0;
                  ins3 = 0;
                  for (let j in res2.data.LIST_DATA) {
                    total =
                      parseFloat(total) +
                      parseFloat(res2.data.LIST_DATA[j].TOTAL_PREM);
                    if (
                      res2.data.LIST_DATA[j].INS_TYPE ==
                      "ประกันภัยรถยนต์-ภาคสมัครใจ"
                    ) {
                      ins1 = res2.data.LIST_DATA[j].TOTAL_PREM;
                    } else if (
                      res2.data.LIST_DATA[j].INS_TYPE ==
                      "ประกันภัยรถยนต์-ภาคบังคับ"
                    ) {
                      ins2 = res2.data.LIST_DATA[j].TOTAL_PREM;
                    } else if (
                      res2.data.LIST_DATA[j].INS_TYPE == "ประกันชีวิต"
                    ) {
                      ins3 = res2.data.LIST_DATA[j].TOTAL_PREM;
                    }
                  }
                  form.push({
                    agr_code: res.data.LIST_DATA[i].AGR_CODE,
                    install: res.data.LIST_DATA[i].INSTALL,
                    brand: res.data.LIST_DATA[i].BRAND,
                    reg_num: res.data.LIST_DATA[i].REG_NUM,
                    reg_province: res.data.LIST_DATA[i].REG_PROVINCE,
                    ins1: ins1,
                    ins2: ins2,
                    ins3: ins3,
                    type: "agr",
                  });
                  if (mount) {
                    setPaymentlist(form);
                    setTotalamt(total);
                  }
                });
            }
          }
        });
    } else {
      if (type === "agr") {
        total = parseFloat(install);
        axios
          .get(API_MYASK + "/Insurance?" + "agr_code=" + "'" + agr_code + "'")
          .then((res) => {
            let form = [];
            console.log(res);
            if (res.data.CODE === "200") {
              for (let i in res.data.LIST_DATA) {
                total =
                  parseFloat(total) +
                  parseFloat(res.data.LIST_DATA[i].TOTAL_PREM);
                if (
                  res.data.LIST_DATA[i].INS_TYPE == "ประกันภัยรถยนต์-ภาคสมัครใจ"
                ) {
                  ins1 = res.data.LIST_DATA[i].TOTAL_PREM;
                } else if (
                  res.data.LIST_DATA[i].INS_TYPE == "ประกันภัยรถยนต์-ภาคบังคับ"
                ) {
                  ins2 = res.data.LIST_DATA[i].TOTAL_PREM;
                } else if (res.data.LIST_DATA[i].INS_TYPE == "ประกันชีวิต") {
                  ins3 = res.data.LIST_DATA[i].TOTAL_PREM;
                }
              }
            }
            form.push({
              agr_code: agr_code,
              install: install,
              ins1: ins1,
              ins2: ins2,
              ins3: ins3,
              type: "agr",
            });
            if (mount) {
              setPaymentlist(form);
              setTotalamt(total);
              console.log(form);
            }
          });
      } else if (type === "ins") {
        let agr;
        if (agrtmp != "") {
          agr = agrtmp;
        } else {
          if (agr_code != "") {
            agr = "'" + agr_code + "'";
          }
        }
        axios.get(API_MYASK + "/Insurance?" + "agr_code=" + agr).then((res) => {
          let form = [];
          console.log(res);
          if (res.data.CODE === "200") {
            for (let i in res.data.LIST_DATA) {
              total =
                parseFloat(total) +
                parseFloat(res.data.LIST_DATA[i].TOTAL_PREM);
              form.push({
                agr_code: res.data.LIST_DATA[i].AGR_CODE,
                ins_type: res.data.LIST_DATA[i].INS_TYPE,
                pol_num: res.data.LIST_DATA[i].POL_NUM,
                total_prem: res.data.LIST_DATA[i].TOTAL_PREM,
                type: "ins",
              });
            }
          }
          if (mount) {
            setPaymentlist(form);
            setTotalamt(total);

            console.log(form);
          }
        });
      }
    }

    return () => {
      mount = false;
    };
  }, []);

  const path = {
    Path: [
      {
        title: "ข้อมูลของฉัน",
        pathLink: "/information",
      },
      {
        title: "รายการชำระเงิน",
        pathLink: "/manageagreement-paymentlist",
      },
    ],
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const formElementsArray = [];
  for (let key in paymentlist) {
    formElementsArray.push({
      id: key,
      config: paymentlist[key],
    });
  }

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  return (
    <PathTree list={path.Path}>
      {redirectpage}
      <div className="ask__font__main">
        <ManageAgreementPaymentList
          children={formElementsArray.map((formElement) => (
            <PaymentList
              key={formElement.id}
              type={formElement.config.type}
              number={formElement.config.agr_code}
              carbrand={formElement.config.brand}
              carnumber={formElement.config.reg_num}
              carcountry={formElement.config.reg_province}
              amount={formElement.config.install}
              ins_type={formElement.config.ins_type}
              pol_num={formElement.config.pol_num}
              total_prem={formElement.config.total_prem}
              ins1={formElement.config.ins1}
              ins2={formElement.config.ins2}
              ins3={formElement.config.ins3}
              open={openEdit}
              handleOpen={() => handleOpenEdit()}
              close={() => handleCloseEdit()}
            />
          ))}
          onSelectPayment={() => onSelectPayment()}
          amount={totalamt}
        ></ManageAgreementPaymentList>
      </div>
    </PathTree>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAgreementControlsPaymentList);
