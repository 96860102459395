import React from "react";
import { connect } from "react-redux";

import * as actions from "../../store/action/index";
import * as actionTypes from "../../store/action/actionTypes";
import Loading from "./../../components/UI/Loading/Loading";
import TrackingRequestCovid from "../../components/TrackingCovid19/TrackingRequestCovid";

const TrackingRequestsCovid = (props) => {
  return (
    <div>
      {props.requestsCovid != null && props.requestsCovid.length === 0 && (
        <center>
          <p className="ask__font__main">ไม่พบข้อมูล</p>
        </center>
      )}
      {props.requestsCovidState === actionTypes.VIEW_STATE.LOADING && (
        <center>
          <Loading />
        </center>
      )}

      <div className="grid">
        {props.requestsCovid != null &&
          props.requestsCovid.length > 0 &&
          props.requestsCovid.map((request, index) => (
            <div
              className="col-12 sm:col-12 md:col-6 md:col-offset-3 lg:col-6 lg:col-offset-3 
              xl:col-6 xl:col-offset-3 "
              key={"div_reqc_" + index}
            >
              <TrackingRequestCovid request={request} key={"reqc" + index} />
            </div>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    requestsCovid: state.tracking.requestsCovid,
    requestsCovidState: state.tracking.requestsCovidState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackingRequestsCovid);
