import React, { useState, useEffect } from "react";
import "./forgot.css";
import Input from "./../../../components/UI/Input/Input";
import {
  updateObject,
  checkValidity,
  checkError,
  randNumber,
  randString,
  logError,
} from "./../../../shared/utility";
import axios from "axios";
import { API_MYASK, API_MYASK_HEADER, API_OTP } from "../../../shared/constant";
// import { Redirect } from "react-router-dom";
import ModalError from "./../../../components/UI/Error/Error";

const ForgotUsername = (props) => {
  const [resetPassForm, setResetPassForm] = useState({
    form: {
      mobileNo: {
        order: "0",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        maxLength: 10, //ใช้ของ input html
        validation: {
          required: true,
          isNumeric: true,
          minLength: 10, //ใช้ของ utility เพราะต้องการดัก spacebar
          maxLength: 10,
        },
        mode: "numeric",
        valid: false,
        touched: false,
        textInvalid: "",
        label: "เบอร์โทรศัพท์",
        labelError: "เบอร์โทรศัพท์",
      },
      username: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "",
        },
        value: "",
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        textInvalid: "",
        label: "",
        labelError: "",
      },
    },
    formIsValid: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeTel = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    if (isValid || val === "") {
      return val;
    } else {
      return val.substring(val.length - 1, 0);
    }
  };

  const onChangeHandler = (val, id) => {
    val = onChangeTel(val);
    const validate = checkValidity(val, resetPassForm.form[id].validation);
    let text = checkError(
      validate.code,
      resetPassForm.form[id].labelError,
      resetPassForm.form[id].validation
    );
    let updatedFormElement;
    updatedFormElement = updateObject(resetPassForm.form[id], {
      value: val,
      valid: validate.isValid,
      textInvalid: text,
      touched: true,
    });
    let updatedForm = updateObject(resetPassForm.form, {
      [id]: updatedFormElement,
    });
    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    const updatedState = updateObject(resetPassForm, {
      form: updatedForm,
      formIsValid: formIsValid,
    });
    setResetPassForm(updatedState);
  };

  const checkedValidate = () => {
    if (!resetPassForm.formIsValid) {
      let updatedForm;
      let arrayObj = [];
      let updatedFormElement;
      let text;
      for (let field in resetPassForm.form) {
        const validate = checkValidity(
          resetPassForm.form[field].value,
          resetPassForm.form[field].validation
        );
        text = checkError(
          validate.code,
          resetPassForm.form[field].labelError,
          resetPassForm.form[field].validation
        );
        updatedFormElement = updateObject(resetPassForm.form[field], {
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });

        arrayObj.push(updatedFormElement);
      }
      let arrayForgotUserName = [];
      for (let i in arrayObj) {
        if (arrayObj[i].order === "0") {
          arrayForgotUserName[0] = arrayObj[i];
        }
      }

      updatedForm = updateObject(resetPassForm.form, {
        mobileNo: arrayForgotUserName[0],
      });
      let updatedState = updateObject(resetPassForm, {
        form: updatedForm,
      });
      setResetPassForm(updatedState);
    } else {
      resetPass();
    }
  };

  const [reren, setReren] = useState("");

  const resetPass = () => {
    //--- 7/1/2020 ถ้าจะเอา otp กลับมา ให้ ลบ code นี้ออก ---/
    /*
        axios.get(API_MYASK + "/ForgetUsername?tel=" + resetPassForm.form.mobileNo.value)
             .then(res => {
                if (res.data.CODE === '200') {
                    props.history.push({
                        pathname: '/success',
                        title: "ชื่อผู้ใช้งานของคุณคือ",
                        text: res.data.LIST_DATA[0].USER_NAME,
                        path: "/login"
                    });
                } else {
                    const updatedState =
                        updateObject(resetPassForm,
                            {
                                form: updateObject(resetPassForm.form,
                                    {
                                        mobileNo: updateObject(resetPassForm.form.mobileNo, {
                                            textInvalid: 'ไม่พบ เบอร์โทรศัพท์',
                                            touched: true,
                                            // valid: false
                                        })
                                    }
                                )
                            }
                        )
                    setResetPassForm(updatedState);
                }
        })
        */
    //------ End ถ้าจะเอา otp กลับมา ให้ ลบ code นี้ออก -------//

    //------- 7/1/2020 ปิด otp
    //------- ถ้าจะเปิด แค่เอาคอมเม้นด้านล่างออก แล้วคอมเม้นด้านบน

    axios
      .get(
        API_MYASK + "/ForgetUsername?tel=" + resetPassForm.form.mobileNo.value
      )
      .then((res) => {
        if (res.data.CODE === "200") {
          let username = res.data.LIST_DATA[0].USER_NAME;
          const refOtp = randString(6);
          const passOtp = randNumber(6);
          axios
            .post(API_OTP, {
              mobile: resetPassForm.form.mobileNo.value,
              refOtp: refOtp,
              passOtp: passOtp,
            })
            .then((res) => {
              // For Test
              const otpData = {
                refOtp: refOtp,
                passOtp: passOtp,
                mobile: resetPassForm.form.mobileNo.value,
              };
              axios
                .post(API_MYASK + "/SaveOTP", otpData, {
                  headers: API_MYASK_HEADER,
                })
                .then((res) => {
                  if (res.data.CODE === "200") {
                    props.history.push({
                      pathname: "/otp",
                      mobile: "mobile",
                      refOtp: refOtp,
                      otpPass: passOtp,
                      username: username,
                      mobileNo: resetPassForm.form.mobileNo.value,
                      status: "forgot",
                    });
                  } else {
                    logError(
                      "forgot username(1)" +
                        res.data.MSG +
                        " " +
                        res.data.CODE +
                        " " +
                        resetPassForm.form.mobileNo.value
                    );
                  }
                })
                .catch((err) => {
                  err =
                    "forgot username(2)" +
                    err +
                    "," +
                    resetPassForm.form.mobileNo.value +
                    "," +
                    refOtp +
                    "," +
                    passOtp +
                    ";";
                  logError(err);
                  setReren(
                    <ModalError
                      title="เกิดข้อผิดพลาด"
                      text="กรุณาทำรายการใหม่ในภายหลัง"
                      color="red"
                      border="0.1rem solid red"
                      link="/forgotusername"
                    ></ModalError>
                  );
                });
            })
            .catch((err) => {
              err =
                "forgot username(3)," +
                err +
                "," +
                resetPassForm.form.mobileNo.value +
                "," +
                refOtp +
                "," +
                passOtp +
                ";";
              logError(err);
              setReren(
                <ModalError
                  title="เกิดข้อผิดพลาด"
                  text="กรุณาทำรายการใหม่ในภายหลัง"
                  color="red"
                  border="0.1rem solid red"
                  link="/forgotusername"
                ></ModalError>
              );
            });
        } else {
          const updatedState = updateObject(resetPassForm, {
            form: updateObject(resetPassForm.form, {
              mobileNo: updateObject(resetPassForm.form.mobileNo, {
                textInvalid: "ไม่พบ เบอร์โทรศัพท์",
                touched: true,
                // valid: false
              }),
            }),
          });
          setResetPassForm(updatedState);
        }
      });

    //------ End ปิด Otp ----//
  };

  const submitHandler = (event) => {
    window.scrollTo(0, 0);

    event.preventDefault();
    checkedValidate();
  };
  return (
    <div className="forgot__container">
      {reren}
      <h4 className="forgot__header ask__font__title">ลืมชื่อผู้ใช้งาน</h4>
      <form onSubmit={submitHandler}>
        <div className="forgot__body ask__font__main">
          <div className="forgot__body__firstname">
            {/* <label>เบอร์โทรศัพท์ผู้ขอสินเชื่อที่เคยลงทะเบียนไว้ *</label> */}
            <Input
              className="form-control"
              elementType="input"
              type="number"
              max={resetPassForm.form.mobileNo.maxLength}
              min={resetPassForm.form.mobileNo.minLength}
              mode={resetPassForm.form.mobileNo.mode}
              value={resetPassForm.form.mobileNo.value}
              invalid={!resetPassForm.form.mobileNo.valid}
              touched={resetPassForm.form.mobileNo.touched}
              shouldValidate={resetPassForm.form.mobileNo.validation.required}
              textInvalid={resetPassForm.form.mobileNo.textInvalid}
              changed={(event) =>
                onChangeHandler(event.target.value, "mobileNo")
              }
              label={resetPassForm.form.mobileNo.label}
              styleLabel={{ width: "100%", padding: "0px" }}
              lablePos={"top"}
            />
          </div>
          <div className="forgot__body_submit ask__font__main">
            <button
              className="btn-submit"
              style={{ fontFamily: "Prompt-Regular" }}
            >
              ยืนยัน
            </button>
          </div>
          <div className="forgot__body__firstname">
            <label>
              กรณีลืมเบอร์โทรศัพท์ที่เคยลงทะเบียนไว้ กรุณาติดต่อที่เบอร์
              02-6796226, 02-6796262 ต่อ 182, 185
            </label>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotUsername;
