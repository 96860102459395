import React, { useState, useEffect } from "react";
import "./signin.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_MYASK, API_MYASK_HEADER } from "../../../shared/constant";
import { connect } from "react-redux";
import * as actions from "./../../../store/action/index";
// import PathTree from "./../../../components/UI/PathTree/PathTree";
import {
  updateObject,
  checkValidity,
  checkError,
} from "../../../../src/shared/utility.js";
import Input from "./../../../components/UI/Input/Input";
import Loading from "./../../../components/UI/Loading/Loading";
// import { Button } from "@material-ui/core";

const Signin = (props) => {
  const [signin, setSignin] = useState({
    form: {
      user: {
        order: "0",
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        textInvalid: "กรุณาระบุ ชื่อผู้ใช้งาน",
        label: "ชื่อผู้ใช้งาน",
      },
      password: {
        order: "1",
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        textInvalid: "กรุณาระบุ รหัสผ่าน",
        label: "รหัสผ่าน",
      },
    },
    errors: {
      user: "",
      password: "",
    },
    isAuthenticated: false,
    isSignup: false,
    formIsValid: false,
    textError: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.lineId !== null && props.token !== null) {
      const query = new URLSearchParams(props.location.search);
      const src = {};
      for (let param of query.entries()) {
        src[param[0]] = param[1];
      }
      if (Object.keys(src).length > 0) {
        // sessionStorage.setItem('token','eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJpZCIsImlhdCI6MTU2NzU2NDQwMX0.xp2Ae4J19uWPvCG_lF5efEvfK5ROLGS94Xl06Kxnzcc');
        // sessionStorage.setItem('userName','test');
        const target = src.target === "status" ? true : false;
        props.history.push({
          pathname: "/agreements",
          isDefault: target,
        });
      }
    }

    SetLoading(false);
  }, []);

  const [loading, SetLoading] = useState(true);

  const inputChangedHandler = (event, field) => {
    const validate = checkValidity(
      event.target.value,
      signin.form[field].validation
    );
    const text = checkError(
      validate.code,
      signin.form[field].label,
      signin.form[field].validation
    );

    const updatedFormElement = updateObject(signin.form[field], {
      value: event.target.value,
      valid: validate.isValid,
      textInvalid: text,
      touched: true,
    });

    let updatedForm = updateObject(signin.form, {
      [field]: updatedFormElement,
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    const updatedState = updateObject(signin, {
      form: updatedForm,
      formIsValid: formIsValid,
      textError: "",
    });
    setSignin(updatedState);
  };

  const checkedValidate = () => {
    if (!signin.formIsValid) {
      let updatedForm;
      let arrayObj = [];
      for (let field in signin.form) {
        const validate = checkValidity(
          signin.form[field].value,
          signin.form[field].validation
        );
        const text = checkError(
          validate.code,
          signin.form[field].label,
          signin.form[field].validation
        );
        let updatedFormElement = updateObject(signin.form[field], {
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
        arrayObj.push(updatedFormElement);
      }

      let arraySignin = [];
      for (let i in arrayObj) {
        if (arrayObj[i].order === "0") {
          arraySignin[0] = arrayObj[i];
        } else if (arrayObj[i].order === "1") {
          arraySignin[1] = arrayObj[i];
        }
      }
      updatedForm = updateObject(signin.form, {
        user: arraySignin[0],
        password: arraySignin[1],
      });

      let updatedState = updateObject(signin, {
        form: updatedForm,
      });

      setSignin(updatedState);
    } else {
      const data_login = {
        username: signin.form.user.value,
        password: signin.form.password.value,
        lineid: props.lineId,
      };

      axios
        .post(API_MYASK + "/Login", data_login, {
          headers: API_MYASK_HEADER,
        })
        .then((res) => {
          if (res.data.CODE === "200") {
            props.onAuth(res.data.MSG, signin.form.user.value);
            // this.props.onSetCustInfo(res.data.DATA.FirstName, res.data.DATA.LastName);
            axios
              .get(
                API_MYASK +
                  "/GetConfig?user_name=" +
                  signin.form.user.value +
                  "&token=" +
                  props.token
              )
              .then((res) => {
                props.onSetLineToken(res.data.DATA.LINE_KEY);
              });
            props.history.push({
              pathname: "/agreements",
            });
          } else {
            const updatedState = updateObject(signin, {
              // form: updateObject(signin.form, {
              textError: res.data.MSG,
              // })
            });
            setSignin(updatedState);
          }
        });
    }
  };

  const submitHandler = (event) => {
    window.scroll(0, 0);
    event.preventDefault();
    checkedValidate();
    // -------------------------------------- //
  };

  const [type, setType] = useState("password");
  const [showPW, setShowPW] = useState(false);
  const onShowPW = () => {
    if (type === "password") {
      setType("input");
      setShowPW(true);
    } else {
      setType("password");
      setShowPW(false);
    }
  };

  if (loading) {
    return (
      <div style={style}>
        <Loading />
      </div>
    );
  }

  return (
    <div className="login__container">
      <h4 className="login__header ask__font__title">เข้าสู่ระบบ</h4>
      <form onSubmit={submitHandler}>
        <div className="login__body ask__font__main">
          <div className="login__body_username">
            {signin.textError ? (
              <label className="text__invalid">{signin.textError}</label>
            ) : null}
            {/* <label>ชื่อผู้ใช้งาน</label> */}
            <Input
              className="form-control"
              elementType="input"
              type="text"
              value={signin.form.user.value}
              invalid={!signin.form.user.valid}
              touched={signin.form.user.touched}
              shouldValidate={signin.form.user.validation.required}
              textInvalid={signin.form.user.textInvalid}
              changed={(event) => inputChangedHandler(event, "user")}
              labelPos={"top"}
              label={signin.form.user.label}
              styleLabel={{ width: "100%", padding: "0px" }}
            />
          </div>
          <div className="login__body_password">
            {/* <label>รหัสผ่าน</label> */}
            <Input
              className="form-control"
              elementType="input"
              type={type}
              value={signin.form.password.value}
              invalid={!signin.form.password.valid}
              touched={signin.form.password.touched}
              shouldValidate={signin.form.password.validation.required}
              textInvalid={signin.form.password.textInvalid}
              changed={(event) => inputChangedHandler(event, "password")}
              labelPos={"top"}
              label={signin.form.password.label}
              styleLabel={{ width: "100%", padding: "0px" }}
              onClick={() => onShowPW()}
              show={showPW}
            />
          </div>
          <div className="login__body_submit">
            <button
              className="btn-submit ask__font__main"
              style={{ fontFamily: "Prompt-Regular" }}
            >
              เข้าสู่ระบบ
            </button>
          </div>
          <div className="login__body_forgot">
            <Link to="/forgot">ลืมชื่อผู้ใช้งาน</Link>
            <div className="split" />
            <Link to="/forgot">ลืมรหัสผ่าน</Link>
          </div>
        </div>
      </form>

      <p className="login__footer ask__font__main">
        ยังไม่มีบัญชีผู้ใช้งาน?
        <Link to="/register">สมัครบริการ</Link>
      </p>
      {/* <div className="App">
          <img src={signin.lineImg} alt="line" />
          Line 16.28: {signin.lineID}
          Img: {signin.lineImg}
          <div className="support">
            <Button
              variant="contained"
              onClick={this.getProfile}
              style={{ marginRight: "20px" }}
              color="primary"
            >
              Getdata INFO
            </Button>
            <Button
              variant="contained"
              onClick={this.sendMessage}
              style={{ marginRight: "20px" }}
            >
              Send Message
            </Button>
            <Button
              variant="contained"
              onClick={this.closeLIFF}
              color="secondary"
            >
              Close LIFF
            </Button>
          </div>
        </div> */}
    </div>
  );
};

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lineId: state.line.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (token, userName) => dispatch(actions.auth(token, userName)),
    onSetLineToken: (token) => dispatch(actions.setLineToken(token)),
    onSetCustInfo: (firstName, lastName) =>
      dispatch(actions.setCustInfo(firstName, lastName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
