import React from 'react';
import './Icon.css';

const icon = (props) => {

    let type;

    const style = {
        padding: props.padding,
        width: props.width,
        height: props.height,
        margin: props.margin,
        textAlign: props.align,
    }

    if (props.icon === "facebook") {
        type = "icon__facebook";
    }
    else if (props.icon === "line") {
        type = "icon__line";
    }
    else if (props.icon === "mail") {
        type = "icon__mail";
    }
    else if (props.icon === "telephone") {
        type = "icon__telephone";
    }
    else if (props.icon === "map") {
        type = "icon__map";
    }
    else if (props.icon === "edit") {
        type = "icon__edit";
    }


    return (
        <a href={props.path} target={props.target}>
            <div className={["icon__main", [type], props.classes].join(' ')}
                style={style}
                onClick={props.clicked}></div>
        </a>
    );
};

export default icon;