import React from 'react';
import Grid from '@material-ui/core/Grid';
// import Description from '../description';
import '../../Apply/Apply.css'

const ManageAgreementChangeAddr = (props) => {
    const topic = {
        marginTop: '2rem',
    }
    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <p className="text-bold text-center ask__font__title" style={topic}>เปลี่ยนแปลงที่อยู่</p>
            </Grid>
            <Grid item xs={12}>
                <center>
                    {props.children}
                </center>
            </Grid>
        </Grid>
    );
}

export default ManageAgreementChangeAddr;
