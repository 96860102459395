import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Consent, { handelSubmit } from "../../components/Consent/Consent";
import PathTree from "../../components/UI/PathTree/PathTree";
import Input from "../../components/UI/Input/Input";
import { connect } from "react-redux";
import {
  updateObject,
  checkValidity,
  checkError,
} from "../../../src/shared/utility.js";
import { API_MYASK, API_MYASK_HEADER } from "./../../shared/constant";
// import { Redirect } from "react-router-dom";
import axios from "axios";
import ModalError from "./../../components/UI/Error/Error";

const ConsentControls = (props) => {
  var totalCheckFile = 0;
  //var pPATHfile ="//JAVADEV04//PDPAConsent//TempRequest/";
  var pPATHfile = "//172.16.1.111/Public/PDPAConsent/TempRequest/";
  const [reren, setReren] = useState("");
  const [usr, setUsr] = useState(props.location.search.substring(1) || "C");
  const [totalFile, setFile] = useState(0);

  const path = {
    Path: [
      {
        title: "แบบคำร้องขอใช้สิทธิ์ข้อมูลส่วนบุคคล",
        pathLink: "/consent",
      },
    ],
  };

  const label = {
    color: "#000",
    // width: '50%',
  };

  let notice_type = "";
  const [noticetypeState, setnoticetypeState] = useState({
    notice1: {
      checked: false,
    },
    notice2: {
      checked: false,
    },
    notice3: {
      checked: false,
    },
    notice4: {
      checked: false,
    },
    notice5: {
      checked: false,
    },
    notice6: {
      checked: false,
    },
  });

  const [consentForm, setconsentForm] = useState({
    form: {
      name: {
        order: "0",
        label: "ชื่อ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "ชื่อ",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "ชื่อ",
      },
      lastname: {
        order: "1",
        label: "นามสกุล:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "นามสกุล",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "นามสกุล",
      },
      tel: {
        order: "2",
        label: "เบอร์มือถือ:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "number",
          placeholder: "เบอร์มือถือ",
        },
        type: "number",
        mode: "numeric",
        value: "",
        maxLength: 10, //ใช้ของ input html
        validation: {
          required: true,
          isNumeric: true,
          minLength: 10, //ใช้ของ utility เพราะต้องการดัก spacebar
          maxLength: 10,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "เบอร์มือถือ",
      },
      email: {
        order: "3",
        label: "อีเมล์:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "อีเมล์",
        },
        value: "",
        validation: {
          required: false,
          isEmail: true,
        },
        valid: true,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "อีเมล์",
      },
      cardno: {
        order: "4",
        label: "เลขที่บัตรประชาชน:",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "number",
          placeholder: "เลขที่บัตรประชาชน",
        },
        type: "number",
        mode: "numeric",
        value: "",
        maxLength: 13, //ใช้ของ input html
        validation: {
          required: true,
          isNumeric: true,
          minLength: 13, //ใช้ของ utility เพราะต้องการดัก spacebar
          maxLength: 13,
        },
        valid: false,
        touched: false,
        styleLabel: label,
        textInvalid: "",
        labelError: "เลขที่บัตรประชาชน",
      },
      noticetype: {
        order: "5",
        label: "สิทธิที่ท่านต้องการขอใช้ เกี่ยวกับข้อมูลส่วนบุคคล:",
        labelPos: "left",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        text: "fontSize='25'",
        validation: {
          required: true,
        },
        text: "",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "สิทธิที่ท่านต้องการขอใช้ เกี่ยวกับข้อมูลส่วนบุคคล",
      },
      noticetype2: {
        order: "6",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "fontSize='25'",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "สิทธิที่ท่านต้องการขอใช้ เกี่ยวกับข้อมูลส่วนบุคคล",
      },
      noticetype3: {
        order: "7",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "fontSize='25'",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "สิทธิที่ท่านต้องการขอใช้ เกี่ยวกับข้อมูลส่วนบุคคล",
      },
      noticetype4: {
        order: "8",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "fontSize='25'",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "สิทธิที่ท่านต้องการขอใช้ เกี่ยวกับข้อมูลส่วนบุคคล",
      },
      noticetype5: {
        order: "9",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "fontSize='25'",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "สิทธิที่ท่านต้องการขอใช้ เกี่ยวกับข้อมูลส่วนบุคคล",
      },
      noticetype6: {
        order: "10",
        label: "",
        labelPos: "",
        elementType: "checkbox",
        elementConfig: {},
        value: "",
        validation: {},
        text: "fontSize='25'",
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "เรื่องขอใช้สิทธิ์ข้อมูลส่วนบุคคล",
      },
      callback: {
        order: "11",
        label: "ช่องทางการแจ้งการปฏิบัติตามคำขอให้สิทธิ :",
        labelPos: "left",
        elementType: "select",
        elementConfig: {
          options: [
            { value: "", displayValue: "กรุณาเลือก" },
            { value: "01", displayValue: "ทางอีเมล์" },
            { value: "02", displayValue: "ทางจดหมาย (กรุณากรอกที่อยู่)" },
            { value: "03", displayValue: "ช่องทางอื่น โปรดระบุ" },
          ],
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        styleLabel: label,
        touched: false,
        textInvalid: "",
        labelError: "ช่องทางการแจ้งการปฏิบัติตามคำขอให้สิทธิ",
      },
      callbacktext: {
        order: "12",
        label:
          "ข้อมูลช่องทางการติดต่อกลับ(ที่อยู่/อื่นๆ หรือระบุอีเมล์ด้านบน):",
        labelPos: "left",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder:
            "ข้อมูลช่องทางการติดต่อกลับ(ที่อยู่/อื่นๆ หรือระบุอีเมล์ด้านบน)",
        },
        value: "",
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        styleLabel: label,
        textInvalid: "ข้อมูลช่องทางการติดต่อกลับ(ที่อยู่/อื่นๆ)",
        labelError: "ที่อยู่/อื่นๆ",
      },
    },
    formIsValid: false,
  });

  useEffect(() => {
    let mount = true;
    window.scrollTo(0, 0);
    axios.get(API_MYASK + "/GetRequestConsent").then((res) => {
      axios.get(API_MYASK + "/GetProductType").then((res2) => {
        console.log(res2);

        let updateType = updateObject(consentForm.form.noticetype, {
          value: res.data.LIST_DATA[0].id_code,
          text: res.data.LIST_DATA[0].remark,
        });
        let updateType2 = updateObject(consentForm.form.noticetype2, {
          value: res.data.LIST_DATA[1].id_code,
          text: res.data.LIST_DATA[1].remark,
        });
        let updateType3 = updateObject(consentForm.form.noticetype3, {
          value: res.data.LIST_DATA[2].id_code,
          text: res.data.LIST_DATA[2].remark,
        });
        let updateType4 = updateObject(consentForm.form.noticetype4, {
          value: res.data.LIST_DATA[3].id_code,
          text: res.data.LIST_DATA[3].remark,
        });
        let updateType5 = updateObject(consentForm.form.noticetype5, {
          value: res.data.LIST_DATA[4].id_code,
          text: res.data.LIST_DATA[4].remark,
        });
        let updateType6 = updateObject(consentForm.form.noticetype6, {
          value: res.data.LIST_DATA[5].id_code,
          text: res.data.LIST_DATA[5].remark,
        });

        let updateForm = updateObject(consentForm.form, {
          noticetype: updateType,
          noticetype2: updateType2,
          noticetype3: updateType3,
          noticetype4: updateType4,
          noticetype5: updateType5,
          noticetype6: updateType6,
        });
        let updateState = updateObject(consentForm, {
          form: updateForm,
        });

        if (mount) {
          setconsentForm(updateState);
        }
      });
    });

    return () => {
      mount = false;
    };
  }, []);

  const [loading, setLoading] = useState(false);

  const onChangeTel = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    if (isValid || val === "") {
      return val;
    } else {
      return val.substring(val.length - 1, 0);
    }
  };

  const onChangeCardno = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    let pval = "";
    if (isValid || val === "") {
      //return val;
      pval = val;
    } else {
      //return val.substring(val.length - 1, 0);
      pval = val.substring(val.length - 1, 0);
    }
    return pval;
  };

  const inputChangedHandler = (event, field) => {
    let updatedFormElement;
    let updatedFormElement2;
    let updatedFormElement3;

    console.log(field + " " + event);

    if (field === "tel") {
      event = onChangeTel(event);
    }

    if (field === "cardno") {
      event = onChangeCardno(event);
    }

    let updateChecked;

    if (field === "noticetype") {
      if (noticetypeState.notice1.checked === false) {
        updateChecked = updateObject(noticetypeState.notice1, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice1, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice1: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype2") {
      if (noticetypeState.notice2.checked === false) {
        updateChecked = updateObject(noticetypeState.notice2, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice2, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice2: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype3") {
      if (noticetypeState.notice3.checked === false) {
        updateChecked = updateObject(noticetypeState.notice3, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice3, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice3: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype4") {
      if (noticetypeState.notice4.checked === false) {
        updateChecked = updateObject(noticetypeState.notice4, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice4, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice4: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype5") {
      if (noticetypeState.notice5.checked === false) {
        updateChecked = updateObject(noticetypeState.notice5, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice5, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice5: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "noticetype6") {
      if (noticetypeState.notice6.checked === false) {
        updateChecked = updateObject(noticetypeState.notice6, {
          checked: true,
        });
      } else {
        updateChecked = updateObject(noticetypeState.notice6, {
          checked: false,
        });
      }
      let updateNoticeState = updateObject(noticetypeState, {
        notice6: updateChecked,
      });
      setnoticetypeState(updateNoticeState);
    } else if (field === "cardno") {
      let updateFormValidate = updateObject(
        consentForm.form["cardno"].validation,
        {
          required: true,
        }
      );

      updatedFormElement = updateObject(consentForm.form["cardno"], {
        valid: false,
        validation: updateFormValidate,
      });

      const validate = checkValidity(event, consentForm.form[field].validation);

      const text = checkError(
        validate.code,
        consentForm.form[field].labelError,
        consentForm.form[field].validation
      );

      updatedFormElement2 = updateObject(consentForm.form[field], {
        value: event,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });

      let updatedForm = updateObject(consentForm.form, {
        ["cardno"]: updatedFormElement,
        [field]: updatedFormElement2,
      });

      const updatedState = updateObject(consentForm, {
        form: updatedForm,
      });

      setconsentForm(updatedState);
      console.log(consentForm);
    } else if (field === "callback" && event === "01") {
      let updateFormValidate = updateObject(
        consentForm.form["email"].validation,
        {
          required: true,
        }
      );

      updatedFormElement = updateObject(consentForm.form["email"], {
        valid: false,
        validation: updateFormValidate,
      });

      const validate = checkValidity(event, consentForm.form[field].validation);

      const text = checkError(
        validate.code,
        consentForm.form[field].labelError,
        consentForm.form[field].validation
      );

      updatedFormElement2 = updateObject(consentForm.form[field], {
        value: event,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });

      let updatedForm = updateObject(consentForm.form, {
        ["email"]: updatedFormElement,
        [field]: updatedFormElement2,
      });

      const updatedState = updateObject(consentForm, {
        form: updatedForm,
      });

      setconsentForm(updatedState);
      console.log(consentForm);
    } else if (field === "callback" && event === "02") {
      let updateFormValidate = updateObject(
        consentForm.form["callbacktext"].validation,
        {
          required: true,
        }
      );

      updatedFormElement = updateObject(consentForm.form["callbacktext"], {
        valid: false,
        validation: updateFormValidate,
      });

      let updateFormValidate2 = updateObject(
        consentForm.form["email"].validation,
        {
          required: false,
        }
      );

      updatedFormElement3 = updateObject(consentForm.form["email"], {
        valid: true,
        validation: updateFormValidate2,
      });

      const validate = checkValidity(event, consentForm.form[field].validation);

      const text = checkError(
        validate.code,
        consentForm.form[field].labelError,
        consentForm.form[field].validation
      );

      updatedFormElement2 = updateObject(consentForm.form[field], {
        value: event,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });

      let updatedForm = updateObject(consentForm.form, {
        ["email"]: updatedFormElement3,
        ["callbacktext"]: updatedFormElement,
        [field]: updatedFormElement2,
      });

      const updatedState = updateObject(consentForm, {
        form: updatedForm,
      });

      setconsentForm(updatedState);
      console.log(consentForm);
    } else if (field === "callback" && event !== "01" && event !== "02") {
      //Other channel
      let updateFormValidate = updateObject(
        consentForm.form["email"].validation,
        {
          required: false,
        }
      );

      updatedFormElement = updateObject(consentForm.form["email"], {
        valid: true,
        validation: updateFormValidate,
      });

      let updateFormValidate2 = updateObject(
        consentForm.form["callbacktext"].validation,
        {
          required: false,
        }
      );

      updatedFormElement3 = updateObject(consentForm.form["callbacktext"], {
        valid: true,
        validation: updateFormValidate2,
      });

      const validate = checkValidity(event, consentForm.form[field].validation);

      const text = checkError(
        validate.code,
        consentForm.form[field].labelError,
        consentForm.form[field].validation
      );

      updatedFormElement2 = updateObject(consentForm.form[field], {
        value: event,
        valid: validate.isValid,
        textInvalid: text,
        touched: true,
      });

      let updatedForm = updateObject(consentForm.form, {
        ["email"]: updatedFormElement,
        ["callbacktext"]: updatedFormElement3,
        [field]: updatedFormElement2,
      });

      const updatedState = updateObject(consentForm, {
        form: updatedForm,
      });

      setconsentForm(updatedState);
    } else {
      const validate = checkValidity(event, consentForm.form[field].validation);
      const text = checkError(
        validate.code,
        consentForm.form[field].labelError,
        consentForm.form[field].validation
      );

      if (field === "email") {
        //formIsValid : true
        if (event === "") {
          updatedFormElement = updateObject(consentForm.form[field], {
            value: event,
            valid: false,
            textInvalid: text,
            touched: false,
            isEmail: false,
          });
        } else {
          updatedFormElement = updateObject(consentForm.form[field], {
            value: event,
            valid: true,
            textInvalid: text,
            touched: true,
            isEmail: true,
          });
        }
      } else {
        updatedFormElement = updateObject(consentForm.form[field], {
          value: event,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
      }

      let updatedForm = updateObject(consentForm.form, {
        [field]: updatedFormElement,
      });

      let formIsValid = true;
      for (let inputIdentifier in updatedForm) {
        formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
      }

      const updatedState = updateObject(consentForm, {
        form: updatedForm,
        formIsValid: formIsValid,
      });

      setconsentForm(updatedState);
    }
  };

  const getUplaodFiles = () => {
    //window.alert("in getUplaodFiles");
    const data = {
      device: "web",
      userName: "",
      userCode: "",
      comCode: "",
      pTaskCode: "DSR-01",
      pChannelMail: "01",
      pathfile: pPATHfile,
      pfilename: "",
      pcardno: consentForm.form["cardno"].value,
      pfilename: "",
      pfilesize: 0,
    };

    axios
      .post(API_MYASK + "/PostReadAttachedfile", data, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        //setFile(res.data.LIST_DATA.length);
        window.alert("in API");
        //console.log("totalCheckFile="+res.data.LIST_DATA.length);
        totalCheckFile = res.data.LIST_DATA.length;
        //window.alert("in API="+totalCheckFile );
        //console.log(res.data.LIST_DATA);
        //console.log("totalCheckFile="+res.data.LIST_DATA.length);
      })
      .catch((err) => {
        setReren(
          <ModalError
            title="เกิดข้อผิดพลาด"
            text="กรุณาทำรายการใหม่ในภายหลัง"
            color="red"
            border="0.1rem solid red"
            link="/forgotusername"
          ></ModalError>
        );
      });
  };

  const checkedValidate = () => {
    /*console.log("notice1="+noticetypeState.notice1.checked);
   console.log("notice2="+noticetypeState.notice2.checked);
   console.log("notice3="+noticetypeState.notice3.checked);
   console.log("notice4="+noticetypeState.notice4.checked);
   console.log("notice5="+noticetypeState.notice5.checked);
   console.log("notice6="+noticetypeState.notice6.checked);
   console.log("callback="+consentForm.form["callback"].valid);
   console.log("cardno="+consentForm.form["cardno"].valid);
   console.log("name="+consentForm.form["name"].valid);
   console.log("lastname="+consentForm.form["lastname"].valid);
   console.log("tel="+consentForm.form["tel"].valid);
   console.log("email="+consentForm.form["email"].valid);
   console.log("callbacktext="+consentForm.form["callbacktext"].valid);
   */
    //return;
    //if (true
    if (
      consentForm.form["callback"].valid === true &&
      (noticetypeState.notice1.checked === true ||
        noticetypeState.notice2.checked === true ||
        noticetypeState.notice3.checked === true ||
        noticetypeState.notice4.checked === true ||
        noticetypeState.notice5.checked === true ||
        noticetypeState.notice6.checked === true) &&
      consentForm.form["cardno"].valid === true &&
      consentForm.form["name"].valid === true &&
      consentForm.form["lastname"].valid === true &&
      consentForm.form["tel"].valid === true &&
      consentForm.form["email"].valid === true &&
      consentForm.form["callback"].valid === true &&
      consentForm.form["callbacktext"].valid === true
    ) {
      consentForm.formIsValid = true;
      //window.alert("before API");
      const data = {
        device: "web",
        userName: "",
        userCode: "",
        comCode: "",
        pcardno: consentForm.form["cardno"].value,
      };

      var checkCus = "";
      axios
        .post(API_MYASK + "/Searchcusinfo", data, {
          headers: API_MYASK_HEADER,
        })
        .then((res) => {
          console.log(res);
          //window.alert("in API");
          if (res.data.CODE === "200") {
            checkCus = "Y";
          } else {
            checkCus = "N";
          }

          if (checkCus === "Y") {
            sendData();
          } else {
            window.alert(
              "ไม่พบฐานข้อมูลของลูกค้าที่ทำสินเชื่อกับบริษัท จึงกดส่งข้อมูลไม่ผ่าน!"
            );
            window.scrollTo(0, 0);
          }
        })
        .catch((err) => {
          setReren(
            <ModalError
              title="เกิดข้อผิดพลาด"
              text="กรุณาทำรายการใหม่ในภายหลัง"
              color="red"
              border="0.1rem solid red"
              link="/forgotusername"
            ></ModalError>
          );
        });

      //sendData();
    }

    if (!consentForm.formIsValid) {
      let updatedForm;
      let arrayObj = [];
      let updatedFormElement;
      let text;
      for (let field in consentForm.form) {
        const validate = checkValidity(
          consentForm.form[field].value,
          consentForm.form[field].validation
        );
        text = checkError(
          validate.code,
          consentForm.form[field].labelError,
          consentForm.form[field].validation
        );
        updatedFormElement = updateObject(consentForm.form[field], {
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
        arrayObj.push(updatedFormElement);
      }

      const arrayComplaint = [];
      for (let i in arrayObj) {
        if (arrayObj[i].order === "0") {
          arrayComplaint[0] = arrayObj[i];
        } else if (arrayObj[i].order === "1") {
          arrayComplaint[1] = arrayObj[i];
        } else if (arrayObj[i].order === "2") {
          arrayComplaint[2] = arrayObj[i];
        } else if (arrayObj[i].order === "3") {
          arrayComplaint[3] = arrayObj[i];
        } else if (arrayObj[i].order === "4") {
          arrayComplaint[4] = arrayObj[i];
        } else if (arrayObj[i].order === "5") {
          arrayComplaint[5] = arrayObj[i];
        } else if (arrayObj[i].order === "6") {
          arrayComplaint[6] = arrayObj[i];
        } else if (arrayObj[i].order === "7") {
          arrayComplaint[7] = arrayObj[i];
        } else if (arrayObj[i].order === "8") {
          arrayComplaint[8] = arrayObj[i];
        } else if (arrayObj[i].order === "9") {
          arrayComplaint[9] = arrayObj[i];
        } else if (arrayObj[i].order === "10") {
          arrayComplaint[10] = arrayObj[i];
        } else if (arrayObj[i].order === "11") {
          arrayComplaint[11] = arrayObj[i];
        } else if (arrayObj[i].order === "12") {
          arrayComplaint[12] = arrayObj[i];
        } else if (arrayObj[i].order === "13") {
          arrayComplaint[13] = arrayObj[i];
        } else if (arrayObj[i].order === "14") {
          arrayComplaint[14] = arrayObj[i];
        }
      }

      updatedForm = updateObject(consentForm.form, {
        name: arrayComplaint[0],
        lastname: arrayComplaint[1],
        tel: arrayComplaint[2],
        email: arrayComplaint[3],
        cardno: arrayComplaint[4],
        noticetype: arrayComplaint[5],
        noticetype2: arrayComplaint[6],
        noticetype3: arrayComplaint[7],
        noticetype4: arrayComplaint[8],
        noticetype5: arrayComplaint[9],
        noticetype6: arrayComplaint[10],
        callback: arrayComplaint[11],
        callbacktext: arrayComplaint[12],
      });

      let updatedState = updateObject(consentForm, {
        form: updatedForm,
      });
      setconsentForm(updatedState);
    }
  };

  const sendData = () => {
    setLoading(true);

    if (noticetypeState.notice1.checked === true) {
      notice_type = "01";
    }
    if (noticetypeState.notice2.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "02";
      } else {
        notice_type = "02";
      }
    }
    if (noticetypeState.notice3.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "03";
      } else {
        notice_type = "03";
      }
    }
    if (noticetypeState.notice4.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "04";
      } else {
        notice_type = "04";
      }
    }
    if (noticetypeState.notice5.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "05";
      } else {
        notice_type = "05";
      }
    }
    if (noticetypeState.notice6.checked === true) {
      if (notice_type !== "") {
        notice_type = notice_type + "," + "06";
      } else {
        notice_type = "06";
      }
    }

    let sourceby = "";
    if (props.lineId !== null) {
      sourceby = "02";
    } else if (usr !== "C") {
      sourceby = "04";
    } else {
      sourceby = "01";
    }

    //Json to Service
    const data = {
      first_name: consentForm.form.name.value,
      last_name: consentForm.form.lastname.value,
      card_no: consentForm.form.cardno.value,
      e_mail: consentForm.form.email.value,
      mobile_phone: consentForm.form.tel.value,
      contact_channel: consentForm.form.callback.value,
      contact_text: consentForm.form.callbacktext.value,
      request_type: notice_type,
      source_by: sourceby,
      input_user: usr,
    };

    console.log(notice_type);
    //Save Consent
    axios
      .post(API_MYASK + "/SaveConsent", data, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        //Start
        console.log(res);
        const data2 = {
          device: "web",
          userName: "",
          userCode: usr,
          comCode: "",
          pTaskCode: "DSR-01",
          pChannelMail: "01",
          pToemail: consentForm.form.email.value,
          pBccemail: "",
          pSumresp: "",
        };

        //Send E-Mail
        axios
          .post(API_MYASK + "/ConsSendMail/PostSendMail", data2, {
            headers: API_MYASK_HEADER,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            window.alert("error sendmail");
            setReren(
              <ModalError
                title="เกิดข้อผิดพลาด"
                text="กรุณาทำรายการใหม่ในภายหลัง"
                color="red"
                border="0.1rem solid red"
                link="/forgotusername"
              ></ModalError>
            );
          });
      })
      .catch((err) => {
        window.alert("error save");
        setReren(
          <ModalError
            title="เกิดข้อผิดพลาด"
            text="กรุณาทำรายการใหม่ในภายหลัง"
            color="red"
            border="0.1rem solid red"
            link="/forgotusername"
          ></ModalError>
        );
      });

    props.history.push({
      // ใช้ในกรณีเซิฟเวอร์เทส
      pathname: "/success",
      //pathname: "",
      title: "เราได้รับข้อมูลของท่านแล้ว",
      path: "/myask/#/",
      text: "จะติดต่อกลับหาท่านโดยเร็วที่สุด",
      // text2: 'ท่านสามารถติดตามผลได้ทาง...'
    });

    //  props.history.push({
    // //   // ใช้ในกรณี localhost, production
    //    pathname: "/success",
    //    title: "เราได้รับข้อมูลของท่านแล้ว",
    //   path: "/",
    //    text: "จะติดต่อกลับหาท่านโดยเร็วที่สุด",
    // //   // text2: 'ท่านสามารถติดตามผลได้ทาง...'
    //  });
  }; //End

  const submitHandler = (event) => {
    //event.preventDefault();
    //window.scrollTo(0, 0);
    checkedValidate();
  };

  const formElementsArray = [];
  for (let key in consentForm.form) {
    console.log("key is : " + key);
    console.log(consentForm.form[key]);
    formElementsArray[consentForm.form[key].order] = {
      id: key,
      config: consentForm.form[key],
    };
  }

  return (
    <PathTree list={path.Path}>
      {reren}
      <Consent
        loading={loading}
        xcard={consentForm.form.cardno.value}
        xfirstname={consentForm.form.name.value}
        xlastname={consentForm.form.lastname.value}
        xtel={consentForm.form.tel.value}
        xemail={consentForm.form.email.value}
        xnoticetype={notice_type}
        xcallback={consentForm.form.callback.value}
        title="แบบคำร้องขอใช้สิทธิ์ข้อมูลส่วนบุคคล"
        children={formElementsArray.map((formElement) => (
          <div className="label__pos ask__font__main" key={formElement.id}>
            {/* <label className="label_text">{formElement.config.label}</label > */}
            <Input
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation.required}
              touched={formElement.config.touched}
              max={formElement.config.maxLength}
              min={formElement.config.minLength}
              mode={formElement.config.mode}
              align={formElement.config.align}
              type={formElement.config.type}
              styleInput={formElement.config.styleInput}
              styleLabel={formElement.config.styleLabel}
              textInvalid={formElement.config.textInvalid}
              isEmail={formElement.config.isEmail}
              changed={(event) =>
                inputChangedHandler(event.target.value, formElement.id)
              }
              labelPos={"left"}
              fontSize={"1.2rem"}
              label={formElement.config.label}
              text={formElement.config.text}
            />
          </div>
        ))}
        submitHandler={submitHandler}
      ></Consent>
    </PathTree>
  );
};

const mapStateToProps = (state) => {
  return {
    lineId: state.line.userId,
    ssCard: state.scard,
  };
};
export default connect(mapStateToProps, null)(ConsentControls);
