import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import ButtonBase from "@material-ui/core/ButtonBase";

import * as actions from "./../../../../store/action/index";
import * as actionTypes from "./../../../../store/action/actionTypes";
import { numberWithCommas } from "../../../../shared/utility";
import Loading from "./../../../../components/UI/Loading/Loading";
import { Fragment } from "react";

const ClosedAgreement = (props) => {
  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  let [agrCode, setAgrCode] = useState(null);
  let [action, setAction] = useState("n");
  let [comCode, setComCode] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (query.get("agr")) {
      setAgrCode(query.get("agr"));
    }
    if (query.get("action")) {
      setAction(query.get("action"));
    }
    if (query.get("comCode")) {
      setComCode(query.get("comCode"));
    }

    props.getClosedAgreementDetail(
      query.get("agr"),
      query.get("comCode"),
      props.runNo
    );
  }, []);

  const confirmClosedAgreement = () => {
    let canInsert = false;

    const index = props.payment_agreements.findIndex(
      (p) => p.agreement.agr_code === agrCode
    );
    if (index === -1) {
      canInsert = true;
    }
    if (canInsert) {
      let items = [];

      let item = {
        key: "01",
        sub_key: "0",
        title: "ปิดบัญชี",
        amount: props.closedDetail.sum_paid_all,
      };
      items.push(item);

      props.insertAgreementPayList(
        {
          agr_code: agrCode,
          com_code: comCode,
          body: props.closedDetail.body,
          brand: props.closedDetail.brand,
          reg_num: props.closedDetail.reg_num,
          reg_province: props.closedDetail.reg_province,
        },
        agrCode,
        items,
        true
      );
    }
    history.push({
      pathname: "/agreements",
    });
  };

  let redirectpage = null;
  if (!sessionStorage.getItem("token")) {
    redirectpage = <Redirect to="/login" />;
  }

  if (props.runNo == null || props.runNo === "") {
    redirectpage = <Redirect to="/agreements" />;
  }

  return (
    <div className="ask__font__main">
      {redirectpage}
      <Button className="p-button-text m-4" onClick={() => history.goBack()}>
        <span style={{ color: "#ff9600" }}> {"< ย้อนกลับ"}</span>
      </Button>
      <div className="grid mt-2" style={{ width: "100%" }}>
        <div className="col-12 mb-4">
          <center>
            <p className="text-bold ask__font__title">
              รายการชำระเงิน(ปิดบัญชี)
            </p>
          </center>
        </div>
        <div
          className="grid col-12 branchpanel mx-3 justify-content-center"
          style={{ paddingTop: "1rem" }}
        >
          <div className="col-12 sm:col-12 md:col-8">
            <Card
              style={{
                color: "#000000de",
                border: "1px solid",
                borderRadius: "3px",
                borderColor: "#000000",
                fontFamily: "Prompt-Regular",
              }}
            >
              <div className="p-3 ask__font__main">
                <div className="font-bold" style={{ textAlign: "center" }}>
                  เลขที่สัญญา {agrCode}
                </div>
                <Divider />
                {props.closedDetail == null &&
                  props.closedDetailState ===
                    actionTypes.VIEW_STATE.SUCCESS && (
                    <center>
                      <p className="ask__font__main">ไม่พบข้อมูล</p>
                    </center>
                  )}
                {props.closedDetailState === actionTypes.VIEW_STATE.LOADING && (
                  <center>
                    <Loading />
                  </center>
                )}
                {props.closedDetail != null &&
                  props.closedDetailState ===
                    actionTypes.VIEW_STATE.SUCCESS && (
                    <div className="grid mt-4" style={{ width: "100%" }}>
                      {props.closedDetail.x_condition !== "" && (
                        <div
                          className="col-12  my-6 py-6 align-items-center"
                          style={{ color: "#296DD6", textAlign: "center" }}
                        >
                          {props.closedDetail.x_condition}
                        </div>
                      )}
                      {props.closedDetail.x_condition === "" && (
                        <Fragment>
                          {props.closedDetail.items.length > 0 &&
                            props.closedDetail.items.map((item, index) => (
                              <Fragment key={item.i_order}>
                                <div className="col-6">
                                  <p>{item.description}</p>
                                </div>
                                <div className="col-6">
                                  <p className="text-right">
                                    {item.ori_amt} บาท
                                  </p>
                                </div>
                              </Fragment>
                            ))}

                          <div className="col-6">
                            <p className="font-bold">ยอดชำระ</p>
                          </div>
                          <div className="col-6">
                            <p className="text-right">
                              {numberWithCommas(
                                parseFloat(
                                  Math.round(
                                    props.closedDetail.sum_paid_all * 100
                                  ) / 100
                                ).toFixed(2)
                              )}{" "}
                              บาท
                            </p>
                          </div>
                          <div className="col-12">
                            <p className="font-bold">
                              (ภายในวันที่ {props.closedDetail.n_due_i})
                            </p>
                          </div>
                          <div className="col-12 mt-6">
                            <p className="font-bold">
                              หากชำระเกินวันที่ {props.closedDetail.n_due_i}{" "}
                              ยอดดังกล่าวอาจมีการเปลี่ยนแปลง
                            </p>
                          </div>
                          {props.closedDetail.new_tr !== "P" &&
                            props.closedDetail.new_tr !== "CL" &&
                            props.closedDetail.new_tr !== "CML" && (
                              <div className="col-12 mt-3">
                                <p
                                  className="font-bold"
                                  style={{ color: "#f44336" }}
                                >
                                  ไม่รวมค่าโอนกรรมสิทธิ์ (ถ้ามี)
                                </p>
                              </div>
                            )}
                          <div className="col-12 mt-6">
                            <p className="font-bold">เจ้าหน้าที่ดูแล</p>
                          </div>
                          <div className="col-12">
                            <p>{props.closedDetail.officer}</p>
                          </div>
                          {action === "y" && (
                            <div className="col-12  mt-4">
                              <div className=" flex justify-content-end">
                                <Button
                                  label="ยืนยันที่จะปิดบัญชี"
                                  className="p-button-raised p-button-danger mt-4 p-3"
                                  style={{
                                    borderRadius: "0px",
                                    backgroundColor: "#f44336",
                                  }}
                                  onClick={() => confirmClosedAgreement()}
                                />
                              </div>
                            </div>
                          )}
                        </Fragment>
                      )}
                    </div>
                  )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    payment_agreements: state.payment.payment_agreements,
    closedDetail: state.agreement.closedDetail,
    closedDetailState: state.agreement.closedDetailState,
    closedDetailStateMsg: state.agreement.closedDetailStateMsg,
    runNo: state.agreement.run_no,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    insertAgreementPayList: (agreement, agr_code, items, is_closed) =>
      dispatch(
        actions.insertAgreementPayList(agreement, agr_code, items, is_closed)
      ),
    getClosedAgreementDetail: (agr_code, com_code, run_no) =>
      dispatch(actions.getClosedAgreementDetail(agr_code, com_code, run_no)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClosedAgreement);
