//import React from 'react';
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Icon from "./../UI/Icon/Icon";
import "./../Apply/Apply.css";
import Loading from "./../UI/Loading/Loading";
import "./../Consent/Consentccs.css";
import DownloadButton from "../../containers/ConsentControls/Consentdowloadfile";
import { connect } from "react-redux";
import { browserVersion } from "react-device-detect";
import { API_MYASK, API_MYASK_HEADER } from "./../../shared/constant";
import axios from "axios";
import ModalError from "./../../components/UI/Error/Error";
import { Card, debounce } from "@material-ui/core";

const Consent = (props) => {
  var totalSelectFile = 0;
  var totalFiles = 0;
  var dataSelectItems = [];
  //var pPATHfile ="//JAVADEV04//PDPAConsent//TempRequest/";
  var pPATHfile = "//172.16.1.111/Public/PDPAConsent/TempRequest/";
  var totalSizeFile = 0;
  var totalKB = 0;
  var totalMB = 0;
  var final_totalB = 0;
  var final_totalKB = 0;
  var final_totalMB = 0;

  const topic = {
    marginTop: "0.5rem",
    marginBottom: "2rem",
  };

  const [filestate, setbase64URL] = useState("");
  const [xreren, setRerenTmp] = useState("");
  const [pTotalFile, setpTotal] = useState(0);

  const getTotalSizes = () => {
    totalKB = totalSizeFile / 1024;
    totalMB = totalKB / 1024;
    final_totalB = parseFloat(totalSizeFile).toFixed(2);
    final_totalKB = parseFloat(totalKB).toFixed(2);
    final_totalMB = parseFloat(totalMB).toFixed(2);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      //let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        //console.log("Called", reader);
        baseURL = reader.result;
        //console.log("baseURL="+baseURL);
        resolve(baseURL);
      };
    });
  };

  const ShowAlert = (e) => {
    if (props.xcard == "") {
      alert("โปรดระบุ เลขที่บัตรประชาชน");
    } else {
      handleFileInputChange(e);
    }
  };

  const updateList = function (file, itemno) {
    var input = file;

    const data = {
      device: "web",
      userName: "",
      userCode: "",
      comCode: "",
      pTaskCode: "DSR-01",
      pChannelMail: "01",
      pathfile: pPATHfile,
      pfilename: input.name, //input.files.item(i).name,
      pcardno: props.xcard,
      pfilename: input.name, //input.files.item(i).name,
      pfilesize: input.base64,
    };

    axios
      .post(API_MYASK + "/Postuploadfile", data, { headers: API_MYASK_HEADER })
      .then((res) => {
        if (res.data.CODE == "200") {
          if (totalSelectFile == itemno + 1) {
            getUplaodFiles();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileInputChange = (e) => {
    let { fileitemsList } = e.target.files;
    fileitemsList = e.target.files;
    totalSelectFile = fileitemsList.length;

    for (let i = 0; i < fileitemsList.length; i++) {
      let fileitems = fileitemsList[i];
      getBase64(fileitems)
        .then((result) => {
          fileitems["base64"] = result;

          setbase64URL({
            base64URL: result,
            fileitems,
          });

          updateList(fileitems, i);
        })
        .catch((err) => {
          console.log(err);
        });

      setbase64URL({
        file: fileitems,
      });
    } //End For
  };

  const getUplaodFiles = () => {
    var output = document.getElementById("fileAll");
    var children = "";

    const data = {
      device: "web",
      userName: "",
      userCode: "",
      comCode: "",
      pTaskCode: "DSR-01",
      pChannelMail: "01",
      pathfile: pPATHfile,
      pfilename: "", //input.files.item(i).name,
      pcardno: props.xcard,
      pfilename: "", //input.files.item(i).name,
      pfilesize: 0,
    };

    axios
      .post(API_MYASK + "/PostReadAttachedfile", data, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        totalSizeFile = 0;
        //console.log(res.data.LIST_DATA);
        for (let i in res.data.LIST_DATA) {
          children += '<div class="item">';
          children +=
            '<h3><b><input type="checkbox" id={' +
            i +
            '} value="' +
            res.data.LIST_DATA[i].file_name +
            '" >';
          children +=
            " " +
            res.data.LIST_DATA[i].file_name +
            "  ขนาดไฟล์ : " +
            res.data.LIST_DATA[i].file_size +
            "</h3></b>";
          children += "</div>";
          totalSizeFile = totalSizeFile + res.data.LIST_DATA[i].file_sizeonly;
        }
        getTotalSizes();
        output.innerHTML =
          "<ul>" +
          children +
          '</ul></div><br><h3><p className="font-bold text-left" style={{ color: "red", fontSize: "1.3rem" }}>Total size of all files : <br> Total Bytes :' +
          final_totalB +
          " Bytes.<br>Total Kilobytes : " +
          final_totalKB +
          " KB.<br> Total Megabytes : " +
          final_totalMB +
          " MB.</p></h3>";
      })
      .catch((err) => {
        setRerenTmp(
          <ModalError
            title="เกิดข้อผิดพลาด"
            text="กรุณาทำรายการใหม่ในภายหลัง"
            color="red"
            border="0.1rem solid red"
            link="/forgotusername"
          ></ModalError>
        );
      });
  };

  const getData = () => {
    // this function will get called when the save button is clicked
    var itemForm = document.getElementById("fileAll"); // getting the parent container of all the checkbox inputs
    var checkBoxes = itemForm.querySelectorAll('input[type="checkbox"]'); // get all the check box
    let i = 0;

    dataSelectItems = [];
    checkBoxes.forEach((fileAll) => {
      // loop all the checkbox item
      if (fileAll.checked) {
        //if the check box is checked
        let dataSelect = {
          // create an object
          //FILE_NAME  : fileAll.value,
          //FILE_CHECK : fileAll.checked,
          folder_name: pPATHfile,
          file_name: fileAll.value,
          file_nameonly: fileAll.value,
          file_size: 0,
          file_type: "",
        };
        //console.log("item selcted =>"+fileAll.value+" item checked =>"+fileAll.checked);
        dataSelectItems.push(dataSelect); //stored the objects to result array
      }
      i++;
    });

    if (dataSelectItems.length > 0) {
      const data = {
        device: "web",
        userName: "",
        userCode: "",
        comCode: "",
        pTaskCode: "DSR-01",
        pathfile: pPATHfile,
        pfilename: "", //input.files.item(i).name,
        pcardno: props.xcard,
        pfilename: "", //input.files.item(i).name,
        pfilesize: 0,
        objfileupload: dataSelectItems,
      };

      axios
        .post(API_MYASK + "/PostDeleteAttachedfile", data, {
          headers: API_MYASK_HEADER,
        })
        .then((res) => {
          if (res.data.CODE == "200") {
            getUplaodFiles();
          }
        })
        .catch((err) => {
          setRerenTmp(
            <ModalError
              title="เกิดข้อผิดพลาด"
              text="กรุณาทำรายการใหม่ในภายหลัง"
              color="red"
              border="0.1rem solid red"
              link="/forgotusername"
            ></ModalError>
          );
        });
    } else {
      window.alert("กรุณาเลือกไฟล์ที่ต้องการลบ!");
    }
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    var checkCus = "";
    var itemForm = document.getElementById("fileAll");
    var checkBoxes = itemForm.querySelectorAll('input[type="checkbox"]');
    let dataUpItems = [];
    var output = document.getElementById("fileAll");
    var children = "";

    checkBoxes.forEach((fileAll) => {
      // loop all the checkbox item
      let dataSelect = {
        folder_name: pPATHfile,
        file_name: fileAll.value,
        file_nameonly: fileAll.value,
        file_size: fileAll.file_size,
        file_type: "",
      };
      dataUpItems.push(dataSelect);
    });

    totalFiles = 0;
    totalFiles = dataUpItems.length;

    const data = {
      device: "web",
      userName: "",
      userCode: "",
      comCode: "",
      pcardno: props.xcard,
    };

    if (
      props.xcard == "" ||
      props.xfirstname == "" ||
      props.xlastname == "" ||
      props.xtel == "" ||
      props.xcallback == ""
    ) {
      window.alert("กรุณาระบุข้อมูล!");
      window.scrollTo(0, 0);
      props.submitHandler();
    } else {
      axios
        .post(API_MYASK + "/Searchcusinfo", data, {
          headers: API_MYASK_HEADER,
        })
        .then((res) => {
          if (res.data.CODE == "200") {
            checkCus = "Y";
          } else {
            checkCus = "N";
          }

          const data = {
            device: "web",
            userName: "",
            userCode: "",
            comCode: "",
            pTaskCode: "DSR-01",
            pChannelMail: "01",
            pathfile: pPATHfile,
            pfilename: "", //input.files.item(i).name,
            pcardno: props.xcard,
            pfilename: "", //input.files.item(i).name,
            pfilesize: 0,
          };

          totalSizeFile = 0;
          axios
            .post(API_MYASK + "/PostReadAttachedfile", data, {
              headers: API_MYASK_HEADER,
            })
            .then((res) => {
              for (let i in res.data.LIST_DATA) {
                totalSizeFile =
                  totalSizeFile + res.data.LIST_DATA[i].file_sizeonly;
              }

              if (totalFiles == 0 || totalSizeFile == 0) {
                window.alert(
                  "กรุณาระบุเลขที่บัตรประชาชน และกรุณาเลือกเอกสารที่ส่ง(Upload Files)!"
                );
                window.scrollTo(0, 0);
              }

              if (totalSizeFile > 10485760 && totalFiles > 0) {
                window.alert(
                  "ขนาดของไฟล์เอกสารที่ส่ง(Upload Files) มีขนาดใหญ่เกินกว่าที่กำหนด!"
                );
                window.scrollTo(0, 0);
              }

              if (checkCus == "N") {
                window.alert(
                  "ไม่พบฐานข้อมูลของลูกค้าที่ทำสินเชื่อกับบริษัท จึงกดส่งข้อมูลไม่ผ่าน!"
                );
                window.scrollTo(0, 0);
              }

              if (
                totalFiles > 0 &&
                totalSizeFile <= 10485760 &&
                checkCus == "Y"
              ) {
                window.scrollTo(0, 0);
                props.submitHandler();
              }
            })
            .catch((err) => {
              setRerenTmp(
                <ModalError
                  title="เกิดข้อผิดพลาด"
                  text="กรุณาทำรายการใหม่ในภายหลัง"
                  color="red"
                  border="0.1rem solid red"
                  link="/forgotusername"
                ></ModalError>
              );
            });
        })
        .catch((err) => {
          setRerenTmp(
            <ModalError
              title="เกิดข้อผิดพลาด"
              text="กรุณาทำรายการใหม่ในภายหลัง"
              color="red"
              border="0.1rem solid red"
              link="/forgotusername"
            ></ModalError>
          );
        });
    }
  };

  return (
    <div className="panel">
      <p className="text-bold text-center ask__font__title" style={topic}>
        {props.title}
      </p>
      <br></br>
      <br></br>
      <form onSubmit={handelSubmit}>
        <Grid container spacing={3}>
          {props.children}
          {props.loading ? (
            <Grid item xs={12}>
              <center>
                <Loading />
              </center>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <button className="btn-submit btn_apply_submit">ส่งข้อมูล</button>
            </Grid>
          )}
        </Grid>
      </form>
      <div className="panel">
        <div className="row">
          <table width={400}>
            <tr>
              <td>
                <p
                  className="font-bold text-left"
                  style={{ color: "blue", fontSize: "1.3rem" }}
                >
                  การขอดำเนินการตามสิทธิของท่าน
                  กรุณาแนบเอกสารแบบฟอร์มร้องขอใช้สิทธิที่บริษัทให้อัพโหลดนี้
                  โดยลงรายละเอียด และลายมือชื่อให้ครบถ้วน
                  พร้อมแนบสำเนาบัตรประชาชนมาพร้อมกัน
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  className="font-bold text-left"
                  style={{ color: "red", fontSize: "1.3rem" }}
                >
                  <u>จึงจะถือว่ากระบวนการขอสิทธิเสร็จสิ้น</u>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  className="font-bold text-left"
                  style={{ color: "blue", fontSize: "1.3rem" }}
                >
                  ทั้งนี้เพื่อให้ทางบริษัทฯ
                  สามารถตรวจสอบว่าท่านอยู่ภายใต้เงื่อนไขที่จะได้รับสิทธินั้นๆ
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p
                  className="font-bold text-left"
                  style={{ color: "red", fontSize: "1.3rem" }}
                >
                  <u>
                    และเมื่อท่านได้รับการตรวจสอบว่าได้รับสิทธิทางบริษัทจะแจ้งผลการดำเนินการไปยังท่านในลำดับต่อไป
                    ขอบพระคุณ
                  </u>
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="panel">
        <table width={100}>
          <tr>
            <td align="top">
              <table>
                <tr>
                  <td>
                    <button
                      name="btnDownload"
                      className="btn-submit btnupload"
                      onClick={DownloadButton}
                    >
                      แบบคำร้องขอใช้สิทธิ์ข้อมูลส่วนบุคคล
                    </button>
                    <br></br>
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table>
                <tr width={100}>
                  <td width={100}>
                    <button
                      className="btn-submit btnupload"
                      width={100}
                      onChange={ShowAlert}
                    >
                      <input
                        type="file"
                        multiple="true"
                        width={100}
                        id="file"
                        accept=".pdf,.png,.jpg,.jpeg,.gif,.bmp"
                        maxFileSize="10485760"
                        hidden
                      />
                      <label for="file">เอกสารที่ส่ง(Upload files)</label>
                    </button>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table>
                <tr align="left">
                  <td align="left">
                    {" "}
                    <p
                      className="font-bold text-left"
                      style={{ color: "red", fontSize: "1.3rem" }}
                    >
                      ***ประเภทไฟล์ที่อัพโหลด :
                    </p>
                  </td>
                  <td align="left">
                    {" "}
                    <p
                      className="font-bold text-left"
                      style={{ color: "blue", fontSize: "1.3rem" }}
                    >
                      .pdf, .jpg, .jpeg, .png, .gif, .bmp ขนาดไฟล์รวมไม่เกิน 10
                      MB.
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table>
                <tr align="left">
                  <td align="left">
                    <p>
                      <h3>
                        <b>เอกสารที่ขอแก้ไข :</b>
                      </h3>
                    </p>
                  </td>
                  <td align="left">
                    <button id="submit" onClick={getData}>
                      ลบไฟล์ (Delete Files)
                    </button>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div id="fileList"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div id="fileAll"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lineFriend: state.auth.lineFriend,
    telNo: state.auth.telNo,
    mailTo: state.auth.mailTo,
  };
};

export default connect(mapStateToProps, null)(Consent);
