import React, { Fragment } from "react";
import { Button } from "primereact/button";
import CheckImage from "../../../assets/image/check.png";

import { useHistory } from "react-router-dom";

const SignupResult = (props) => {
  const history = useHistory();

  const onClickButton = () => {
    history.push({ pathname: "/agreements" });
  };
  return (
    <Fragment>
      <div
        className="col-12 sm:col-12 md:col-8 md:col-offset-2 
                  lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2 mt-6 px-6"
      >
        <center>
          <img
            src={CheckImage}
            style={{ width: "128px", height: "128px" }}
            alt="success"
          ></img>

          <p
            className="text-bold ask__font__title"
            style={{ color: "#EC2323", marginTop: "4rem" }}
          >
            การลงทะเบียนเสร็จสิ้นแล้ว
          </p>
        </center>
        <div style={{ marginTop: "8rem" }}></div>

        <div className="grid justify-content-center mt-6">
          <Button
            label="กลับไปหน้า สัญญาของฉัน"
            className="col-5 sm:col-5  xl:col-3
                p-button-success mt-6 p-3"
            style={{
              borderRadius: "0px",
              backgroundColor: "#ff9600",
            }}
            onClick={onClickButton}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SignupResult;
