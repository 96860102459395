import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import OTPInput from "otp-input-react";
import Loading from "../UI/Loading/Loading";
import { API_MYASK } from "./../../shared/constant";
import { updateObject } from "./../../shared/utility";

const ConfirmEmailEtax = (props) => {
  const [refId, setRefId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [OTP, setOTP] = useState("");
  const [errorMsgOTP, setErrorMsgOTP] = useState("");
  const [checkingOTP, setCheckingOTP] = useState(false);

  useEffect(() => {
    if (props.email != "" && props.email != null) {
      sendMailOTP(props.email);
    }
  }, [props.email]);

  const sendMailOTP = (email) => {
    if (sessionStorage.getItem("from_cs") === "true") {
      setLoading(false);
      return;
    }

    setLoading(true);
    axios
      .post(
        API_MYASK + "/sentMailOtp",
        {
          email_to: email,
          owner: sessionStorage.getItem("userName"),
          subject: "Confirm your email",
          second_expired: 300,
          template: 3,
          option_password: {
            length_digit: 6,
          },
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.code == 200) {
          setRefId(res.data.ref_id);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
      });
  };

  const checkOTP = () => {
    if (sessionStorage.getItem("from_cs") === "true") {
      props.onNextPage();
      return;
    }
    setCheckingOTP(true);
    setErrorMsgOTP("");

    axios
      .post(API_MYASK + "/registerEtax", {
        ref_id: refId,
        email: props.email,
        password: OTP,
        username: sessionStorage.getItem("userName"),
        token: sessionStorage.getItem("token"),
      })
      .then((res) => {
        setCheckingOTP(false);
        if (res.data.CODE == 200) {
          props.onNextPage();
        } else {
          setErrorMsgOTP(res.data.MSG);
        }
      })
      .catch((err) => {
        setCheckingOTP(false);
        setErrorMsgOTP(err);
      });
  };

  let otpInput = null;
  if (
    (refId != null && loading == false) ||
    sessionStorage.getItem("from_cs") === "true"
  ) {
    otpInput = (
      <OTPInput
        className="justify-content-center"
        value={OTP}
        onChange={setOTP}
        autoFocus
        OTPLength={6}
        otpType="number"
        disabled={false}
        inputStyles={{
          marginRight: "1rem",
          height: "3.5rem",
          width: "3.5rem",
          fontSize: "2rem",
          border: "none",
          borderBottom: "1px solid #ccc",
        }}
      />
    );
  } else if (loading == true) {
    otpInput = (
      <center>
        <Loading />
      </center>
    );
  }
  return (
    <Fragment>
      <div
        className="col-12 sm:col-12 md:col-8 md:col-offset-2 
                lg:col-8 lg:col-offset-2 xl:col-8 xl:col-offset-2 mt-6 px-6"
      >
        <center>
          <p className="ask__font__title">
            ขณะนี้ ระบบได้ส่งอีเมลล์ {props.email} ตามที่ท่านได้ระบุไว้
          </p>
          <div style={{ marginTop: "4rem" }}></div>
          <p className="ask__font__title">
            กรุณาเข้าอีเมลล์ของท่าน นำเลขรหัส 6
            หลักที่ได้รับจากอีเมลล์ที่ท่านได้รับ
            กรอกลงในช่องด้านล่างเพื่อทำการยืนยัน
          </p>
        </center>
        <div style={{ marginTop: "8rem" }}></div>
        <div
          className="col-12 sm:col-12 md:col-6 md:col-offset-3 
                lg:col-6 lg:col-offset-3 xl:col-6 xl:col-offset-3 
                my-6 mt-6 ask__font__title"
        >
          {otpInput}
        </div>
        <Dialog
          header="กำลังตรวจสอบรหัส..."
          visible={checkingOTP}
          style={{ width: "50vw" }}
          closeOnEscape={false}
          closable={false}
          onHide={() => {}}
        >
          <center>
            <Loading></Loading>
          </center>
        </Dialog>
        {errorMsgOTP !== "" && (
          <center>
            <p className="ask__font__title" style={{ color: "#EC2323" }}>
              {errorMsgOTP}
            </p>
          </center>
        )}

        <div style={{ marginTop: "8rem" }}></div>

        <div className="grid justify-content-between mt-6">
          <Button
            label="ย้อนกลับ"
            className="col-4 sm:col-4  xl:col-3
            p-button-outlined  mt-6 p-3"
            style={{
              border: "0.1rem solid",
              borderRadius: "0.5rem",
              borderColor: "#ff9600",
              backgroundColor: "#ffffff",
              color: "#ff9600",
              boxShadow: "",
            }}
            onClick={() => props.onPrevPage()}
          />
          <Button
            disabled={
              (OTP.length < 6 || checkingOTP) &&
              sessionStorage.getItem("from_cs") != "true"
            }
            label="ยืนยัน"
            className="col-4 sm:col-4  xl:col-3
            p-button-success mt-6 p-3"
            style={{
              borderRadius: "0px",
              backgroundColor: "#ff9600",
            }}
            onClick={checkOTP}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmEmailEtax;
