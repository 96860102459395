import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../Apply/Apply.css';
import Header from './../../UI/Header/Header.js';
import mp from './../../../assets/main/main-payment.jpeg';
import Button from '../../UI/Button/Button';
import Paper from '../../UI/Paper/Paper';


const ManageAgreementPaymentList = (props) => {
    const topic = {
        marginTop: '2rem',
    }
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <center>
            <Grid item xs={12}>
                <Header img={mp} textHeader="ชำระค่างวด" />
            </Grid>            
            <br/>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Paper padding="1.5rem" align="left" border="1px solid" borderColor="#001F3F" width="90%" borderRadius="0px" color="#001F3F"
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <p style={{ color: 'white' }}>ยอดชำระรวม</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right text-bold" style={{ color: 'white' }}>{numberWithCommas(parseFloat(Math.round(props.amount * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={9}>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <Button btnType="submit" width="100%" children="ชำระเงิน" borderRadius="0px" clicked={props.onSelectPayment}></Button>
                            </Grid>
                        </Grid>
                    } />

            </Grid>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <br />
            {props.children}

        </center>
    );
}

export default ManageAgreementPaymentList;
