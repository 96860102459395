import React from "react";
import ProductDetail from "../../components/Product/ProductDetail";
import axios from "axios";
import { updateObject } from "../../../src/shared/utility.js";
import { API_MYASK } from "../../shared/constant";

import p_hpnew from "../../assets/p_hpnew.jpg";
import p_hpused from "../../assets/p_hpused.jpg";
import p_cl from "../../assets/p_cl.jpg";
import p_pl from "../../assets/p_pl.jpg";
import p_shb from "../../assets/p_shb.jpg";
import p_cml from "../../assets/p_cml.jpg";

const ProductDetails = (props) => {
  const [productForm, setProductForm] = React.useState({
    products: [],
    error: null,
  });

  const [productid, setProductid] = React.useState(
    props.location.product_id || sessionStorage.getItem("product_id")
  );
  const [name, setName] = React.useState(
    props.location.name || sessionStorage.getItem("name")
  );
  const [descrip, setDescrip] = React.useState(
    props.location.descrip || sessionStorage.getItem("descrip")
  );
  const [title, setTitle] = React.useState(
    props.location.title || sessionStorage.getItem("title")
  );
  const [color] = React.useState(
    props.location.color || sessionStorage.getItem("color")
  );
  const [borderColor] = React.useState(
    props.location.borderColor || sessionStorage.getItem("borderColor")
  );
  const [image, setImage] = React.useState(
    props.location.image || sessionStorage.getItem("image")
  );
  const [url, setUrl] = React.useState(props.location.url_product);
  const [loading, setLoading] = React.useState(false);
  // const [image, setImage] = React.useState('');

  React.useEffect(() => {
    window.scrollTo(0, 0);

    let id;
    setLoading(true);
    if (props.match.params.product === "hire-purchase-new") {
      id = "HP-NEW";
      setUrl("hire-purchase-new");
    } else if (props.match.params.product === "hire-purchase-used") {
      id = "HP-USED";
      setUrl("hire-purchase-used");
    } else if (props.match.params.product === "sale-and-hire-purchase-back") {
      id = "SHB";
      setUrl("sale-and-hire-purchase-back");
    } else if (props.match.params.product === "car-loan") {
      id = "CL";
      setUrl("car-loan");
    } else if (props.match.params.product === "personal-loan") {
      id = "PL";
      setUrl("personal-loan");
    } else if (props.match.params.product === "commercial-loan") {
      id = "CML";
      setUrl("commercial-loan");
    }

    if (sessionStorage.getItem("name") === null || id) {
      axios.get(API_MYASK + "/Products?id=" + id).then((res) => {
        let img;
        let data = res.data.LIST_DATA[0];
        if (data.image === "hp_new") {
          img = p_hpnew;
        } else if (data.image === "hp_used") {
          img = p_hpused;
        } else if (data.image === "cl") {
          img = p_cl;
        } else if (data.image === "pl") {
          img = p_pl;
        } else if (data.image === "shb") {
          img = p_shb;
        } else if (data.image === "cml") {
          img = p_cml;
        }
        const updatedState = updateObject(productForm, {
          products: {
            product_id: data.product_id,
            name: data.name_th,
            title: data.title_th,
            descrip: data.desc_th,
            color: data.color,
            borderColor: data.borderColor,
            image: img,
          },
        });
        setProductForm(updatedState);
        setName(updatedState.products.name);
        setTitle(updatedState.products.title);
        setDescrip(updatedState.products.descrip);
        setImage(updatedState.products.image);
        setProductid(updatedState.products.product_id);

        sessionStorage.setItem("product_id", updatedState.products.product_id);
        sessionStorage.setItem("name", updatedState.products.name);
        sessionStorage.setItem("descrip", updatedState.products.descrip);
        sessionStorage.setItem("title", updatedState.products.title);
        sessionStorage.setItem("image", updatedState.products.image);
        if (res.data.CODE === "200") {
          setLoading(false);
        }
      });
    }
  }, [props.match.params.product]);

  React.useEffect(() => {
    sessionStorage.setItem("product_id", productid);
    sessionStorage.setItem("name", name);
    sessionStorage.setItem("descrip", descrip);
    sessionStorage.setItem("title", title);
    sessionStorage.setItem("color", color);
    sessionStorage.setItem("borderColor", borderColor);
    sessionStorage.setItem("image", image);
  }, [productid]);

  const onSelectApply = (product_id) => {
    props.history.push({
      pathname: "/apply/" + url,
      product_id: product_id,
    });
  };

  return (
    <div className="ask__font__main">
      <ProductDetail
        product_id={productid}
        name={name}
        descrip={descrip}
        title={title}
        color={color}
        borderColor={borderColor}
        image={image}
        loading={loading}
        onClicked={() => onSelectApply(productid)}
      ></ProductDetail>
    </div>
  );
};

export default ProductDetails;
