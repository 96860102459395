import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from './../../../components/UI/Button/Button';
// import Description from '../description';
import '../../Apply/Apply.css'
import Header from './../../UI/Header/Header.js';
import mii from './../../../assets/main/main-insuranceinfo2.jpeg';

const ManageAgreementInsurance = (props) => {
    const topic = {
        marginTop: '2rem',
    }
    return (
        <div>
            <Grid item xs={12}>
                <Header img={mii} textHeader="ข้อมูลประกัน" />
            </Grid>
            <div className="branchpanel" style={{ paddingTop: '1rem' }}>
                {props.count
                    ? <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={7}>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <div style={{ padding: '1rem 0rem 0rem 0rem' }}>
                                <Button btnType="normal" width="100%" children="จ่ายค่าประกันรวม" borderRadius="0px" border="0px" clicked={props.onSelectPayment}></Button>
                            </div>
                        </Grid>
                        <Grid item xs={false} sm={false} md={3}>
                        </Grid>
                        {/* <Grid item xs={false} sm={1} md={1}>
                </Grid> */}
                        <Grid item xs={12}>
                            <center>
                                {props.children}
                            </center>
                        </Grid>
                        {/* <Grid item xs={false} sm={1} md={1}>
                </Grid> */}
                    </Grid >
                    : <Grid container spacing={2}>
                        <Grid item xs={12}><center><p>ไม่พบข้อมูล</p></center></Grid>
                    </Grid>
                }
            </div>
        </div >
    );
}

export default ManageAgreementInsurance;
