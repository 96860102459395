import React, { Fragment, useEffect, useState } from "react";
import "./forgot.css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Input from "./../../../components/UI/Input/Input";
import Loading from "./../../../components/UI/Loading/Loading";
import {
  updateObject,
  checkValidity,
  checkError,
} from "./../../../shared/utility";
import axios from "axios";
import { API_MYASK, API_MYASK_HEADER } from "../../../shared/constant";
import NewOTP from "../../newotp/newotp";
import ForgotResult from "./forgotresult";

const Forgot = (props) => {
  const [usernameRes, setUsernameRes] = useState("");
  const [passwordRes, setPasswordRes] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [page, setPage] = useState(1);
  const [forgotForm, setForgotForm] = useState({
    form: {
      idcard: {
        order: "0",
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "a-z,A-Z,0-9,@-_.",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
        textInvalid: "",
        label: "เลขบัตรประชาชน / เอกสารแสดงตน",
        labelError: "เลขบัตรประชาชน / เอกสารแสดงตน",
      },
    },
    formIsValid: false,
    textError: "",
    chkError: false,
  });

  const onChangeNumber = (val) => {
    const pattern = /^\d+$/;
    const isValid = pattern.test(val);
    if (isValid || val === "") {
      return val;
    } else {
      return val.substring(val.length - 1, 0);
    }
  };

  const onChangeHandler = (val, id) => {
    // if (id === "idcard") {
    //   val = onChangeNumber(val);
    // }
    const validate = checkValidity(val, forgotForm.form[id].validation);

    let text = checkError(
      validate.code,
      forgotForm.form[id].label,
      forgotForm.form[id].validation
    );

    let updatedFormElement;

    if (id === "idcard") {
      if (val === "") {
        updatedFormElement = updateObject(forgotForm.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: false,
        });
      } else {
        updatedFormElement = updateObject(forgotForm.form[id], {
          value: val,
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
      }
    }

    let updatedForm;
    updatedForm = updateObject(forgotForm.form, {
      [id]: updatedFormElement,
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }

    const updatedState = updateObject(forgotForm, {
      form: updatedForm,
      formIsValid: formIsValid,
      formCheckErr: false,
    });
    setForgotForm(updatedState);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkUsernameByIdCard = () => {
    const data = {
      id_card: forgotForm.form.idcard.value,
    };
    setIsLoding(true);
    axios
      .post(API_MYASK + "/checkExistUser", data, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        setIsLoding(false);
        if (res.data.CODE === "200") {
          goToOtpPage();
        } else {
          setErrorMsg(res.data.MSG);
        }
      })
      .catch((err) => {
        setIsLoding(false);
        setErrorMsg(err);
      });
  };

  const onGetUserPasswordByIdCard = (mobile_no, ref_otp, pwd_otp) => {
    let data = {
      id_card: forgotForm.form.idcard.value,
      mobile_no: mobile_no,
      ref_otp: ref_otp,
      pwd_otp: pwd_otp,
    };

    setIsLoding(true);
    axios
      .post(API_MYASK + "/getUserPassword", data, {
        headers: API_MYASK_HEADER,
      })
      .then((res) => {
        setIsLoding(false);
        if (res.data.CODE == "200") {
          const username = res.data.DATA.username;
          const password = res.data.DATA.password;
          setUsernameRes(username);
          setPasswordRes(password);
          goToResultPage();
        } else {
          setErrorMsg(res.data.MSG);
        }
      })
      .catch((err) => {
        setIsLoding(false);
        setErrorMsg(err.message);
      });
  };

  const checkedValidate = () => {
    if (!forgotForm.formIsValid) {
      let updatedForm;
      let arrayObj = [];
      let updatedFormElement;
      let text;
      for (let field in forgotForm.form) {
        const validate = checkValidity(
          forgotForm.form[field].value,
          forgotForm.form[field].validation
        );
        text = checkError(
          validate.code,
          forgotForm.form[field].label,
          forgotForm.form[field].validation
        );
        updatedFormElement = updateObject(forgotForm.form[field], {
          valid: validate.isValid,
          textInvalid: text,
          touched: true,
        });
        arrayObj.push(updatedFormElement);
      }

      let arrayForgot = [];
      for (let i in arrayObj) {
        if (arrayObj[i].order === "0") {
          arrayForgot[0] = arrayObj[i];
        }
      }
      updatedForm = updateObject(forgotForm.form, {
        idcard: arrayForgot[0],
      });
      let updatedState = updateObject(forgotForm, {
        form: updatedForm,
        chkError: true,
      });
      setForgotForm(updatedState);
    } else {
      checkUsernameByIdCard();
    }
  };

  const submitHandler = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    checkedValidate();
  };

  const goBackForgotForm = () => {
    setPage(1);
  };

  const goToOtpPage = () => {
    setPage(2);
  };

  const goToResultPage = () => {
    setPage(3);
  };

  return (
    <div className="forgot__container">
      <Dialog visible={isLoding} style={{ width: "60vw" }}>
        <center>
          <Loading />
        </center>
      </Dialog>
      <Dialog
        visible={errorMsg != ""}
        style={{ width: "80vw" }}
        onHide={() => setErrorMsg("")}
        dismissableMask
      >
        <center>
          <p className="mt-4" style={{ color: "red", fontSize: "1.8rem" }}>
            {errorMsg}
          </p>
          <div className="mt-6">
            <Button label="ตกลง" onClick={() => setErrorMsg("")} autoFocus />
          </div>
        </center>
      </Dialog>
      {(page == 1 || page == 2) && (
        <Fragment>
          <h4 className="forgot__header ask__font__title">
            ลืมผู้ใช้งานและรหัสผ่าน
          </h4>
          {page == 1 && (
            <form onSubmit={submitHandler}>
              <div className="forgot__body ask__font__main">
                <div className="forgot__body__firstname">
                  {forgotForm.textError ? (
                    <label className="text__invalid">
                      {forgotForm.textError}
                    </label>
                  ) : null}
                  <Input
                    className="form-control"
                    elementType="input"
                    elementConfig={forgotForm.form.idcard.elementConfig}
                    type="text"
                    value={forgotForm.form.idcard.value}
                    invalid={!forgotForm.form.idcard.valid}
                    touched={forgotForm.form.idcard.touched}
                    max={forgotForm.form.idcard.maxLength}
                    shouldValidate={forgotForm.form.idcard.validation.required}
                    textInvalid={forgotForm.form.idcard.textInvalid}
                    changed={(event) => {
                      onChangeNumber(event.target.value);
                      onChangeHandler(event.target.value, "idcard");
                    }}
                    label={forgotForm.form.idcard.label}
                    labelPos={"top"}
                    styleLabel={{ width: "100%", padding: "0px" }}
                  />
                </div>
                <div className="forgot__body_submit ask__font__main">
                  <button
                    className="btn-submit"
                    style={{ fontFamily: "Prompt-Regular" }}
                    onClick={submitHandler}
                  >
                    ต่อไป
                  </button>
                </div>
              </div>
            </form>
          )}

          {page == 2 && (
            <div className="signup__body ask__font__main">
              <NewOTP
                goback={goBackForgotForm}
                mode="forgot"
                idcard={forgotForm.form.idcard.value}
                onSubmit={onGetUserPasswordByIdCard}
              />
            </div>
          )}
        </Fragment>
      )}

      {page == 3 && (
        <ForgotResult username={usernameRes} password={passwordRes} />
      )}
    </div>
  );
};
export default Forgot;
