import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../UI/Button/Button';
import Paper from '../UI/Paper/Paper';
import Input from '../UI/Input/Input';
import Link from '@material-ui/core/Link';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import '../Apply/Apply.css';

const description = (props) => {
    // console.log(props)
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const add2 = props.add2 || props.post;
    return (
        <div>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Paper padding="1.5rem" align="left" border="1px solid" width="90%" borderColor="#909090" borderRadius="0px"
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <p className="text-bold">เลขที่สัญญา {props.number}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="text-right"><Link style={{
                                    fontSize: "1.6rem",
                                    color: "#ff9600",
                                }} component="button" variant="body2" onClick={props.onSelectNumber}>
                                    <u>ข้อมูลประกัน</u>
                                </Link></div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="hline" />
                            </Grid>
                            <Grid item xs={12}>
                                <p className="text-bold">ข้อมูลทรัพย์สิน</p>
                            </Grid>
                            {props.cartype
                                ?
                                <Grid item md={12} sm={12} xs={12}>
                                    <p>{props.cartype}</p>
                                </Grid>
                                : null}
                            {props.carbrand || props.description
                                ?
                                <Grid item md={12} sm={12} xs={12}>
                                    <p>{props.carbrand} - {props.description}</p>
                                </Grid>
                                : null}
                            {props.carnumber || props.carcountry
                                ?
                                <Grid item md={12} sm={12} xs={12}>
                                    <p>{props.carnumber} {props.carcountry}</p>
                                </Grid>
                                : null}
                            {!props.cartype && !props.carbrand && !props.description && !props.carnumber && !props.carcountry
                                ? <Grid item md={12} sm={12} xs={12}><p>-</p>
                                </Grid>
                                : null}
                            <Grid item xs={12}>
                                <p className="text-bold">ข้อมูลสัญญา</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ประเภทสินเชื่อ</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.type}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ดอกเบี้ย</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.interest} %</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>วันที่ทำสัญญา</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.firstdate}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ยอดสินเชื่อทั้งหมด/งวด</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{numberWithCommas(parseFloat(Math.round(props.total * 100) / 100).toFixed(2))} บาท / {props.term} งวด</p>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="text-bold">ข้อมูลสินเชื่อ</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ค่างวดต่อเดือน</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{numberWithCommas(parseFloat(Math.round(props.pricepermonth * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ยอดคงเหลือ</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{numberWithCommas(parseFloat(Math.round(props.balance * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>วันที่ครบกำหนด</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.date}</p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={8}>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4}>
                                <div className="text-right"><Link style={{
                                    fontSize: "1.6rem",
                                    color: "#ff9600",
                                }} component="button" variant="body2" onClick={props.onSelectHistory}>
                                    <u> ประวัติการชำระเงิน</u>
                                </Link></div>
                            </Grid>
                        </Grid >
                    }
                />
            </Grid>
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            <br />
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
            {!props.edit ?
                <Grid item xs={12} sm={12} md={6}>
                    <Paper padding="1.5rem" align="left" border="1px solid" width="90%" borderColor="#909090" borderRadius="0px"
                        children={
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <p className="text-bold">ที่อยู่ในการจัดส่งเอกสาร</p>
                                </Grid>
                                <Grid item xs={4}>
                                    <EditIcon style={{ color: "#FF851B", float: "right", width: "50px", height: "30px", cursor: "pointer" }} onClick={props.btnedit} />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="hline" />
                                </Grid>
                                <Grid item xs={12}>
                                    <p className="text-center">{props.add1}</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <p className="text-center">{props.add2} {props.post}</p>
                                </Grid>
                                <Grid item xs={12}>
                                </Grid>
                                <Grid item xs={12}>
                                    <p className="text-center">Email: {props.email}</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <p className="text-center">Tel: {props.mobile_no}</p>
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>
                : <Grid item xs={12} sm={12} md={6}>
                    <Paper padding="1.5rem" align="left" border="1px solid" width="90%" borderColor="#909090" borderRadius="0px"
                        children={
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <p className="text-bold">แก้ไขที่อยู่ในการจัดส่งเอกสาร</p>
                                </Grid>
                                <Grid item xs={4}>
                                    <SaveIcon style={{ color: "#FF851B", float: "right", width: "50px", height: "30px", cursor: "pointer" }} onClick={props.btnedit} />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="hline" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input elementType="input" value={props.add1} changed={() => props.changeadd1} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input elementType="input" value={add2} changed={() => props.changeadd1} />
                                    {/* <p className="text-center">{props.add2} {props.post}</p> */}
                                </Grid>
                                <Grid item xs={12}>
                                    <Input elementType="input" value={props.email} changed={() => props.changeadd1} />
                                    {/* <p className="text-center">Email: {props.email}</p> */}
                                </Grid>
                                <Grid item xs={12}>
                                    <Input elementType="input" value={props.mobile_no} changed={() => props.changeadd1} />
                                    {/* <p className="text-center">Tel: {props.mobile_no}</p> */}
                                </Grid>
                            </Grid>
                        }
                    />
                </Grid>}
            <Grid item xs={false} sm={false} md={3}>
            </Grid>
        </div>
    );
}

export default description;
