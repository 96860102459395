import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '../../UI/Paper/Paper';
import Button from '../../UI/Button/Button';
import Loading from './../../UI/Loading/Loading';
import '../../Apply/Apply.css';

const ManageAgreementHistory = (props) => {

    let form = props.children;
    const topic = {
        marginTop: '2rem',
    }

    const [check, setCheck] = React.useState(0)
    React.useEffect(() => {
        setCheck(props.check);
    }, [props.check]);

    const [Payment, setPayment] = React.useState(false);
    const [Regis, setRegis] = React.useState(false);
    const [Insurance, setInsurance] = React.useState(false);
    const [Etc, setEtc] = React.useState(false);

    // const handleTab = (event) => {
    //     props.onSelected2(event);
    // };

    const handlePayment = () => {
        // handleTab("1");
        setPayment(true);
        setRegis(false);
        setInsurance(false);
        setEtc(false);
        setCheck(1);
    }

    const handleInsurance = () => {
        // handleTab("2");
        setPayment(false);
        setRegis(false);
        setInsurance(true);
        setEtc(false);
    }

    const handleRegis = () => {
        // handleTab("3");
        setPayment(false);
        setRegis(true);
        setInsurance(false);
        setEtc(false);
    }

    const handleEtc = () => {
        // handleTab("4");
        setPayment(false);
        setRegis(false);
        setInsurance(false);
        setEtc(true);
    }

    let classes1;
    let classes2;
    let classes3;
    let classes4;
    if (Payment === true) {
        classes1 = "menuclick";
        classes2 = "menunormal";
        classes3 = "menunormal";
        classes4 = "menunormal";
    }
    else if (Insurance === true || check === 2) {
        classes2 = "menuclick";
        classes1 = "menunormal";
        classes3 = "menunormal";
        classes4 = "menunormal";
    }
    else if (Regis === true || check === 3) {
        classes3 = "menuclick";
        classes1 = "menunormal";
        classes2 = "menunormal";
        classes4 = "menunormal";
    }
    else if (Etc === true || check === 4) {
        classes4 = "menuclick";
        classes3 = "menunormal";
        classes1 = "menunormal";
        classes2 = "menunormal";
    }
    else {
        classes1 = "menuclick";
        classes2 = "menunormal";
        classes3 = "menunormal";
        classes4 = "menunormal";
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <p className="text-bold text-center ask__font__title" style={topic}>ประวัติการชำระเงิน</p>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2}>
            </Grid>
            <Grid item xl={2} lg={2} md={2} xs={12} sm={2}>
                <center><Button btnType={classes1} clicked={() => handlePayment()}>ค่างวด</Button></center>
            </Grid>
            <Grid item xl={2} lg={2} md={2} xs={12} sm={2}>
                <center><Button btnType={classes2} clicked={() => handleInsurance()}>ค่าประกัน</Button></center>
            </Grid>
            <Grid item xl={2} lg={2} md={2} xs={12} sm={2}>
                <center><Button btnType={classes3} clicked={() => handleRegis()}>ค่าทะเบียน</Button></center>
            </Grid>
            <Grid item xl={2} lg={2} md={2} xs={12} sm={2}>
                <center><Button btnType={classes4} clicked={() => handleEtc()}>ค่าบริการอื่นๆ</Button></center>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2}>
            </Grid>

            {props.loading
                ? <Grid item xs={12}>
                    <center><Loading /></center>
                </Grid>
                : <Grid item xs={12}>
                    <center>
                        {props.children.length === 0 ? <p>ไม่พบประวัติชำระเงิน</p>
                            : <div>
                                <Grid item xs={false} sm={false} md={3}>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Paper padding="1.5rem" align="left" border="1px solid" borderColor="#909090" width="90%" borderRadius="0px"
                                        children={form}
                                    />
                                </Grid>

                                <Grid item xs={false} sm={false} md={3}>
                                </Grid>
                            </div>}
                    </center>
                </Grid>
            }
        </Grid>
    );
}

export default ManageAgreementHistory;
