import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../UI/Button/Button';
import Paper from '../UI/Paper/Paper';
import Link from '@material-ui/core/Link';
import ProgressBar from '../UI/ProgressBar/ProgressBar';
import '../Apply/Apply.css';

const info = (props) => {

    // console.log(props)
    // #075796 สินเชื่อรถยนต์
    // #F003C8 สินเชื่อส่วนบุคคล
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let color = "";

    if (props.type === "สินเชื่อส่วนบุคคล") {
        color = "#F003C8";
    }
    else {
        color = "#075796";
    }
    return (
        <Grid item xs={12} sm={12} md={6}>
            <center>
                <Paper margin="1rem" classes="width100" borderRadius="1rem" padding="1.5rem" align="left" border="2px solid" borderColor={color}
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p className="text-bold text-center" style={{ color: color, fontSize: "1.8rem" }}>{props.type}</p>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-bold text-right">เลขที่สัญญา</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-bold">{props.number}</p>
                            </Grid>
                            {props.carcountry || props.carnumber || props.carbrand
                                ? <Grid item xs={12}>
                                    <p className="text-bold  text-center">{props.carbrand} {props.carnumber} {props.carcountry}</p>
                                </Grid>
                                : <Grid item xs={12}>
                                    <p className="text-bold  text-center"><br /></p>
                                </Grid>
                            }
                            <Grid item xs={5}>
                                <div className="text-center"><Link style={{
                                    fontSize: "1.5rem",
                                    color: "#ff9600",
                                }} component="button" variant="body2" onClick={props.onSelectNumber}>
                                    <u>รายละเอียดสัญญา</u>
                                </Link></div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="vline text-center"></div>
                            </Grid>
                            <Grid item xs={5}>
                                <div className="text-center"><Link style={{
                                    fontSize: "1.5rem",
                                    color: "#ff9600",
                                }} component="button" variant="body2" onClick={props.onSelectCloseAcc}>
                                    <u>ปิดบัญชี</u>
                                </Link></div>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="text-bold text-center">สถานะค่างวด</p>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="text-center">{props.date}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <ProgressBar bgcolor="#00FF44" completed={(parseFloat(props.n_term) / parseFloat(props.terms) * 100).toString()} n_term={props.n_term} terms={props.terms} />
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ยอดทั้งหมด</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{numberWithCommas(parseFloat(Math.round(props.ar * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ชำระแล้ว</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{numberWithCommas(parseFloat(Math.round((props.ar - props.balance) * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>คงเหลือ</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{numberWithCommas(parseFloat(Math.round(props.balance * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="hline" />
                            </Grid>
                            <Grid item xs={6}>
                                <p>วันครบกำหนดชำระ</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{props.date}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p>ยอดที่ต้องชำระ</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p className="text-right">{numberWithCommas(parseFloat(Math.round(props.pricepermonth * 100) / 100).toFixed(2))} บาท</p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={8}>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4}>
                                <Button btnType="normal" width="100%" children="จ่ายค่างวด" borderRadius="0px" clicked={props.onSelectQRCode}></Button>
                            </Grid>
                        </Grid >
                    }
                />
            </center>
        </Grid >
    );
}

export default info;
