import React, { useState, useEffect } from "react";
import "./layout.css";
import logo from "./../../assets/logo_banner_no_name.png";
import logo2 from "./../../assets/ic_logo_banner_no_name2.png";
import logoCompany from "./../../assets/logo_company_new.png";
// import logomobile from './../../assets/logoW.PNG';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Grid from "@material-ui/core/Grid";
import Hoc from "../../hoc/hoc";
import Icon from "./../../components/UI/Icon/Icon";
// import { PATH_TEL, PATH_LINE, PATH_MAIL } from './../../shared/constant';
// import { positions } from '@material-ui/system';
import * as actions from "./../../store/action/index";
import { connect } from "react-redux";

const Layout = (props) => {
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const hide = query.get("single");
    if (hide != null && hide === "true") {
      props.setSingle(true);
    }
  }, []);

  const topic = {
    // fontSize: '1.6rem',
    marginTop: "0.5rem",
    marginBottom: "2rem",
  };

  const handleBranch = (region) => {
    props.history.push({
      pathname: "/branch",
      region: region,
    });
  };

  let mail = "mailto:" + props.mailTo;
  let tel = "tel:" + props.telNo;

  const [menuCheck, setMenuCheck] = useState(false);

  return (
    <Hoc>
      <header
        className="header"
        style={{
          height: menuCheck ? "100%" : "5.7rem",
          display: props.single ? "none" : {},
        }}
      >
        <div className="header__container">
          <div className="header__logo">
            <a href="/">
              <img
                src={logoCompany}
                alt="logo"
                className="header__logo__img"
              ></img>
              {/* <img src={logomobile} alt='logo' className="header__logomobile__img"></img> */}
            </a>
          </div>
          {/* <FontAwesomeIcon icon={faBars} className="header__menu-main__toggler" type="checkbox" data-toggle="collapse" data-target="headerLink" aria-controls="headerLink"
                        aria-expanded="false" aria-label="toggle header">
                    </FontAwesomeIcon> */}
          <input
            className="header__menu-main__toggler"
            type="checkbox"
            id="menu-btn"
            checked={menuCheck}
            onChange={() => setMenuCheck(!menuCheck)}
          ></input>
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <div
            className="header__menu__nav"
            id="menu-nav"
            onClick={() => setMenuCheck(false)}
          >
            <div className="header__menu-main">
              <div
                className="header__menu-main__collapse collapse"
                id="headerLink"
              >
                <ul className="header__menu-main__list">
                  <li>
                    <Link to="/" className="header__menu-main__link">
                      หน้าแรก
                    </Link>
                  </li>
                  <li>
                    <div className="line"></div>
                  </li>
                  <li>
                    <Link to="/products" className="header__menu-main__link">
                      ผลิตภัณฑ์
                    </Link>
                  </li>
                  <li>
                    <div className="line"></div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header__menu-login">
              <ul className="header__menu-main__list">
                {/* {!props.isAuthenticated
                                    ? <li></li>
                                    : <li><Link to="/manageagreement-paymentlist" className="header__dropdown__link">ชำระเงิน</Link></li>
                                }
                                {!props.isAuthenticated
                                    ? <li></li>
                                    : <li><Link to="/manageagreement-insurance" className="header__dropdown__link">ข้อมูลประกัน</Link></li>
                                } */}
                {!props.isAuthenticated ? (
                  <li></li>
                ) : (
                  <li>
                    <Link to="/information" className="header__dropdown__link">
                      ข้อมูลของฉัน
                    </Link>
                  </li>
                )}
                {!props.isAuthenticated ? (
                  <li></li>
                ) : (
                  <li>
                    <div className="line"></div>
                  </li>
                )}
                <li>
                  <Link to="/complaint" className="header__menu-main__link">
                    ร้องเรียน
                  </Link>
                </li>
                <li>
                  <div className="line"></div>
                </li>
                <li>
                  <Link to="/consent" className="header__menu-main__link">
                    แบบคำร้องขอใช้สิทธิ์ข้อมูลส่วนบุคคล
                  </Link>
                </li>
                <li>
                  <div className="line"></div>
                </li>
                <li>
                  <Link to="/contact" className="header__menu-main__link">
                    ติดต่อเรา
                  </Link>
                </li>
                <li>
                  <div className="line"></div>
                </li>
                <li>
                  <Link
                    to="/contact__covid19"
                    className="header__menu-main__link"
                  >
                    ติดต่อเรา#Covid19
                  </Link>
                </li>
                <li>
                  <div className="line"></div>
                </li>
                <li>
                  <Link to="/branch" className="header__menu-main__link">
                    ค้นหาสาขา
                  </Link>
                </li>
                <li>
                  <div className="line"></div>
                </li>
                {!props.isAuthenticated ? (
                  <li>
                    <Link to="/login" className="header__dropdown__link">
                      เข้าสู่ระบบ
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link to="/logout" className="header__menu-main__link">
                      ออกจากระบบ
                    </Link>
                  </li>
                )}
                <li>
                  <div className="line"></div>
                </li>
                {/* <li><Link to="" className="header__menu-main__link">V 3.0.3</Link></li> */}
              </ul>
            </div>
          </div>
        </div>
      </header>
      <div
        className="body_container"
        style={{
          top: props.single ? "0" : {},
        }}
      >
        {props.children}
        <div
          className="ask__font__footer"
          style={{
            display: props.single ? "none" : {},
          }}
        >
          <div className="hline" style={{ marginBottom: "3rem" }}></div>
          <div className="footer">
            <Grid container>
              <div className="product__link__footer">
                <p className="ask__font__main" style={{ marginBottom: "1rem" }}>
                  ผลิตภัณฑ์
                </p>
                <ul className="link__footer">
                  <li>
                    <Link
                      to="/product-detail/car-loan"
                      className="text__link__footer"
                    >
                      สินเชื่อทะเบียนรถยนต์-ไม่โอนเล่ม
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/product-detail/commercial-loan"
                      className="text__link__footer"
                    >
                      สินเชื่อเพื่อการพาณิชย์
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/product-detail/hire-purchase-new"
                      className="text__link__footer"
                    >
                      สินเชื่อเช่าซื้อรถใหม่
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/product-detail/hire-purchase-used"
                      className="text__link__footer"
                    >
                      สินเชื่อเช่าซื้อรถใช้แล้ว
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/product-detail/personal-loan"
                      className="text__link__footer"
                    >
                      สินเชื่อส่วนบุคคล
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/product-detail/sale-and-hire-purchase-back"
                      className="text__link__footer"
                    >
                      สินเชื่อทะเบียนรถยนต์-โอนเล่ม
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="contact__link__footer">
                <p className="ask__font__main" style={{ marginBottom: "1rem" }}>
                  ติดต่อเรา
                </p>
                <ul className="link__footer">
                  <li>
                    <a
                      className="text__link__footer"
                      onClick={() => handleBranch("C")}
                    >
                      ภาคกลาง
                    </a>
                  </li>
                  <li>
                    <a
                      className="text__link__footer"
                      onClick={() => handleBranch("E")}
                    >
                      ภาคตะวันออก
                    </a>
                  </li>
                  <li>
                    <a
                      className="text__link__footer"
                      onClick={() => handleBranch("WS")}
                    >
                      ภาคตะวันตก-ภาคใต้
                    </a>
                  </li>
                  <li>
                    <a
                      className="text__link__footer"
                      onClick={() => handleBranch("N")}
                    >
                      ภาคเหนือ
                    </a>
                  </li>
                  <li>
                    <a
                      className="text__link__footer"
                      onClick={() => handleBranch("NE")}
                    >
                      ภาคตะวันออกเฉียงเหนือ
                    </a>
                  </li>
                  <li>
                    <div className="icon__footer__container">
                      <center>
                        <Grid container className="grid_icon">
                          <Grid item>
                            <Icon
                              classes="icon__footer"
                              icon="telephone"
                              path={tel}
                            />
                          </Grid>
                          {/* <Grid item>
                                                    <Icon classes="icon__footer" target="_blank" icon="facebook" path="https://www.facebook.com/ask.shb/" />
                                                </Grid> */}
                          <Grid item>
                            <Icon
                              classes="icon__footer"
                              target="_blank"
                              icon="line"
                              path={props.lineFriend}
                            />
                          </Grid>
                          <Grid item>
                            <Icon
                              classes="icon__footer"
                              icon="mail"
                              path={mail}
                            />
                          </Grid>
                          <Grid item>
                            <Icon
                              classes="icon__footer"
                              icon="map"
                              path="/branch"
                            />
                          </Grid>
                        </Grid>
                      </center>
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
          </div>
          <center style={{ margin: "2rem 0rem" }}>
            <p>copyright 2019 © เอเซียเสริมกิจลีสซิ่ง</p>
          </center>
        </div>
      </div>
    </Hoc>
  );
};

const mapStateToProps = (state) => {
  return {
    lineFriend: state.auth.lineFriend,
    telNo: state.auth.telNo,
    mailTo: state.auth.mailTo,
    single: state.global.single,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSingle: (flag) => dispatch(actions.setSingle(flag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
