import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  tabIndex: 0,
  search: "",
  agreements: null,
  agreementsState: actionTypes.VIEW_STATE.INITIAL,
  agreementsStateMsg: "",
  detail: null,
  detailState: actionTypes.VIEW_STATE.INITIAL,
  detailStateMsg: "",
  insurances: null,
  insurancesState: actionTypes.VIEW_STATE.INITIAL,
  insurancesStateMsg: "",
  paymentsHistory: null,
  paymentsHistoryState: actionTypes.VIEW_STATE.INITIAL,
  paymentsHistoryStateMsg: "",
  closedDetail: null,
  closedDetailState: actionTypes.VIEW_STATE.INITIAL,
  closedDetailStateMsg: "",
};

const setTabAgreementIndex = (state, action) => {
  return updateObject(state, {
    tabIndex: action.tab_index,
  });
};

const filterAgreements = (state, action) => {
  return updateObject(state, {
    search: action.filter,
  });
};

const getAgreementsStart = (state, action) => {
  return updateObject(state, {
    agreements: null,
    agreementsState: actionTypes.VIEW_STATE.LOADING,
    agreementsStateMsg: "",
  });
};

const getAgreementsSuccess = (state, action) => {
  return updateObject(state, {
    run_no: action.run_no,
    agreements: action.agreements,
    agreementsState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getAgreementsFailed = (state, action) => {
  return updateObject(state, {
    agreementsState: actionTypes.VIEW_STATE.ERROR,
    agreementsStateMsg: action.error,
  });
};

const getAgreementDetailStart = (state, action) => {
  return updateObject(state, {
    detail: null,
    detailState: actionTypes.VIEW_STATE.LOADING,
    detailStateMsg: "",
  });
};

const getAgreementDetailSuccess = (state, action) => {
  return updateObject(state, {
    detail: action.detail,
    detailState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getAgreementDetailFailed = (state, action) => {
  return updateObject(state, {
    detailState: actionTypes.VIEW_STATE.ERROR,
    detailStateMsg: action.error,
  });
};

const getInsuranceAgreementStart = (state, action) => {
  return updateObject(state, {
    insurances: null,
    insurancesState: actionTypes.VIEW_STATE.LOADING,
    insurancesStateMsg: "",
  });
};

const getInsuranceAgreementSuccess = (state, action) => {
  return updateObject(state, {
    insurances: action.insurances,
    insurancesState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getInsuranceAgreementFailed = (state, action) => {
  return updateObject(state, {
    insurancesState: actionTypes.VIEW_STATE.ERROR,
    insurancesStateMsg: action.error,
  });
};

const getPaymentAgreementStart = (state, action) => {
  return updateObject(state, {
    paymentsHistory: null,
    paymentsHistoryState: actionTypes.VIEW_STATE.LOADING,
    paymentsHistoryStateMsg: "",
  });
};

const getPaymentAgreementSuccess = (state, action) => {
  return updateObject(state, {
    paymentsHistory: action.payments_history,
    paymentsHistoryState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getPaymentAgreementFailed = (state, action) => {
  return updateObject(state, {
    paymentsHistoryState: actionTypes.VIEW_STATE.ERROR,
    paymentsHistoryStateMsg: action.error,
  });
};

const getClosedAgreementDetailStart = (state, action) => {
  return updateObject(state, {
    closedDetail: null,
    closedDetailState: actionTypes.VIEW_STATE.LOADING,
    closedDetailStateMsg: "",
  });
};

const getClosedAgreementDetailSuccess = (state, action) => {
  return updateObject(state, {
    closedDetail: action.detail,
    closedDetailState: actionTypes.VIEW_STATE.SUCCESS,
  });
};

const getClosedAgreementDetailFailed = (state, action) => {
  return updateObject(state, {
    closedDetailState: actionTypes.VIEW_STATE.ERROR,
    closedDetailStateMsg: action.error,
  });
};

const clearAgreementState = (state, action) => {
  return initialState;
};

const insertPayDataStart = (state, action) => {
  let objAgr = null;
  let index = -1;
  if (state.agreements != null && state.agreements.length > 0) {
    index = state.agreements.findIndex((item) => {
      return item.agr_code == action.agr_code;
    });
    objAgr = { ...state.agreements[index] };
  }

  const newArray = state.agreements.filter((item) => {
    return true;
  });
  objAgr.overdue_amount = null;
  objAgr.installment_i_vat = null;
  objAgr.n_due_i = null;
  objAgr.n_due_i_date = null;
  objAgr.pay_data_status = actionTypes.VIEW_STATE.LOADING;
  newArray.splice(index, 1, objAgr);

  return updateObject(state, {
    agreements: newArray,
  });
};

const insertPayDataSuccess = (state, action) => {
  let objAgr = null;
  let index = -1;
  if (state.agreements != null && state.agreements.length > 0) {
    index = state.agreements.findIndex((item) => {
      return item.agr_code == action.agr_code;
    });
    objAgr = { ...state.agreements[index] };
  }

  const newArray = state.agreements.filter((item) => {
    return true;
  });

  objAgr.overdue_amount = action.data.overdue_amount;
  objAgr.installment_i_vat = action.data.installment_i_vat;
  objAgr.n_due_i = action.data.n_due_i;
  objAgr.n_due_i_date = action.data.n_due_i_date;
  objAgr.items = action.data.items;
  objAgr.pay_data_status = actionTypes.VIEW_STATE.SUCCESS;
  newArray.splice(index, 1, objAgr);

  return updateObject(state, {
    agreements: newArray,
  });
};

const insertPayDataFailed = (state, action) => {
  let objAgr = null;
  let index = -1;
  if (state.agreements != null && state.agreements.length > 0) {
    index = state.agreements.findIndex((item) => {
      return item.agr_code == action.agr_code;
    });
    objAgr = { ...state.agreements[index] };
  }

  const newArray = state.agreements.filter((item) => {
    return true;
  });
  objAgr.overdue_amount = null;
  objAgr.installment_i_vat = null;
  objAgr.n_due_i = null;
  objAgr.n_due_i_date = null;
  objAgr.pay_data_status = actionTypes.VIEW_STATE.ERROR;
  newArray.splice(index, 1, objAgr);

  return updateObject(state, {
    agreements: newArray,
  });
};

const getPaymentDetailOriginStart = (state, action) => {
  let objAgr = null;
  let index = -1;
  if (state.agreements != null && state.agreements.length > 0) {
    index = state.agreements.findIndex((item) => {
      return item.agr_code == action.agr_code;
    });
    objAgr = { ...state.agreements[index] };
  }

  const newArray = state.agreements.filter((item) => {
    return true;
  });
  objAgr.paymentDetailOrigin = null;
  objAgr.paymentDetailState = actionTypes.VIEW_STATE.LOADING;
  objAgr.paymentDetailStateMsg = "";

  newArray.splice(index, 1, objAgr);

  return updateObject(state, {
    agreements: newArray,
  });
};

const getPaymentDetailOriginSuccess = (state, action) => {
  let objAgr = null;
  let index = -1;
  if (state.agreements != null && state.agreements.length > 0) {
    index = state.agreements.findIndex((item) => {
      return item.agr_code == action.agr_code;
    });
    objAgr = { ...state.agreements[index] };
  }

  const newArray = state.agreements.filter((item) => {
    return true;
  });

  objAgr.paymentDetailOrigin = action.paymentDetailOrigin;
  objAgr.paymentDetailState = actionTypes.VIEW_STATE.SUCCESS;
  newArray.splice(index, 1, objAgr);
  return updateObject(state, {
    agreements: newArray,
  });
};

const getPaymentDetailOriginFailed = (state, action) => {
  let objAgr = null;
  let index = -1;
  if (state.agreements != null && state.agreements.length > 0) {
    index = state.agreements.findIndex((item) => {
      return item.agr_code == action.agr_code;
    });
    objAgr = { ...state.agreements[index] };
  }

  const newArray = state.agreements.filter((item) => {
    return true;
  });

  objAgr.paymentDetailState = actionTypes.VIEW_STATE.ERROR;
  objAgr.paymentDetailStateMsg = action.error;
  newArray.splice(index, 1, objAgr);

  return updateObject(state, {
    agreements: newArray,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AGREEMENTS_START:
      return getAgreementsStart(state, action);
    case actionTypes.GET_AGREEMENTS_SUCCESS:
      return getAgreementsSuccess(state, action);
    case actionTypes.GET_AGREEMENTS_FAILED:
      return getAgreementsFailed(state, action);
    case actionTypes.GET_AGREEMENT_DETAIL_START:
      return getAgreementDetailStart(state, action);
    case actionTypes.GET_AGREEMENT_DETAIL_SUCCESS:
      return getAgreementDetailSuccess(state, action);
    case actionTypes.GET_AGREEMENT_DETAIL_FAILED:
      return getAgreementDetailFailed(state, action);
    case actionTypes.GET_INSURANCE_AGREEMENT_START:
      return getInsuranceAgreementStart(state, action);
    case actionTypes.GET_INSURANCE_AGREEMENT_SUCCESS:
      return getInsuranceAgreementSuccess(state, action);
    case actionTypes.GET_INSURANCE_AGREEMENT_FAILED:
      return getInsuranceAgreementFailed(state, action);
    case actionTypes.GET_PAYMENT_AGREEMENT_START:
      return getPaymentAgreementStart(state, action);
    case actionTypes.GET_PAYMENT_AGREEMENT_SUCCESS:
      return getPaymentAgreementSuccess(state, action);
    case actionTypes.GET_PAYMENT_AGREEMENT_FAILED:
      return getPaymentAgreementFailed(state, action);
    case actionTypes.FILTER_AGREEMENTS:
      return filterAgreements(state, action);
    case actionTypes.SET_TAB_AGREEMENT_INDEX:
      return setTabAgreementIndex(state, action);
    case actionTypes.GET_CLOSED_AGREEMENT_DETAIL_START:
      return getClosedAgreementDetailStart(state, action);
    case actionTypes.GET_CLOSED_AGREEMENT_DETAIL_SUCCESS:
      return getClosedAgreementDetailSuccess(state, action);
    case actionTypes.GET_CLOSED_AGREEMENT_DETAIL_FAILED:
      return getClosedAgreementDetailFailed(state, action);
    case actionTypes.CLEAR_AGREEMENT_STATE:
      return clearAgreementState(state, action);
    case actionTypes.INSERT_PAY_DATA_START:
      return insertPayDataStart(state, action);
    case actionTypes.INSERT_PAY_DATA_SUCCESS:
      return insertPayDataSuccess(state, action);
    case actionTypes.INSERT_PAY_DATA_FAILED:
      return insertPayDataFailed(state, action);
    case actionTypes.GET_PAYMENT_DETAIL_ORIGIN_START:
      return getPaymentDetailOriginStart(state, action);
    case actionTypes.GET_PAYMENT_DETAIL_ORIGIN_SUCCESS:
      return getPaymentDetailOriginSuccess(state, action);
    case actionTypes.GET_PAYMENT_DETAIL_ORIGIN_FAILED:
      return getPaymentDetailOriginFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
