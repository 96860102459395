import { put, delay } from "redux-saga/effects";
import axios from "axios";

import * as actions from "../action/index";
import { API_MYASK, API_MYASK_HEADER } from "../../shared/constant";

export function* getPaymentItemSaga(action) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  yield put(actions.getPaymentItemStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");

  const req = {
    user_name: username,
    token: token,
    agr_code: action.agr_code,
    com_code: action.com_code,
    run_no: action.run_no,
  };

  let url = API_MYASK + "/paymentItem";

  try {
    const response = yield axios.post(url, req, {
      cancelToken: source.token,
      headers: API_MYASK_HEADER,
    });
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let paymentItems = [];
      for (let i in response.data.LIST_DATA) {
        let obj = response.data.LIST_DATA[i];
        let item = {
          key: obj.KEY,
          sub_key: obj.SUB_KEY,
          title: obj.TITLE,
          amount: obj.AMOUNT.replaceAll(",", ""),
        };

        paymentItems.push(item);
      }
      yield put(actions.getPaymentItemSuccess(paymentItems));
    } else {
      yield put(actions.getPaymentItemFailed(response.data.message));
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
      source.cancel("Operation canceled by the user.");
    } else {
      if (error.response.data.message != undefined) {
        yield put(actions.getPaymentItemFailed(error.response.data.message));
      } else if (error.message != undefined) {
        yield put(actions.getPaymentItemFailed(error.message));
      } else {
        yield put(actions.getPaymentItemFailed("Error Something wrong."));
      }
    }
  }
}

export function* getFullSummaryPaymentSaga(action) {
  yield put(actions.getFullSummaryPaymentStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");
  const agr_code = action.agr_code;

  let url =
    API_MYASK +
    "/getFullSummaryInstallment?user_name=" +
    username +
    "&agr_code=" +
    agr_code +
    "&token=" +
    token;

  try {
    const response = yield axios.get(url);
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      let detail = null;

      if (response.data.DATA) {
        let obj = response.data.DATA;

        let bType = obj.B_TYPE;
        detail = {
          fin_type: obj.FIN_TYPE,
          b_type: obj.B_TYPE,
          agr_code: obj.AGR_CODE,
          cus_code: obj.CUS_CODE,
          body: obj.BODY,
          brand: obj.BRAND,
          reg_num: obj.REG_NUM,
          reg_province: obj.REG_PROVINCE,
          asset_description: obj.ASSET_DESCRIPTION,
          agr_flg: obj.AGR_FLG,
          terms: obj.TERMS,
          os_terms: obj.OS_TERMS,
          paid_terms: obj.PAID_TERMS,
          original_amount: obj.ORIGINAL_AMOUNT,
          paid_amount: obj.PAID_AMOUNT,
          os_balance: obj.OS_BALANCE,
          disbursement_date: obj.DISBURSEMENT_DATE,
          installment_i_vat: obj.INSTALLMENT_I_VAT,
          n_due_i: obj.N_DUE_I,
          x_condition: obj.X_CONDITION,
          officer: obj.OFFICER,
          scan_code: obj.SCAN_CODE,
          ref_1: obj.REF_1,
          ref_2: obj.REF_2,
          term_arrears: obj.TERM_ARREARS,
          overdue_inst: obj.OVERDUE_INST,
          pastdue_vat: bType === "HP/LS" ? obj.PASTDUE_VAT : null,
          interest_charge: bType === "HP/LS" ? obj.INTEREST_CHARGE : null,
          follow_up_fee: obj.FOLLOW_UP_FEE,
          chq_charge: bType === "HP/LS" ? obj.CHQ_CHARGE : null,
          other: bType === "HP/LS" ? obj.OTHER : null,
          net_rtn_charge: bType === "Loan" ? obj.NET_RTN_CHARGE : null,
          net_direct_debit_charge:
            bType === "Loan" ? obj.NET_DIRECT_DEBIT_CHARGE : null,
          interest_ext: bType === "Loan" ? obj.INTEREST_EXT : null,
          fee_ext: bType === "Loan" ? obj.FEE_EXT : null,
        };
      }

      yield put(actions.getFullSummaryPaymentSuccess(detail));
    } else {
      yield put(actions.getFullSummaryPaymentFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(
        actions.getFullSummaryPaymentFailed(error.response.data.message)
      );
    } else if (error.message != undefined) {
      yield put(actions.getFullSummaryPaymentFailed(error.message));
    } else {
      yield put(actions.getFullSummaryPaymentFailed("Error Something wrong."));
    }
  }
}

export function* clearStatePaymentSaga(action) {
  yield put(actions.clearPaymentState());
}

/* unused 
export function* generateBatchSaga(action) {
  yield put(actions.generateBatchStart());

  const username = yield sessionStorage.getItem("userName");
  const token = yield sessionStorage.getItem("token");
  const runNo = action.runNo;

  let payments = action.payments;

  let paymentsReq = [];

  for (let i in payments) {
    let payment = payments[i];
    let dtArr = [];
    for (let j in payment.payment_detail) {
      let dt = payment.payment_detail[j];
      let obj = {
        offsetting_rule: dt.key,
        sub_off_rule: dt.sub_key,
        amount: dt.amount,
      };
      dtArr.push(obj);
    }
    console.log(payment.agreement);
    let req = {
      run_no: runNo,
      com_code: payment.agreement.com_code,
      agr_code: payment.agreement.agr_code,
      pay_flag: payment.closed == true ? "C" : "U",
      amount: payment.payment_amount,
      list_payment_detail: dtArr,
    };
    paymentsReq.push(req);
  }

  const req = {
    user_name: username,
    token: token,
    list_payment: paymentsReq,
  };

  let url = API_MYASK + "/generateBatch";

  try {
    const response = yield axios.post(url, req, {
      headers: API_MYASK_HEADER,
    });
    if (response.data.CODE === "200" || response.data.CODE === "204") {
      console.log(response.data);
      //yield put(actions.getPaymentItemSuccess(paymentItems));
    } else {
      yield put(actions.generateBatchFailed(response.data.message));
    }
  } catch (error) {
    if (error.response.data.message != undefined) {
      yield put(actions.generateBatchFailed(error.response.data.message));
    } else if (error.message != undefined) {
      yield put(actions.generateBatchFailed(error.message));
    } else {
      yield put(actions.generateBatchFailed("Error Something wrong."));
    }
  }
}
/* unused */
